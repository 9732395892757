/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getSubProcessResultError,
  Priority,
  ResultStatus,
  ResultUpdateRequest,
  SubProcess,
  SubProcessResult,
} from '../model/assessmentDetail.model';
import style from './auditFixList.style';
import { AuditFix } from './AuditFix';
import { saveSubProcessResult, selectAssessment, selectAssessmentStatus } from '../store/assessmentDetailSlice';
import { toastService } from '../../../core/services/toastService';
import { useAppDispatch } from '../../../core/store';
import { STATUS } from '../model/assessmentStatus';
import { fetchFindingSuggestionsForPriority } from '../store/findingSuggestionsSlice';
import { BrandCode } from '../../../shared/model/brand.model';

interface AuditFixListProps {
  subProcesses: SubProcess[];
  onClose: () => void;
}

export const AuditFixList = ({ subProcesses, onClose }: AuditFixListProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [subProcessList, setSubProcessList] = useState(subProcesses);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSubProcess, setCurrentSubProcess] = useState(subProcessList[currentIndex]);
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const assessmentStatus = useSelector(selectAssessmentStatus);
  const assessment = useSelector(selectAssessment);
  const [pastExamplesOpen, setPastExamplesOpen] = useState<boolean>(false);

  const setCurrentResult = useCallback(
    (result: SubProcessResult) =>
      setCurrentSubProcess(prevState => {
        return { ...prevState, result };
      }),
    []
  );

  useEffect(() => {
    if (subProcessList.length === 0) onClose();
  }, [onClose, subProcessList]);

  const getCurrentResultError = useCallback(
    () => getSubProcessResultError(currentSubProcess.result),
    [currentSubProcess]
  );

  const updateCurrentState = useCallback((spList: (SubProcess & { processCode: string })[], increment = 0) => {
    setCurrentIndex(prevState => {
      const newIndex = (prevState + increment) % spList.length;
      if (spList.length > 0) {
        setCurrentSubProcess(spList[newIndex]);
      }
      return newIndex;
    });
  }, []);

  const onSkip = useCallback(() => updateCurrentState(subProcessList, 1), [subProcessList, updateCurrentState]);

  const handleOnSave = useCallback(async () => {
    const request: ResultUpdateRequest = {
      ...currentSubProcess.result,
      processCode: currentSubProcess.processCode,
      subProcessCode: currentSubProcess.code,
    };
    const response = await dispatch(saveSubProcessResult(request));

    setSubProcessList(prevState => {
      const newValue = prevState.filter(v => v.code !== request.subProcessCode);
      updateCurrentState(newValue);
      return newValue;
    });

    if (response != null) toastService.success();
  }, [currentSubProcess, dispatch, updateCurrentState]);

  const confirmationEnable = useMemo(
    () =>
      (currentSubProcess.result.priority === Priority.SIGNIFICANT ||
        currentSubProcess.result.priority === Priority.HIGH) &&
      currentSubProcess.result.attachments.length === 0 &&
      currentSubProcess.result.pictures.length === 0 &&
      assessmentStatus != null &&
      [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessmentStatus.code),
    [
      currentSubProcess.result.attachments.length,
      currentSubProcess.result.pictures.length,
      currentSubProcess.result.priority,
      assessmentStatus,
    ]
  );

  const openPastExamples = useCallback(() => {
    setPastExamplesOpen(true);
    dispatch(
      fetchFindingSuggestionsForPriority(
        currentSubProcess.processCode,
        currentSubProcess.code,
        assessment?.store.brand.code as BrandCode,
        assessment?.store.keringRegion,
        assessment?.store.keringCountry.code
      )
    );
  }, [dispatch, assessment, currentSubProcess]);

  const onSaveClick = () => {
    if (confirmationEnable) {
      setConfirmIsOpen(true);
    } else {
      handleOnSave();
    }
  };

  return (
    <div css={style.container}>
      <div css={style.content}>
        <AuditFix
          subProcess={currentSubProcess}
          onChange={result => setCurrentResult(result)}
          pastExamplesOpen={pastExamplesOpen}
          setPastExamplesOpen={setPastExamplesOpen}
        />
      </div>
      <div css={style.footer}>
        {subProcessList.length > 1 ? (
          <Button css={style.skipButton} icon labelPosition='right' onClick={onSkip}>
            {t(`assessment.skip`)}
            <Icon name='share' />
          </Button>
        ) : (
          <div />
        )}
        <div>
          {currentSubProcess.result.status === ResultStatus.FAILED && (
            <Button css={style.saveButton} icon onClick={openPastExamples}>
              <Icon name='clock' />
              <span style={{ marginLeft: '10px' }}>{t('assessment.checkList.pastExamples')}</span>
            </Button>
          )}
          <Button
            disabled={getCurrentResultError() != null}
            css={style.saveButton}
            icon
            labelPosition='right'
            onClick={onSaveClick}>
            {t(`assessment.save`)}
            <Icon name='angle right' />
          </Button>
        </div>

        <Confirm
          css={style.modalConfirm}
          open={confirmIsOpen}
          onCancel={() => setConfirmIsOpen(false)}
          content={t('assessment.checkList.saveConfirmMessage') || undefined}
          onConfirm={() => {
            setConfirmIsOpen(false);
            handleOnSave();
          }}
          size='small'
        />
      </div>
    </div>
  );
};
