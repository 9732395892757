/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Radio } from 'semantic-ui-react';
import style from './assessmentsBulkUpdateWizard.style';
import {
  AssessmentBulkUpdateWizardAction,
  AssessmentBulkUpdateWizardField,
  AssessmentBulkUpdateWizardStep,
} from '../../model/assessmentBulkUpdate';

interface SelectActionStepProps {
  onClose: () => void;
  setStep: (step: AssessmentBulkUpdateWizardStep) => void;
  action: AssessmentBulkUpdateWizardAction | null;
  setAction: (value: AssessmentBulkUpdateWizardAction | null) => void;
  onConfirm: () => void;
  field: AssessmentBulkUpdateWizardField | null;
}

export const SelectActionStep = ({
  onClose,
  setStep,
  action,
  setAction,
  onConfirm,
  field,
}: SelectActionStepProps): JSX.Element => {
  const { t } = useTranslation();

  const onClickNext = () => {
    switch (action) {
      case AssessmentBulkUpdateWizardAction.FIND_AND_REPLACE:
        setStep(AssessmentBulkUpdateWizardStep.SELECT_VALUE_TO_REPLACE);
        break;
      case AssessmentBulkUpdateWizardAction.CLEAN:
        onConfirm();
        break;
      default:
        setStep(AssessmentBulkUpdateWizardStep.SELECT_NEW_VALUE);
    }
  };

  const onClickPrev = () => {
    setStep(AssessmentBulkUpdateWizardStep.SELECT_FIELD_TO_EDIT);
    setAction(null);
  };

  return (
    <>
      <Modal.Header>
        <div css={style.header}>
          <div>{t('assessment.bulkUpdate.wizard.steps.SELECT_ACTION')}</div>
          <div css={style.headerIcon} onClick={onClose}>
            <Icon className='fas fa-close' />
          </div>
        </div>
      </Modal.Header>

      <Modal.Content css={style.modalContent}>
        <div css={style.radioGroup}>
          {Object.values(AssessmentBulkUpdateWizardAction).map(a => (
            <div css={style.radioButtonSubdescription}>
              <Radio css={style.radioCheckbox} checked={a === action} onClick={() => setAction(a)} />
              <div css={style.radioLabel}>
                <div>{t(`assessment.bulkUpdate.wizard.actions.${a}`)}</div>
                <div css={style.radioSubdescription}>
                  {t(`assessment.bulkUpdate.wizard.actionsSubdescription.${a}`)}
                </div>
              </div>
            </div>
          ))}
        </div>
        {field === AssessmentBulkUpdateWizardField.AUDITORS &&
          !(action == null || action === AssessmentBulkUpdateWizardAction.CLEAN) && (
            <div css={style.disclaimer}>{t('assessment.bulkUpdate.wizard.auditorsDisclaimer')}</div>
          )}
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button css={style.button} onClick={onClickPrev}>
          {t('assessment.bulkUpdate.wizard.buttons.prev')}
        </Button>
        <Button css={style.button} onClick={onClickNext} disabled={action == null}>
          {action === AssessmentBulkUpdateWizardAction.CLEAN
            ? t('assessment.bulkUpdate.wizard.buttons.confirm')
            : t('assessment.bulkUpdate.wizard.buttons.next')}
        </Button>
      </Modal.Actions>
    </>
  );
};
