/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Confirm, Dimmer, Header, Loader, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import { useAppDispatch } from '../../../core/store';
import style from './auditCampaignDetail.style';
import {
  approveCampaignProposals,
  fetchAuditCampaign,
  selectCampaign,
  selectIsFetchingCampaign,
  updateCampaignBrandSchedulers,
  updateCampaignDistributionList,
  updateCampaignName,
} from '../store/auditCampaignDetailSlice';
import { AuditCampaignDetailHeader } from '../components/auditCampaignDetail/AuditCampaignDetailHeader';
import {
  AuditCampaign,
  isCampaignApprovableByAuditorManager,
  isCampaignDeletable,
  isCampaignEditable,
} from '../model/auditCampaign';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { Contact, emptyContact } from '../../users/model/user.model';
import { BrandSchedulersField } from '../components/internalAuditWizard/BrandSchedulersField';
import { DistributionListAssessmentField } from '../../assessments/components/DistributionListAssessmentField';
import { TextInputField } from '../../assessments/components/TextInputField';
import { BrandField } from '../components/internalAuditWizard/BrandField';
import { RegionField } from '../components/internalAuditWizard/RegionField';
import { SimpleField } from '../../../shared/SimpleField';
import { CampaignProposals } from './AuditCampaigns';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import footerStyle from '../components/proposalDetail/footerButtons/detailFooter.style';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../model/genericAuditProposal';
import { BrandCode } from '../../../shared/model/brand.model';
import {
  fetchBrandSchedulers,
  selectBrandSchedulers,
  selectIsFetchingBrandSchedulers,
} from '../store/brandSchedulersSlice';

export const AuditCampaignDetail = (): JSX.Element => {
  const { campaignId = '' } = useParams<{ campaignId: string }>();
  const dispatch = useAppDispatch();
  const campaign = useSelector(selectCampaign);
  const principal = useSelector(selectPrincipal);
  const userCanEdit = campaign != null ? isCampaignEditable(principal, campaign) : false;
  const userCanDelete = campaign != null ? isCampaignDeletable(principal, campaign) : false;
  const isFetchingCampaign = useSelector(selectIsFetchingCampaign);

  useEffect(() => {
    dispatch(fetchAuditCampaign(campaignId));
  }, [dispatch, campaignId]);

  const showApproveButton = useMemo(
    () =>
      checkRole(principal, ROLES.AUDITOR_MANAGER) &&
      campaign != null &&
      campaign.proposals.some(p => p.status === INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED),
    [principal, campaign]
  );

  return isFetchingCampaign ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : campaign ? (
    <>
      <AuditCampaignDetailHeader campaign={campaign} deletable={userCanDelete} />
      <div css={style.component}>
        <div css={style.body}>
          <div css={style.content}>
            <AuditCampaignDetailFields campaign={campaign} canEdit={userCanEdit} />
            <div css={style.proposalsContainer}>
              <div css={style.proposalsTitle}>Proposals</div>
              <CampaignProposals campaign={campaign} />
            </div>
          </div>
        </div>
        {showApproveButton && <AuditCampaignFooter campaign={campaign} />}
      </div>
    </>
  ) : (
    <div css={style.notFound}>Audit Campaign does not exist</div>
  );
};

interface AuditCampaignDetailFieldsProps {
  campaign: AuditCampaign;
  canEdit?: boolean;
}
const AuditCampaignDetailFields = ({ campaign, canEdit = false }: AuditCampaignDetailFieldsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { brand, region, auditDateFrom, auditDateTo, brandSchedulers, distributionList, id, name, owner } = campaign;
  const campaignBandScheduler = useMemo(() => brandSchedulers[0] || emptyContact(), [brandSchedulers]);
  const brandSchedulersOptions = useSelector(selectBrandSchedulers);
  const isFetching = useSelector(selectIsFetchingBrandSchedulers);

  const onCampaignNameUpdate = (campaignName: string) => {
    if (canEdit) {
      dispatch(updateCampaignName(id, campaignName));
    }
  };

  const onCampaignDistributionListUpdate = (distributionListValue: Contact[]) => {
    if (canEdit) {
      dispatch(
        updateCampaignDistributionList(
          id,
          distributionListValue.map(c => c.email)
        )
      );
    }
  };

  const onCampaignBrandSchedulerUpdate = (contact: Contact) => {
    if (canEdit) {
      dispatch(fetchBrandSchedulers({ brand: brand.code, region: region.description }));
      dispatch(updateCampaignBrandSchedulers(id, [contact.email]));
    }
  };

  return (
    <div css={style.campaignDetail}>
      <TextInputField
        icon='folder'
        defaultValue={name}
        editable={canEdit}
        title={t('auditCampaigns.creation.name')}
        placeholder='Name'
        label={{ icon: 'folder' }}
        onEdited={onCampaignNameUpdate}
        mutable
      />
      <SimpleField iconClassName=' fas fa-user-shield' value={owner.name} title={t('auditCampaigns.owner')} />
      <BrandField brandList={[]} defaultValue={brand} />
      <RegionField regionList={[]} defaultValue={region} />
      <BrandSchedulersField
        brandSchedulers={brandSchedulersOptions}
        defaultValue={campaignBandScheduler}
        editable={canEdit}
        onSelect={onCampaignBrandSchedulerUpdate}
        loading={isFetching}
        assessmentBrand={campaign.brand.code as BrandCode}
        assessmentRegion={region.description}
      />
      <DistributionListAssessmentField
        defaultValue={distributionList}
        title={t('auditCampaigns.creation.distributionList') || ''}
        editable={canEdit}
        onEdited={onCampaignDistributionListUpdate}
      />
      <div css={style.campaignDates}>
        <SimpleField
          iconClassName='fas fa-calendar-alt'
          value={auditDateFrom}
          title={t('auditCampaigns.creation.dateFrom')}
        />
        <SimpleField
          iconClassName='fas fa-calendar-alt'
          value={auditDateTo}
          title={t('auditCampaigns.creation.dateTo')}
        />
      </div>
    </div>
  );
};

interface AuditCampaignFooterProps {
  campaign: AuditCampaign;
}
const AuditCampaignFooter = ({ campaign }: AuditCampaignFooterProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const principal = useSelector(selectPrincipal);
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onApproveClick = useCallback(async () => {
    if (isCampaignApprovableByAuditorManager(principal, campaign)) {
      setConfirmOpen(true);
    } else {
      setWarningOpen(true);
    }
  }, [campaign, principal]);

  const onApproveConfirm = useCallback(async () => {
    const campaignApproved = await dispatch(approveCampaignProposals(campaign.id));
    if (campaignApproved) {
      navigate(`/audit-campaigns`);
    }
  }, [campaign.id, dispatch, navigate]);

  return (
    <div css={footerStyle.footerContainer}>
      <Button
        css={footerStyle.fatFooterButtonEnd}
        labelPosition='right'
        icon='forward'
        content={t('auditCampaigns.sendToTheBrand')}
        onClick={onApproveClick}
      />

      <Modal
        css={style.modal}
        onClose={() => setWarningOpen(false)}
        onOpen={() => setWarningOpen(true)}
        open={warningOpen}>
        <Header icon='warning sign' content='Warning' />
        <Modal.Content>
          <p>{t('auditCampaigns.approveWarning')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setWarningOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
      <Confirm
        css={style.modalConfirm}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          onApproveConfirm();
          setConfirmOpen(false);
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </div>
  );
};
