/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { Brand } from '../../../../shared/model/brand.model';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';

interface AssessmentBrandMultiSelectFieldProps {
  options: Brand[];
  defaultValue?: Brand[];
  editable?: boolean;
  onUpdate?: (brand: Brand[]) => void;
}

export function AssessmentBrandMultiSelectField({
  options,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AssessmentBrandMultiSelectFieldProps) {
  const toSelectItem = (item: Brand): SelectItem => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo,
    };
  };

  const toBrand = (item: SelectItem): Brand => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo || '',
    };
  };

  return (
    <ModalMultiSelectField
      icon='copyright'
      defaultValue={defaultValue.map(toSelectItem) || []}
      list={options.map(toSelectItem)}
      title={t('assessment.archive.filters.brands') || ''}
      editable={editable}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(i => toBrand(i)));
        }
      }}
    />
  );
}
