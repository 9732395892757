/** @jsxImportSource @emotion/react */
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import style from '../internalAuditWizard/auditPlanningWizard.style';
import { onStepChange, onStoreSelection, selectStep, WIZARD_STEP } from '../../store/brandAuditProposalWizardSlice';
import { useAppDispatch } from '../../../../core/store';
import { Store } from '../../../../shared/model/store.model';
import { BrandStoreSelectionStep } from './BrandStoreSelectionStep';
import { BrandProposalCreationStep } from './BrandProposalCreationStep';

interface BrandAuditPlanningWizardProps {
  onSuccessfulCreation: () => void;
}

export function BrandAuditPlanningWizard({ onSuccessfulCreation }: BrandAuditPlanningWizardProps): JSX.Element {
  return (
    <div css={style.container}>
      <WizardStep onSuccessfulCreation={onSuccessfulCreation} />
    </div>
  );
}

interface WizardStepProps {
  onSuccessfulCreation: () => void;
}

const WizardStep = ({ onSuccessfulCreation }: WizardStepProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const step = useSelector(selectStep);

  switch (step) {
    case WIZARD_STEP.CREATE_PROPOSAL:
      return (
        <BrandProposalCreationStep
          onSuccessfulCreation={() => {
            onSuccessfulCreation();
          }}
          onBack={() => {
            dispatch(onStepChange(WIZARD_STEP.SELECT_STORE));
          }}
        />
      );
    case WIZARD_STEP.LOADING:
      return (
        <div>
          <Loader size='large' active />
        </div>
      );
    case WIZARD_STEP.SELECT_STORE:
    default:
      return (
        <BrandStoreSelectionStep
          onStoreSelection={(store: Store) => {
            dispatch(onStoreSelection(store));
            dispatch(onStepChange(WIZARD_STEP.CREATE_PROPOSAL));
          }}
        />
      );
  }
};
