import { css, SerializedStyles } from '@emotion/react';
import { StatusCode } from '../model/assessmentStatus';
import style from '../../../shared/global.style';

const header = css`
  display: flex;
  align-items: center;
  background-color: var(--saa-primary-color);
  color: var(--saa-white-color);
`;

const backIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    margin: 0 0 0 10px;
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const assessmentHeader = css`
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 90px);
`;

const dot = (status: StatusCode): SerializedStyles => {
  const color = style.statusColor(status);
  return css`
    && {
      height: 20px;
      width: 20px;
      background-color: ${color};
      border-radius: 50%;
      display: inline-block;
    }
  `;
};

const tabs = css`
  display: flex;
`;

const assessment = css`
  margin-bottom: 10px;
  overflow: auto;
  > div {
    background-color: var(--saa-white-color);
  }
`;

const content = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const rowMultipleField = css`
  display: flex;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

export default {
  header,
  backIcon,
  assessmentHeader,
  dot,
  tabs,
  assessment,
  content,
  rowMultipleField,
};
