import { Brand } from '../../../shared/model/brand.model';

export interface ProposalQueryParams {
  brands: string[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
}

export interface ProposalsFilters {
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
}

export const emptyProposalsFilters = {
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  jdaCodes: [],
};

export const covertFiltersInQueryParams = (filters: ProposalsFilters): ProposalQueryParams => ({
  ...filters,
  brands: filters.brands.map(b => b.code),
});
