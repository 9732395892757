/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { SubProcess, SubProcessError } from '../model/assessmentDetail.model';
import { ModalPage } from '../../../shared/ModalPage';
import { ResultItemField } from './ResultItemField';
import style from './errorResultItem.style';
import { AuditFixList } from './AuditFixList';

interface ErrorResultItemProps {
  subProcess: SubProcess;
  error: SubProcessError;
}

export const ErrorResultItem = ({ subProcess, error }: ErrorResultItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [isReadonlyCheckListOpen, setReadonlyCheckListOpen] = useState(false);

  return isReadonlyCheckListOpen ? (
    <ModalPage onClose={() => setReadonlyCheckListOpen(false)} title={t('assessment.audit.fixListTitle') || ''}>
      <AuditFixList subProcesses={[subProcess]} onClose={() => setReadonlyCheckListOpen(false)} />
    </ModalPage>
  ) : (
    <div css={style.itemColor}>
      <ResultItemField
        onClick={() => setReadonlyCheckListOpen(true)}
        className={error === SubProcessError.MERGE ? 'fas fa-tools' : 'fas fa-hammer'}
        value={t(`assessment.audit.result.error.${error}`)}
      />
    </div>
  );
};
