import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../core/services/toastService';
import { Contact } from '../../users/model/user.model';
import {
  BrandAuditProposal,
  BrandProposalChangeStatusRequest,
  UpdateBrandProposalAuditorsRequest,
  UpdateBrandProposalBrandSchedulersRequest,
  UpdateBrandProposalDateRequest,
  UpdateBrandProposalDistributionListRequest,
  UpdateBrandProposalProposedByBrandDateRequest,
} from '../model/brandAuditProposal';
import { ProposedPeriod } from '../model/genericAuditProposal';

interface BrandAuditProposalDetailSliceState {
  proposal: BrandAuditProposal | null;
  isFetching: boolean;
  isUpdating: boolean;
  error: string;
}

const initialState: BrandAuditProposalDetailSliceState = {
  proposal: null,
  isFetching: false,
  isUpdating: false,
  error: '',
};

export const brandAuditProposalDetailSlice = createSlice({
  name: 'brandAuditProposalDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandAuditProposalDetailSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<BrandAuditProposalDetailSliceState>,
      { payload }: PayloadAction<BrandAuditProposal>
    ) => ({
      ...state,
      isFetching: false,
      proposal: payload,
      error: '',
    }),
    httpError: (state: Draft<BrandAuditProposalDetailSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    updateProposal: (
      state: Draft<BrandAuditProposalDetailSliceState>,
      { payload }: PayloadAction<BrandAuditProposal>
    ) => ({
      ...state,
      proposal: payload,
    }),
    startUpdating: (state: Draft<BrandAuditProposalDetailSliceState>) => ({
      ...state,
      isUpdating: true,
    }),
    finishUpdating: (state: Draft<BrandAuditProposalDetailSliceState>) => ({
      ...state,
      isUpdating: false,
      error: '',
    }),
  },
});

export const { startFetch, finishFetch, httpError, updateProposal, startUpdating, finishUpdating } =
  brandAuditProposalDetailSlice.actions;

export default brandAuditProposalDetailSlice.reducer;

export const fetchBrandAuditProposal =
  (proposalId: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const proposal = await api.getBrandAuditProposal(proposalId);
      dispatch(finishFetch(proposal));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateProposedByBrandDate =
  (proposedPeriods: ProposedPeriod[]): AppThunk =>
  async (dispatch: any, state: any) => {
    const request: UpdateBrandProposalProposedByBrandDateRequest = { proposedPeriods };
    const proposal = state().brandAuditProposalDetail.proposal as BrandAuditProposal;

    try {
      const updatedProposal = await api.updateBrandProposalProposedByBrandDate(proposal.id, request);
      dispatch(updateProposal(updatedProposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateBrandProposalDate =
  (dateFrom: string, dateTo: string): AppThunk =>
  async (dispatch: any, state: any) => {
    const request: UpdateBrandProposalDateRequest = {
      proposedDateFrom: new Date(dateFrom),
      proposedDateTo: new Date(dateTo),
    };
    const proposal = state().brandAuditProposalDetail.proposal as BrandAuditProposal;

    try {
      const updatedProposal = await api.updateBrandProposalDate(proposal.id, request);
      dispatch(updateProposal(updatedProposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const onSelectBrandScheduler =
  (brandScheduler: Contact): AppThunk =>
  async (dispatch: any, state: any) => {
    const request: UpdateBrandProposalBrandSchedulersRequest = {
      brandSchedulers: [brandScheduler.email],
    };

    const proposalId = state().brandAuditProposalDetail.proposal.id as string;
    try {
      const updatedProposal = await api.updateBrandProposalBrandScheduler(proposalId, request);
      dispatch(updateProposal(updatedProposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const onUpdateDistributionList =
  (proposalId: string, distributionList: string[]): AppThunk =>
  async (dispatch: any) => {
    try {
      const request: UpdateBrandProposalDistributionListRequest = { distributionList };
      const updatedProposal = await api.updateBrandProposalDistributionList(proposalId, request);
      dispatch(updateProposal(updatedProposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateBrandProposalAuditors =
  (proposalId: string, auditors: Contact[]): AppThunk =>
  async (dispatch: any) => {
    const request: UpdateBrandProposalAuditorsRequest = {
      auditors: auditors.map(auditor => auditor.email),
    };
    try {
      const proposal = await api.updateBrandProposalAuditors(proposalId, request);
      dispatch(updateProposal(proposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const deleteBrandProposal =
  (proposalId: string): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      await api.deleteBrandAuditProposal(proposalId);
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const brandProposalChangeStatus =
  (
    proposalId: string,
    changeStatusRequest: BrandProposalChangeStatusRequest
  ): AppThunk<Promise<BrandAuditProposal | null>> =>
  async (dispatch: any): Promise<BrandAuditProposal | null> => {
    dispatch(startUpdating());
    try {
      const updatedProposal = await api.brandProposalChangeStatus(proposalId, changeStatusRequest);
      dispatch(updateProposal(updatedProposal));
      toastService.success();
      return updatedProposal;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    } finally {
      dispatch(finishUpdating());
    }
  };

export const selectBrandAuditProposal = (state: RootState) => state.brandAuditProposalDetail.proposal;

export const selectIsFetching = (state: RootState): boolean => state.brandAuditProposalDetail.isFetching;

export const selectIsUpdating = (state: RootState): boolean => state.auditProposalDetail.isUpdating;
