import { css } from '@emotion/react';
import { StatusCode, STATUS } from '../features/assessments/model/assessmentStatus';

const scrollbar = css`
  *::-webkit-scrollbar {
    width: 10px;
  }
  // firefox
  * {
    scrollbar-width: thin;
  }
  @media (max-width: 1200px) {
    *::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }
`;

const subtitleNormal = css`
  && {
    font-size: 1.143rem;
    letter-spacing: 0.007rem;
    line-height: 1.714rem;
    font-weight: 500;
  }
`;

const subtitleSmall = css`
  && {
    font-size: 1rem;
    letter-spacing: 0.007rem;
    line-height: 1.286rem;
    font-weight: 500;
  }
`;

const bodySmall = css`
  && {
    font-size: 1rem;
    letter-spacing: 0.007rem;
    line-height: 1.571rem;
    font-weight: normal;
  }
`;

const bodyNormal = css`
  && {
    font-size: 1.143rem;
    letter-spacing: 0.007rem;
    line-height: 1.714rem;
    font-weight: normal;
  }
`;

const primaryButton = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    min-width: max-content;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const secondaryButton = css`
  ${primaryButton};
  && {
    background-color: var(--saa-primary-color-80);
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color-80);
    }
  }
`;

const smallButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0;
  width: 30px;
  min-width: 30px;
  border-radius: 4px;
  i {
    line-height: 12px;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
`;

const primarySmallButton = css`
  ${primaryButton}
  &&&&&& {
    ${smallButton}
    && {
      background-color: var(--saa-primary-color);
    }
    &&:hover,
    &&:focus {
      background-color: var(--saa-primary-color);
    }
  }
`;

const statusColor = (status: StatusCode): string => {
  switch (status) {
    case STATUS.IN_PROGRESS.code:
      return `var(--saa-assessment-in-progress)`;
    case STATUS.MERGING.code:
      return `var(--saa-assessment-merging)`;
    case STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code:
      return `var(--saa-assessment-under-review-by-regional-manager)`;
    case STATUS.READY_TO_REVIEW.code:
      return `var(--saa-assessment-ready-to-review)}`;
    case STATUS.UNDER_REVIEW.code:
      return `var(--saa-assessment-under-review)}`;
    case STATUS.APPROVED_TO_BE_SENT.code:
      return `var(--saa-assessment-approved-to-be-sent)}`;
    case STATUS.SENT_TO_THE_STORES.code:
      return `var(--saa-assessment-sent-to-the-stores)}`;
    case STATUS.ACTIONS_PLAN_UNDER_REVIEW.code:
      return `var(--saa-assessment-actions-plans-under-review)}`;
    case STATUS.CLOSED.code:
      return `var(--saa-assessment-closed)}`;
    default:
      return '';
  }
};

const modalConfirm = css`
  && {
    max-width: 75%;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    > div {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;

const confirmButton = css`
  &&&& {
    background-color: var(--saa-primary-color);
  }
`;

export default {
  subtitleNormal,
  subtitleSmall,
  bodySmall,
  bodyNormal,
  statusColor,
  scrollbar,
  primaryButton,
  secondaryButton,
  modalConfirm,
  confirmButton,
  primarySmallButton,
};
