/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { ModalSelectField, SelectItem } from '../../../../shared/ModalSelectField';
import { Region } from '../../../../shared/model/region.model';

interface RegionFieldProps {
  defaultValue?: Region;
  regionList: Region[];
  editable?: boolean;
  onSelect?: (region: Region) => void;
  loading?: boolean;
}

export const RegionField = ({
  defaultValue,
  regionList,
  editable = false,
  onSelect,
  loading = false,
}: RegionFieldProps): JSX.Element => {
  const toSelectItem = (item: Region): SelectItem | undefined => {
    if (item)
      return {
        code: item.code,
        description: item.description,
      };
    return undefined;
  };

  const toRegion = (item: SelectItem): Region => {
    return {
      code: item.code,
      description: item.description,
    };
  };

  return (
    <ModalSelectField
      icon='globe'
      defaultValue={defaultValue != null ? toSelectItem(defaultValue) : undefined}
      list={regionList.map((r): SelectItem => toSelectItem(r) as SelectItem)}
      loading={loading}
      title={t('auditCampaigns.creation.region') || ''}
      editable={editable}
      onSelect={(selectedBrand: SelectItem) => {
        if (onSelect) {
          onSelect(toRegion(selectedBrand));
        }
      }}
    />
  );
};
