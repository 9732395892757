import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../core/store';
import { checkValueInclude, groupBy } from '../../../shared/utils';
import api from '../utils/api';
import { BrandAuditProposal } from '../model/brandAuditProposal';
import { BRAND_AUDIT_PROPOSAL_STATUS, ProposedPeriod } from '../model/genericAuditProposal';

interface BrandAuditProposalsSliceState {
  brandProposals: BrandAuditProposal[];
  isFetching: boolean;
  error: string;
  filter: string;
}

const initialState: BrandAuditProposalsSliceState = {
  brandProposals: [],
  isFetching: false,
  error: '',
  filter: '',
};

export const brandAuditProposalsSlice = createSlice({
  name: 'brandProposals',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandAuditProposalsSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<BrandAuditProposalsSliceState>, { payload }: PayloadAction<BrandAuditProposal[]>) => ({
      ...state,
      isFetching: false,
      brandProposals: payload,
      error: '',
    }),
    httpError: (state: Draft<BrandAuditProposalsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setFilter: (state: Draft<BrandAuditProposalsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    addBrandProposal: (
      state: Draft<BrandAuditProposalsSliceState>,
      { payload }: PayloadAction<BrandAuditProposal>
    ) => ({
      ...state,
      brandProposals: [...state.brandProposals, payload],
    }),
  },
});

export const { startFetch, finishFetch, httpError, setFilter, addBrandProposal } = brandAuditProposalsSlice.actions;

export default brandAuditProposalsSlice.reducer;

export const fetchBrandAuditProposals = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const brandProposals = await api.getBrandAuditProposals();
    dispatch(finishFetch(brandProposals));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onFilterChange =
  (filter: string): AppThunk =>
  (dispatch: any) => {
    dispatch(setFilter(filter));
  };

export const selectIsFetching = (state: RootState) => state.brandAuditProposals.isFetching;

export const selectBrandProposals = (state: RootState) => state.brandAuditProposals.brandProposals;
export const selectBrandProposalsFilter = (state: RootState) => state.brandAuditProposals.filter;

const selectFilteredSortedBrandProposals = createSelector(
  selectBrandProposals,
  selectBrandProposalsFilter,
  (proposals, filter) => {
    const filteredProposals = proposals.filter(p => checkProposalFilter(p, filter));
    return filteredProposals.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  }
);

export const selectBrandProposalsGroupedByStatus = createSelector(selectFilteredSortedBrandProposals, proposals => {
  const statusArray = Object.keys(BRAND_AUDIT_PROPOSAL_STATUS);
  const proposalsSortedByStatus = [...proposals].sort((a: BrandAuditProposal, b: BrandAuditProposal) =>
    statusArray.indexOf(a.status) > statusArray.indexOf(b.status) ? 1 : -1
  );
  return groupBy('status')(proposalsSortedByStatus) as Record<BRAND_AUDIT_PROPOSAL_STATUS, BrandAuditProposal[]>;
});

const checkProposalFilter = (proposal: BrandAuditProposal, filterValue: string): boolean => {
  return (
    checkValueInclude(proposal.store.name, filterValue) ||
    checkValueInclude(proposal.store.brand.description, filterValue) ||
    checkValueInclude(proposal.store.jdaCode, filterValue) ||
    checkValueInclude(proposal.store.hfmCode, filterValue) ||
    checkValueInclude(proposal.store.city, filterValue) ||
    checkValueInclude(proposal.status, filterValue) ||
    proposal.auditors.some(auditor => checkValueInclude(auditor.email, filterValue)) ||
    proposal.auditors.some(auditor => checkValueInclude(auditor.name, filterValue)) ||
    proposal.distributionList.some(distributionListValue =>
      checkValueInclude(distributionListValue.email, filterValue)
    ) ||
    checkDateInInterval(proposal.proposedDateFrom, proposal.proposedDateTo, filterValue, proposal.proposedPeriods)
  );
};

const checkDateInInterval = (from: string, to: string, value: string, proposedPeriods: ProposedPeriod[]): boolean => {
  const dateString = value.replace(/[-/]*$/, '');
  const date = new Date(dateString);

  if (proposedPeriods.length > 0) {
    return true;
  }

  if (date.toDateString() === 'Invalid Date') {
    return false;
  }

  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (dateString.length === 4) {
    return fromDate.getFullYear() >= date.getFullYear() && date.getFullYear() <= toDate.getFullYear();
  }
  if (dateString.length === 6 || dateString.length === 7) {
    return (
      fromDate.getFullYear() >= date.getFullYear() &&
      date.getFullYear() <= toDate.getFullYear() &&
      date.getMonth() >= fromDate.getMonth() &&
      date.getMonth() <= toDate.getMonth()
    );
  }

  return date >= fromDate && date <= toDate;
};
