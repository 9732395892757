import { css } from '@emotion/react';
import style from '../../../../shared/global.style';

const container = css`
  background-color: var(--saa-primary-color-10);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const search = css`
  padding: 0 5px 10px 5px;
  &&& :first-child {
    margin: auto;
  }
`;

const store = css`
  &&& {
    cursor: pointer;
    display: flex;
    height: 70px;
    padding: 10px;
    flex-grow: 0;
    border-bottom: solid 1px var(--saa-primary-color-10);
  }
`;

const selectionContainer = css`
  position: relative;
  background-color: var(--saa-white-color);
  overflow: auto;
  flex: 1;
`;

const searchBoxContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const title = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${style.subtitleNormal}
`;

const subTitle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ...${style.subtitleSmall};
    color: var(--saa-primary-color-60)
`;

const titleContainer = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
`;

const emptyMessage = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--saa-primary-color-60);
  font-size: 1.3rem;
  font-weight: 500;
`;

export default {
  container,
  search,
  store,
  selectionContainer,
  title,
  subTitle,
  titleContainer,
  emptyMessage,
  searchBoxContainer,
};
