import { Store } from '../../../shared/model/store.model';
import { Contact } from '../../users/model/user.model';
import { Assessment, Auditor } from '../../assessments/model/assessment';

export enum INTERNAL_AUDIT_PROPOSAL_STATUS {
  DRAFT = 'DRAFT',
  PROPOSED = 'PROPOSED',
  CLOSED = 'CLOSED',
  SCHEDULED = 'SCHEDULED',
  DELETED = 'DELETED',
  REJECTED_BY_AUDITOR_MANAGER = 'REJECTED_BY_AUDITOR_MANAGER',
  APPROVED_BY_AUDITOR_MANAGER = 'APPROVED_BY_AUDITOR_MANAGER',
  REPROPOSED_BY_BRAND = 'REPROPOSED_BY_BRAND',
  REJECTED_BY_BRAND = 'REJECTED_BY_BRAND',
}

export enum BRAND_AUDIT_PROPOSAL_STATUS {
  PROPOSED = 'PROPOSED',
  REPROPOSED_BY_AUDITOR_SCHEDULER = 'REPROPOSED_BY_AUDITOR_SCHEDULER',
  TO_BE_APPROVED = 'TO_BE_APPROVED',
  REJECTED_BY_AUDITOR_MANAGER = 'REJECTED_BY_AUDITOR_MANAGER',
  SCHEDULED = 'SCHEDULED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export interface ProposalNote {
  author: Contact;
  created: string;
  lastUpdate: string;
  content: string;
}
export type ProposalType = 'internal' | 'brand';

export interface GenericAuditProposal {
  id: string;
  store: Store;
  proposedDateFrom: string;
  proposedDateTo: string;
  proposedByBrandDateFrom: string;
  proposedByBrandDateTo: string;
  proposedPeriods: ProposedPeriod[];
  brandSchedulers: Contact[];
  distributionList: Contact[];
  auditors: Auditor[];
  createdBy: Contact;
  createdAt: string;
  note?: ProposalNote;
  brandNote?: ProposalNote;
  auditorNote?: ProposalNote;
  type?: ProposalType;
  status: INTERNAL_AUDIT_PROPOSAL_STATUS | BRAND_AUDIT_PROPOSAL_STATUS;
  assessment?: Assessment;
  passedByBrand?: boolean;
}

export interface ProposedPeriod {
  dateFrom: string;
  dateTo: string;
}
