/** @jsxImportSource @emotion/react */

import { Label } from 'semantic-ui-react';
import React from 'react';
import style from './filterBadge.style';

interface BadgeProps {
  value?: string;
  label: string;
}

export const FilterBadge = ({ value, label }: BadgeProps) => {
  return (
    <Label css={style.badge}>
      <span css={style.badgeTitle}>{label}</span>
      {value && (
        <>
          <span css={style.badgeTitle}>:</span>
          <span css={style.badgeValue}>{value}</span>
        </>
      )}
    </Label>
  );
};
