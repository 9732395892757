import { css, SerializedStyles } from '@emotion/react';
import {
  BRAND_AUDIT_PROPOSAL_STATUS,
  INTERNAL_AUDIT_PROPOSAL_STATUS,
  ProposalType,
} from '../model/genericAuditProposal';

export const proposalStatusColor = (status: INTERNAL_AUDIT_PROPOSAL_STATUS): string => {
  switch (status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return `var(--saa-audit-proposal-proposed)`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.CLOSED:
      return `var(--saa-audit-proposal-closed)`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
      return `var(--saa-audit-proposal-draft)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER:
      return `var(--saa-audit-proposal-approved-by-auditor-manager)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return `var(--saa-audit-proposal-rejected-by-auditor-manager)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DELETED:
      return `var(--saa-audit-proposal-deleted)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return `var(--saa-audit-proposal-rejected-by-brand)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
      return `var(--saa-audit-proposal-reproposed-by-brand)}`;
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return `var(--saa-audit-proposal-scheduled)}`;
    default:
      return '';
  }
};
export const brandProposalStatusColor = (status: BRAND_AUDIT_PROPOSAL_STATUS): string => {
  switch (status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return `var(--saa-audit-proposal-proposed)`;
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
      return `var(--saa-audit-proposal-reproposed-by-auditor-scheduler)`;
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
      return `var(--saa-audit-proposal-to-be-approved)`;
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return `var(--saa-audit-proposal-rejected-by-auditor-manager)}`;
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return `var(--saa-audit-proposal-scheduled)}`;
    case BRAND_AUDIT_PROPOSAL_STATUS.CLOSED:
      return `var(--saa-audit-proposal-closed)`;
    case BRAND_AUDIT_PROPOSAL_STATUS.DELETED:
      return `var(--saa-audit-proposal-deleted)}`;
    default:
      return '';
  }
};

const card = (
  type: ProposalType,
  status: INTERNAL_AUDIT_PROPOSAL_STATUS | BRAND_AUDIT_PROPOSAL_STATUS
): SerializedStyles => {
  const borderColor =
    type === 'internal'
      ? proposalStatusColor(status as INTERNAL_AUDIT_PROPOSAL_STATUS)
      : brandProposalStatusColor(status as BRAND_AUDIT_PROPOSAL_STATUS);
  return css`
    &&&&& {
      text-decoration: none;
      color: var(--saa-primary-color);
      width: 340px;
      border-radius: 0;
      flex-shrink: 0;
      margin: 0 10px 0 0;
      @media (min-width: 1200px) {
        margin-top: 10px;
      }
      border-left: solid 5px ${borderColor};

      :hover {
        && {
          box-shadow: 0 1px 3px 0 ${borderColor}, 0 0 0 1px ${borderColor};
        }
      }
    }
  `;
};

const body = css`
  &&& {
    min-height: 110px;
    padding: 10px;
  }
`;

const header = css`
  &&& {
    display: flex;
    padding: 0;
    flex-grow: 0;
  }
`;
const bodyRow = css`
  display: flex;
  padding-bottom: 5px;
`;

export default {
  body,
  header,
  card,
  bodyRow,
};
