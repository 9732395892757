/** @jsxImportSource @emotion/react */
import { Table, Icon } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import style from './assessmentReportTableHeaderRow.style';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { checkRoles, ROLES } from '../../../auth/model/principal.model';
import { useAppDispatch } from '../../../../core/store';
import { setColumnsWithActiveFilters, setCurrentColumn } from '../../store/assessmentReportResultsSlice';

interface AssessmentReportTableHeaderRowProps {
  sortColumn: string;
  direction: 'ascending' | 'descending' | undefined;
  setFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  columnsWithActiveFilters: string[];
}
export const AssessmentReportTableHeaderRow = ({
  sortColumn,
  direction,
  setFilterModalOpen,
  columnsWithActiveFilters,
}: AssessmentReportTableHeaderRowProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const getSortingDirection = (column: string): 'ascending' | 'descending' | undefined =>
    sortColumn.toLowerCase() === column.toLowerCase() ? direction : undefined;

  const user = useSelector(selectPrincipal);
  const isAuditor = useCallback(() => checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]), [user]);

  const handleColumnClick = (column: string) => {
    setFilterModalOpen(prev => !prev)
   
    if(!columnsWithActiveFilters.includes(column)) {
      dispatch(setColumnsWithActiveFilters([...columnsWithActiveFilters, column]))
    }
    dispatch(setCurrentColumn(column))
  }

  return (
    <Table.Header css={style.header}>
      <Table.Row>
        {isAuditor() && (
          <Table.HeaderCell
            width={2}
            singleLine
            sorted={getSortingDirection('status')}
            onClick={() => handleColumnClick('status')}>
            {t('assessment.report.columns.status')}
            {columnsWithActiveFilters?.includes('status')  && <Icon name="filter" />}
          </Table.HeaderCell>
        )}
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('brand')}
          onClick={() => handleColumnClick('brand')}>
          {t('assessment.report.columns.brand')}
          {columnsWithActiveFilters?.includes('brand')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('region')}
          onClick={() => handleColumnClick('region')}>
          {t('assessment.report.columns.region')}
          {columnsWithActiveFilters?.includes('region')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('country')}
          onClick={() => handleColumnClick('country')}>
          {t('assessment.report.columns.country')}
          {columnsWithActiveFilters?.includes('country')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('city')}
          onClick={() => handleColumnClick('city')}>
          {t('assessment.report.columns.city')}
          {columnsWithActiveFilters?.includes('city')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('hfmCode')}
          onClick={() => handleColumnClick('hfmCode')}>
          {t('assessment.report.columns.hfmCode')}
          {columnsWithActiveFilters?.includes('hfmCode')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('jdaCode')}
          onClick={() => handleColumnClick('jdaCode')}>
          {t('assessment.report.columns.jdaCode')}
          {columnsWithActiveFilters?.includes('jdaCode')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('locationName')}
          onClick={() =>  handleColumnClick('locationName')}>
          {t('assessment.report.columns.locationName')}
          {columnsWithActiveFilters?.includes('locationName')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('year')}
          onClick={() =>  handleColumnClick('year')}>
          {t('assessment.report.columns.year')}
          {columnsWithActiveFilters?.includes('year')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('month')}
          onClick={() =>  handleColumnClick('month')}>
          {t('assessment.report.columns.month')}
          {columnsWithActiveFilters?.includes('month')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('processCode')}
          onClick={() =>  handleColumnClick('processCode')}>
          {t('assessment.report.columns.processCode')}
          {columnsWithActiveFilters?.includes('processCode')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('processDescription')}
          onClick={() => handleColumnClick('processDescription')}>
          {t('assessment.report.columns.processDescription')}
          {columnsWithActiveFilters?.includes('processDescription')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessCode')}
          onClick={() => handleColumnClick('subProcessCode')}>
          {t('assessment.report.columns.subProcessCode')}
          {columnsWithActiveFilters?.includes('subProcessCode')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessDescription')}
          onClick={() => handleColumnClick('subProcessDescription')}>
          {t('assessment.report.columns.subProcessDescription')}
          {columnsWithActiveFilters?.includes('subProcessDescription')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={getSortingDirection('subProcessResultFindings')}
          onClick={() => handleColumnClick('subProcessResultFindings')}>
          {t('assessment.report.columns.subProcessResultFindings')}
          {columnsWithActiveFilters?.includes('subProcessResultFindings')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessResultPriority')}
          onClick={() => handleColumnClick('subProcessResultPriority')}>
          {t('assessment.report.columns.subProcessResultPriority')}
          {columnsWithActiveFilters?.includes('subProcessResultPriority')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('subProcessResultPriority')}
          onClick={() => handleColumnClick('subProcessResultRecommendation')}>
          {t('assessment.report.columns.subProcessResultRecommendation')}
          {columnsWithActiveFilters?.includes('subProcessResultRecommendation')  && <Icon name="filter" />}
        </Table.HeaderCell>
        {isAuditor() && (
          <>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('subProcessScore')}
              onClick={() => handleColumnClick('subProcessScore')}>
              {t('assessment.report.columns.subProcessScore')}
              {columnsWithActiveFilters?.includes('subProcessScore')  && <Icon name="filter" />}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('failedScorePercentage')}
              onClick={() => handleColumnClick('failedScorePercentage')}>
              {t('assessment.report.columns.failedScorePercentage')}
              {columnsWithActiveFilters?.includes('failedScorePercentage')  && <Icon name="filter" />}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('rating')}
              onClick={() => handleColumnClick('rating')}>
              {t('assessment.report.columns.rating')}
              {columnsWithActiveFilters?.includes('rating')  && <Icon name="filter" />}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('successScorePercentage')}
              onClick={() => handleColumnClick('successScorePercentage')}>
              {t('assessment.report.columns.successScorePercentage')}
              {columnsWithActiveFilters?.includes('successScorePercentage')  && <Icon name="filter" />}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              singleLine
              sorted={getSortingDirection('keyBusinessRisks')}
              onClick={() => handleColumnClick('keyBusinessRisks')}>
              {t('assessment.report.columns.keyBusinessRisks')}
              {columnsWithActiveFilters?.includes('keyBusinessRisks')  && <Icon name="filter" />}
            </Table.HeaderCell>
          </>
        )}
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanDescription')}
          onClick={() => handleColumnClick('actionPlanDescription')}>
          {t('assessment.report.columns.actionPlanDescription')}
          {columnsWithActiveFilters?.includes('actionPlanDescription')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisor')}
          onClick={() => handleColumnClick('actionPlanSupervisor')}>
          {t('assessment.report.columns.actionPlanSupervisor')}
          {columnsWithActiveFilters?.includes('actionPlanSupervisor')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanSupervisorRole')}
          onClick={() => handleColumnClick('actionPlanSupervisorRole')}>
          {t('assessment.report.columns.actionPlanSupervisorRole')}
          {columnsWithActiveFilters?.includes('actionPlanSupervisorRole')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanDueDate')}
          onClick={() => handleColumnClick('actionPlanDueDate')}>
          {t('assessment.report.columns.actionPlanDueDate')}
          {columnsWithActiveFilters?.includes('actionPlanDueDate')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpStatus')}
          onClick={() => handleColumnClick('followUpStatus')}>
          {t('assessment.report.columns.followUpStatus')}
          {columnsWithActiveFilters?.includes('followUpStatus')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpResult')}
          onClick={() => handleColumnClick('followUpResult')}>
          {t('assessment.report.columns.followUpResult')}
          {columnsWithActiveFilters?.includes('followUpResult')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpDescription')}
          onClick={() => handleColumnClick('followUpDescription')}>
          {t('assessment.report.columns.followUpDescription')}
          {columnsWithActiveFilters?.includes('followUpDescription')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpSupervisor')}
          onClick={() => handleColumnClick('followUpSupervisor')}>
          {t('assessment.report.columns.followUpSupervisor')}
          {columnsWithActiveFilters?.includes('followUpSupervisor')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpSupervisorRole')}
          onClick={() => handleColumnClick('followUpSupervisorRole')}>
          {t('assessment.report.columns.followUpSupervisorRole')}
          {columnsWithActiveFilters?.includes('followUpSupervisorRole')  && <Icon name="filter" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpDueDate')}
          onClick={() => handleColumnClick('followUpDueDate')}>
          {t('assessment.report.columns.followUpDueDate')}
          {columnsWithActiveFilters?.includes('followUpDueDate')  && <Icon name="filter" />}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
