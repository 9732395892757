/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import * as emailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';
import style from './distributionListAssessmentField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';
import { AdUserDropdownField } from '../../users/components/AdUserDropdownField';
import { AdUser, Contact } from '../../users/model/user.model';

interface AssessmentFieldProps {
  defaultValue?: Contact[];
  editable?: boolean;
  onEdited?: (values: Contact[]) => void;
  title?: string;
}

export function DistributionListAssessmentField({
  defaultValue = [],
  editable = false,
  onEdited = () => null,
  title = '',
}: AssessmentFieldProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [distributionListState, setDistributionListState] = React.useState<Contact[]>(defaultValue);
  const [inputState, setInputState] = React.useState({ name: '', email: '' });
  const { t } = useTranslation();
  const updateValue = () => {
    onEdited(distributionListState);
    setOpen(false);
  };
  const cancelValue = () => {
    setDistributionListState(defaultValue);
    setInputState({ name: '', email: '' });
    setOpen(false);
  };

  const getFieldDescription = useCallback(() => defaultValue.map(c => c.email).join(', '), [defaultValue]);

  const addEmail = () => {
    const state = [...distributionListState];
    state.push(inputState);
    setDistributionListState(state);
    setInputState({ name: '', email: '' });
  };

  const removeEmail = (email: string) => {
    setDistributionListState(distributionListState?.filter(c => c.email !== email));
  };

  const onAdUserChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setInputState({ name: `${selectedUser.firstName} ${selectedUser.familyName}`, email: selectedUser.email });
      // const fullName = `${selectedUser.firstName} ${selectedUser.familyName}`;
      // setName(fullName.trim());
    } else {
      setInputState({ name: '', email: '' });
      // setName('');
    }
  };

  function isValidEmail() {
    return (
      distributionListState != null &&
      inputState.email !== '' &&
      emailValidator.validate(inputState.email) &&
      !distributionListState.includes(inputState)
    );
  }

  useEffect(() => {
    const state = [...defaultValue];
    setDistributionListState(state);
  }, [defaultValue, open, editable]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <Icon name='mail' />
        <div css={style.valueContainer}>
          <span css={style.value}> {getFieldDescription()} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div>
            <div css={style.inputContainer}>
              <AdUserDropdownField
                placeholder={t('users.search') || ''}
                onChange={onAdUserChange}
                // error={!isValidForm && emailErrorMessage !== ''}
                cornerIcon='search'
                value={inputState.email}
                // assessmentBrand={brand || (assessment?.store?.brand?.code as BrandCode)}
              />

              <Button onClick={addEmail} disabled={!isValidEmail()} icon>
                <Icon name='plus'> </Icon>
              </Button>
            </div>
            <div css={style.resultContainer}>
              {distributionListState.map(contact => (
                <div css={style.checkBoxContainer} key={contact.email}>
                  <Icon name='mail' />
                  <div css={style.distributionListMemberInfo}>
                    <div>{contact.email}</div>
                    <div css={style.distributionListMemberName}>{contact.name}</div>
                  </div>
                  <Button icon onClick={() => removeEmail(contact.email)}>
                    <Icon name='remove' />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
