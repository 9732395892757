import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import { ResultStatus } from '../model/assessmentDetail.model';

const result = css`
  &&& {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: solid 1px var(--saa-primary-color-10);
    margin-top: 0;
  }
`;

const resultTitle = css`
  ${globalStyle.bodySmall};

  &&&&& {
    background-color: var(--saa-white-color);
    display: flex;
    font-weight: 600;
    padding: 10px 2px;
    align-items: center;
    cursor: pointer;
    div {
      flex: 1;
    }
    i {
      font-size: 1.3rem;
    }
  }
`;
const resultContent = css`
  &&&&& {
    background-color: var(--saa-primary-color-10);
    margin: 0;
    color: var(--saa-primary-color);
    border-top: solid 2px var(--saa-primary-color-40);
    > div {
      padding: 0;
    }
  }
`;

const resultBoxContainer = css`
  padding: 0 10px;
`;

const resultBoxContainerWithValidation = (status: ResultStatus) => css`
  padding: 0 10px;
  > div {
    ${status === ResultStatus.TO_DO ? 'box-shadow: 0 0 0 2px var(--saa-error-dark)' : ''}
  }
`;

const title = css`
  ${globalStyle.subtitleNormal};
  & {
    font-weight: bold;
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--saa-blue-20);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const copyButtonContainer = css`
  padding: 10px 10px 20px;
`;

const copyButton = css`
  ${globalStyle.secondaryButton}
  &&&& {
    width: 100%;
  }
`;

const picturesListContainer = css`
  padding: 0 10px 10px;
`;

export default {
  result,
  resultTitle,
  resultContent,
  resultBoxContainer,
  title,
  copyButtonContainer,
  copyButton,
  picturesListContainer,
  resultBoxContainerWithValidation,
};
