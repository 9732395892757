/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Dimmer, Loader } from 'semantic-ui-react';
import { t } from 'i18next';
import { useAppDispatch } from '../../../../core/store';
import { Store } from '../../../../shared/model/store.model';
import {
  onStoreFilterChange,
  selectIsFetchingStores,
  selectStoreFilter,
  selectStores,
} from '../../store/brandAuditProposalWizardSlice';
import { BrandImage } from '../../../../shared/BrandImage';
import { SearchBox } from '../../../../shared/SearchBox';
import style from './brandStoreSelectionStep.style';

export const BrandStoreSelectionStep = ({
  onStoreSelection,
}: {
  onStoreSelection: (store: Store) => void;
}): JSX.Element => {
  const stores = useSelector(selectStores);
  const isFetchingStores = useSelector(selectIsFetchingStores);
  const showSelectionContainer = useMemo(
    () => stores.length > 0 || isFetchingStores,
    [stores.length, isFetchingStores]
  );

  return (
    <div css={style.container}>
      <div css={!showSelectionContainer && style.searchBoxContainer}>
        {!showSelectionContainer && <p css={style.emptyMessage}>{t('brandAuditProposals.selectStore')}</p>}
        <StoreSearchBox />
      </div>
      {showSelectionContainer && (
        <div css={style.selectionContainer}>
          {isFetchingStores ? (
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          ) : (
            stores.map(value => <StoreSelection key={value.jdaCode} store={value} onSelect={onStoreSelection} />)
          )}
        </div>
      )}
    </div>
  );
};

interface StoreSelectionProps {
  store: Store;
  onSelect: (store: Store) => void;
}
const StoreSelection = ({ store, onSelect }: StoreSelectionProps): JSX.Element => {
  return (
    <div css={style.store} onClick={() => onSelect(store)}>
      <BrandImage src={store.brand.logo} />
      <div css={style.titleContainer}>
        <span css={style.title}>{store.name}</span>
        <span css={style.subTitle}>{store.jdaCode}</span>
      </div>
    </div>
  );
};

const StoreSearchBox = (): JSX.Element => {
  const filter = useSelector(selectStoreFilter);
  const dispatch = useAppDispatch();

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onStoreFilterChange(value));
  }, 500);

  return (
    <div css={style.search}>
      <SearchBox
        defaultValue={filter}
        onChange={debounced}
        autoFocus
        placeholder={t('brandAuditProposals.searchLocationName') || ''}
      />
    </div>
  );
};
