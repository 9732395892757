/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { ChangeDetail, ChangeType } from '../model/assessment';
import style from './assessmentChangesHistory.style';

interface AssessmentChangesHistoryProps {
  changesHistory: ChangeDetail[];
}

export const AssessmentChangesHistory = ({ changesHistory }: AssessmentChangesHistoryProps): JSX.Element => {
  const { t } = useTranslation();

  const formatDay = useCallback((d: Date): string => {
    return moment(d).format('DD-MM-yyyy');
  }, []);

  const formatHour = useCallback((d: Date): string => {
    return moment(d).format('kk:mm');
  }, []);

  const getChangeIcon = (changeType: ChangeType) => {
    switch (changeType) {
      case ChangeType.STORE_MANAGER:
        return <Icon size='large' className='fas fa-user-tie' />;
      case ChangeType.STORE_MANAGER_STARTING_DATE:
        return <Icon size='large' name='calendar alternate' />;
      case ChangeType.ASSISTANT_STORE_MANAGER:
        return <Icon size='large' className='fas fa-user-tie' />;
      case ChangeType.AUDITORS:
        return <Icon size='large' name='users' />;
      case ChangeType.ACTION_PLAN_COORDINATORS:
        return <Icon size='large' className='fas fa-user-tie' />;
      case ChangeType.DISTRIBUTION_LIST:
        return <Icon size='large' name='mail' />;
      case ChangeType.READ_ONLY_DISTRIBUTION_LIST:
        return <Icon size='large' name='mail' />;
      case ChangeType.OVERVIEW:
        return <Icon size='large' className='fas fa-file-alt' />;
      case ChangeType.INTERNAL_COMMENTS:
        return <Icon size='large' name='comment outline' />;
      case ChangeType.RETAIL_AREA_MANAGER:
        return <Icon size='large' className='fas fa-user-tie' />;
      case ChangeType.RETAIL_PROCEDURE:
        return <Icon size='large' name='shopping bag' />;
      case ChangeType.ENTITY_AUDIT:
        return <Icon size='large' name='user secret' />;
      case ChangeType.FINDING:
        return <Icon size='large' name='clipboard list' />;
      case ChangeType.ACTION_PLAN:
        return <Icon size='large' name='clipboard list' />;
      case ChangeType.FOLLOW_UP:
        return <Icon size='large' name='clipboard check' />;
      case ChangeType.TOPICS_FOR_DISCUSSION:
        return <Icon size='large' name='comments' />;
      case ChangeType.STATUS_CHANGE:
        return <Icon size='large' name='exchange' />;
      case ChangeType.AUDIT_FIELDWORK:
        return <Icon size='large' name='calendar alternate' />;
      case ChangeType.STORE_EMPLOYEES:
        return <Icon size='large' name='pencil' />;
      case ChangeType.ASSESSMENT_CREATION:
        return <Icon size='large' name='plus circle' />;
      default:
        return <Icon size='large' name='pencil' />;
    }
  };

  const getChangeLabel = (changeDetail: ChangeDetail): string => {
    const changedField = t(`assessment.fieldsChanged.${changeDetail.type}`);
    const changedValue =
      changeDetail.type === ChangeType.STATUS_CHANGE
        ? t(`assessment.status.${changeDetail.value}`)
        : changeDetail.value;
    if (
      changeDetail.type === ChangeType.FINDING ||
      changeDetail.type === ChangeType.ACTION_PLAN ||
      changeDetail.type === ChangeType.FOLLOW_UP
    )
      return `${changedValue} ${changedField} change`;
    if (changedValue === '') {
      return `${changedField} change`;
    }
    return `${changedField} change: ${changedValue}`;
  };

  const getAuthorTimeLabel = (changeDetail: ChangeDetail): string => {
    return `by ${changeDetail.author.name} on ${formatDay(changeDetail.timestamp)} at ${formatHour(
      changeDetail.timestamp
    )}`;
  };

  return (
    <div css={style.changesHistoryContainer}>
      {changesHistory.map(changeDetail => (
        <div css={style.changeDetailContainer}>
          {getChangeIcon(changeDetail.type)}
          <div css={style.changeDetailText}>
            <b css={style.bold}>{getChangeLabel(changeDetail)}</b>
            <br />
            <i css={style.authorTime}>{getAuthorTimeLabel(changeDetail)}</i>
          </div>
        </div>
      ))}
    </div>
  );
};
