import { css } from '@emotion/react';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const tabContentContainer = css`
  flex-grow: 1;
  overflow: auto;
`;

const displayNone = css`
  display: none;
`;

export default {
  container,
  tabContentContainer,
  displayNone,
};
