import { css, SerializedStyles } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&& {
    max-height: 450px;
    > div {
      padding: 0px;
    }
  }
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
  && {
    padding: 0;
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const validationIcon = css`
  ${style.validationIcon}
`;

const freeTextTitle = (isValid: boolean): SerializedStyles => css`
  ${style.freeTextTitle(isValid)}
`;

const labeledField = css`
  ${style.labeledField}
`;

const labeledEditableField = css`
  ${style.labeledEditableField}
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  actions,
  button,
  checkBoxContainer,
  editIcon,
  validationIcon,
  freeTextTitle,
  labeledField,
  labeledEditableField,
  subTitle,
  valueContainer,
  value,
};
