/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { t } from 'i18next';
import { AuditProposal } from '../model/auditProposal';
import { BrandImage } from '../../../shared/BrandImage';
import style from './auditProposalCardHeader.style';
import { BrandAuditProposal } from '../model/brandAuditProposal';

interface AuditProposalHeaderProps {
  proposal: AuditProposal | BrandAuditProposal;
}

export const AuditProposalCardHeader = ({ proposal }: AuditProposalHeaderProps): JSX.Element => {
  const { store, status, type } = proposal;

  return (
    <div css={style.store}>
      <BrandImage src={store.brand.logo} />
      <div css={style.titleContainer}>
        <span css={style.title} title={store.name}>
          {store.name}
        </span>
        <div css={style.line2}>
          <span css={style.subTitle}>{store.jdaCode}</span>
          <div css={style.status}>
            {proposal.type === 'internal' ? (
              <span css={style.statusLabel}>{t(`auditCampaigns.status.${status}`)}</span>
            ) : (
              <span css={style.statusLabel}>{t(`brandAuditProposals.status.${status}`)}</span>
            )}
            <Icon name='square' css={style.statusIcon(type, status)} />
          </div>
        </div>
      </div>
    </div>
  );
};
