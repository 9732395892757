/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Button, Icon, Input, Modal } from 'semantic-ui-react';
import moment from 'moment';
import style from './yearMonthField.style';
import { useCtrlEnterToRunCallback } from '../core/utils';

interface YearMonthFieldProps {
  defaultYear?: number;
  defaultMonth?: number;
  editable?: boolean;
  onEdited?: (year: number | undefined, month: number | undefined) => void;
  title?: string | null;
  clearable?: boolean;
}

export const YearMonthField = ({
  defaultYear,
  defaultMonth,
  editable = false,
  onEdited = () => null,
  title = '',
  clearable = false,
}: YearMonthFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const today = moment();

  const [yearState, setYearState] = React.useState(defaultYear || today.year());
  const [monthState, setMonthState] = React.useState(defaultMonth || today.month() + 1);

  const updateValue = () => {
    if (yearState != null && monthState != null) {
      onEdited(yearState, monthState);
      setOpen(false);
    }
  };
  const cancelValue = () => {
    setYearState(defaultYear || today.year());
    setMonthState(defaultMonth || today.month() + 1);
    setOpen(false);
  };

  const getFieldDescription = useCallback(() => {
    if (defaultYear == null || defaultMonth == null) {
      return '';
    }
    return `${defaultYear}-${defaultMonth}`;
  }, [defaultYear, defaultMonth]);

  const changeYear = (value: number) => {
    setYearState(yearState + value);
  };

  const changeMonth = (value: number) => {
    setMonthState(monthState + value);
  };

  function canClickButton(value: number, min?: number, max?: number) {
    return (min == null || value > min) && (max == null || value < max);
  }

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div
        title={title as string}
        css={editable ? style.editableField : style.field}
        onClick={() => editable && setOpen(true)}>
        <Icon name='calendar alternate' />
        <div css={style.valueContainer}>
          <span css={style.value}> {getFieldDescription()} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.inputContainer}>
            <Input type='text' value={yearState} disabled>
              <Button type='submit' onClick={() => changeYear(-1)} disabled={!canClickButton(yearState, 0)} icon>
                <Icon name='minus' />
              </Button>
              <input />
              <Button
                type='submit'
                onClick={() => changeYear(1)}
                disabled={!canClickButton(yearState, undefined, 3000)}
                icon>
                <Icon name='plus' />
              </Button>
            </Input>
            <Input type='text' value={monthState} disabled>
              <Button type='submit' onClick={() => changeMonth(-1)} disabled={!canClickButton(monthState, 1)} icon>
                <Icon name='minus' />
              </Button>
              <input />
              <Button
                type='submit'
                onClick={() => changeMonth(1)}
                disabled={!canClickButton(monthState, undefined, 12)}
                icon>
                <Icon name='plus' />
              </Button>
            </Input>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
