import { css } from '@emotion/react';
import style from '../../../../assessments/components/field.style';

const storeDetail = css`
  margin-bottom: 10px;
  background-color: var(--saa-white-color);
`;
const proposalDetail = css`
  background-color: var(--saa-white-color);
`;

const rowMultipleField = css`
  display: flex;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;
const content = css`
  background-color: var(--saa-primary-color-10);
  color: var(--saa-primary-color);
  height: 100%;
`;

const body = css`
  height: calc(100% - 60px);
  overflow: auto;
  margin: 10px 0;
`;

const editIcon = css`
  ${style.editIcon}
`;

const noteContainer = css`
  padding-top: 10px;
`;

const note = css`
  padding-bottom: 10px;
`;

export default {
  body,
  content,
  storeDetail,
  proposalDetail,
  rowMultipleField,
  editIcon,
  noteContainer,
  note,
};
