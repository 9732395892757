/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import {
  fetchActionPlanCoordinators,
  selectActionPlanCoordinators,
  selectIsFetching,
} from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';
import { BrandCoordinator } from '../../assessments/model/assessment';

interface ActionPlanCoordinatorMultiSelectFieldProps {
  filters: AssessmentBulkUpdateFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (value: string[]) => void;
}

export function ActionPlanCoordinatorMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: ActionPlanCoordinatorMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchActionPlanCoordinators(filters));
    }
  }, [dispatch, editable, filters]);
  const { t } = useTranslation();

  const actionPlanCoordinatorList = useSelector(selectActionPlanCoordinators);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: BrandCoordinator | undefined): SelectItem => {
    if (item == null) {
      return { code: '', description: '' };
    }

    return {
      code: item.email,
      description: item.name,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      iconClassName='fas fa-user-tie'
      defaultValue={
        defaultValue != null
          ? defaultValue.map(v => toSelectItem(actionPlanCoordinatorList.find((s: BrandCoordinator) => s.email === v)))
          : []
      }
      loading={isFetching}
      list={actionPlanCoordinatorList.map((b: any): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.bulkUpdate.filters.actionPlanCoordinators') || ''}
      editable={editable}
      onUpdateValue={(selectedStore: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedStore.map(i => toString(i)));
        }
      }}
    />
  );
}
