import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';
import { ProcessScoreRating } from '../model/assessmentDetail.model';
import { AuditScoreRating } from '../model/assessment';

const ratingColor = (rating: AuditScoreRating | ProcessScoreRating) => {
  switch (rating) {
    case AuditScoreRating.P:
    case ProcessScoreRating.P:
    case AuditScoreRating.A:
    case ProcessScoreRating.A:
      return '#5AC66C';
    case AuditScoreRating.B:
    case ProcessScoreRating.B:
      return '#f4f470';
    case AuditScoreRating.C:
    case ProcessScoreRating.C:
      return '#FFC000';
    case AuditScoreRating.D:
    case ProcessScoreRating.D:
      return '#FF5050';
    default:
      return 'var(--saa-primary-color)';
  }
};

const container = css`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const processScoreContainer = css`
  overflow: auto;
  flex: 1;
  margin: 0 0 1em;
`;

const card = css`
  &&& {
    margin: 0 0 1em 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
`;

const processCard = css`
  ${card};
  &&& {
    margin: 1em;
    width: calc(100% - 2em);
  }
`;

const cardTitle = css`
  && {
    ${style.subtitleNormal}
  }
`;

const cardContent = css`
  &&& {
    padding: 0;
    display: flex;
    > div {
      margin: 0.5em 0.5em 0.25em;
    }
    > div:first-child {
      flex-basis: 35%;
    }
    @media (min-width: 1200px) {
      > div:first-child {
        flex: 1;
      }
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }
`;

const totalCardContent = css`
  ${cardContent};
  &&& {
    > div:first-child {
      flex: 1;
    }
  }
`;

const cardField = css`
  flex: 1;
  padding: 0.5em;
  ${style.bodyNormal}
`;

const percentageField = (percentage: number, rating: AuditScoreRating | ProcessScoreRating): SerializedStyles => {
  const color = ratingColor(rating);
  return css`
    background: linear-gradient(to left, var(--saa-blue-20) ${100 - percentage * 100}%, ${color} 0%);
    text-align: center;
    ${cardField};
    height: calc(100% - 14px);
    display: flex;
    > div {
      background-color: var(--saa-white-color);
      border: solid 2px ${color};
      padding: 0 5px;
      font-weight: 500;
      width: 75px;
      border-radius: 20px;
      text-align: center;
      margin: auto;
    }
  `;
};

const ratingField = (rating: AuditScoreRating | ProcessScoreRating): SerializedStyles => {
  const color = ratingColor(rating);
  return css`
    color: var(--saa-primary-color);
    ${cardField};
    && {
      text-align: center;
      background-color: var(--saa-blue-20);
    }
    > div {
      display: flex;
      justify-content: center;
      > div {
        background-color: var(--saa-white-color);
        border: solid 2px ${color};
        font-weight: bold;
        flex-basis: 20%;
        flex-shrink: 0;
        border-radius: 30px;
        text-align: center;
        height: 26px;
        align-self: center;
        max-width: 75px;
      }
      > span {
        font-size: 9px;
        text-align: initial;
        padding-left: 5px;
        box-shadow: inset 2.5px 0px 0px -1px ${color};
        margin-left: 5px;
        font-weight: 600;
        max-width: 250px;
      }
      @media (min-width: 1200px) {
        > span {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  `;
};

const subtitle = css`
  line-height: 14px;
  font-size: 10px;
  text-align: right;
  width: 100%;
  color: var(--saa-primary-color-80);
`;

export default {
  container,
  processScoreContainer,
  card,
  cardTitle,
  cardContent,
  percentageField,
  ratingField,
  processCard,
  subtitle,
  totalCardContent,
};
