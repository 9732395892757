/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../core/store';
import style from './assessmentCreation.style';
import { SelectAvailableStores } from './SelectAvailableStores';
import { StoreHeader } from '../../../shared/StoreHeader';
import { AssessmentCreationRequest, Auditor, BrandCoordinator } from '../model/assessment';
import { AuditorsAssessmentField } from './AuditorsAssessmentField';
import { createAssessment } from '../store/assessmentsSlice';
import { Store } from '../../../shared/model/store.model';
import { onCloseCreation, selectSelectedStore, selectStore, resetSelectedStore } from '../store/onCreationSlice';
import { DistributionListAssessmentField } from './DistributionListAssessmentField';
import { SimpleField } from '../../../shared/SimpleField';
import { toastService } from '../../../core/services/toastService';
import { BrandCoordinatorField } from './BrandCoordinatorField';
import { YearMonthField } from '../../../shared/YearMonthField';
import { BrandCode } from '../../../shared/model/brand.model';
import { Contact } from '../../users/model/user.model';

interface YearMonth {
  year: number;
  month: number;
}

const dateToYearMonth = (date: Date): YearMonth => ({ year: date.getFullYear(), month: date.getMonth() + 1 });

export interface AssessmentCreationFields {
  store: Store;
  yearMonth: YearMonth;
  auditors: Auditor[];
  distributionList: Contact[];
  readOnlyDistributionList: Contact[];
  brandCoordinatorList: BrandCoordinator[];
}

export const AssessmentCreation = (): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const selectedStore = useSelector(selectSelectedStore);
  const [auditors, setAuditors] = useState<Auditor[]>([]);
  const [yearMonth, setYearMonth] = useState<YearMonth>(dateToYearMonth(new Date()));
  const [distributionList, setDistributionList] = useState<Contact[]>([]);
  const [readOnlyDistributionList, setReadOnlyDistributionList] = useState<Contact[]>([]);
  const [brandCoordinatorList, setBrandCoordinatorList] = useState<BrandCoordinator[]>([]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    setYearMonth(dateToYearMonth(new Date()));
    setAuditors([]);
    setDistributionList([]);
    setReadOnlyDistributionList([]);
    setBrandCoordinatorList([]);
  }, [selectedStore]);

  const fields: AssessmentCreationFields = useMemo(
    () => ({
      store: selectedStore as Store,
      yearMonth,
      auditors,
      distributionList,
      readOnlyDistributionList,
      brandCoordinatorList,
    }),
    [selectedStore, yearMonth, auditors, distributionList, readOnlyDistributionList, brandCoordinatorList]
  );

  const create = async () => {
    const request: AssessmentCreationRequest = {
      storeJdaCode: (selectedStore as Store).jdaCode,
      year: yearMonth.year || 0,
      month: yearMonth.month || 0,
      auditorEmails: auditors.map(a => a.email),
      distributionList: distributionList.map(c => c.email),
      readOnlyDistributionList: readOnlyDistributionList.map(c => c.email),
      brandCoordinatorList,
    };
    setIsCreating(true);
    const creationResult = await dispatch(createAssessment(request));
    setIsCreating(false);
    if (creationResult != null) {
      dispatch(onCloseCreation());
      toastService.success();
    }
  };

  const onSelect = useCallback((store: Store) => dispatch(selectStore(store)), [dispatch]);

  const cancel = useCallback(() => dispatch(resetSelectedStore()), [dispatch]);

  const canCreate = () => selectedStore != null;

  return (
    <div css={style.component}>
      {isCreating && (
        <Dimmer active inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      )}
      <div css={style.body}>
        <div css={style.content}>
          {selectedStore == null ? (
            <SelectAvailableStores onStoreSelect={onSelect} />
          ) : (
            <AssessmentCreationForm
              fields={fields}
              onYearMonthChange={setYearMonth}
              onAuditorsChange={setAuditors}
              onDistributionListChange={setDistributionList}
              onReadOnlyDistributionListChange={setReadOnlyDistributionList}
              onBrandCoordinatorListChange={setBrandCoordinatorList}
            />
          )}
        </div>
      </div>
      <div css={style.footer}>
        {selectedStore != null && (
          <Button type='button' css={style.button} onClick={cancel}>
            <Icon name='chevron left' />
            {t('assessment.back')}
          </Button>
        )}

        <Button type='button' css={style.button} onClick={() => create()} disabled={!canCreate()}>
          <Icon name='save' />
          {t('assessment.createAssessment')}
        </Button>
      </div>
    </div>
  );
};

interface AssessmentCreationFormProps {
  fields: AssessmentCreationFields;
  onYearMonthChange: (yearMonth: YearMonth) => void;
  onAuditorsChange: (auditors: Auditor[]) => void;
  onDistributionListChange: (distributionList: Contact[]) => void;
  onReadOnlyDistributionListChange: (readOnlyDistributionList: Contact[]) => void;
  onBrandCoordinatorListChange: (brandCoordinatorList: BrandCoordinator[]) => void;
}
export const AssessmentCreationForm = ({
  fields,
  onYearMonthChange,
  onAuditorsChange,
  onDistributionListChange,
  onReadOnlyDistributionListChange,
  onBrandCoordinatorListChange,
}: AssessmentCreationFormProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <div css={style.storeDetail}>
        <StoreHeader store={fields.store} />
        <AssessmentStore store={fields.store} />
      </div>
      <div css={style.assessmentDetail}>
        <YearMonthField
          defaultYear={fields.yearMonth.year}
          defaultMonth={fields.yearMonth.month}
          editable
          onEdited={(year, month) => {
            onYearMonthChange({ year: year || 0, month: month || 0 });
          }}
          title={t('assessment.year-month')}
        />
        <AuditorsAssessmentField
          defaultValue={fields.auditors}
          editable
          onEdited={(auditors: Auditor[]) => {
            onAuditorsChange(auditors);
          }}
          title={t('assessment.auditors') || ''}
          assessmentBrand={fields.store.brand.code as BrandCode}
        />
        <BrandCoordinatorField
          className='fas fa-user-tie'
          defaultValue={fields.brandCoordinatorList}
          title={t('assessment.brandCoordinators')}
          editable
          onEdited={(coordinatorList: BrandCoordinator[]) => {
            onBrandCoordinatorListChange(coordinatorList);
          }}
          brand={fields.store.brand.code as BrandCode}
        />
        <DistributionListAssessmentField
          defaultValue={fields.distributionList}
          editable
          onEdited={(distributionList: Contact[]) => {
            onDistributionListChange(distributionList);
          }}
          title={t('assessment.distributionList') || ''}
        />
        <DistributionListAssessmentField
          defaultValue={fields.readOnlyDistributionList}
          editable
          onEdited={(readOnlyDistributionList: Contact[]) => {
            onReadOnlyDistributionListChange(readOnlyDistributionList);
          }}
          title={t('assessment.readOnlyDistributionList') || ''}
        />
      </div>
    </>
  );
};

interface AssessmentStoreProps {
  store: Store;
}

export const AssessmentStore = ({ store }: AssessmentStoreProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleField icon='copyright' value={store.brand.description} title={t('store.brand')} />
      <SimpleField icon='map marker' value={store.fullAddress} title={t('store.fullAddress')} />
      <SimpleField icon='code' value={store.fullCode} title={t('store.fullCode')} />
      <SimpleField iconClassName='fas fa-user-tie' value={store.storeManager.name} title={t('store.storeManager')} />
    </div>
  );
};
