import { css, keyframes, SerializedStyles } from '@emotion/react';
import { StatusCode } from '../model/assessmentStatus';
import style from '../../../shared/global.style';
import fieldStyle from './field.style';

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

/* const inputContainer = css`
  &&&& {
    textarea {
      border-color: var(--saa-primary-color-10);
      outline: none;
      width: 100%;
      height: 235px;
      max-height: 235px;
      min-width: 250px;
      min-height: 235px;
      overflow: auto;
      resize: none;
    }
  }
`; */

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const header = css`
  display: flex;
  align-items: center;
  background-color: var(--saa-primary-color);
  color: var(--saa-white-color);
`;

const backIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    margin: 0 0 0 10px;
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;
const fileIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const userFileIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    color: var(--saa-white-color);
  }
`;

const assessmentHeader = css`
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(100% - 90px);
`;

const dot = (status: StatusCode): SerializedStyles => {
  const color = style.statusColor(status);
  return css`
    && {
      height: 20px;
      width: 20px;
      background-color: ${color};
      border-radius: 50%;
      display: inline-block;
    }
  `;
};

const smallDotsContainer = css`
  display: flex;
  margin: 0 10px 0 auto;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const smallDot = (status: StatusCode): SerializedStyles => {
  const color = style.statusColor(status);
  return css`
    && {
      height: 10px;
      width: 10px;
      margin-left: 3px;
      background-color: ${color};
      border-radius: 50%;
      display: inline-block;
    }
  `;
};

const menuRowContainer = css`
  && {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-40);
      > i {
        color: var(--saa-primary-color-40);
      }
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

const userFileIconAnimated = css`
  ${userFileIcon};
  // animation: ${spin} 1s infinite;
  opacity: 0.7;
`;

const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;

export default {
  header,
  backIcon,
  assessmentHeader,
  dot,
  modal,
  modalContent,
  actions,
  button,
  fileIcon,
  smallDotsContainer,
  smallDot,
  menuRowContainer,
  userFileIcon,
  userFileIconAnimated,
  modalConfirm,
  confirmButton,
};
