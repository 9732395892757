import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const field = css`
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px var(--saa-primary-color-10);
  i {
    flex-shrink: 0;
  }
  //background-color: var(--saa-primary-color-60);
  background-color: var(--saa-blue-20);
`;

const editableField = css`
  ${field};
  cursor: pointer;
`;

const titleContainer = css`
  display: flex;
  width: 100%;
  align-items: center;
`;

const filterButton = css`
  &&& {
    margin-left: auto;
    ${style.primarySmallButton};
  }
`;

const toggleContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--saa-white-color);
  padding: 15px;
  color: var(--saa-primary-color);
`;

const toggleItem = css`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px var(--saa-primary-color-10);
`;

const valueContainer = css`
  ${style.bodyNormal};
  margin: 0 10px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const checkboxLabel = css`
  ${style.bodyNormal};
  &&& {
    color: var(--saa-primary-color);
    display: flex;
    align-items: center;
  }
`;

const checkBoxContainer = css`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const filterButtonsContainer = css`
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  gap: 10px;
  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const applyFiltersButton = css`
  ${style.primaryButton};
  width: 120px;
  @media (max-width: 540px) {
    flex: 1;
  }
`;

const badgeGroup = css`
  height: max-content;
  display: flex;
  align-items: center;

  .ui.labels {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;
const noAppliedFiltersLabel = css`
  color: var(--saa-blue-60);
  font-style: italic;
`;

const priorityRadioButton = css`
  &&& {
    min-height: 15px;
    font-size: 0.857rem;
    padding: 10px 5px;
    width: max-content;
    text-transform: capitalize;
  }
`;
const radioButtonsContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export default {
  field,
  editableField,
  valueContainer,
  checkBoxContainer,
  toggleContainer,
  toggleItem,
  titleContainer,
  filterButton,
  checkboxLabel,
  filterButtonsContainer,
  applyFiltersButton,
  badgeGroup,
  noAppliedFiltersLabel,
  priorityRadioButton,
  radioButtonsContainer,
};
