import axios, { AxiosResponse } from 'axios';
import paths from './paths';
import { toPrincipal, Principal } from '../model/principal.model';

const api = {
  getToken: async (
    tokenUrl: string,
    code: string | (string | null)[] | null | undefined,
    redirectUri: string,
    clientId: string
  ): Promise<AxiosResponse> =>
    axios({
      method: 'post',
      url: paths.getToken(),
      data: {
        tokenUrl,
        code,
        redirectUri,
        clientId,
        grant_type: 'authorization_code',
      },
      headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': 'true',
      },
    }),
  getPrincipal: async (): Promise<Principal> => {
    const response = await axios.get(paths.getPrincipal());
    return toPrincipal(response.data);
  },
  refreshToken: async (): Promise<AxiosResponse> => {
    return axios.get(paths.refreshToken());
  },
  putPrincipalPolicy: async (): Promise<Principal> => {
    const response = await axios.put(paths.putPrincipalPolicy());
    return toPrincipal(response.data);
  },
};

export default api;
