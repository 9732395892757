/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Loader, Pagination as Pager, PaginationProps, Table, Modal, Button, Input, Checkbox } from 'semantic-ui-react';
import { AssessmentReportTableRow } from './AssessmentReportTableRow';
import { AssessmentReportTableHeaderRow } from './AssessmentReportTableHeaderRow';
import style from './assessmentReportTable.style';
import { AssessmentReportResult } from '../../model/assessmentReportResult';
import { useAppDispatch } from '../../../../core/store';
import { editIsAllSelectedOnSingleColumn, editSelectedOptionsOnSingleColumn, onSort, selectColumnsWithActiveFilters, selectColumnFilterHeader,  selectActiveColumnFilters,  selectCurrentColumn, selectCurrentColumnSelectedOptions, selectFilterOptionsPerSingleColumn, selectIsCurrentColumnAllSelected, setColumnsWithActiveFilters, setSearchBoxColumnFilter, setActiveColumnFilters, setPagination } from '../../store/assessmentReportResultsSlice';



interface AssessmentReportTableProps {
  paginatedElements: AssessmentReportResult[];
  totalPages?: number;
  currentPage?: number;
  sortColumn: string;
  direction?: 'ascending' | 'descending';
  onChangePage?(page: number): void;
  onClickRow?(result: AssessmentReportResult): void;
  rowHeight?: number;
  pageSize?: number;
  totalCount?: number;
  isLoadingData?: boolean;
}

export const AssessmentReportTable: React.FC<AssessmentReportTableProps> = ({
  paginatedElements,
  totalPages,
  sortColumn,
  currentPage,
  direction,
  onChangePage,
  onClickRow,
  rowHeight,
  pageSize,
  totalCount,
  isLoadingData,
}) => {
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false)
  const columnsWithActiveFilters = useSelector(selectColumnsWithActiveFilters)



  const rows = paginatedElements?.map((result) => (
    <AssessmentReportTableRow
      key={`${result.jdaCode}-${result.year}-${result.month}-${result.processCode}-${result.subProcessCode}`}
      result={result}
      onClickRow={onClickRow}
      rowHeight={rowHeight}
      cellTitle={false}
    />
  ));
  const handleChangePage = (event: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
    if (onChangePage != null) onChangePage(activePage as number);
  };

  return (
    <>
      <div css={style.tableContainer}>
        <Table celled selectable={onClickRow != null} sortable={paginatedElements?.length > 0} striped compact>
          <AssessmentReportTableHeaderRow
            sortColumn={sortColumn} 
            direction={direction} 
            setFilterModalOpen={setFilterModalOpen}
            columnsWithActiveFilters={columnsWithActiveFilters}
          />
          {!isLoadingData && <Table.Body>{rows}</Table.Body>}
        </Table>
        {isLoadingData ? (
          <Loader active style={{ margin: 'auto' }} />
        ) : (
          paginatedElements?.length === 0 && <div style={{ margin: 'auto', fontWeight: 500 }}>No data</div>
        )}
        <TableColumnFilterModal 
          isFilterOpen={isFilterModalOpen} 
          setFilterModalOpen={setFilterModalOpen} 
          columnsWithActiveFilters={columnsWithActiveFilters}
          /> 
      </div>
      {paginatedElements?.length > 0 && totalPages && currentPage && onChangePage && pageSize && totalCount && (
        <div css={style.footer}>
          <Pager
            css={style.paginationContainer}
            totalPages={totalPages}
            activePage={currentPage}
            onPageChange={handleChangePage}
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          <div css={style.counter}>
            <span>
              {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)}
            </span>
            <span style={{ fontWeight: 400 }}> of</span>
            <span> {totalCount}</span>
            <span style={{ fontWeight: 400 }}> items</span>
          </div>
        </div>
      )}
    </>
  );
};

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}

interface TableColumnFilterModalProps {
  isFilterOpen: boolean;
  setFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  columnsWithActiveFilters: string[];
}

const TableColumnFilterModal = ({ 
  isFilterOpen, 
  setFilterModalOpen, 
  columnsWithActiveFilters
}: TableColumnFilterModalProps) => {
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
  const currentColumn = useSelector(selectCurrentColumn)
  const activeFilters = useSelector(selectActiveColumnFilters);
  const [updatedFilters, setUpdatedFilters] = useState(activeFilters)
  const columnFilterName = useSelector(selectColumnFilterHeader);
  const currentColumnSelectedOptions = useSelector(selectCurrentColumnSelectedOptions);
  const currentColumnFilterOptions = useSelector(selectFilterOptionsPerSingleColumn);
  const isCurrentColumnAllSelected = useSelector(selectIsCurrentColumnAllSelected);


  
  const currentOptionsContainNumbers = currentColumnFilterOptions?.find((o) => typeof o === 'number')
  const isStoreScore = currentColumn === 'subProcessScore'
  const isStoreFailedScorePercentage = currentColumn === 'failedScorePercentage'
  const isStoreSuccessScorePercentage = currentColumn === 'successScorePercentage'



  const onAscendingSortSelection = () => {
    if(isCurrentColumnAllSelected) {
      dispatch(setColumnsWithActiveFilters(columnsWithActiveFilters?.filter((c) => c !== currentColumn)))
    } 
    dispatch(onSort(currentColumn, 'ascending'))
    setFilterModalOpen(false);
  };

  const onDescendingSortSelection = () => {
    if(isCurrentColumnAllSelected) {
      dispatch(setColumnsWithActiveFilters(columnsWithActiveFilters?.filter((c) => c !== currentColumn)))
    } 
    dispatch(onSort(currentColumn, 'descending'))
    setFilterModalOpen(false);
  };

 
  useEffect(() => {
    setSelectedOptions(currentColumnSelectedOptions)
    setIsAllSelected(isCurrentColumnAllSelected)
    setUpdatedFilters(activeFilters)
    if(isCurrentColumnAllSelected) {
      setSelectedOptions(currentColumnFilterOptions)
      setIsAllSelected(isCurrentColumnAllSelected)
    } 
    else if (currentColumnSelectedOptions?.length > 0) {
      setSelectedOptions(currentColumnSelectedOptions)
    } 
    else {
      setSelectedOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentColumn, isCurrentColumnAllSelected, currentColumnSelectedOptions, activeFilters])

 
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    setSelectedOptions(selectedOptions)
    dispatch(setSearchBoxColumnFilter(e.target.value))
  };

  
  const handleSelectAll = () => {
    if(isAllSelected) {
      setSelectedOptions([])
      setIsAllSelected(false)
    } else {
      setSelectedOptions(currentColumnFilterOptions)
      setIsAllSelected(true)
    }
  };

  const handleOptionChange = (option: string | number) => {
  
    let updatedSelectedOptions = [...selectedOptions]
    if (!updatedSelectedOptions?.includes(option)) {
      updatedSelectedOptions?.push(option);
      setUpdatedFilters([...updatedFilters, option])
    } else {
      updatedSelectedOptions = updatedSelectedOptions?.filter(item => item !== option);
      setUpdatedFilters(updatedFilters.filter((f) => f !== option));
    }
    setSelectedOptions(updatedSelectedOptions);
    
    if (updatedSelectedOptions?.length !== currentColumnFilterOptions?.length) {
      setIsAllSelected(false)
      setUpdatedFilters(updatedSelectedOptions)
    } else if (updatedSelectedOptions?.length > 1) {
      setIsAllSelected(true)
      setUpdatedFilters([])
    }

  };

   const applyFilters = () => {
    if(isAllSelected) {
      if(searchTerm === '') {
        dispatch(setColumnsWithActiveFilters(columnsWithActiveFilters?.filter((c) => c !== currentColumn)))
        dispatch(setActiveColumnFilters(activeFilters?.filter((f) => {
          return !currentColumnFilterOptions?.includes(f);
        })))
      } else {
        dispatch(setActiveColumnFilters(currentColumnFilterOptions))
      }
    } else {
      dispatch(setActiveColumnFilters(updatedFilters));
    }
    dispatch(editIsAllSelectedOnSingleColumn({ [currentColumn]: isAllSelected }))
    dispatch(editSelectedOptionsOnSingleColumn({ [currentColumn]: selectedOptions }))
    dispatch(setPagination({page: 1, limit: 30}))
    setFilterModalOpen(false);
   };

   const handleModalClose = () => {
    setSelectedOptions(currentColumnSelectedOptions)
    setIsAllSelected(isCurrentColumnAllSelected)
    if(isAllSelected && isCurrentColumnAllSelected) {
      dispatch(setColumnsWithActiveFilters(columnsWithActiveFilters?.filter((c) => c !== currentColumn)))
    }
    setSearchTerm('')
    dispatch(setSearchBoxColumnFilter(''))
    setFilterModalOpen(false)
   };

 
   const getAscendingSortingDescription = () => {
    if (currentOptionsContainNumbers || isStoreScore || isStoreFailedScorePercentage || isStoreSuccessScorePercentage) return 'Sort Smallest to Largest'
    return 'Sort A to Z'
   }

   const getDescendingSortingDescription = () => {
    if (currentOptionsContainNumbers || isStoreScore || isStoreFailedScorePercentage || isStoreSuccessScorePercentage) return 'Sort Largest to Smallest'
    return 'Sort Z to A'
   }
    
  
  return (
    <Modal open={isFilterOpen} onClose={handleModalClose} css={style.filterModal}>
      <Modal.Header css={style.modalHeader}>{columnFilterName} <Icon name="filter"/></Modal.Header>
      <Modal.Content css={style.filterModalContent}>
        <Button css={style.sortingButton} onClick={onAscendingSortSelection}><Icon css={style.sortingIcon} name='long arrow alternate up'/>{getAscendingSortingDescription()}</Button>
        <Button css={style.sortingButton} onClick={onDescendingSortSelection}><Icon css={style.sortingIcon} name='long arrow alternate down'/> {getDescendingSortingDescription()}</Button>
        <Input
          type="text"
          placeholder="Search..."
          css={style.searchBox} 
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <Checkbox
            label="(Select All)"
            checked={isAllSelected} 
            onChange={handleSelectAll} 
          />
          {currentColumnFilterOptions?.map(option => (
            <div key={option}>
              <Checkbox 
                label={option.toString()} 
                checked={isAllSelected || selectedOptions?.includes(option)} 
                onChange={() => handleOptionChange(option)} 
              />
            </div>
          ))}
        </div>
        <Button disabled={selectedOptions?.length === 0} css={style.applyFiltersButton} onClick={applyFilters}>Apply Filters</Button>
      </Modal.Content>
    </Modal>
  );
};




