import axios from 'axios';
import qs from 'query-string';
import paths from './paths';

import { Brand, toBrand } from '../../../shared/model/brand.model';
import { AssessmentBulkUpdateQueryParams } from '../model/assessmentBulkUpdateQueryParams';
import { Store, toStore } from '../../../shared/model/store.model';
import {
  AssessmentBulkUpdate,
  AssessmentBulkUpdateAuditorsEmailsRequest,
  AssessmentBulkUpdateBrandCoordinatorsRequest,
  AssessmentBulkUpdateDistributionListRequest,
  AssessmentBulkUpdateReadOnlyDistributionListRequest,
  toAssessmentBulkUpdate,
} from '../model/assessmentBulkUpdate';
import { Auditor, BrandCoordinator, toAuditor, toBrandCoordinator } from '../../assessments/model/assessment';
import { Contact, toContact } from '../../users/model/user.model';

const api = {
  getAssessments: async (params: AssessmentBulkUpdateQueryParams): Promise<AssessmentBulkUpdate[]> => {
    const response = await axios.get(paths.getAssessments(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.flatMap(toAssessmentBulkUpdate);
  },
  getStatuses: async (params: AssessmentBulkUpdateQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getStatuses(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getFollowUpStatuses: async (params: AssessmentBulkUpdateQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getFollowUpStatuses(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getBrands: async (params: AssessmentBulkUpdateQueryParams): Promise<Brand[]> => {
    const response = await axios.get(paths.getBrands(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrand);
  },
  getRegions: async (params: AssessmentBulkUpdateQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getRegions(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getCountries: async (params: AssessmentBulkUpdateQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getCountries(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getCities: async (params: AssessmentBulkUpdateQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getCities(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getStores: async (params: AssessmentBulkUpdateQueryParams): Promise<Store[]> => {
    const response = await axios.get(paths.getStores(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toStore);
  },
  getAuditors: async (params: AssessmentBulkUpdateQueryParams): Promise<Auditor[]> => {
    const response = await axios.get(paths.getAuditors(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toAuditor);
  },
  getAllAuditors: async (): Promise<Auditor[]> => {
    const response = await axios.get(paths.getAllAuditors());
    return response.data.map(toAuditor);
  },
  updateAuditors: async (request: AssessmentBulkUpdateAuditorsEmailsRequest): Promise<AssessmentBulkUpdate[]> => {
    const response = await axios.put(paths.updateAuditors(), request);
    return response.data.map(toAssessmentBulkUpdate);
  },
  getActionPlanCoordinators: async (params: AssessmentBulkUpdateQueryParams): Promise<BrandCoordinator[]> => {
    const response = await axios.get(paths.getActionPlanCoordinators(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrandCoordinator);
  },
  updateActionPlanCoordinators: async (
    request: AssessmentBulkUpdateBrandCoordinatorsRequest
  ): Promise<AssessmentBulkUpdate[]> => {
    const response = await axios.put(paths.updateActionPlanCoordinators(), request);
    return response.data.map(toAssessmentBulkUpdate);
  },
  getDistributionListMembers: async (params: AssessmentBulkUpdateQueryParams): Promise<Contact[]> => {
    const response = await axios.get(paths.getDistributionListMembers(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toContact);
  },
  updateDistributionListMembers: async (
    request: AssessmentBulkUpdateDistributionListRequest
  ): Promise<AssessmentBulkUpdate[]> => {
    const response = await axios.put(paths.updateDistributionListMembers(), request);
    return response.data.map(toAssessmentBulkUpdate);
  },
  getReadOnlyDistributionListMembers: async (params: AssessmentBulkUpdateQueryParams): Promise<Contact[]> => {
    const response = await axios.get(paths.getReadOnlyDistributionListMembers(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toContact);
  },
  updateReadOnlyDistributionListMembers: async (
    request: AssessmentBulkUpdateReadOnlyDistributionListRequest
  ): Promise<AssessmentBulkUpdate[]> => {
    const response = await axios.put(paths.updateReadOnlyDistributionListMembers(), request);
    return response.data.map(toAssessmentBulkUpdate);
  },
};

export default api;
