/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card } from 'semantic-ui-react';
import {
  AuditScore,
  ProcessScore,
  processInternalControlDescription,
  auditInternalControlDescription,
} from '../model/assessmentDetail.model';
import style from './auditSummary.style';
import { AuditScoreRating } from '../model/assessment';

interface AuditSummaryProps {
  auditScore?: AuditScore;
}

export const AuditSummary = ({ auditScore }: AuditSummaryProps): JSX.Element => {
  return auditScore != null ? (
    <div css={style.container}>
      <TotalScoreCard overallRatingPercentage={auditScore.overallRatingPercentage} rating={auditScore.rating} />
      <div css={style.processScoreContainer}>
        {auditScore.processScores.map(ps => (
          <ProcessCard processScore={ps} key={ps.code} />
        ))}
      </div>
      )
    </div>
  ) : (
    <></>
  );
};

interface TotalScoreCardProps {
  overallRatingPercentage: number;
  rating: AuditScoreRating;
}

const TotalScoreCard = ({ overallRatingPercentage, rating }: TotalScoreCardProps): JSX.Element => {
  return (
    <Card css={style.card}>
      <Card.Content css={style.cardTitle}>Total score</Card.Content>
      <Card.Content css={style.totalCardContent}>
        <div>
          <div css={style.percentageField(overallRatingPercentage, rating)}>
            <div>{Math.round((overallRatingPercentage * 100 + Number.EPSILON) * 100) / 100}%</div>
          </div>
          <div css={style.subtitle}>Overall percentage</div>
        </div>
        <div>
          <div css={style.ratingField(rating)}>
            <div>
              <div>{rating}</div>
              <span>{auditInternalControlDescription.get(rating)}</span>
            </div>
          </div>
          <div css={style.subtitle}>Rating and description</div>
        </div>
      </Card.Content>
    </Card>
  );
};

interface ProcessCardProps {
  processScore: ProcessScore;
}

const ProcessCard = ({ processScore }: ProcessCardProps): JSX.Element => {
  return (
    <Card css={style.processCard}>
      <Card.Content css={style.cardTitle}>
        {processScore.code} - {processScore.description}
      </Card.Content>
      <Card.Content css={style.cardContent}>
        <div>
          <div css={style.percentageField(processScore.scorePercentage, processScore.rating)}>
            <div>{Math.round((processScore.scorePercentage * 100 + Number.EPSILON) * 100) / 100}%</div>
          </div>
          <div css={style.subtitle}>Score percentage</div>
        </div>
        <div>
          <div css={style.ratingField(processScore.rating)}>
            <div>
              <div>{processScore.rating}</div>
              <span>{processInternalControlDescription.get(processScore.rating)}</span>
            </div>
          </div>
          <div css={style.subtitle}>Rating and description</div>
        </div>
      </Card.Content>
    </Card>
  );
};
