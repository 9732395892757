import { css } from '@emotion/react';

const header = css`
  @media (min-width: 760px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  background-color: var(--saa-primary-color-80);
  &&&&& {
    th {
      color: var(--saa-white-color);
      border-radius: 0;
      background-color: var(--saa-primary-color-80);
    }
    th:hover {
      color: var(--saa-white-color);
      border-radius: 0;
      background-color: var(--saa-primary-color-60);
    }
  }
`;

const checkboxCell = css`
  &&&& {
    padding: 0;
  }
`;
const checkboxContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export default {
  header,
  checkboxCell,
  checkboxContainer,
};
