/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, Label, SemanticICONS } from 'semantic-ui-react';
import { SerializedStyles } from '@emotion/react';
import style from './simpleField.style';

interface SimpleFieldProps {
  icon?: SemanticICONS;
  error?: boolean;
  value?: string;
  children?: JSX.Element;
  title: string;
  iconClassName?: string;
  className?: string;
  errorMessage?: string[] | string;
  css?: SerializedStyles[];
}

export const SimpleField = ({
  icon,
  error = false,
  value,
  children,
  title,
  errorMessage,
  iconClassName = '',
  className = '',
  css = [],
}: SimpleFieldProps): JSX.Element => {
  return (
    <div title={title} css={[style.field, ...css]} className={className}>
      <Icon className={iconClassName} name={icon} color={error ? 'red' : 'black'} />
      {error && (
        <Label basic color='red' pointing css={style.errorLabel}>
          {errorMessage}
        </Label>
      )}
      <div css={style.valueContainer}>
        {children ?? <span css={style.value}> {value} </span>}
        <span css={style.subTitle}>{title}</span>
      </div>
    </div>
  );
};
