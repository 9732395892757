/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditingResult,
  emptyAuditingResult,
  mergeAuditingResultAndResultUpdateRequest,
  ResultUpdateRequest,
} from '../model/assessmentDetail.model';
import offlineApi from '../utils/offlineApi';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { addAndSyncRequests } from './auditorUpdateRequestsSlice';
import { ChangeDetail, ChangeType } from '../model/assessment';
import { addChangeDetailToChangesHistory } from './assessmentDetailSlice';

interface AuditorResultsSliceState {
  value: AuditingResult[];
  isFetching: boolean;
  error: string;
}

const initialState = {
  value: [],
  isFetching: false,
  error: '',
} as AuditorResultsSliceState;

export const auditorResultsSlice = createSlice({
  name: 'auditorResults',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditorResultsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<AuditorResultsSliceState>, action: PayloadAction<AuditingResult[]>) => {
      return {
        isFetching: false,
        value: action.payload,
        error: '',
      };
    },
    httpError: (state: Draft<AuditorResultsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: () => initialState,
    invalidateCache: (state: Draft<AuditorResultsSliceState>) => ({ ...state }),
    change: (state: Draft<AuditorResultsSliceState>, { payload }: PayloadAction<AuditingResult>) => {
      const newValue = [...state.value];
      const elementIndex = newValue.findIndex(v => v.id === payload.id);
      if (elementIndex >= 0) {
        newValue.splice(elementIndex, 1, payload);
      } else {
        newValue.push(payload);
      }
      return {
        isFetching: false,
        value: newValue,
        error: '',
      };
    },
  },
});

export const { startFetch, finishFetch, httpError, change, invalidateCache } = auditorResultsSlice.actions;

export const fetchAuditorResults = (assessmentId: string) => async (dispatch: any, state: any) => {
  dispatch(startFetch());
  try {
    const auditingResults = await offlineApi.getResults(assessmentId);
    dispatch(finishFetch(auditingResults));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const saveAuditingResultAction =
  (preAuditingResult: AuditingResult, request: ResultUpdateRequest) =>
  (dispatch: any, state: () => any): void => {
    try {
      dispatch(
        addAndSyncRequests({
          ...request,
          assessmentId: state().assessmentDetail.value.id,
          auditorEmail: state().principal.data.email,
        })
      );

      dispatch(addChangeDetailToChangesHistory(getChangeDetail(preAuditingResult)));
      dispatch(change(mergeAuditingResultAndResultUpdateRequest(preAuditingResult, request)));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

const getChangeDetail = (preAuditingResult: AuditingResult): ChangeDetail => {
  const author = { name: preAuditingResult.auditor.name, email: preAuditingResult.auditor.email };

  const splitSPCode = preAuditingResult.subProcessCode.split('.');
  const value = `${preAuditingResult.processCode}.${splitSPCode.length >= 2 ? splitSPCode[1] : ''}`;

  return {
    type: ChangeType.FINDING,
    value,
    author,
    timestamp: new Date(),
  };
};

export const selectAuditingResults = (state: any) => state.auditorResults.value as AuditingResult[];

export const selectProcessAuditingResults = (processCode: string) => {
  return createSelector(selectAuditingResults, auditingResults => {
    return auditingResults.filter(ar => ar.processCode === processCode);
  });
};

export const selectAuditingResult = (
  processCode: string,
  subProcessCode: string,
  availableKeyBusinessRisks: string[]
) => {
  return createSelector(selectPrincipal, selectAuditingResults, (user, auditingResults) => {
    return (
      auditingResults.find(
        ar =>
          ar.processCode === processCode &&
          ar.subProcessCode === subProcessCode &&
          ar.auditor.email.toLowerCase() === user.email.toLowerCase()
      ) ||
      emptyAuditingResult(
        { name: `${user.firstName} ${user.surname}`, email: user.email, approvalRequired: user.approvalRequired },
        processCode,
        subProcessCode,
        availableKeyBusinessRisks
      )
    );
  });
};

export const selectSubProcessAuditingResults = (processCode: string, subProcessCode: string) => {
  return createSelector(selectPrincipal, selectAuditingResults, (user, auditingResults) => {
    return auditingResults.filter(ar => ar.processCode === processCode && ar.subProcessCode === subProcessCode) || [];
  });
};

export default auditorResultsSlice.reducer;
