import { Store, toStore } from '../../../shared/model/store.model';
import { STATUS, Status, StatusCode } from './assessmentStatus';
import { getDateTimeString } from '../../../core/utils';
import { Contact } from '../../users/model/user.model';

export interface Assessment {
  id: string;
  store: Store;
  code: string;
  year: number;
  month: number;
  auditors: Auditor[];
  status: Status;
  creationDate: string;
  distributionList: Contact[];
  readOnlyDistributionList: Contact[];
  brandCoordinatorList: BrandCoordinator[];
  scoreRating: AuditScoreRating;
  changesHistory: ChangeDetail[];
}

export enum ChangeType {
  STORE_MANAGER = 'STORE_MANAGER',
  STORE_MANAGER_STARTING_DATE = 'STORE_MANAGER_STARTING_DATE',
  ASSISTANT_STORE_MANAGER = 'ASSISTANT_STORE_MANAGER',
  AUDITORS = 'AUDITORS',
  ACTION_PLAN_COORDINATORS = 'ACTION_PLAN_COORDINATORS',
  DISTRIBUTION_LIST = 'DISTRIBUTION_LIST',
  READ_ONLY_DISTRIBUTION_LIST = 'READ_ONLY_DISTRIBUTION_LIST',
  OVERVIEW = 'OVERVIEW',
  INTERNAL_COMMENTS = 'INTERNAL_COMMENTS',
  RETAIL_AREA_MANAGER = 'RETAIL_AREA_MANAGER',
  RETAIL_PROCEDURE = 'RETAIL_PROCEDURE',
  ENTITY_AUDIT = 'ENTITY_AUDIT',
  FINDING = 'FINDING',
  ACTION_PLAN = 'ACTION_PLAN',
  FOLLOW_UP = 'FOLLOW_UP',
  TOPICS_FOR_DISCUSSION = 'TOPICS_FOR_DISCUSSION',
  STATUS_CHANGE = 'STATUS_CHANGE',
  AUDIT_FIELDWORK = 'AUDIT_FIELDWORK',
  STORE_EMPLOYEES = 'STORE_EMPLOYEES',
  ASSESSMENT_CREATION = 'ASSESSMENT_CREATION',
}

export interface ChangeDetail {
  type: ChangeType;
  value: string;
  author: Contact;
  timestamp: Date;
}

export interface Auditor {
  name: string;
  email: string;
  approvalRequired: boolean;
}

export interface BrandCoordinator {
  name: string;
  email: string;
  role: string;
}

export interface Supervisor {
  name: string;
  email: string;
  jobTitle: string;
}

export interface ActionPlanEditor {
  name: string;
  email: string;
}

export interface ActionPlanSubmitAuthor {
  name: string;
  email: string;
}

export interface Quarter {
  quarter: number;
  year: number;
}

export const sortedAssessmentStatus: StatusCode[] = [
  'NEW',
  'IN_PROGRESS',
  'MERGING',
  'READY_TO_REVIEW_BY_REGIONAL_MANAGER',
  'UNDER_REVIEW_BY_REGIONAL_MANAGER',
  'READY_TO_REVIEW',
  'UNDER_REVIEW',
  'APPROVED_TO_BE_SENT',
  'SENT_TO_THE_STORES',
  'ACTIONS_PLAN_UNDER_REVIEW',
  'CLOSED',
  'DELETED',
];

export enum AuditScoreRating {
  P = 'A',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export const toBrandCoordinator = (o: Record<string, unknown>): BrandCoordinator => {
  return o != null
    ? {
        name: o.name as string,
        email: o.email as string,
        role: o.role as string,
      }
    : { name: '', email: '', role: '' };
};

export const toAssessment = (o: Record<string, unknown>): Assessment => {
  return {
    id: o.id as string,
    store: toStore(o.store as Record<string, unknown>),
    code: o.code as string,
    year: o.year as number,
    month: o.month as number,
    auditors: (o.auditors as Record<string, unknown>[]).map(toAuditor) as Auditor[],
    status: STATUS[o.status as StatusCode],
    creationDate: getDateTimeString(o.creationDate as string),
    distributionList: o.distributionList as Contact[],
    readOnlyDistributionList: o.readOnlyDistributionList as Contact[],
    brandCoordinatorList: (o.brandCoordinatorList as Record<string, unknown>[]).map(
      toBrandCoordinator
    ) as BrandCoordinator[],
    scoreRating: o.scoreRating as AuditScoreRating,
    changesHistory: o.changesHistory as ChangeDetail[],
  };
};

export const toAuditor = (o: Record<string, unknown>): Auditor => {
  return {
    name: o.name as string,
    email: o.email as string,
    approvalRequired: o.approvalRequired as boolean,
  };
};

export const toSuperVisor = (o: Record<string, unknown>): Supervisor => {
  return {
    name: o.name as string,
    email: o.email as string,
    jobTitle: o.jobTitle as string,
  };
};

export interface AssessmentCreationRequest {
  storeJdaCode: string;
  auditorEmails: string[];
  distributionList: string[];
  readOnlyDistributionList: string[];
  year: number;
  month: number;
  brandCoordinatorList: BrandCoordinator[];
}

export interface AssessmentCreationFromProposalRequest {
  auditorEmails: string[];
  distributionList: string[];
  readOnlyDistributionList: string[];
  year: number;
  month: number;
  brandCoordinatorList: BrandCoordinator[];
}
