/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { Button, Checkbox, Icon, Modal } from 'semantic-ui-react';
import style from './multiSelectField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface MultiSelectFieldProps {
  defaultValue?: string[];
  options: string[];
  editable?: boolean;
  onChange?: (value: string[]) => void;
  title?: string;
  isValid?: boolean;
  mutable?: boolean;
}

export const MultiSelectField = ({
  defaultValue = [],
  options,
  editable = false,
  onChange = () => null,
  title = '',
  isValid = true,
  mutable = false,
}: MultiSelectFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [selectedMultiSelectState, setSelectedMultiSelectState] = React.useState(defaultValue);
  const isChecked = (id: string) => selectedMultiSelectState.includes(id);

  const getValue = useCallback(() => defaultValue, [defaultValue]);

  useEffect(() => {
    if (mutable) setSelectedMultiSelectState(defaultValue);
  }, [defaultValue, mutable, options]);

  const onChangeCheckbox = (id: string) => {
    if (isChecked(id)) {
      setSelectedMultiSelectState(selectedMultiSelectState.filter(v => v !== id));
    } else {
      setSelectedMultiSelectState([...selectedMultiSelectState, ...options.filter(o => o === id)]);
    }
  };

  const updateValue = () => {
    onChange(selectedMultiSelectState);
    setOpen(false);
  };
  const cancelValue = () => {
    setSelectedMultiSelectState(defaultValue);
    setOpen(false);
  };

  // const getFieldDescription = useCallback(() => defaultValue.join(', '), [defaultValue]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div css={style.textTitle(isValid)} title={title}>
        {title}
      </div>
      <div css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <div>
          {(getValue() as string[]).map(value => (
            <div css={style.value} key={value}>
              {value}
            </div>
          ))}
          {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}{' '}
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            {options.map((o, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div css={style.checkBoxContainer} key={`${o}-${index}`}>
                <Checkbox css={style.checkBox} checked={isChecked(o)} label={o} onChange={() => onChangeCheckbox(o)} />
              </div>
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
