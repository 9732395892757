import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import fieldStyle from './field.style';
import { ResultStatus } from '../model/assessmentDetail.model';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

const content = css`
  flex-grow: 1;
  color: var(--saa-primary-color);
  padding: 0 0 10px;
  overflow: auto;
`;

const footer = css`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 5px solid var(--saa-white-color);
`;

const infoButton = css`
  &&& {
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin-right: auto;
  }
`;

const infoIcon = css`
  && {
    color: var(--saa-blue-90);
    font-size: 2.143rem;
  }
`;

const infoModal = css`
  && {
    max-height: 100%;
    max-width: 400px;
    min-height: 300px;
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const infoModalContent = css`
  && {
  }
`;

const saveButton = css`
  ${globalStyle.primaryButton};
`;

const pastExamplesButton = css`
  ${globalStyle.primaryButton};
  && {
    margin-right: 10px;
  }
`;

const title = css`
  ${globalStyle.subtitleNormal};
  && {
    padding: 5px 10px;
    background-color: var(--saa-blue-20);
    color: black;
    margin-bottom: 10px;
  }
`;

const submitAndSaveContainer = css`
  && {
    display: flex;
  }
`;
const submitAndSave = css`
  ${globalStyle.primaryButton};
`;

const checkBoxContainer = css`
  ${fieldStyle.checkBoxContainer};
`;

const opacity = css`
  opacity: 0.5;
`;

const freeTextTitle = css`
  ${globalStyle.subtitleNormal}
  padding: 0 10px;
  background-color: var(--saa-blue-20);
`;

const freeTextContainerFailed = (status: ResultStatus): SerializedStyles => css`
  padding-bottom: 10px;
  > div {
    > div {
      > div,
      > i,
      > span {
        display: ${status !== ResultStatus.FAILED ? 'none' : ''};
      }
    }
  }
`;

const freeTextContainerPassed = (status: ResultStatus): SerializedStyles => css`
  padding-bottom: 10px;
  > div {
    > div {
      > div,
      > i,
      > span {
        display: ${status !== ResultStatus.PASSED ? 'none' : ''};
      }
    }
  }
`;

const observationContainer = css`
  padding-bottom: 10px;
`;

const auditNoteContainer = (noPaddingBefore: boolean) => css`
  ${noPaddingBefore ? '' : '  padding-top: 10px;'};
  padding-bottom: 10px;
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;

const priorityRow = css`
  &&& {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: solid 1px var(--saa-primary-color-10);
    margin: 0;
  }
`;

const noSuggestionsLabel = css`
  color: var(--saa-primary-color);
  padding: 10px;
`;

const priorityTitle = css`
  ${globalStyle.bodySmall};

  &&&&& {
    background-color: var(--saa-white-color);
    display: flex;
    font-weight: 600;
    padding: 10px 2px;
    align-items: center;
    cursor: pointer;
    div {
      flex: 1;
    }
    i {
      font-size: 1.3rem;
    }
  }
`;

const suggestionContainer = css`
  border: 1px solid var(--saa-primary-color-60);
  border-radius: 5px;
  margin: 10px;
  :hover {
    border: 2px solid var(--saa-primary-color);
  }
`;

const suggestionTitle = css`
  background-color: var(--saa-blue-20);
`;

const suggestionLabel = css`
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
  color: var(--saa-primary-color);
`;

const suggestionIndex = css`
  display: inline-block;
  color: var(--saa-primary-color-60);
  font-size: 0.8rem;
  font-weight: 600;
`;

const suggestionText = css`
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  padding: 10px;
`;

export default {
  container,
  content,
  footer,
  infoButton,
  infoIcon,
  infoModal,
  infoModalContent,
  saveButton,
  submitAndSave,
  checkBoxContainer,
  freeTextContainerFailed,
  freeTextContainerPassed,
  freeTextTitle,
  opacity,
  auditNoteContainer,
  observationContainer,
  submitAndSaveContainer,
  modalConfirm,
  actions,
  button,
  pastExamplesButton,
  title,
  priorityRow,
  priorityTitle,
  suggestionTitle,
  suggestionText,
  suggestionContainer,
  suggestionLabel,
  suggestionIndex,
  noSuggestionsLabel,
};
