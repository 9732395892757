/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface UnsavedChangesSliceState {
  present: boolean;
}

const initialState = {
  present: false,
} as UnsavedChangesSliceState;

export const unsavedChangesSlice = createSlice({
  name: 'unsavedChanges',
  initialState,
  reducers: {
    UPDATE_PRESENCE: (state: Draft<UnsavedChangesSliceState>, action: PayloadAction<boolean>) => {
      state.present = action.payload;
    },
  },
});

export const { UPDATE_PRESENCE } = unsavedChangesSlice.actions;

export const setUnsavedChangesPresence = (presence: boolean) => async (dispatch: any) => {
  dispatch(UPDATE_PRESENCE(presence));
};

export const selectUnsavedChangesPresence = (state: any) => state.unsavedChanges.present as boolean;

export default unsavedChangesSlice.reducer;
