/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Form, Radio, Segment, TextArea } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './rescheduleModalContent.style';
import { getDate } from '../../../../../core/utils';
import { MultipleDateRangeField, getMinDateOfRange } from '../../../../../shared/MultipleDateRangeField';
import { ProposedPeriod } from '../../../model/genericAuditProposal';

interface RejectModalContentProps {
  onChange: (proposedPeriods: ProposedPeriod[], note: string, isReschedule: boolean) => void;
  canReject: boolean;
}

export function RescheduleBrandModalContent({ onChange, canReject }: RejectModalContentProps): JSX.Element {
  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>([]);
  const [note, setNote] = useState('');
  const [minDate, setMinDate] = useState(getDate());
  const [isReschedule, setIsReschedule] = useState<boolean>(true);

  useEffect(() => {
    onChange(proposedPeriodsState, note, isReschedule);
  }, [note, onChange, isReschedule, proposedPeriodsState]);

  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const onDateUpdate = async (proposedPeriods: ProposedPeriod[]) => {
    setProposedPeriodsState(proposedPeriods);
    setMinDate(getMinDateOfRange(proposedPeriods));
  };

  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      {canReject && (
        <Form css={style.checkBoxContent}>
          <Form.Field css={style.RadioButtonField}>
            <Radio
              label='Reschedule'
              name='radioGroup'
              checked={isReschedule}
              onChange={() => {
                setIsReschedule(true);
              }}
            />
          </Form.Field>
          <Form.Field css={style.RadioButtonField}>
            <Radio
              label='Reject'
              name='radioGroup'
              checked={!isReschedule}
              onChange={() => {
                setIsReschedule(false);
              }}
            />
          </Form.Field>
        </Form>
      )}
      <Form css={style.fieldContent}>
        <h4>{t('auditCampaigns.proposalDetail.footer.rejectNote')}</h4>
        <TextArea
          placeholder={t('auditCampaigns.proposalDetail.footer.rescheduleNotePlaceHolder')}
          onChange={event => {
            onChangeNote(event);
          }}
        />
      </Form>
      {isReschedule && (
        <Segment css={style.fieldContent}>
          <h4>Audit dates</h4>
          <div css={style.CalendarField}>
            <MultipleDateRangeField
              defaultProposedPeriods={proposedPeriodsState}
              editable
              onEdited={(_proposedPeriods: ProposedPeriod[]) => onDateUpdate(_proposedPeriods)}
              title={t('brandAuditProposals.proposalDetail.availabilityPeriods') || ''}
              minDate={minDate}
            />
          </div>
        </Segment>
      )}
    </div>
  );
}
