/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'semantic-ui-react';
import { ResultStatus } from '../model/assessmentDetail.model';
import style from './subProcessResultBox.style';

interface SubProcessResultBoxProps {
  status: ResultStatus;
  onChange?: (value: ResultStatus) => void;
  readonly?: boolean;
  name?: string;
  todoStatus?: boolean;
  showTitle?: boolean;
  mutable?: boolean;
}

export const SubProcessResultBox = ({
  status,
  onChange = () => null,
  readonly = true,
  name = 'resultGroup',
  todoStatus = true,
  showTitle = true,
  mutable = false,
}: SubProcessResultBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = useState(status);

  useEffect(() => {
    if (mutable) {
      setValue(status);
    }
  }, [mutable, status]);

  const changeStatus = useCallback(
    (newStatus: ResultStatus) => {
      setValue(newStatus);
      onChange(newStatus);
    },
    [onChange]
  );
  return (
    <div css={style.container}>
      {showTitle ? <div css={style.title}>{t('assessment.checkList.result')}</div> : <></>}
      <div css={style.radioBox}>
        {todoStatus ? (
          <Radio
            name={name}
            checked={value === ResultStatus.TO_DO}
            onChange={() => changeStatus(ResultStatus.TO_DO)}
            css={style.radioElement}
            disabled={readonly}
            label={t('assessment.checkList.toDo')}
          />
        ) : (
          <></>
        )}
        <Radio
          name={name}
          checked={value === ResultStatus.PASSED}
          onChange={() => changeStatus(ResultStatus.PASSED)}
          css={style.radioElement}
          disabled={readonly}
          label={t('assessment.checkList.passed')}
        />
        <Radio
          name={name}
          checked={value === ResultStatus.FAILED}
          onChange={() => changeStatus(ResultStatus.FAILED)}
          css={style.radioElement}
          disabled={readonly}
          label={t('assessment.checkList.failed')}
        />
      </div>
    </div>
  );
};
