import { Brand } from '../../../shared/model/brand.model';
import { Auditor } from './assessment';

interface AssessmentFilterInterface {
  status: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  auditors: Auditor[];
  monthFrom?: number;
  monthTo?: number;
  yearFrom?: number;
  yearTo?: number;
}

interface AssessmentQueryParamsInterface {
  status: string[];
  brands: string[];
  regions: string[];
  countries: string[];
  auditors: string[];
  monthFrom?: number;
  monthTo?: number;
  yearFrom?: number;
  yearTo?: number;
}

interface AssessmentSharedFilter {
  status?: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  auditors: string[];
  monthFrom?: number;
  monthTo?: number;
  cities?: string[];
  jdaCodes?: string[];
  yearFrom?: number;
  yearTo?: number;
  brandCoordinators?: string[];
  distributionListMembers?: string[];
  readOnlyDistributionListMembers?: string[];
}

const emptyAssessmentFilterInterface: AssessmentFilterInterface = {
  status: [],
  brands: [],
  regions: [],
  countries: [],
  auditors: [],
  monthFrom: undefined,
  monthTo: undefined,
};

const covertAssessmentFiltersInQueryParams = (filters: AssessmentFilterInterface): AssessmentQueryParamsInterface => ({
  ...filters,
  status: filters.status,
  brands: filters.brands.map(b => b.code),
  auditors: filters.auditors.map(a => a.name),
});

const convertAssessmentFilterInSharedFilter = (filters: AssessmentFilterInterface): AssessmentSharedFilter => ({
  ...filters,
  status: filters.status,
  auditors: filters.auditors.map(a => a.name),
});

export type { AssessmentFilterInterface, AssessmentSharedFilter };
export { emptyAssessmentFilterInterface, covertAssessmentFiltersInQueryParams, convertAssessmentFilterInSharedFilter };
