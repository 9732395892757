/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import dayGridPlugin from '@fullcalendar/daygrid';

import { DatesSetArg, EventContentArg, EventInput } from '@fullcalendar/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Icon, Label, Modal, Tab } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import style from './auditProposalsAgenda.style';
import { useAppDispatch } from '../../../core/store';

import {
  fetchAuditProposals,
  updateTextFilter,
  selectDateRange,
  selectFilteredSortedProposals,
  selectProposalsTextFilter,
  selectProposalsFilters,
  setFilters,
} from '../store/auditProposalsAgendaSlice';
import { SearchBox } from '../../../shared/SearchBox';
import { AuditProposalCardHeader } from '../components/AuditProposalCardHeader';
import { RowField } from '../../assessments/components/AssessmentCard';
import { Contact } from '../../users/model/user.model';
import { AuditProposalWithCampaignInfo } from '../model/auditProposal';
import { getDate, getForcedDateFromUtc, useCtrlEnterToRunCallback } from '../../../core/utils';
import { BrandImage } from '../../../shared/BrandImage';
import { ModalPage } from '../../../shared/ModalPage';
import { Brand } from '../../../shared/model/brand.model';
import { emptyProposalsFilters, ProposalsFilters } from '../model/proposalsQueryParams';
import { BrandMultiSelectField } from '../components/agenda/BrandMultiSelectField';
import { RegionMultiSelectField } from '../components/agenda/RegionMultiSelectField';
import { CountryMultiSelectField } from '../components/agenda/CountryMultiSelectField';
import { CityMultiSelectField } from '../components/agenda/CityMultiSelectField';
import { StoreMultiSelectField } from '../components/agenda/StoreMultiSelectField';

interface EventContentProps {
  event: EventContentArg;
}

const CalendarProposalCard = ({ event }: EventContentProps): JSX.Element => {
  const { proposal } = event.event.extendedProps;
  const detailLink =
    proposal.type === 'brand'
      ? `/brand-audit-proposals/${proposal.id}`
      : `/audit-campaigns/${proposal.campaignId}/internal-audit-proposals/${proposal.id}`;

  return (
    <Link to={detailLink}>
      <div css={style.calendarProposalCard}>
        {event.view.type === 'dayGridMonth' ? (
          <div css={style.monthCalendarProposalCardContent}>
            <div css={style.monthBrandContainer}>
              <BrandImage src={proposal.store.brand.logo} />
            </div>
            <div>
              <div>
                <RowField iconName='shopping bag' value={`${proposal.store.name} (${proposal.store.jdaCode})`} />
              </div>
              <div>
                <RowField iconName='copyright' value={proposal.store.brand.description} />
              </div>
              <div>
                <RowField
                  iconName='users'
                  value={proposal.auditors.map((auditor: Contact) => auditor.name).join(', ')}
                />
              </div>
              {proposal.type === 'internal' && (
                <div>
                  <RowField iconName='folder open' value={proposal.campaignName} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <AuditProposalCardHeader key={proposal.id} proposal={proposal} />
            <div css={style.calendarProposalCardContent}>
              <div>
                <RowField
                  iconName='users'
                  value={proposal.auditors.map((auditor: Contact) => auditor.name).join(', ')}
                />
              </div>
              <div>
                <RowField iconName='calendar alternate' value={`From: ${proposal.proposedDateFrom}`} />
              </div>
              <div>
                <RowField iconName='calendar alternate' value={`To: ${proposal.proposedDateTo}`} />
              </div>
              {proposal.type === 'internal' && (
                <div>
                  <RowField iconName='folder open' value={proposal.campaignName} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

const EventContent = ({ event }: EventContentProps): JSX.Element => {
  return (
    <div css={style.eventContent(event.view.type)}>
      <CalendarProposalCard event={event} />
    </div>
  );
};

interface DateRangeSelectorProps {
  onGotoDate: (date: Date) => void;
}

export const DateRangeSelector = ({ onGotoDate }: DateRangeSelectorProps): JSX.Element => {
  const currentRange = useSelector(selectDateRange);
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [calendarState, setCalendarState] = React.useState(
    currentRange.from == null || currentRange.from === '' ? getDate() : getForcedDateFromUtc(currentRange.from)
  );

  useEffect(() => {
    setCalendarState(
      currentRange.from == null || currentRange.from === '' ? getDate() : getForcedDateFromUtc(currentRange.from)
    );
  }, [currentRange]);

  const updateValue = () => {
    onGotoDate(calendarState);
    setOpen(false);
  };

  const updateTodayValue = () => {
    onGotoDate(new Date());
    setOpen(false);
  };

  const cancelValue = () => {
    setCalendarState(
      currentRange.from == null || currentRange.from === '' ? getDate() : getForcedDateFromUtc(currentRange.from)
    );
    setOpen(false);
  };

  const getCurrentRange = useCallback(() => {
    return `${moment(currentRange.from).format('MMM DD')} - ${moment(currentRange.to).format('MMM DD')}`;
  }, [currentRange.from, currentRange.to]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <>
      <Button css={style.currentDateRange} onClick={() => setOpen(true)}>
        {getCurrentRange()}
      </Button>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{t('auditProposalsAgenda.customDate')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div>
              <Calendar
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(v: Date | Date[]) => setCalendarState(v as Date)}
                value={calendarState}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => updateTodayValue()}>
            <Icon name='clock' /> Today
          </Button>
          <Button css={style.button} onClick={() => cancelValue()}>
            <Icon name='cancel' /> Cancel
          </Button>
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface AgendaToolbarHeaderProps {
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
  onChangeView: (view: string) => void;
  onGotoDate: (date: Date) => void;
}

export const AgendaToolbarHeader = ({
  onPrevButtonClick,
  onNextButtonClick,
  onChangeView,
  onGotoDate,
}: AgendaToolbarHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const searchFilter = useSelector(selectProposalsTextFilter);
  const { t } = useTranslation();
  const [filterWindowOpen, setFilterWindowOpen] = React.useState(false);
  const filters = useSelector(selectProposalsFilters);

  const [brands, setBrands] = useState<Brand[]>(filters.brands);
  const [regions, setRegions] = useState<string[]>(filters.regions);
  const [countries, setCountries] = useState<string[]>(filters.countries);
  const [cities, setCities] = useState<string[]>(filters.cities);
  const [jdaCodes, setJdaCodes] = useState<string[]>(filters.jdaCodes);

  const getFilters = useCallback((): ProposalsFilters => {
    return {
      brands,
      regions,
      countries,
      cities,
      jdaCodes,
    };
  }, [brands, regions, countries, cities, jdaCodes]);

  const numberOfFiltersApplied = useMemo(
    () =>
      (brands.length > 0 ? 1 : 0) +
      (regions.length > 0 ? 1 : 0) +
      (countries.length > 0 ? 1 : 0) +
      (cities.length > 0 ? 1 : 0) +
      (jdaCodes.length > 0 ? 1 : 0),
    [brands, regions, countries, cities, jdaCodes]
  );

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(updateTextFilter(value));
  }, 500);

  const onClearFilters = () => {
    setBrands([]);
    setRegions([]);
    setCountries([]);
    setCities([]);
    setJdaCodes([]);
  };

  const onApplyFilters = () => {
    dispatch(setFilters(getFilters()));
    setFilterWindowOpen(false);
  };

  const regionFilters = useMemo(
    () => ({
      ...emptyProposalsFilters,
      brands,
    }),
    [brands]
  );

  const countryFilters = useMemo(
    () => ({
      ...emptyProposalsFilters,
      brands,
      regions,
    }),
    [brands, regions]
  );

  const cityFilters = useMemo(
    () => ({
      ...emptyProposalsFilters,
      brands,
      regions,
      countries,
    }),
    [brands, regions, countries]
  );

  const jdaCodeFilters = useMemo(
    () => ({
      ...emptyProposalsFilters,
      brands,
      regions,
      countries,
      cities,
    }),
    [brands, regions, countries, cities]
  );

  return (
    <div css={style.calendarToolbar}>
      <Button.Group css={style.changeDateButtons}>
        <Button css={style.iconButton} onClick={onPrevButtonClick} icon='angle left' />
        <DateRangeSelector onGotoDate={onGotoDate} />
        <Button css={style.iconButton} onClick={onNextButtonClick} icon='angle right' />
      </Button.Group>
      <Tab
        css={style.changeViewButtons}
        menu={{ borderless: true }}
        onTabChange={(event, data) => {
          onChangeView(['listMonth', 'dayGridWeek', 'dayGridMonth'][data.activeIndex as number]);
        }}
        panes={[
          { menuItem: t('auditProposalsAgenda.listMonth') },
          { menuItem: t('auditProposalsAgenda.dayGridWeek') },
          { menuItem: t('auditProposalsAgenda.dayGridMonth') },
        ]}
      />
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={searchFilter} onChange={(filter: string) => debounced(filter.trim())} />
        <div css={style.filterButtonContainer} onClick={() => setFilterWindowOpen(true)}>
          <Button css={style.filterButton} icon='filter' />
          {numberOfFiltersApplied > 0 && (
            <Label css={style.filterButtonBadge} floating circular>
              {numberOfFiltersApplied}
            </Label>
          )}
        </div>
        {filterWindowOpen && (
          <ModalPage onClose={() => setFilterWindowOpen(false)} title={t('auditProposalsAgenda.filters') || ''}>
            <div css={style.filterContainer}>
              <div>
                <BrandMultiSelectField
                  filters={emptyProposalsFilters}
                  defaultValue={brands}
                  editable
                  onUpdate={selectedValues => {
                    setBrands(selectedValues);
                    setRegions(emptyProposalsFilters.regions);
                    setCountries(emptyProposalsFilters.countries);
                    setCities(emptyProposalsFilters.cities);
                    setJdaCodes(emptyProposalsFilters.jdaCodes);
                  }}
                />
                <RegionMultiSelectField
                  filters={regionFilters}
                  defaultValue={regions}
                  editable={brands.length > 0}
                  onUpdate={selectedValues => {
                    setRegions(selectedValues);
                    setCountries(emptyProposalsFilters.countries);
                    setCities(emptyProposalsFilters.cities);
                    setJdaCodes(emptyProposalsFilters.jdaCodes);
                  }}
                />
                <CountryMultiSelectField
                  filters={countryFilters}
                  defaultValue={countries}
                  editable={regions.length > 0}
                  onUpdate={selectedValues => {
                    setCountries(selectedValues);
                    setCities(emptyProposalsFilters.cities);
                    setJdaCodes(emptyProposalsFilters.jdaCodes);
                  }}
                />
                <CityMultiSelectField
                  filters={cityFilters}
                  defaultValue={cities}
                  editable={countries.length > 0}
                  onUpdate={selectedValues => {
                    setCities(selectedValues);
                    setJdaCodes(emptyProposalsFilters.jdaCodes);
                  }}
                />
                <StoreMultiSelectField
                  filters={jdaCodeFilters}
                  defaultValue={jdaCodes}
                  editable={cities.length > 0}
                  onUpdate={selectedValues => setJdaCodes(selectedValues)}
                />
              </div>
              <div css={style.filterButtonsContainer}>
                <Button
                  css={style.applyFiltersButton}
                  content={t('assessment.audit.clearFilters')}
                  onClick={onClearFilters}
                  icon='eraser'
                />
                <Button
                  css={style.applyFiltersButton}
                  icon='filter'
                  content={t('assessment.audit.applyFilters')}
                  onClick={onApplyFilters}
                />
              </div>
            </div>
          </ModalPage>
        )}
      </div>
    </div>
  );
};

export const AuditProposalsAgenda = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const proposals = useSelector(selectFilteredSortedProposals);
  const calendar = useRef<FullCalendar>(null);
  const { t } = useTranslation();
  const setDateRange = useCallback(
    (from: Date, to: Date): void => {
      dispatch(fetchAuditProposals(from, to));
    },
    [dispatch]
  );

  const convertProposalToEvent = (proposal: AuditProposalWithCampaignInfo) => ({
    start: moment(proposal.proposedDateFrom).startOf('day').toDate(),
    end: moment(proposal.proposedDateTo).endOf('day').toDate(),
    extendedProps: {
      proposal,
    },
  });
  const next = useCallback(() => {
    if (calendar.current != null) {
      calendar.current.getApi().next();
    }
  }, []);

  const goToDate = useCallback((date: Date) => {
    if (calendar.current != null) {
      calendar.current.getApi().gotoDate(date);
    }
  }, []);

  const prev = useCallback(() => {
    if (calendar.current != null) {
      calendar.current.getApi().prev();
    }
  }, []);

  const changeView = useCallback((view: string) => {
    if (calendar.current != null) {
      calendar.current.getApi().changeView(view);
    }
  }, []);

  const [events, setEvents] = React.useState<EventInput[]>(proposals.map(convertProposalToEvent));

  useEffect(() => {
    setEvents(proposals.map(convertProposalToEvent));
  }, [dispatch, proposals]);

  return (
    <div css={style.container}>
      <AgendaToolbarHeader
        onPrevButtonClick={prev}
        onNextButtonClick={next}
        onChangeView={changeView}
        onGotoDate={goToDate}
      />
      <FullCalendar
        ref={calendar}
        plugins={[listPlugin, dayGridPlugin, momentPlugin]}
        views={{
          dayGridWeek: {
            dayHeaderFormat: 'ddd DD',
          },
          dayGridMonth: {
            dayHeaderFormat: 'ddd',
          },
        }}
        initialView='listMonth'
        headerToolbar={false}
        events={events}
        allDayText=''
        listDayFormat='yyyy-MM-DD'
        listDaySideFormat='dddd'
        eventBackgroundColor='transparent'
        eventBorderColor='transparent'
        eventContent={(eventInfo: EventContentArg) => <EventContent event={eventInfo} />}
        height='100%'
        weekends={false}
        firstDay={1}
        datesSet={({ start, end }: DatesSetArg) => {
          setDateRange(start, moment(end).subtract(1, 'day').toDate());
        }}
        noEventsContent={t('auditProposalsAgenda.noItemsFound')}
      />
    </div>
  );
};
