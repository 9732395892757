import { css, SerializedStyles } from '@emotion/react';
import style from './global.style';

const tab = (selected: boolean, last: boolean, icon: boolean, hasIcon?: boolean): SerializedStyles => {
  return css`
    cursor: pointer;
    max-width: ${icon ? '44px' : 'none'};
    justify-content: center;
    background-color: var(--saa-primary-color-80);
    border-right: ${last ? 'none' : '1px solid var(--saa-primary-color-60)'};
    color: var(--saa-white-color);
    text-decoration: ${selected ? 'underline' : 'none'};
    flex-basis: 50%;
    text-align: center;
    ${style.subtitleNormal};
    padding: 10px;
    &&& {
      font-weight: ${selected ? '500' : '400'};
    }
    @media (max-width: 640px) {
      display: ${hasIcon ? 'none' : 'auto'};
    }
    @media (min-width: 640px) {
      display: ${icon ? 'none' : 'auto'};
    }
  `;
};

const icon = css`
  && {
    margin: 0;
  }
`;

export default {
  tab,
  icon,
};
