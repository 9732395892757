/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { ProposalsFilters } from '../../model/proposalsQueryParams';
import { fetchProposalsRegions, selectIsFetching, selectProposalsRegions } from '../../store/auditProposalsAgendaSlice';

interface RegionMultiSelectFieldProps {
  filters: ProposalsFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function RegionMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: RegionMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchProposalsRegions(filters));
    }
  }, [dispatch, editable, filters]);

  const regions = useSelector(selectProposalsRegions);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: item,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='world'
      defaultValue={defaultValue != null ? defaultValue.map(v => toSelectItem(v)) : []}
      loading={isFetching}
      list={regions.map((b): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.archive.filters.regions') || ''}
      editable={editable}
      onUpdateValue={(selectedItems: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedItems.map(i => toString(i)));
        }
      }}
    />
  );
}
