/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { Auditor } from '../../model/assessment';

interface AssessmentAuditorMultiSelectFieldProps {
  options: Auditor[];
  defaultValue?: Auditor[];
  editable?: boolean;
  onUpdate?: (auditor: Auditor[]) => void;
}

export function AssessmentAuditorMultiSelectField({
  options,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AssessmentAuditorMultiSelectFieldProps) {
  const toSelectItem = (item: Auditor): SelectItem => {
    return { code: item.email, description: item.name };
  };

  const toAuditor = (item: SelectItem): Auditor => {
    return { email: item.code, name: item.description } as Auditor;
  };

  return (
    <ModalMultiSelectField
      icon='users'
      defaultValue={defaultValue.map(toSelectItem) || []}
      list={options.map(toSelectItem)}
      title={t('assessment.archive.filters.auditors') || ''}
      editable={editable}
      onUpdateValue={(selectedAuditor: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedAuditor.map(toAuditor));
        }
      }}
    />
  );
}
