import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field};
  cursor: pointer;
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
`;

const button = css`
  ${style.button}
`;

const inputContainer = css`
  &&&& {
    textarea {
      ${style.textarea};
    }
  }
`;

const modalContainer = css`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

const commentsContainer = css`
  && {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const addButtonContainer = css`
  && {
    margin-top: auto;
    padding: 10px;
    text-align: right;
  }
`;

const noComments = css`
  color: var(--saa-primary-color-60);
  margin: auto;
`;

const addButton = css`
  ${style.button};
`;

const commentContainer = (fromAuditor: boolean, isMyComment: boolean) => css`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-left: ${fromAuditor ? '15px' : 'auto'};
  margin-right: ${fromAuditor ? 'auto' : '15px'};
  position: relative;
  border: solid 1px var(--saa-primary-color-20);

  ::before {
    content: '';
    position: absolute;
    visibility: visible;
    top: 0;
    right: ${!fromAuditor ? '-8px' : 'auto'};
    left: ${fromAuditor ? '-8px' : 'auto'};
    border: 8px solid transparent;
    border-top: ${isMyComment
      ? '10px solid var(--saa-blue-60)'
      : fromAuditor
      ? '10px solid var(--saa-primary-color-80)'
      : '10px solid var(--saa-primary-color-60)'};
    clear: both;
  }
`;

const commentHeader = (fromAuditor: boolean, isMyComment: boolean) => css`
  background-color: ${isMyComment
    ? 'var(--saa-blue-60)'
    : fromAuditor
    ? 'var(--saa-primary-color-80)'
    : 'var(--saa-primary-color-60)'};
  display: flex;
  padding: 10px;
`;

const commentAuthor = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  margin-right: 10px;
  text-align: left;
`;

const commentCreated = css`
  margin-left: auto;
`;

const commentContent = (editMode: boolean) => css`
  background-color: ${editMode ? 'var(--saa-primary-color-10)' : 'var(--saa-white-color)'};
  padding: ${editMode ? '10px' : '0'};
  display: flex;
  align-items: center;
`;

const commentTextArea = (editMode: boolean) => css`
  border-radius: ${editMode ? '20px' : '0'};
  overflow: hidden;
  width: 100%;
  outline: none;
  border-color: transparent;
  resize: none;
  background-color: var(--saa-white-color);
  padding: 10px;
`;

const commentFooter = css`
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color-80);
  padding: 5px;
  display: flex;
  align-items: center;
`;

const commentFooterEdited = css`
  margin-right: auto;
  font-size: 10px;
  font-style: italic;
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const commentFooterButton = css`
  &&& {
    padding: 3px 6px;
    margin-left: auto;
    border-radius: 20px;
    i {
      font-size: 10px;
    }
  }
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  button,
  checkBoxContainer,
  editIcon,
  modalContainer,
  commentsContainer,
  addButtonContainer,
  addButton,
  commentContainer,
  commentHeader,
  commentAuthor,
  commentCreated,
  commentContent,
  commentTextArea,
  commentFooter,
  commentFooterButton,
  commentFooterEdited,
  noComments,
};
