import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

const content = css`
  flex-grow: 1;
  color: var(--saa-primary-color);
  padding: 0 0 10px;
  overflow: auto;
`;

const footer = css`
  flex-shrink: 0;
  height: 60px;
  padding: 10px 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 5px solid var(--saa-white-color);
`;

const rightSideButtons = css`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const skipButton = css`
  && {
    ${globalStyle.primaryButton};
  }
`;

const saveButton = css`
  && {
    ${globalStyle.primaryButton};
    margin-left: auto;
  }
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;

export default {
  container,
  content,
  footer,
  skipButton,
  saveButton,
  modalConfirm,
  rightSideButtons,
};
