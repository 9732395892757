import { css } from '@emotion/react';
import style from '../features/assessments/components/field.style';

const field = css`
  ${style.field};
  position: relative;
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const errorLabel = css`
  &&&& {
    position: absolute;
    left: 90px;
    top: 45px;
    font-size: 0.8em;
    background-color: var(--saa-color-white);
  }
`;

export default {
  field,
  subTitle,
  valueContainer,
  value,
  errorLabel,
};
