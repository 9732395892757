import { API_URL } from '../../../constants';

const USERS_PATH = `${API_URL}/users`;
const AD_USERS_PATH = `${API_URL}/ad-users`;
const KERING_REGION_PATH = `${API_URL}/kering-regions`;
const KERING_COUNTRY_PATH = `${API_URL}/kering-countries`;

const paths = {
  getUsers: (): string => `${USERS_PATH}`,
  deleteUser: (id: string): string => `${USERS_PATH}/${id}`,
  putUser: (id: string): string => `${USERS_PATH}/${id}`,
  getAdUsers: () => `${AD_USERS_PATH}`,
  getMergedUsers: () => `${USERS_PATH}/merged`,
  postUser: (): string => `${USERS_PATH}`,
  getBrandSchedulers: (): string => `${USERS_PATH}/brand-schedulers`,
  getKeringRegions: (): string => `${KERING_REGION_PATH}`,
  getKeringCountries: (): string => `${KERING_COUNTRY_PATH}`,
};

export default paths;
