import { css, SerializedStyles } from '@emotion/react';
import globalStyle from './global.style';

const field = css`
  display: flex;
  position: relative;
  padding: 10px;
  align-items: center;
  border-bottom: solid 1px var(--saa-primary-color-10);
  i {
    flex-shrink: 0;
  }
  div {
    margin: 0 10px;
    padding-right: 10px;
    padding-left: 10px;
    flex-grow: 1;
    text-align: left;
  }
`;

const editableField = css`
  && {
    ${field};
    cursor: pointer;
    position: relative;
    div {
      padding-right: 30px;
    }
  }
`;

const editIcon = css`
  &&& {
    position: absolute;
    border-radius: 25px;
    padding: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    font-size: 0.857rem;
    background-color: var(--saa-primary-color-10);
    && i {
      margin: 0;
    }
  }
`;

const modal = css`
  && {
    max-width: 400px;
  }
`;

const modalContent = css`
  &&& {
    display: flex;
    justify-content: center;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;
    > div {
      overflow: auto;
      flex: 1;
    }
  }
`;

const checkBoxContainer = css`
  && {
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--saa-primary-color-10);
    padding: 10px;

    button {
      margin-left: auto;
      border-radius: 20px;
      background-color: var(--saa-white-color);
    }
  }
`;

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const textarea = css`
  border-color: var(--saa-primary-color-10);
  outline: none;
  width: 100%;
  height: 240px;
  max-height: 240px;
  min-width: 250px;
  min-height: 240px;
  overflow: auto;
  resize: none;
  background-color: transparent;
`;

const validationIcon = css`
  &&& {
    font-size: 1.071rem;
    color: var(--saa-error-dark);
  }
`;

const freeTextTitle = (isValid: boolean): SerializedStyles => css`
  ${globalStyle.subtitleNormal}
  && {
    padding: 0 10px;
    background-color: var(--saa-blue-20);
    font-weight: ${isValid ? '' : 600};
    display: flex;
    align-items: center;
    color: ${isValid ? '' : 'var(--saa-error-dark)'};
    > i {
      padding-left: 10px;
    }
  }
`;

const labeledField = css`
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 40px;
  div {
    margin-right: 10px;
    flex-grow: 1;
    text-align: left;
    ${globalStyle.bodyNormal}
  }
`;

const labeledEditableField = css`
  && {
    ${labeledField};
    cursor: pointer;
    position: relative;
    div {
      padding-right: 30px;
    }
  }
`;

const subTitle = css`
  font-size: 8px;
  line-height: 8px;
  color: var(--saa-primary-color-80);
`;

const valueContainer = css`
  display: flex;
  flex-direction: column;
`;

const value = css`
  min-height: 19.2px;
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  button,
  checkBoxContainer,
  editIcon,
  textarea,
  validationIcon,
  freeTextTitle,
  labeledField,
  labeledEditableField,
  subTitle,
  valueContainer,
  value,
};
