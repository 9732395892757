/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import style from './yearMonthRangeField.style';
import { useCtrlEnterToRunCallback } from '../core/utils';

interface YearMonthRangeFieldProps {
  yearFrom?: number;
  monthFrom?: number;
  yearTo?: number;
  monthTo?: number;
  editable?: boolean;
  onEdited?: (
    yearFrom: number | undefined,
    monthFrom: number | undefined,
    yearTo: number | undefined,
    monthTo: number | undefined
  ) => void;
  title?: string | null;
  clearable?: boolean;
}

export const YearMonthRangeField = ({
  yearFrom,
  monthFrom,
  yearTo,
  monthTo,
  editable = false,
  onEdited = () => null,
  title = '',
  clearable = false,
}: YearMonthRangeFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const [yearFromState, setYearFromState] = React.useState(yearFrom);
  const [monthFromState, setMonthFromState] = React.useState(monthFrom);
  const [yearToState, setYearToState] = React.useState(yearTo);
  const [monthToState, setMonthToState] = React.useState(monthTo);

  useEffect(() => {
    setYearFromState(yearFrom);
    setMonthFromState(monthFrom);
    setYearToState(yearTo);
    setMonthToState(monthTo);
  }, [yearFrom, monthFrom, yearTo, monthTo]);

  const updateValue = useCallback(() => {
    if (yearFromState != null && monthFromState != null && yearToState != null && monthToState != null) {
      onEdited(yearFromState, monthFromState, yearToState, monthToState);
      setOpen(false);
    }
  }, [yearFromState, monthFromState, yearToState, monthToState, onEdited]);

  const clearValue = useCallback(() => {
    setYearFromState(undefined);
    setMonthFromState(undefined);
    setYearToState(undefined);
    setMonthToState(undefined);
    onEdited(undefined, undefined, undefined, undefined);
    setOpen(false);
  }, [onEdited]);

  const cancelValue = useCallback(() => {
    setYearFromState(yearFrom);
    setMonthFromState(monthFrom);
    setYearToState(yearTo);
    setMonthToState(monthTo);
    setOpen(false);
  }, [yearFrom, monthFrom, yearTo, monthTo]);

  const getFieldDescription = useCallback(() => {
    if (yearFrom == null || monthFrom == null || yearTo == null || monthTo == null) {
      return '';
    }
    return `${yearFromState}-${monthFromState} ~ ${yearToState}-${monthToState}`;
  }, [yearFrom, monthFrom, yearTo, monthTo, yearFromState, monthFromState, yearToState, monthToState]);

  useCtrlEnterToRunCallback(updateValue, open);

  const getCalendarValues = useCallback(() => {
    const ris = [];

    const fromDateObject =
      yearFromState != null && monthFromState != null
        ? new DateObject({ year: yearFromState, month: monthFromState, day: 1 })
        : undefined;
    const toDateObject =
      yearToState != null && monthToState != null
        ? new DateObject({ year: yearToState, month: monthToState, day: 1 })
        : undefined;

    if (fromDateObject != null) {
      ris.push(fromDateObject);
    }
    if (toDateObject != null) {
      ris.push(toDateObject);
    }
    return ris;
  }, [yearFromState, monthFromState, yearToState, monthToState]);

  return (
    <div>
      <div
        title={title as string}
        css={editable ? style.editableField : style.field}
        onClick={() => editable && setOpen(true)}>
        <Icon name='calendar alternate' />
        <div css={style.valueContainer}>
          <span css={style.value}> {getFieldDescription()} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.inputContainer}>
            <Calendar
              onlyMonthPicker
              range
              css={style.calendar}
              value={getCalendarValues()}
              highlightToday={false}
              onChange={(selectedDates: DateObject[]) => {
                const from = selectedDates[0];
                const to = selectedDates[1];
                if (from != null) {
                  setYearFromState(from.year);
                  setMonthFromState(from.month.number);
                } else {
                  setYearFromState(undefined);
                  setMonthFromState(undefined);
                }

                if (to != null) {
                  setYearToState(to.year);
                  setMonthToState(to.month.number);
                } else {
                  setYearToState(undefined);
                  setMonthToState(undefined);
                }
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => clearValue()}>
              <Icon name='eraser' /> Clear
            </Button>
          )}
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button
            css={style.button}
            disabled={yearFromState == null || monthFromState == null || yearToState == null || monthToState == null}
            onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
