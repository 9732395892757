import { API_URL } from '../../../constants';

const BRANDS_PATH = `${API_URL}/brands`;
const AUDIT_CAMPAIGNS_PATH = `${API_URL}/audit-campaigns`;
const INTERNAL_AUDIT_PROPOSALS_PATH = 'internal-audit-proposals';
const BRAND_AUDIT_PROPOSALS_PATH = `${API_URL}/brand-audit-proposals`;
const AUDIT_PROPOSALS_PATH = `${API_URL}/audit-proposals`;

const STORES_PATH = `${API_URL}/stores-brand-scheduler`;

const paths = {
  createAuditProposal: (campaignId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}`,
  getAuditProposal: (campaignId: string, proposalId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/${proposalId}`,
  deleteAuditProposal: (campaignId: string, proposalId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/${proposalId}`,
  getAuditCampaigns: (): string => `${AUDIT_CAMPAIGNS_PATH}`,
  getAuditCampaign: (campaignId: string): string => `${AUDIT_CAMPAIGNS_PATH}/${campaignId}`,
  getBrandSchedulers: (): string => `${AUDIT_PROPOSALS_PATH}/brand-schedulers`,
  deleteAuditCampaign: (campaignId: string): string => `${AUDIT_CAMPAIGNS_PATH}/${campaignId}`,
  updateAuditCampaignName: (campaignId: string): string => `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/name`,
  updateAuditCampaignDistributionList: (campaignId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/distribution-list`,
  updateAuditCampaignBrandSchedulers: (campaignId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/brand-schedulers`,
  approveCampaignProposals: (campaignId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/status-changes`,
  updateProposedDate: (campaignId: string, proposalId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/${proposalId}/proposed-dates`,
  updateProposalAuditors: (campaignId: string, proposalId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/${proposalId}/auditors`,
  changeStatus: (campaignId: string, proposalId: string): string =>
    `${AUDIT_CAMPAIGNS_PATH}/${campaignId}/${INTERNAL_AUDIT_PROPOSALS_PATH}/${proposalId}/status-changes`,
  createAuditCampaign: (): string => `${AUDIT_CAMPAIGNS_PATH}`,
  getBrands: (): string => `${BRANDS_PATH}`,
  getBrandRegions: (brandCode: string): string => `${BRANDS_PATH}/${brandCode}/regions`,
  getBrandAuditProposals: (): string => `${BRAND_AUDIT_PROPOSALS_PATH}`,
  createBrandAuditProposals: (): string => `${BRAND_AUDIT_PROPOSALS_PATH}`,
  getBrandAuditProposal: (proposalId: string): string => `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}`,
  getAvailableStores: (): string => `${STORES_PATH}`,
  getAuditProposals: (): string => `${AUDIT_PROPOSALS_PATH}`,
  deleteBrandAuditProposal: (proposalId: string): string => `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}`,
  brandProposalChangeStatus: (proposalId: string): string =>
    `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/status-changes`,
  updateBrandProposalDate: (proposalId: string): string => `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/proposed-dates`,
  updateBrandProposalProposedByBrandDate: (proposalId: string): string =>
    `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/proposed-by-brand-dates`,
  updateBrandProposalBrandScheduler: (proposalId: string): string =>
    `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/brand-schedulers`,
  updateBrandProposalDistributionList: (proposalId: string): string =>
    `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/distribution-list`,
  updateBrandProposalAuditors: (proposalId: string): string => `${BRAND_AUDIT_PROPOSALS_PATH}/${proposalId}/auditors`,
  getProposalsBrands: (): string => `${AUDIT_PROPOSALS_PATH}/brands`,
  getProposalsRegions: (): string => `${AUDIT_PROPOSALS_PATH}/regions`,
  getProposalsCountries: (): string => `${AUDIT_PROPOSALS_PATH}/countries`,
  getProposalsCities: (): string => `${AUDIT_PROPOSALS_PATH}/cities`,
  getProposalsStores: (): string => `${AUDIT_PROPOSALS_PATH}/stores`,
};

export default paths;
