/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Icon, Modal, TextArea } from 'semantic-ui-react';
import style from './freeTextField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface FreeTextFieldProps {
  title: string;
  defaultValue: string;
  editable?: boolean;
  onChange?: (value: string) => void;
  isValid?: boolean;
  mutable?: boolean;
  placeholder?: string;
  openWithEdit?: boolean;
  openWithEditOnClose?: () => void;
}

export const FreeTextField = ({
  title,
  defaultValue,
  onChange = () => null,
  editable = false,
  isValid = true,
  mutable = false,
  placeholder = '',
  openWithEdit = false,
  openWithEditOnClose,
}: FreeTextFieldProps): JSX.Element => {
  const [value, setValue] = React.useState(defaultValue);
  const [open, setOpen] = React.useState(openWithEdit && value === '');

  useEffect(() => {
    if (mutable) setValue(defaultValue);
  }, [defaultValue, mutable]);

  const updateValue = () => {
    onChange(value);
    setOpen(false);
  };
  const cancelValue = () => {
    setValue(defaultValue);
    setOpen(false);
    if (openWithEdit && openWithEditOnClose && value === '') {
      openWithEditOnClose();
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div css={style.freeTextTitle(isValid)} title={title}>
        {title}
      </div>
      <div css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <div style={{ whiteSpace: 'pre-line' }}>
          {' '}
          {(value === '' || value === null) && placeholder !== '' ? placeholder : value}{' '}
          {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}{' '}
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <TextArea
                value={value}
                type='text'
                label={{ icon: 'note' }}
                onChange={event => onChangeInput(event)}
                placeholder={title}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
