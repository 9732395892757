import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import fieldStyle from '../../assessments/components/field.style';

const component = css`
  display: flex;
  height: calc(100% - 60px);
  flex-direction: column;
`;

const campaignDetail = css`
  background-color: var(--saa-white-color);
`;

const content = css`
  background-color: var(--saa-primary-color-10);
  color: var(--saa-primary-color);
  height: 100%;
`;

const body = css`
  height: 100%;
  overflow: auto;
  margin: 10px 0;
`;

const campaignDates = css`
  display: flex;
  width: 100%;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

const notFound = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  background-color: var(--saa-white-color);
`;

const proposalsContainer = css`
  margin: 5px;
`;

const proposalsTitle = css`
  ${globalStyle.bodyNormal};
  && {
    font-weight: 500;
  }
  margin-bottom: 5px;
  @media (min-width: 1200px) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

const warningIcon = css`
  && {
    font-size: 1.714rem;
    color: var(--saa-primary-color);
  }
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;

const confirmButton = css`
  ${globalStyle.confirmButton}
`;

export default {
  component,
  body,
  content,
  campaignDetail,
  campaignDates,
  notFound,
  proposalsContainer,
  proposalsTitle,
  modal,
  modalContent,
  warningIcon,
  modalConfirm,
  confirmButton,
};
