/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import style from './assessmentsBulkUpdateWizard.style';
import {
  AssessmentBulkUpdateNewBrandCoordinators,
  AssessmentBulkUpdateWizardAction,
  AssessmentBulkUpdateWizardField,
  AssessmentBulkUpdateWizardSelectedValue,
  AssessmentBulkUpdateWizardStep,
  UpdateBrandCoordinatorRequest,
} from '../../model/assessmentBulkUpdate';
import { SelectActionStep } from './SelectActionStep';
import { SelectFieldToEditStep } from './SelectFieldToEditStep';
import { SelectValueToReplaceStep } from './SelectValueToReplaceStep';
import { SelectNewValueStep } from './SelectNewValueStep';

import {
  selectSelectedAssessments,
  selectSortedAndFilteredResults,
  updateActionPlanCoordinators,
  updateAuditors,
  updateDistributionListMembers,
  updateReadOnlyDistributionListMembers,
} from '../../store/assessmentBulkUpdateSlice';
import { useAppDispatch } from '../../../../core/store';
import { ConfirmUpdateStep } from './ConfirmUpdateStep';
import { toastService } from '../../../../core/services/toastService';

interface AssessmentBulkUpdateWizardProps {
  open: boolean;
  closeWizard: () => void;
}

export const AssessmentBulkUpdateWizard = ({ open, closeWizard }: AssessmentBulkUpdateWizardProps): JSX.Element => {
  const [step, setStep] = useState(AssessmentBulkUpdateWizardStep.SELECT_FIELD_TO_EDIT);
  const [field, setField] = useState<AssessmentBulkUpdateWizardField | null>(null);
  const [action, setAction] = useState<AssessmentBulkUpdateWizardAction | null>(null);
  const [valueToReplace, setValueToReplace] = useState<string | null>(null);
  const [newValues, setNewValues] = useState<AssessmentBulkUpdateWizardSelectedValue[] | null>(null);
  const [validUpdates, setValidUpdates] = useState<any[] | null>(null);
  const [notValidUpdates, setNotValidUpdates] = useState<any[] | null>(null);
  const selectedAssessments = useSelector(selectSelectedAssessments);
  const assessments = useSelector(selectSortedAndFilteredResults);
  const dispatch = useAppDispatch();
  const onClose = useCallback((): void => {
    setStep(AssessmentBulkUpdateWizardStep.SELECT_FIELD_TO_EDIT);
    setField(null);
    setAction(null);
    setValueToReplace(null);
    setNewValues(null);
    setValidUpdates(null);
    setNotValidUpdates(null);
    closeWizard();
  }, [closeWizard]);

  const getUpdateValues = useCallback(
    (oldValues: string[]): string[] => {
      switch (action) {
        case AssessmentBulkUpdateWizardAction.ADD:
          return _.uniq([...oldValues, ...(newValues?.map(value => value.email) || [])]);
        case AssessmentBulkUpdateWizardAction.CLEAN:
          return [];
        case AssessmentBulkUpdateWizardAction.REPLACE:
          return newValues?.map(value => value.email) || oldValues;
        case AssessmentBulkUpdateWizardAction.FIND_AND_REPLACE:
          return _.uniq([
            ...oldValues.filter(value => value !== valueToReplace),
            ...(newValues?.map(value => value.email) || []),
          ]);
        default:
          return oldValues;
      }
    },
    [action, newValues, valueToReplace]
  );

  const getBrandCoordinatorsUpdateValues = useCallback(
    (oldValues: AssessmentBulkUpdateWizardSelectedValue[]): AssessmentBulkUpdateWizardSelectedValue[] => {
      switch (action) {
        case AssessmentBulkUpdateWizardAction.ADD:
          return _.uniq([...oldValues, ...(newValues || [])]);
        case AssessmentBulkUpdateWizardAction.CLEAN:
          return [];
        case AssessmentBulkUpdateWizardAction.REPLACE:
          return newValues || oldValues;
        case AssessmentBulkUpdateWizardAction.FIND_AND_REPLACE:
          return _.uniq([...oldValues.filter(value => value.email !== valueToReplace), ...(newValues || [])]);
        default:
          return oldValues;
      }
    },
    [action, newValues, valueToReplace]
  );

  const confirmUpdateAuditors = useCallback(async (): Promise<void> => {
    const selected = assessments.filter(assessment => selectedAssessments.includes(assessment.id));
    const response = await dispatch(
      updateAuditors({
        updates: selected.map(assessment => {
          return {
            id: assessment.id,
            auditorEmails: getUpdateValues(assessment.auditors.map(auditor => auditor.email)),
          };
        }),
      })
    );
    if (response != null) {
      toastService.success();
    }
    onClose();
  }, [dispatch, selectedAssessments, assessments, getUpdateValues, onClose]);

  const tryUpdateActionPlanCoordinators = useCallback(async (): Promise<void> => {
    const selected = assessments.filter(assessment => selectedAssessments.includes(assessment.id));
    const allUpdates = selected.map(assessment => {
      return {
        id: assessment.id,
        brandCoordinatorList: getBrandCoordinatorsUpdateValues(
          assessment.actionPlanCoordinators as AssessmentBulkUpdateWizardSelectedValue[]
        ) as UpdateBrandCoordinatorRequest[],
      };
    });
    const valid = allUpdates.filter(update => update.brandCoordinatorList.length <= 2);
    const notValid = allUpdates.filter(update => !valid.some(validUpdate => validUpdate.id === update.id));
    if (notValid.length > 0) {
      setValidUpdates(valid);
      setNotValidUpdates(notValid);
      setStep(AssessmentBulkUpdateWizardStep.CONFIRM_UPDATE);
    } else {
      const response = await dispatch(
        updateActionPlanCoordinators({
          updates: allUpdates,
        })
      );
      if (response != null) {
        toastService.success();
      }
      onClose();
    }
  }, [dispatch, selectedAssessments, assessments, getBrandCoordinatorsUpdateValues, onClose]);

  const confirmUpdateActionPlanCoordinators = useCallback(async (): Promise<void> => {
    const response = await dispatch(
      updateActionPlanCoordinators({
        updates: validUpdates ? (validUpdates as AssessmentBulkUpdateNewBrandCoordinators[]) : [],
      })
    );
    if (response != null) {
      toastService.success();
    }
    onClose();
  }, [dispatch, validUpdates, onClose]);

  const confirmUpdateDistributionListMembers = useCallback(async (): Promise<void> => {
    const selected = assessments.filter(assessment => selectedAssessments.includes(assessment.id));
    const response = await dispatch(
      updateDistributionListMembers({
        updates: selected.map(assessment => {
          return {
            id: assessment.id,
            distributionList: getUpdateValues(assessment.distributionList.map(contact => contact.email)),
          };
        }),
      })
    );
    if (response != null) {
      toastService.success();
    }
    onClose();
  }, [dispatch, selectedAssessments, assessments, getUpdateValues, onClose]);

  const confirmUpdateReadOnlyDistributionListMembers = useCallback(async (): Promise<void> => {
    const selected = assessments.filter(assessment => selectedAssessments.includes(assessment.id));
    const response = await dispatch(
      updateReadOnlyDistributionListMembers({
        updates: selected.map(assessment => {
          return {
            id: assessment.id,
            readOnlyDistributionList: getUpdateValues(
              assessment.readOnlyDistributionList.map(contact => contact.email)
            ),
          };
        }),
      })
    );
    if (response != null) {
      toastService.success();
    }
    onClose();
  }, [dispatch, selectedAssessments, assessments, getUpdateValues, onClose]);

  const onConfirm = useCallback((): void => {
    switch (field) {
      case AssessmentBulkUpdateWizardField.AUDITORS:
        confirmUpdateAuditors();
        break;
      case AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS:
        tryUpdateActionPlanCoordinators();
        break;
      case AssessmentBulkUpdateWizardField.DISTRIBUTION_LIST:
        confirmUpdateDistributionListMembers();
        break;
      case AssessmentBulkUpdateWizardField.READ_ONLY_DISTRIBUTION_LIST:
        confirmUpdateReadOnlyDistributionListMembers();
        break;
      default:
        break;
    }
  }, [
    confirmUpdateAuditors,
    confirmUpdateDistributionListMembers,
    confirmUpdateReadOnlyDistributionListMembers,
    tryUpdateActionPlanCoordinators,
    field,
  ]);

  const onConfirmOnlyValidUpdates = useCallback((): void => {
    switch (field) {
      case AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS:
        confirmUpdateActionPlanCoordinators();
        break;
      default:
        break;
    }
  }, [confirmUpdateActionPlanCoordinators, field]);

  switch (step) {
    case AssessmentBulkUpdateWizardStep.SELECT_FIELD_TO_EDIT:
      return (
        <Modal css={style.modal} open={open}>
          <SelectFieldToEditStep onClose={onClose} setStep={setStep} field={field} setField={setField} />
        </Modal>
      );
    case AssessmentBulkUpdateWizardStep.SELECT_ACTION:
      return (
        <Modal css={style.modal} open={open}>
          <SelectActionStep
            onClose={onClose}
            setStep={setStep}
            action={action}
            setAction={setAction}
            onConfirm={onConfirm}
            field={field}
          />
        </Modal>
      );
    case AssessmentBulkUpdateWizardStep.SELECT_VALUE_TO_REPLACE:
      return (
        <Modal css={style.modal} open={open}>
          <SelectValueToReplaceStep
            onClose={onClose}
            setStep={setStep}
            field={field}
            valueToReplace={valueToReplace}
            setValueToReplace={setValueToReplace}
          />
        </Modal>
      );
    case AssessmentBulkUpdateWizardStep.SELECT_NEW_VALUE:
      return (
        <Modal css={style.modal} open={open}>
          <SelectNewValueStep
            onClose={onClose}
            setStep={setStep}
            action={action}
            field={field}
            newValues={newValues}
            setNewValues={setNewValues}
            onConfirm={onConfirm}
          />
        </Modal>
      );
    case AssessmentBulkUpdateWizardStep.CONFIRM_UPDATE:
      return (
        <Modal css={style.modal} open={open}>
          <ConfirmUpdateStep
            onClose={onClose}
            setStep={setStep}
            validAssessments={assessments.filter(assessment =>
              validUpdates?.some(update => update.id === assessment.id)
            )}
            notValidAssessments={assessments.filter(assessment =>
              notValidUpdates?.some(update => update.id === assessment.id)
            )}
            onConfirm={onConfirmOnlyValidUpdates}
            setValidUpdates={setValidUpdates}
            setNotValidUpdates={setNotValidUpdates}
          />
        </Modal>
      );
    default:
      return <></>;
  }
};
