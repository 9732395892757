/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Icon, LabelGroup } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import style from './assessmentsBulkUpdate.style';
import { AppDispatch, useAppDispatch } from '../../../core/store';
import {
  fetchAssessmentBulkUpdate,
  onChangePage,
  onChangeSelectedAssessments,
  onSort,
  selectFilters,
  selectIsFetching,
  selectPagination,
  selectSelectedAssessments,
  selectShowResults,
  selectSort,
  selectSortedAndFilteredResults,
  selectSortedAndPaginatedResults,
  selectTotalPagesAndCount,
  setShowResults,
} from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateTable } from '../components/assessmentBulkUpdateTable/AssessmentBulkUpdateTable';
import { emptyAssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';
import { BrandMultiSelectField } from '../components/BrandMultiSelectField';
import { Brand } from '../../../shared/model/brand.model';
import { RegionMultiSelectField } from '../components/RegionMultiSelectField';
import { StatusMultiSelectField } from '../components/StatusMultiSelectField';
import { CountryMultiSelectField } from '../components/CountryMultiSelectField';
import { CityMultiSelectField } from '../components/CityMultiSelectField';
import { StoreMultiSelectField } from '../components/StoreMultiSelectField';
import { useCtrlEnterToRunCallback, useEscToRunCallback } from '../../../core/utils';
import { FollowUpStatusMultiSelectField } from '../components/FollowUpStatusMultiSelectField';
import { FilterBadge } from '../../../shared/FilterBadge';
import { AuditorMultiSelectField } from '../components/AuditorMultiSelectField';
import { ActionPlanCoordinatorMultiSelectField } from '../components/ActionPlanCoordinatorMultiSelectField';
import { DistributionListMemberMultiSelectField } from '../components/DistributionListMemberMultiSelectField';
import { ReadOnlyDistributionListMemberMultiSelectField } from '../components/ReadOnlyDistributionListMemberMultiSelectField';
import { AssessmentBulkUpdateWizard } from '../components/assessmentBulkUpdateWizard/AssessmentsBulkUpdateWizard';

export const AssessmentsBulkUpdate = (): JSX.Element => {
  const showFilters = !useSelector(selectShowResults);

  return (
    <div css={style.container}>
      {showFilters ? <AssessmentsBulkUpdateFilters /> : <AssessmentsBulkUpdateResultsTableContainer />}
    </div>
  );
};

export const FiltersBadgesContainer = () => {
  const { t } = useTranslation();
  const filters = useSelector(selectFilters);
  const dispatch = useAppDispatch();

  const getFiltersArray = useCallback(() => {
    const filtersBadges = {
      brands: filters.brands.map(b => b.description).join(', '),
      regions: filters.regions.join(', '),
      countries: filters.countries.join(', '),
      cities: filters.cities.join(', '),
      jdaCodes: filters.jdaCodes.join(', '),
      assessmentStatuses: filters.assessmentStatuses.map(s => t(`assessment.status.${s}`)).join(', '),
      followUpStatuses: filters.followUpStatuses.map(s => t(`assessment.followUpStatus.${s}`)).join(', '),
      auditors: filters.auditors.join(','),
      actionPlanCoordinators: filters.actionPlanCoordinators.join(','),
      distributionListMembers: filters.distributionListMembers.join(','),
      readOnlyDistributionListMembers: filters.readOnlyDistributionListMembers.join(','),
    };

    return Object.keys(filtersBadges)
      .map(k => {
        const realValue = (filters as any)[k];

        return {
          show: !_.isEqual(realValue, (emptyAssessmentBulkUpdateFilters as any)[k]),
          field: k,
          description: (filtersBadges as any)[k],
          label: t(`assessment.bulkUpdate.filters.${k}`),
        };
      })
      .filter(f => f.show);
  }, [filters, t]);

  const onFilterButtonClick = useCallback(() => {
    dispatch(setShowResults(false));
  }, [dispatch]);

  useEscToRunCallback(onFilterButtonClick);

  return (
    <div css={style.badgeGroup}>
      <LabelGroup circular>
        {getFiltersArray().map(f => (
          <FilterBadge value={f.description} label={f.label} />
        ))}
      </LabelGroup>
      <Button css={style.filterButton} onClick={onFilterButtonClick}>
        <Icon name='filter' />
        <span>{t('assessment.bulkUpdate.filtersText')}</span>
      </Button>
    </div>
  );
};

const AssessmentsBulkUpdateResultsTableContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const results = useSelector(selectSortedAndPaginatedResults);
  const allResults = useSelector(selectSortedAndFilteredResults);
  const isFetching = useSelector(selectIsFetching);
  const sort = useSelector(selectSort);
  const pagination = useSelector(selectPagination);
  const { totalPages, totalCount } = useSelector(selectTotalPagesAndCount);
  const handleSort = useCallback((clickedColumn: string) => dispatch(onSort(clickedColumn)), [dispatch]);
  const handleChangePage = useCallback((page: number) => dispatch(onChangePage(page)), [dispatch]);
  const [openWizard, setOpenWizard] = useState(false);
  const selectedAssessments = useSelector(selectSelectedAssessments);
  const handleChangeSelectedAssessments = useCallback(
    (assessments: string[]) => dispatch(onChangeSelectedAssessments(assessments)),
    [dispatch]
  );
  return (
    <div css={style.content}>
      <div css={style.segmentContainer}>
        <FiltersBadgesContainer />
        <AssessmentBulkUpdateWizard open={openWizard} closeWizard={() => setOpenWizard(false)} />
        <div css={style.tableContainer}>
          <AssessmentBulkUpdateTable
            elements={results}
            sortColumn={sort.sortColumn}
            direction={sort.sortOrder}
            handleSort={handleSort}
            totalPages={totalPages}
            currentPage={pagination.page}
            onChangePage={handleChangePage}
            pageSize={pagination.limit}
            totalCount={totalCount}
            isLoadingData={isFetching}
            selectedAssessments={selectedAssessments}
            onChangeSelectedAssessments={handleChangeSelectedAssessments}
            allIds={allResults.map(assessment => assessment.id)}
          />
        </div>
      </div>
      <div css={style.footer}>
        <Button
          css={style.footerButton}
          icon
          labelPosition='left'
          onClick={() => setOpenWizard(true)}
          disabled={selectedAssessments.length === 0}>
          <Icon name='pencil' />
          {t('assessment.bulkUpdate.updateButton')}
        </Button>
      </div>
    </div>
  );
};

const AssessmentsBulkUpdateFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectFilters);

  const [assessmentStatuses, setAssessmentStatuses] = useState<string[]>(filters.assessmentStatuses);
  const [brands, setBrands] = useState<Brand[]>(filters.brands);
  const [regions, setRegions] = useState<string[]>(filters.regions);
  const [countries, setCountries] = useState<string[]>(filters.countries);
  const [cities, setCities] = useState<string[]>(filters.cities);
  const [jdaCodes, setJdaCodes] = useState<string[]>(filters.jdaCodes);
  const [followUpStatuses, setFollowUpStatuses] = useState(filters.followUpStatuses);
  const [auditors, setAuditors] = useState<string[]>(filters.auditors);
  const [actionPlanCoordinators, setActionPlanCoordinators] = useState<string[]>(filters.actionPlanCoordinators);
  const [distributionListMembers, setDistributionListMembers] = useState<string[]>(filters.distributionListMembers);
  const [readOnlyDistributionListMembers, setReadOnlyDistributionListMembers] = useState<string[]>(
    filters.readOnlyDistributionListMembers
  );

  const getFilters = useCallback(
    () => ({
      assessmentStatuses,
      brands,
      regions,
      countries,
      cities,
      jdaCodes,
      followUpStatuses,
      auditors,
      actionPlanCoordinators,
      distributionListMembers,
      readOnlyDistributionListMembers,
    }),
    [
      brands,
      cities,
      countries,
      jdaCodes,
      regions,
      assessmentStatuses,
      followUpStatuses,
      auditors,
      actionPlanCoordinators,
      distributionListMembers,
      readOnlyDistributionListMembers,
    ]
  );

  const search = useCallback(() => {
    dispatch(fetchAssessmentBulkUpdate(getFilters()));
    dispatch(setShowResults(true));
  }, [dispatch, getFilters]);

  const clear = useCallback(() => {
    setAssessmentStatuses([]);
    setBrands([]);
    setRegions([]);
    setCountries([]);
    setCities([]);
    setJdaCodes([]);
    setFollowUpStatuses([]);
    setAuditors([]);
    setActionPlanCoordinators([]);
    setDistributionListMembers([]);
    setReadOnlyDistributionListMembers([]);
  }, []);

  useCtrlEnterToRunCallback(search);

  return (
    <div css={style.filtersContainer}>
      <div>
        <BrandMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={brands}
          editable
          onUpdate={selectedValues => {
            setBrands(selectedValues);
          }}
        />
        <RegionMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={regions}
          editable
          onUpdate={selectedValues => {
            setRegions(selectedValues);
            setCountries(emptyAssessmentBulkUpdateFilters.countries);
            setCities(emptyAssessmentBulkUpdateFilters.cities);
            setJdaCodes(emptyAssessmentBulkUpdateFilters.jdaCodes);
          }}
        />
        <CountryMultiSelectField
          filters={{
            ...emptyAssessmentBulkUpdateFilters,
            regions: getFilters().regions,
          }}
          defaultValue={countries}
          editable={regions.length > 0}
          onUpdate={selectedValues => {
            setCountries(selectedValues);
            setCities(emptyAssessmentBulkUpdateFilters.cities);
            setJdaCodes(emptyAssessmentBulkUpdateFilters.jdaCodes);
          }}
        />
        <CityMultiSelectField
          filters={{
            ...emptyAssessmentBulkUpdateFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
          }}
          defaultValue={cities}
          editable={countries.length > 0}
          onUpdate={selectedValues => {
            setCities(selectedValues);
            setJdaCodes(emptyAssessmentBulkUpdateFilters.jdaCodes);
          }}
        />
        <StoreMultiSelectField
          filters={{
            ...emptyAssessmentBulkUpdateFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
          }}
          defaultValue={jdaCodes}
          editable={cities.length > 0}
          onUpdate={selectedValues => setJdaCodes(selectedValues)}
        />

        <StatusMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={assessmentStatuses}
          editable
          onUpdate={selectedValues => setAssessmentStatuses(selectedValues)}
        />

        <FollowUpStatusMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={followUpStatuses}
          editable
          onUpdate={selectedValues => setFollowUpStatuses(selectedValues)}
        />
        <AuditorMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={auditors}
          editable
          onUpdate={selectedValues => setAuditors(selectedValues)}
        />
        <ActionPlanCoordinatorMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={actionPlanCoordinators}
          editable
          onUpdate={selectedValues => setActionPlanCoordinators(selectedValues)}
        />
        <DistributionListMemberMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={distributionListMembers}
          editable
          onUpdate={selectedValues => setDistributionListMembers(selectedValues)}
        />
        <ReadOnlyDistributionListMemberMultiSelectField
          filters={emptyAssessmentBulkUpdateFilters}
          defaultValue={readOnlyDistributionListMembers}
          editable
          onUpdate={selectedValues => setReadOnlyDistributionListMembers(selectedValues)}
        />
      </div>

      <div css={style.buttonsContainer}>
        <Button css={style.button} onClick={clear}>
          <Icon name='eraser' />
          {t('assessment.bulkUpdate.clear')}
        </Button>
        <Button css={style.button} onClick={search}>
          <Icon name='search' />
          {t('assessment.bulkUpdate.search')}
        </Button>
      </div>
    </div>
  );
};
