/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Checkbox, Icon, SemanticICONS } from 'semantic-ui-react';
import style from './toggleField.style';

interface AssessmentFieldProps {
  icon?: SemanticICONS;
  isValid?: boolean;
  defaultValue: boolean;
  title: string;
  label?: string;
  className?: string;
  onChange?: (value: boolean) => void;
  editable: boolean;
}

export const ToggleField = ({
  icon,
  isValid = true,
  defaultValue,
  title,
  label = '',
  className = '',
  onChange,
  editable = false,
}: AssessmentFieldProps): JSX.Element => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (onChange && value !== defaultValue) onChange(value);
  }, [defaultValue, onChange, value]);

  return (
    <div
      title={title}
      css={editable ? style.editableField : style.field}
      onClick={() => {
        if (onChange != null && editable) setValue(prevState => !prevState);
      }}>
      <Icon className={className} name={icon} color={!isValid ? 'red' : 'black'} />
      <div css={style.valueContainer}>{label}</div>
      <div css={style.checkBoxContainer}>
        <Checkbox toggle checked={value} disabled={!editable} />
      </div>
    </div>
  );
};
