/** @jsxImportSource @emotion/react */
import React from 'react';
import { PrivacyModal } from '../features/auth/components/PrivacyModal';
import style from './pageNoHeader.style';

export const PageNoHeader = (props: { children?: JSX.Element[] | JSX.Element }): JSX.Element => {
  const { children } = props;
  return (
    <>
      <PrivacyModal />
      <div css={style.container}>{children}</div>
    </>
  );
};
