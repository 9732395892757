/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Icon } from 'semantic-ui-react';
import { t } from 'i18next';
import { SearchBox } from '../../../../shared/SearchBox';
import {
  onFilterChange,
  selectFilteredAndOwnedCampaigns,
  selectCampaignFilter,
} from '../../store/auditProposalWizardSlice';
import { useAppDispatch } from '../../../../core/store';
import { BrandImage } from '../../../../shared/BrandImage';
import style from './campaignSelectionStep.style';
import { StepSegment } from '../StepSegment';

export function CampaignSelectionStep({
  onCampaignSelection,
  onCampaignCreation,
}: {
  onCampaignSelection: (data: any) => void;
  onCampaignCreation: () => void;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onFilterChange(value));
  }, 300);

  const searchFilter = useSelector(selectCampaignFilter);
  const filteredAndOwnedCampaigns = useSelector(selectFilteredAndOwnedCampaigns);
  return (
    <StepSegment>
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={searchFilter} onChange={(filter: string) => debounced(filter.trim())} />
      </div>
      <div
        css={style.createCampaignCard}
        onClick={() => {
          onCampaignCreation();
        }}>
        <Icon css={style.plusIcon} name='plus' circular />
        <div css={style.campaignInfo}>
          <span css={style.createCampaignCardLabel}>{t('auditCampaigns.createCampaign')}</span>
        </div>
      </div>
      <div css={style.campaignList}>
        {filteredAndOwnedCampaigns.map(campaign => (
          <div
            key={campaign.id}
            css={style.campaignCard}
            onClick={() => {
              onCampaignSelection(campaign.id);
            }}>
            <BrandImage src={campaign.brand.logo || ''} />
            <div css={style.campaignInfo}>
              <div css={style.campaignName}>{campaign.name}</div>
              <div css={style.campaignDetail}>
                <div>
                  <Icon name='user' /> <span css={style.campaignDetailLabel}>{campaign.brandSchedulers[0].name}</span>
                </div>
                <div>
                  <Icon name='globe' /> <span css={style.campaignDetailLabel}>{campaign.region.description}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </StepSegment>
  );
}
