import { css } from '@emotion/react';

const stepContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const stepContent = css`
  height: 100%;
  max-height: calc(100% - 74px);
  flex-grow: 1;
  && {
    color: var(--saa-primarystepContainer-color-80);
  }
`;

export default {
  stepContainer,
  stepContent,
};
