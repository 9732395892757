import { Brand } from '../../../shared/model/brand.model';

export interface AssessmentReportQueryParams {
  assessmentStatuses: string[];
  brands: string[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  yearFrom?: number;
  monthFrom?: number;
  yearTo?: number;
  monthTo?: number;
  priorities?: string[];
}

export interface AssessmentReportFilters {
  assessmentStatuses: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  yearFrom?: number;
  monthFrom?: number;
  yearTo?: number;
  monthTo?: number;
  followUpStatuses: string[];
  priorities: string[];
}

export const emptyAssessmentReportFilters: AssessmentReportFilters = {
  assessmentStatuses: [],
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  jdaCodes: [],
  monthFrom: undefined,
  monthTo: undefined,
  yearFrom: undefined,
  yearTo: undefined,
  followUpStatuses: [],
  priorities: [],
};

export const covertFiltersInQueryParams = (filters: AssessmentReportFilters): AssessmentReportQueryParams => ({
  ...filters,
  priorities: [],
  brands: filters.brands.map(b => b.code),
});
