import { css } from '@emotion/react';
import style from '../../../../shared/global.style';

const searchBoxContainer = css`
  margin: 10px 5px 0;
  display: flex;
  justify-content: center;
`;

const campaignList = css`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
`;

const campaignCard = css`
  background-color: var(--saa-white-color);
  margin-bottom: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const plusIcon = css`
  &&&&& {
    color: var(--saa-primary-color-60);
    box-shadow: 0 0 0 0.1em var(--saa-primary-color-60) inset;
    font-size: 25px;
    line-height: 16px !important;
    margin-right: 10px;
    ::before {
      font-size: 1rem;
    }
  }
`;

const createCampaignCard = css`
  ${campaignCard};
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const createCampaignCardLabel = css`
  ${style.subtitleNormal};
  color: var(--saa-primary-color-80);
`;
const campaignInfo = css`
  margin-left: 10px;
`;
const campaignName = css`
  ${style.subtitleNormal};
  color: var(--saa-primary-color-80);
  text-align: left;
`;
const campaignDetail = css`
  ${style.bodySmall};
  color: var(--saa-primary-color-80);
  display: flex;
  align-items: center;
`;
const campaignDetailLabel = css`
  ${style.bodySmall};
  color: var(--saa-primary-color-60);
  margin-right: 15px;
`;

export default {
  searchBoxContainer,
  campaignList,
  createCampaignCard,
  plusIcon,
  campaignCard,
  createCampaignCardLabel,
  campaignName,
  campaignInfo,
  campaignDetail,
  campaignDetailLabel,
};
