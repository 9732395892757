import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&& {
    > div {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const inputContainer = css`
  &&&& {
    > div {
      margin-bottom: 10px;
    }
    input {
      border-color: var(--saa-primary-color-10);
      text-align: center;
      width: 100px;
      margin-right: 5px;
      margin-left: 5px;
    }
    button {
      margin: 0;
    }
    .disabled {
      opacity: 1;
    }
  }
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  checkBoxContainer,
  editIcon,
  subTitle,
  valueContainer,
  value,
};
