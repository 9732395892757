/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import style from './topicContainer.style';
import {
  createTopicComment,
  createTopicForDiscussion,
  editTopicForDiscussion,
  selectBrandCoordinatorList,
  selectCanCRUDTopic,
  selectCanWriteTopicComment,
  selectDistributionList,
  selectIsSentToTheStoreStatus,
  selectTopics,
  updateTopicComment,
} from '../store/assessmentDetailSlice';
import { TopicForDiscussion } from '../model/assessmentDetail.model';
import { TopicModal } from './TopicModal';
import { useAppDispatch } from '../../../core/store';
import { toastService } from '../../../core/services/toastService';
import { Contact } from '../../users/model/user.model';

interface TopicContainerProps {
  onChangeTopicActive: (x: boolean) => void;
  isTopicContainerActive: boolean;
}

export const TopicContainer = ({ onChangeTopicActive, isTopicContainerActive }: TopicContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showTopicModal, setShowTopicModal] = React.useState(false);
  const topics = useSelector(selectTopics);
  const [selectedTopic, setSelectedTopic] = useState<TopicForDiscussion>({
    id: '',
    title: '',
    comments: [],
  });
  const canCRUDTopic: boolean = useSelector(selectCanCRUDTopic);
  const distributionList: Contact[] = useSelector(selectDistributionList);
  const brandCoordinatorList: Contact[] = useSelector(selectBrandCoordinatorList);
  const isSentToTheStoreStatus: boolean = useSelector(selectIsSentToTheStoreStatus);
  const canCreateTopicComment = useSelector(selectCanWriteTopicComment);

  const areAllTopicsValid = (): boolean => {
    return topics.every(topic => isLastCommentValid(topic));
  };

  const isLastCommentValid = (topic: TopicForDiscussion): boolean => {
    if (topic.comments.length === 0) {
      return true;
    }
    const sortedComments = topic.comments
      .slice()
      .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const author = sortedComments.at(sortedComments.length - 1)?.author;
    const isAuthorInDistributionList = distributionList.some(contact => contact.email === author?.email);
    const isAuthorBrandCoordinator = brandCoordinatorList.some(contact => contact.email === author?.email);
    return isAuthorBrandCoordinator || isAuthorInDistributionList;
  };

  const onEditTitle = async (newTitle: string) => {
    const newTopic =
      selectedTopic.id === ''
        ? await dispatch(createTopicForDiscussion(newTitle))
        : await dispatch(
            editTopicForDiscussion({
              id: selectedTopic.id,
              title: newTitle,
            })
          );

    setSelectedTopic(
      newTopic !== null
        ? { id: newTopic.id, title: newTitle, comments: newTopic.comments }
        : { id: '', title: newTitle, comments: [] }
    );
  };

  const onCloseTopicModal = () => {
    setShowTopicModal(false);
    setSelectedTopic({
      id: '',
      title: '',
      comments: [],
    });
  };

  const onCreateComment = useCallback(
    async (message: string) => {
      const response = await dispatch(createTopicComment(selectedTopic.id, message));
      if (response != null) {
        setSelectedTopic(response);
        toastService.success();
      }
    },
    [dispatch, selectedTopic]
  );

  const onUpdateComment = useCallback(
    async (message: string, commentId: string) => {
      const response = await dispatch(updateTopicComment(selectedTopic.id, commentId, message));
      if (response != null) {
        setSelectedTopic(response);
        toastService.success();
      }
    },
    [dispatch, selectedTopic]
  );

  const onClickNewTopic = useCallback((topic: TopicForDiscussion) => {
    setSelectedTopic(topic);
    setShowTopicModal(true);
  }, []);

  return (
    <Accordion css={style.process}>
      <Accordion.Title
        active={isTopicContainerActive}
        css={style.processTitle}
        onClick={() => onChangeTopicActive(true)}>
        <Icon name='dropdown' />
        <div>{t('assessment.audit.topicForDiscussion.dropDownTitle')}</div>
        {isSentToTheStoreStatus && !areAllTopicsValid() && <Icon name='comment outline' css={style.errorIcon} />}
      </Accordion.Title>
      <Accordion.Content active={isTopicContainerActive} css={style.processContent}>
        <Accordion.Content active={isTopicContainerActive}>
          <>
            {canCRUDTopic && (
              <>
                <div
                  css={style.newTopicbutton}
                  onClick={() =>
                    onClickNewTopic({
                      id: '',
                      title: '',
                      comments: [],
                    })
                  }>
                  <Icon name='plus' css={style.newTopicIcon} />
                  <div css={style.topicDescription}>{t('New Topic')}</div>
                </div>
              </>
            )}
            {topics.map(topic => (
              <div css={style.topicDescriptionContent} onClick={() => onClickNewTopic(topic)}>
                {canCreateTopicComment ? (
                  <Icon name='pencil' css={style.editTopicIcon} />
                ) : (
                  <Icon name='search' css={style.editTopicIcon} />
                )}
                <div css={style.topicDescription}>{topic.title}</div>
                {isSentToTheStoreStatus && !isLastCommentValid(topic) && (
                  <Icon name='comment outline' css={style.errorIcon} />
                )}
              </div>
            ))}
            {showTopicModal && (
              <TopicModal
                onClose={onCloseTopicModal}
                onCreateComment={onCreateComment}
                onUpdateComment={onUpdateComment}
                selectedTopic={selectedTopic}
                onEditTitle={onEditTitle}
              />
            )}
          </>
        </Accordion.Content>
      </Accordion.Content>
    </Accordion>
  );
};
