/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Principal } from '../model/principal.model';
import api from '../utils/api';
import type { AppThunk } from '../../../core/store';

interface PrincipalSliceState {
  data: Principal | null;
  isFetching: boolean;
  error: string;
}

const initialState = {
  data: null,
  isFetching: false,
  error: '',
} as PrincipalSliceState;

export const principalSlice = createSlice({
  name: 'principal',
  initialState,
  reducers: {
    START_FETCH: (state: PrincipalSliceState) => ({ ...state, isFetching: true }),
    FINISH_FETCH: (state: Draft<PrincipalSliceState>, action: PayloadAction<Principal>) => {
      return { isFetching: false, data: { ...action.payload }, error: '' };
    },
    FAIL_FETCH: (state: PrincipalSliceState, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    UPDATE_POLICY: (state: Draft<PrincipalSliceState>, action: PayloadAction<boolean>) => {
      const principal = state.data;
      const policiesAccepted = action.payload;
      if (principal) {
        principal.policiesAccepted = policiesAccepted;
      }
    },
    FAIL_UPDATE_POLICY: (state: PrincipalSliceState, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { START_FETCH, FINISH_FETCH, FAIL_FETCH, UPDATE_POLICY, FAIL_UPDATE_POLICY } = principalSlice.actions;

export const fetchPrincipal = (): AppThunk => async (dispatch: any) => {
  dispatch(START_FETCH());
  try {
    const principal = await api.getPrincipal();
    dispatch(FINISH_FETCH(principal));
  } catch (error) {
    dispatch(FAIL_FETCH(JSON.stringify(error)));
  }
};

export const acceptPolicy = () => async (dispatch: any) => {
  try {
    const principal = await api.putPrincipalPolicy();
    dispatch(UPDATE_POLICY(principal.policiesAccepted));
  } catch (error) {
    dispatch(FAIL_UPDATE_POLICY(JSON.stringify(error)));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPrincipal = (state: any) => state.principal.data as Principal;

export default principalSlice.reducer;
