import { css } from '@emotion/react';
import style from '../features/assessments/components/field.style';
import globalStyle from './global.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent}
`;

const listItem = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  && {
    padding: 10px;
    :not(:last-of-type) {
      border-bottom: solid 1px var(--saa-primary-color-10);
    }
  }
`;
const itemInfo = css`
  display: flex;
  flex-direction: column;
`;
const description = css``;
const subDescription = css`
  margin-left: 30px;
  font-size: 0.8rem;
`;
const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const checkBoxContainer = css`
  && {
    border-bottom: solid 1px var(--saa-primary-color-10);
    padding: 10px;
  }
`;

const checkBox = css`
  && {
    width: 100%;
    ${globalStyle.bodyNormal}
    input {
      top: 4px;
    }
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      :before {
        top: 3px;
      }
    }
  }
`;

const checkBoxLarge = css`
  && {
    width: 100%;
    ${globalStyle.bodyNormal}
    input {
      top: 4px;
    }
    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      :before {
        top: 15px;
      }
      :after {
        top: 12px;
      }
    }
  }
`;

const noItems = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  listItem,
  editIcon,
  subTitle,
  valueContainer,
  value,
  itemInfo,
  description,
  subDescription,
  checkBoxContainer,
  checkBox,
  checkBoxLarge,
  actions,
  button,
  noItems,
};
