import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const container = css`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const filtersContainer = css`
  margin: auto;
  display: flex;
  max-width: 600px;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding: 15px 15px 0;
  background-color: var(--saa-primary-color-5);
  border-radius: 4px;

  @media (max-width: 540px) {
    height: 100%;
  }
`;

const buttonsContainer = css`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 15px;
  position: sticky;
  bottom: 0;
  background-color: var(--saa-primary-color-5);
  gap: 5px;
`;

const button = css`
  ${globalStyle.primaryButton};
  width: 120px;
  @media (max-width: 540px) {
    flex: 1;
  }
`;

const footerButton = css`
  &&& {
    flex: 1;
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    min-width: 160px;
    max-width: min(250px, 100%);
    min-height: 46px;
    margin-bottom: 5px;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const segmentContainer = css`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--saa-primary-color-10);
    border: none;
    box-shadow: none;
    margin: 0;
    flex-grow: 1;
    overflow: auto;
    padding: 10px;
  }
`;

const content = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const tableContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  //background-color: var(--saa-white-color);
  border-radius: 0;
`;

const badgeGroup = css`
  min-height: 37px;
  height: max-content;
  margin: 5px 0 15px;
  border-bottom: 1px solid var(--saa-primary-color-60);
  padding-bottom: 5px;
  display: flex;
  align-items: center;

  .ui.labels {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;

const filterButton = css`
  && {
    margin-right: 10px;
    margin-left: auto;
    @media (min-width: 540px) {
      ${globalStyle.primaryButton};
    }
    @media (max-width: 540px) {
      ${globalStyle.primarySmallButton};
      span {
        display: none;
      }
    }
  }
`;

const exportButton = css`
  && {
    margin-left: auto;
    @media (min-width: 540px) {
      ${globalStyle.primaryButton};
    }
    @media (max-width: 540px) {
      ${globalStyle.primarySmallButton};
      span {
        display: none;
      }
    }
  }
`;

const footer = css`
  justify-content: flex-end;
  display: flex;
  margin-top: auto;
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  padding: 10px 10px 5px;
  min-height: 60px;
  flex-shrink: 0;
`;

const buttonContainer = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 50%;
`;

const buttonRightContainer = css`
  &&&& {
    ${buttonContainer};
    justify-content: flex-end;
  }
`;

export default {
  container,
  button,
  filtersContainer,
  buttonsContainer,
  segmentContainer,
  tableContainer,
  badgeGroup,
  filterButton,
  exportButton,
  footer,
  buttonRightContainer,
  footerButton,
  content,
};
