import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const modalContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

const topicInputContent = css`
  color: var(--saa-primary-color);
`;

const commentsTitle = css`
  ${globalStyle.subtitleNormal}
  && {
    padding: 0 10px;
    background-color: var(--saa-blue-20);
    display: flex;
    align-items: center;
    color: ${'var(--saa-primary-color)'};
    > i {
      padding-left: 10px;
    }
  }
`;

const inputContainer = css`
  && {
    background-color: var(--saa-primary-color-10);
    overflow: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 15px;
    height: fit-content;
  }
`;

const textarea = css`
  border-color: transparent;
  outline: none;
  width: 100%;
  overflow: hidden;
  resize: none;
  background-color: var(--saa-white-color);
  vertical-align: middle;
  border-radius: 20px;
  padding: 10px;
`;

const textFieldContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
  &&&& {
    textarea {
      ${textarea};
    }
  }
`;

const footer = css`
  display: flex;
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  padding: 10px 10px 5px;
  min-height: 60px;
  flex-shrink: 0;
`;

const buttonRightContainer = css`
  &&&& {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 20%;
    justify-content: flex-end;
  }
`;

const button = css`
  &&& {
    flex: 1;
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    min-width: 160px;
    max-width: min(250px, 100%);
    min-height: 46px;
    margin-bottom: 5px;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
    padding-left: 10px !important;
    padding-right: 36px !important;
    font-size: 0.5rem;
  }
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;
const confirmButton = css`
  ${globalStyle.confirmButton}
`;

const commentsContainer = css`
  && {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--saa-white-color);
  }
`;

const sendIcon = css`
  &&& {
    border-radius: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    height: 35px;
    width: 35px;
    font-size: 0.857rem;
    background-color: var(--saa-primary-color);
    margin-left: 10px;
    cursor: pointer;
  }
`;

const sendIconContent = css`
  &&& {
    display: flex;
    align-items: center;
  }
`;

export default {
  modalContainer,
  topicInputContent,
  commentsTitle,
  inputContainer,
  textFieldContainer,
  sendIcon,
  footer,
  buttonRightContainer,
  button,
  modalConfirm,
  confirmButton,
  commentsContainer,
  sendIconContent,
};
