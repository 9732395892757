import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditProposal,
  ChangeStatusRequest,
  UpdateProposalAuditorsRequest,
  UpdateProposalDateRequest,
} from '../model/auditProposal';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../core/services/toastService';
import { Contact } from '../../users/model/user.model';

interface AuditProposalDetailSliceState {
  campaignId: string;
  proposal: AuditProposal | null;
  isFetching: boolean;
  isUpdating: boolean;
  error: string;
}

const initialState: AuditProposalDetailSliceState = {
  campaignId: '',
  proposal: null,
  isFetching: false,
  isUpdating: false,
  error: '',
};

export const auditProposalDetailSlice = createSlice({
  name: 'auditProposalDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditProposalDetailSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<AuditProposalDetailSliceState>, { payload }: PayloadAction<AuditProposal>) => ({
      ...state,
      isFetching: false,
      proposal: payload,
      error: '',
    }),
    httpError: (state: Draft<AuditProposalDetailSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    updateProposal: (state: Draft<AuditProposalDetailSliceState>, { payload }: PayloadAction<AuditProposal>) => ({
      ...state,
      proposal: payload,
    }),
    setCampaignId: (state: Draft<AuditProposalDetailSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      campaignId: payload,
    }),
    startUpdating: (state: Draft<AuditProposalDetailSliceState>) => ({
      ...state,
      isUpdating: true,
    }),
    finishUpdating: (state: Draft<AuditProposalDetailSliceState>) => ({
      ...state,
      isUpdating: false,
      error: '',
    }),
  },
});

export const { startFetch, finishFetch, httpError, updateProposal, setCampaignId, startUpdating, finishUpdating } =
  auditProposalDetailSlice.actions;

export default auditProposalDetailSlice.reducer;

export const fetchAuditProposal =
  (campaignId: string, proposalId: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const proposal = await api.getAuditProposal(campaignId, proposalId);
      dispatch(finishFetch(proposal));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateProposedDate =
  (campaignId: string, proposalId: string, dateFrom: string, dateTo: string): AppThunk =>
  async (dispatch: any) => {
    const request: UpdateProposalDateRequest = {
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    };
    try {
      const proposal = await api.updateProposedDate(campaignId, proposalId, request);
      dispatch(updateProposal(proposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateProposalAuditors =
  (campaignId: string, proposalId: string, auditors: Contact[]): AppThunk =>
  async (dispatch: any) => {
    const request: UpdateProposalAuditorsRequest = {
      auditors: auditors.map(auditor => auditor.email),
    };
    try {
      const proposal = await api.updateProposalAuditors(campaignId, proposalId, request);
      dispatch(updateProposal(proposal));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const deleteProposal =
  (campaignId: string, proposalId: string): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const proposal = await api.deleteAuditProposal(campaignId, proposalId);
      dispatch(updateProposal(proposal));
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const changeStatus =
  (
    campaignId: string,
    proposalId: string,
    changeStatusRequest: ChangeStatusRequest
  ): AppThunk<Promise<AuditProposal | null>> =>
  async (dispatch: any): Promise<AuditProposal | null> => {
    dispatch(startUpdating());
    try {
      const proposal = await api.changeStatus(campaignId, proposalId, changeStatusRequest);
      dispatch(updateProposal(proposal));
      toastService.success();
      return proposal;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    } finally {
      dispatch(finishUpdating());
    }
  };

export const selectAuditProposal = (state: RootState) => state.auditProposalDetail.proposal;

export const selectIsFetching = (state: RootState): boolean => state.auditProposalDetail.isFetching;

export const selectCampaignId = (state: RootState): string => state.auditProposalDetail.campaignId;

export const selectIsUpdating = (state: RootState): boolean => state.auditProposalDetail.isUpdating;
