import { css, SerializedStyles } from '@emotion/react';
import { Priority } from '../model/assessmentDetail.model';

export const getPriorityColor = (priority: Priority | ''): string => {
  switch (priority) {
    case Priority.PASS:
      return '--saa-primary-color-60';
    case Priority.MEDIUM:
      return '--saa-priority-medium';
    case Priority.SIGNIFICANT:
      return '--saa-priority-significant';
    case Priority.HIGH:
      return '--saa-priority-high';
    case Priority.LOW:
      return '--saa-priority-low';
    default:
      return '';
  }
};

const priorityIconColor = (priority: Priority): SerializedStyles => css`
  &&& {
    color: var(${getPriorityColor(priority)});
  }
`;

export default {
  priorityIconColor,
};
