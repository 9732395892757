import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import i18n from 'i18next';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { AssessmentReportResult } from '../model/assessmentReportResult';
import {
  AssessmentReportFilters,
  covertFiltersInQueryParams,
  emptyAssessmentReportFilters,
} from '../model/assessmentReportQueryParams';
import { Brand, sortBrandDescriptionList, sortBrandList } from '../../../shared/model/brand.model';
import { Store } from '../../../shared/model/store.model';
import { Priority } from '../../assessments/model/assessmentDetail.model';
import { Supervisor } from '../../assessments/model/assessment';

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}

interface OptionsByColumn {
  [columnName: string]: (string | number)[]; 
}

interface SelectAllByColumn {
  [columnName: string]: boolean;
}

interface AssessmentReportResultsSliceState {
  results: AssessmentReportResult[];
  isFetching: boolean;
  error: string;
  generalFilters: AssessmentReportFilters;
  searchBoxColumnFilter: string;
  sort: SortField;
  pagination: Pagination;
  currentColumn: string;
  columnsWithActiveFilters: string[];
  activeColumnFilters: (string | number)[];
  allOptionsByColumn: { [columnName: string]: (string | number)[] };
  selectedOptionsByColumn: { [columnName: string]: (string | number)[] };
  isAllSelectedByColumn: { [columnName: string]: boolean };
  statuses: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  stores: Store[];
  showResults: boolean;
  followUpStatuses: string[];
  priorities: string[];
}

const initialState: AssessmentReportResultsSliceState = {
  results: [],
  isFetching: false,
  error: '',
  generalFilters: emptyAssessmentReportFilters,
  searchBoxColumnFilter: '',
  sort: { sortColumn: 'locationName', sortOrder: 'ascending' },
  pagination: { page: 1, limit: 30 },
  currentColumn: 'locationName',
  columnsWithActiveFilters: [],
  activeColumnFilters: [],
  allOptionsByColumn: {},
  selectedOptionsByColumn: {},
  isAllSelectedByColumn: {},
  statuses: [],
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  stores: [],
  showResults: false,
  followUpStatuses: [],
  priorities: [],
};

export const assessmentReportResultsSlice = createSlice({
  name: 'assessmentReportResults',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentReportResultsSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<AssessmentReportResult[]>
    ) => ({
      ...state,
      isFetching: false,
      results: payload,
      error: '',
    }),
    setCurrentColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      currentColumn: payload
    }),
    setColumnsWithActiveFilters: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<string[]>
    ) => ({
      ...state,
      columnsWithActiveFilters: payload
    }),
    setActiveColumnFilters: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<(string | number)[]>
    ) => ({
      ...state,
      activeColumnFilters: payload
    }),
    setAllOptionsByColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<{ [columnName: string]: (string | number)[] }>
    ) => ({
      ...state,
      allOptionsByColumn: payload
    }),
    setSelectedOptionsByColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<{ [columnName: string]: (string | number)[] }>
    ) => ({
      ...state,
      selectedOptionsByColumn: payload
    }),
    editSelectedOptionsOnSingleColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<{ [columnName: string]: (string | number)[] }>
    ) => ({
      ...state,
      selectedOptionsByColumn: {
        ...state.selectedOptionsByColumn,
        ...payload 
      }
    }),
    setIsAllSelectedByColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<{ [columnName: string]: boolean }>
    ) => ({
      ...state,
      isAllSelectedByColumn: payload
    }),
    editIsAllSelectedOnSingleColumn: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<{ [columnName: string]: boolean }>
    ) => ({
      ...state,
      isAllSelectedByColumn: {
        ...state.isAllSelectedByColumn,
        ...payload 
      }
    }),
    setGeneralFilters: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<AssessmentReportFilters>
    ) => ({
      ...state,
      generalFilters: payload,
    }),
    setSearchBoxColumnFilter: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      searchBoxColumnFilter: payload,
    }),
    setShowResults: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showResults: payload,
    }),
    setPagination: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setSort: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<SortField>) => {
      return {
        ...state,
        sort: payload,
      };
    },
    finishStatusesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      statuses: [...action.payload],
      error: '',
    }),
    finishBrandFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    finishCountriesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...action.payload],
      error: '',
    }),
    finishCitiesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      cities: [...action.payload],
      error: '',
    }),
    finishStoresFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      stores: [...action.payload],
      error: '',
    }),
    finishFollowUpStatusesFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      action: PayloadAction<string[]>
    ): AssessmentReportResultsSliceState => ({
      ...state,
      isFetching: false,
      followUpStatuses: [...action.payload],
      error: '',
    }),
    finishPrioritiesFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      action: PayloadAction<string[]>
    ): AssessmentReportResultsSliceState => ({
      ...state,
      isFetching: false,
      priorities: [...action.payload],
      error: '',
    }),
    httpError: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    reset: (state: Draft<AssessmentReportResultsSliceState>) => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  setCurrentColumn,
  setColumnsWithActiveFilters,
  setActiveColumnFilters,
  setAllOptionsByColumn,
  setSelectedOptionsByColumn,
  editSelectedOptionsOnSingleColumn,
  setIsAllSelectedByColumn,
  editIsAllSelectedOnSingleColumn,
  setGeneralFilters,
  setSearchBoxColumnFilter,
  setShowResults,
  setPagination,
  setSort,
  finishStatusesFetch,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishCitiesFetch,
  finishStoresFetch,
  finishFollowUpStatusesFetch,
  finishPrioritiesFetch,
  httpError,
  reset
} = assessmentReportResultsSlice.actions;

export default assessmentReportResultsSlice.reducer;

const getOptionsForColumn = (results: AssessmentReportResult[], column: string): (string | number)[] => {
  switch (column) {
    case 'status':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'brand':
      return _.uniq(results.map((e) => e[column]?.description != null ? e[column].description : '-'))
    case 'region':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'country':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'city':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'hfmCode':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'jdaCode':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'locationName':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'year':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'month':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'processCode':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'processDescription':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessCode':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessDescription':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessResultFindings':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessResultPriority':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessResultRecommendation':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'subProcessScore':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'failedScorePercentage':
      return _.uniq(results.map((e) => e[column] != null ? `${(e[column] * 100).toFixed(2)}%` : '-'))
    case 'rating':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'successScorePercentage':
      return _.uniq(results.map((e) => e[column] != null ? `${(e[column] * 100).toFixed(2)}%` : '-'))
    case 'keyBusinessRisks':
      return _.uniq(results.map((e) => e[column] != null ? e[column].join(', ') : '-'))
    case 'actionPlanDescription':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'actionPlanSupervisor':
      return _.uniq(results.map((e) => e[column] != null ? e[column].email : '-'))
    case 'actionPlanSupervisorRole':
      return _.uniq(results.map((e) => e.actionPlanSupervisor?.jobTitle != null ? e.actionPlanSupervisor.jobTitle : '-'))
    case 'actionPlanDueDate':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'followUpStatus':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'followUpResult':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'followUpDescription':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    case 'followUpSupervisor':
      return _.uniq(results.map((e) => e[column]?.email != null ? e[column].email : '-'))
    case 'followUpSupervisorRole':
      return _.uniq(results.map((e) => e.followUpSupervisor?.jobTitle != null ? e.followUpSupervisor.jobTitle : '-'))
    case 'followUpDueDate':
      return _.uniq(results.map((e) => e[column] != null ? e[column] : '-'))
    default:
      return []
  }
}
const getAllOptionsAndIsAllSelectedByColumn = (results: AssessmentReportResult[], columns: string[]) => {
  const allOptionsByColumn: OptionsByColumn = {}
  const isAllSelectedByColumn: SelectAllByColumn = {};

  columns.forEach((column) => {
    const options = getOptionsForColumn(results, column);
    allOptionsByColumn[column] = options;
    isAllSelectedByColumn[column] = true; 
  });

  return { allOptionsByColumn, isAllSelectedByColumn };
};

const getColumnsFromResults = (results: AssessmentReportResult[]): string[] => {
  const columns: string[] = ['actionPlanSupervisorRole', 'followUpSupervisorRole'];

  results.forEach((result) => {
    const keys = Object.keys(result);
    keys.forEach((key) => {
      if (!columns.includes(key)) {
        columns.push(key);
      }
    });
  });

  return columns
}


export const fetchAssessmentReportResults =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const results = await api.getAssessmentReportResults(covertFiltersInQueryParams(filters));
      dispatch(finishFetch(results));
      dispatch(setGeneralFilters(filters));

      const columns = getColumnsFromResults(results)
      const { allOptionsByColumn, isAllSelectedByColumn } = getAllOptionsAndIsAllSelectedByColumn(results, columns);

      dispatch(setAllOptionsByColumn(allOptionsByColumn));
      dispatch(setSelectedOptionsByColumn(allOptionsByColumn));
      dispatch(setIsAllSelectedByColumn(isAllSelectedByColumn));

    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const resetAssessmentReportResults = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    dispatch(finishFetch([]));
    dispatch(setGeneralFilters(emptyAssessmentReportFilters));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onChangePage =
  (page: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().assessmentReportResults;
    if (page !== pagination.page) {
      dispatch(setPagination({ ...pagination, page }));
    }
  };

export const onSort =
  (clickedColumn: string, sortOrder: SortOrder): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().assessmentReportResults;

    dispatch(setPagination({ ...pagination, page: 1 }));
    dispatch(setSort({ sortColumn: clickedColumn, sortOrder }));
  };

export const fetchStatuses =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchBrands =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brands: Brand[] = await api.getBrands(covertFiltersInQueryParams(filters));
      dispatch(finishBrandFetch(brands));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchRegions =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const regions: string[] = await api.getRegions(covertFiltersInQueryParams(filters));
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCountries =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const countries: string[] = await api.getCountries(covertFiltersInQueryParams(filters));
      dispatch(finishCountriesFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCities =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const cities: string[] = await api.getCities(covertFiltersInQueryParams(filters));
      dispatch(finishCitiesFetch(cities));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchStores =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getStores(covertFiltersInQueryParams(filters));
      dispatch(finishStoresFetch(stores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchFollowUpStatuses =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getFollowUpStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishFollowUpStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchPriorities =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getPriorities(covertFiltersInQueryParams(filters));
      dispatch(finishPrioritiesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };
 
export const selectIsFetching = (state: RootState): boolean => state.assessmentReportResults.isFetching;
export const selectGeneralFilters = (state: RootState): AssessmentReportFilters => state.assessmentReportResults.generalFilters;
export const selectSort = (state: RootState) => state.assessmentReportResults.sort;
export const selectPagination = (state: RootState) => state.assessmentReportResults.pagination;
export const selectColumnsWithActiveFilters = (state: RootState) => state.assessmentReportResults.columnsWithActiveFilters;
export const selectActiveColumnFilters = (state:RootState) => state.assessmentReportResults.activeColumnFilters;
export const selectPaginationLimit = (state: RootState) => state.assessmentReportResults.pagination.limit;
export const selectResults = (state: RootState): AssessmentReportResult[] => [...state.assessmentReportResults.results];
export const selectSearchBoxFilter = (state: RootState): string => state.assessmentReportResults.searchBoxColumnFilter
export const selectCurrentColumn = (state: RootState) => state.assessmentReportResults.currentColumn;
export const selectAllOptionsByColumn = (state: RootState) => state.assessmentReportResults.allOptionsByColumn;
export const selectSelectedOptionsByColumn = (state: RootState) => state.assessmentReportResults.selectedOptionsByColumn
export const selectIsAllSelectedByColumn = (state: RootState) => state.assessmentReportResults.isAllSelectedByColumn

export const selectStatuses = (state: RootState) => state.assessmentReportResults.statuses;
export const selectBrands = (state: RootState) => [...state.assessmentReportResults.brands].sort(sortBrandList);
export const selectRegions = (state: RootState) => state.assessmentReportResults.regions;
export const selectCountries = (state: RootState) => state.assessmentReportResults.countries;
export const selectCities = (state: RootState) => state.assessmentReportResults.cities;
export const selectStores = (state: RootState) => state.assessmentReportResults.stores;
export const selectShowResults = (state: RootState) => state.assessmentReportResults.showResults;
export const selectFollowUpStatuses = (state: RootState) => state.assessmentReportResults.followUpStatuses;
export const selectPriorities = (state: RootState) => state.assessmentReportResults.priorities;


export const selectColumnFilterHeader = createSelector(
  selectCurrentColumn,
  (column) => {
    switch(column) {
      case 'status':
        return i18n.t('assessment.report.columns.status')
      case 'brand':
        return i18n.t('assessment.report.columns.brand')
      case 'region':
        return i18n.t('assessment.report.columns.region')
      case 'country':
        return i18n.t('assessment.report.columns.country')
      case 'city':
        return i18n.t('assessment.report.columns.city')
      case 'hfmCode':
        return i18n.t('assessment.report.columns.hfmCode')
      case 'jdaCode':
        return i18n.t('assessment.report.columns.jdaCode')
      case 'locationName':
        return i18n.t('assessment.report.columns.locationName')
      case 'year':
        return i18n.t('assessment.report.columns.year')
      case 'month':
        return i18n.t('assessment.report.columns.month')
      case 'processCode':
        return i18n.t('assessment.report.columns.processCode')
      case 'processDescription':
        return i18n.t('assessment.report.columns.processDescription')
      case 'subProcessCode':
        return i18n.t('assessment.report.columns.subProcessCode')
      case 'subProcessDescription':
        return i18n.t('assessment.report.columns.subProcessDescription')
      case 'subProcessResultFindings':
        return i18n.t('assessment.report.columns.subProcessResultFindings')
      case 'subProcessResultPriority':
        return i18n.t('assessment.report.columns.subProcessResultPriority')
      case 'subProcessResultRecommendation':
        return i18n.t('assessment.report.columns.subProcessResultRecommendation')
      case 'subProcessScore':
        return i18n.t('assessment.report.columns.subProcessScore')
      case 'failedScorePercentage':
        return i18n.t('assessment.report.columns.failedScorePercentage')
      case 'rating':
        return i18n.t('assessment.report.columns.rating')
      case 'successScorePercentage':
        return i18n.t('assessment.report.columns.successScorePercentage')
      case 'keyBusinessRisks':
        return i18n.t('assessment.report.columns.keyBusinessRisks')
      case 'actionPlanDescription':
        return i18n.t('assessment.report.columns.actionPlanDescription')
      case 'actionPlanSupervisor':
        return i18n.t('assessment.report.columns.actionPlanSupervisor')
      case 'actionPlanSupervisorRole':
        return i18n.t('assessment.report.columns.actionPlanSupervisorRole')
      case 'actionPlanDueDate':
        return i18n.t('assessment.report.columns.actionPlanDueDate')
      case 'followUpStatus':
        return i18n.t('assessment.report.columns.folloUpStatus')
      case 'followUpResult':
        return i18n.t('assessment.report.columns.followUpResult')
      case 'followUpDescription':
        return i18n.t('assessment.report.columns.followUpDescription')
      case 'followUpSupervisor':
        return i18n.t('assessment.report.columns.followUpSupervisor')
      case 'followUpSupervisorRole':
        return i18n.t('assessment.report.columns.followUpSupervisorRole')
      case 'followUpDueDate':
        return i18n.t('assessment.report.columns.followUpDueDate')
      default:
        return ''
    }
  }
)


export const selectCurrentColumnSelectedOptions = createSelector(
  selectCurrentColumn,
  selectSelectedOptionsByColumn,
  (column, selectedOptions) => {
    return selectedOptions[column]
  }
)

export const selectIsCurrentColumnAllSelected = createSelector(
  selectCurrentColumn,
  selectIsAllSelectedByColumn,
  (column, isAllSelectedByColumn) => {
    return isAllSelectedByColumn[column]
  }
)


export const selectFilterOptionsPerSingleColumn = createSelector(
  selectAllOptionsByColumn,
  selectCurrentColumn,
  selectSearchBoxFilter,
  (allOptions, currentColumn, searchFilter) => {

    let options = allOptions[currentColumn];

    if (options?.length > 0 && searchFilter !== '') {
      options = options.filter((o) => {
        return typeof o === 'string'
          ? o.toLowerCase().includes(searchFilter.toLowerCase())
          : o.toString().includes(searchFilter);
      });
    }

    let sortedOptions;
    if (currentColumn === 'brand') {
        sortedOptions = [...options].sort(sortBrandDescriptionList)
    } else {
      sortedOptions = _.sortBy(options).reverse(); 

    }
    return sortedOptions;
  }
  
)

const getSortedResults = (results: AssessmentReportResult[], sort: SortField) => {
  const sortedData = _.sortBy(results, (r) => {

    let columnValue: string | number | Brand | string[] | Supervisor;
    if (sort.sortColumn === 'actionPlanSupervisorRole') {
      columnValue = r['actionPlanSupervisor' as keyof typeof r]
    } else if (sort.sortColumn === 'followUpSupervisorRole') {
      columnValue = r['followUpSupervisor' as keyof typeof r]
    } else {
      columnValue = r[sort.sortColumn as keyof typeof r];
    }

    if (columnValue == null) {
      columnValue = '-'
    }


    if (typeof columnValue === 'object') {
      if ('description' in columnValue && sort.sortColumn === 'brand') {
        return columnValue.description; 
      }
      if ('email' in columnValue && sort.sortColumn === 'actionPlanSupervisor') {
        return columnValue.email; 
      }
      if ('email' in columnValue && sort.sortColumn === 'followUpSupervisor') {
        return columnValue.email; 
      }
      if ('jobTitle' in columnValue && sort.sortColumn === 'actionPlanSupervisorRole') {
        return columnValue.jobTitle; 
      }
      if ('jobTitle' in columnValue && sort.sortColumn === 'followUpSupervisorRole') {
        return columnValue.jobTitle; 
      }
    }

    return columnValue;
  });

  return sort.sortOrder === 'descending' ? sortedData.reverse() : sortedData;
}


export const selectFilteredAndSortedResults = createSelector(
  selectResults,
  selectSort,
  selectGeneralFilters,
  selectActiveColumnFilters,
  selectColumnsWithActiveFilters,
  (results, sort, generalFilters, activeFilters, columnsWithFilters) => {
    const filteredResults = results.filter(r => {
      let columnFiltersResults = true; 

      if (columnsWithFilters.length > 0 && activeFilters.length > 0) {
        columnFiltersResults = columnsWithFilters.every(column => {
          let columnValue: string | number | Brand | string[] | Supervisor;
          if (column === 'actionPlanSupervisorRole') {
            columnValue = r['actionPlanSupervisor' as keyof typeof r]
          } else if (column === 'followUpSupervisorRole') {
            columnValue = r['followUpSupervisor' as keyof typeof r]
          } else {
            columnValue = r[column as keyof typeof r];
          }

          if (columnValue == null) {
            columnValue = '-'
          }

          if (typeof columnValue === 'string' || typeof columnValue === 'number') {
            return activeFilters?.includes(columnValue);
          }

          if (typeof columnValue === 'object') {
            if ('description' in columnValue && column === 'brand') {
              return activeFilters?.includes(columnValue.description);
            }
            if ('email' in columnValue && column === 'actionPlanSupervisor') {
              return activeFilters?.includes(columnValue.email);
            }
            if ('email' in columnValue && column === 'followUpSupervisor') {
              return activeFilters?.includes(columnValue.email);
            }
            if ('jobTitle' in columnValue && column === 'actionPlanSupervisorRole') {
              return activeFilters?.includes(columnValue.jobTitle as string);;
            }
            if ('jobTitle' in columnValue && column === 'followUpSupervisorRole') {
              return activeFilters?.includes(columnValue.jobTitle as string);;
            }
            if (Array.isArray(columnValue)) {
              return activeFilters?.includes(columnValue.join(', '));
            }
          }

          return false;
        });
      }

      const priorityFilterResults =
        (generalFilters.priorities.length === 0 && r.subProcessResultPriority !== Priority.PASS) ||
        generalFilters.priorities.includes(r.subProcessResultPriority.valueOf());

      return columnFiltersResults && priorityFilterResults;
    });


    const sortedData = getSortedResults(filteredResults, sort);
    return sortedData;
  }
);



export const selectSortedAndPaginatedResults = createSelector(
  selectFilteredAndSortedResults,
  selectPagination,
  (sortedData, pagination) => {
    const startIndex = pagination.page * pagination.limit - pagination.limit;
    const endIndex = startIndex + pagination.limit;
    return sortedData?.slice(startIndex, endIndex);
  }
);
export const selectTotalPagesAndCount = createSelector(
  selectFilteredAndSortedResults,
  selectPaginationLimit,
  (filteredData, paginationLimit) => {
    return {
      totalPages: filteredData.length > 0 ? Math.ceil(filteredData.length / paginationLimit) : 1,
      totalCount: filteredData?.length || 0,
    };
  }
);


