/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { AssessmentArchiveFilters } from '../../model/assessmentArchiveQueryParams';
import { fetchCities, selectCities, selectIsFetching } from '../../store/assessmentsArchiveSlice';

interface CityMultiSelectFieldProps {
  filters: AssessmentArchiveFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function CityMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: CityMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchCities(filters));
    }
  }, [dispatch, editable, filters]);

  const items = useSelector(selectCities);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: item,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='point'
      defaultValue={defaultValue != null ? defaultValue.map(v => toSelectItem(v)) : []}
      loading={isFetching}
      list={items.map((b): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.archive.filters.cities') || ''}
      editable={editable}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(i => toString(i)));
        }
      }}
    />
  );
}
