/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import style from './reproposedDates.style';
import { MultipleDateRangeField } from '../../../../../shared/MultipleDateRangeField';
import { ProposedPeriod } from '../../../model/genericAuditProposal';

interface ReproposedDatesProps {
  reproposedPeriods: ProposedPeriod[];
}

export const ReproposedDates = ({ reproposedPeriods }: ReproposedDatesProps): JSX.Element => {
  return (
    <div css={style.rowMultipleField}>
      <MultipleDateRangeField
        defaultProposedPeriods={reproposedPeriods}
        title={t('brandAuditProposals.proposalDetail.availabilityPeriods') || ''}
      />
    </div>
  );
};
