/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useAppDispatch } from '../../../core/store';
import {
  fetchAuditProposal,
  selectAuditProposal,
  selectIsFetching,
  selectIsUpdating,
  setCampaignId,
} from '../store/auditProposalDetailSlice';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { AuditProposal, isProposalDeletable, isProposalEditable } from '../model/auditProposal';
import { AuditProposalDetailHeader } from '../components/proposalDetail/auditProposalDetail/AuditProposalDetailHeader';
import { AuditProposalDetailFields } from '../components/proposalDetail/auditProposalDetail/AuditProposalDetailFields';
import style from './auditProposalDetail.style';
import { AuditProposalDetailFooter } from '../components/proposalDetail/auditProposalDetail/AuditProposalDetailFooter';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../model/genericAuditProposal';
import { checkRole, ROLES } from '../../auth/model/principal.model';

export function AuditProposalDetail(): JSX.Element {
  const { campaignId = '', proposalId = '' } = useParams<{ campaignId: string; proposalId: string }>();
  const dispatch = useAppDispatch();
  const proposal = useSelector(selectAuditProposal) as AuditProposal;
  const isFetching = useSelector(selectIsFetching);
  const isUpdating = useSelector(selectIsUpdating);
  const principal = useSelector(selectPrincipal);
  const userCanEdit = proposal != null ? isProposalEditable(principal, proposal) : false;
  const userCanDelete = proposal != null ? isProposalDeletable(principal, proposal) : false;
  const navigate = useNavigate();
  const goToCampaign = useCallback(() => navigate(`/audit-campaigns/${campaignId}`), [navigate, campaignId]);

  useEffect(() => {
    dispatch(setCampaignId(campaignId));
    dispatch(fetchAuditProposal(campaignId, proposalId));
  }, [dispatch, campaignId, proposalId]);

  return isFetching || isUpdating ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : proposal ? (
    <>
      <AuditProposalDetailHeader campaignId={campaignId} proposal={proposal} deletable={userCanDelete} />
      <div css={style.component}>
        <div css={style.pageTitle}>{t('auditCampaigns.proposalDetail.title')}</div>
        <AuditProposalDetailFields campaignId={campaignId} proposal={proposal} editable={userCanEdit} />
        {checkRole(principal, ROLES.AUDITOR_MANAGER) && proposal.status === INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED && (
          <div onClick={goToCampaign} css={style.aboveFooterMessage}>
            <span>
              Go to <span>campaign detail</span> to send the whole campaign to the brand
            </span>
          </div>
        )}
        <AuditProposalDetailFooter />
      </div>
    </>
  ) : (
    <div css={style.notFound}>{t('auditCampaigns.proposalDetail.notFound')}</div>
  );
}
