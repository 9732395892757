/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { fetchPriorities, selectIsFetching, selectPriorities } from '../store/assessmentReportResultsSlice';
import { AssessmentReportFilters } from '../model/assessmentReportQueryParams';
import { Priority } from '../../assessments/model/assessmentDetail.model';

interface StatusMultiSelectFieldProps {
  filters: AssessmentReportFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function PriorityMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: StatusMultiSelectFieldProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (editable) {
      dispatch(fetchPriorities(filters));
    }
  }, [dispatch, editable, filters]);

  const items = useSelector(selectPriorities).filter(p => p !== Priority.PASS.valueOf());
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = useCallback(
    (item: string): SelectItem => {
      return {
        code: item,
        description: t(`assessment.priority.${item}`),
      };
    },
    [t]
  );

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='exclamation triangle'
      loading={isFetching}
      defaultValue={defaultValue != null ? defaultValue.map(toSelectItem) : []}
      list={items.map(toSelectItem)}
      title={t('assessment.report.filters.priorities') || ''}
      editable={editable}
      onUpdateValue={(selectedPriority: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedPriority.map(toString));
        }
      }}
    />
  );
}
