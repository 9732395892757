import { API_URL } from '../../../constants';

const AUTH_URL = `${API_URL}/auth`;

const paths = {
  getToken: (): string => `${AUTH_URL}/get-token`,
  getPrincipal: (): string => `${AUTH_URL}/me`,
  refreshToken: (): string => `${AUTH_URL}/refresh-token`,
  putPrincipalPolicy: (): string => `${AUTH_URL}/accept-policies/`,
};

export default paths;
