/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './detailFooter.style';
import { ModalAlert } from '../../../../../shared/ModalAlert';

interface ApproveButtonProps {
  buttonLabel: string;
  onConfirm: () => void;
  confirmMessage?: string;
  showErrorAlert?: boolean;
  errorAlertMessage?: string;
}

export function ApproveButton({
  buttonLabel,
  onConfirm,
  confirmMessage = t('auditCampaigns.proposalDetail.footer.confirmationMessage') || '',
  showErrorAlert = false,
  errorAlertMessage = '',
}: ApproveButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const onButtonClick = () => {
    if (showErrorAlert === true) {
      setAlertIsOpen(true);
    } else {
      setConfirmIsOpen(true);
    }
  };
  return (
    <>
      <Button
        css={style.fatFooterButtonEnd}
        labelPosition='right'
        icon='forward'
        content={buttonLabel}
        onClick={onButtonClick}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        onCancel={() => setConfirmIsOpen(false)}
        content={confirmMessage}
        onConfirm={() => {
          setConfirmIsOpen(false);
          onConfirm();
        }}
        size='small'
      />
      <ModalAlert open={alertIsOpen} onClose={() => setAlertIsOpen(false)} message={errorAlertMessage} />
    </>
  );
}
