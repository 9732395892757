/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AuditProposalCardHeader } from './AuditProposalCardHeader';
import { RowField } from '../../assessments/components/AssessmentCard';
import { AuditProposal } from '../model/auditProposal';
import style from './auditProposalCard.style';
import { BrandAuditProposal } from '../model/brandAuditProposal';

interface ProposalCardProps {
  campaignId?: string;
  proposal: AuditProposal | BrandAuditProposal;
}

export const ProposalCard = ({ campaignId, proposal }: ProposalCardProps): JSX.Element => {
  const {
    id,
    status,
    store,
    auditors,
    proposedDateFrom,
    proposedDateTo,
    proposedByBrandDateFrom,
    proposedByBrandDateTo,
    type,
  } = proposal;
  const detailLink =
    campaignId == null || campaignId === ''
      ? `/brand-audit-proposals/${id}`
      : `/audit-campaigns/${campaignId}/internal-audit-proposals/${id}`;

  return (
    <Card as={Link} to={detailLink} css={style.card(type, status)}>
      <Card.Content css={style.header}>
        <AuditProposalCardHeader proposal={proposal} />
      </Card.Content>
      <Card.Content css={style.body}>
        <Row>
          <RowField width='50%' iconName='map marker' value={store.city} />
          <RowField
            width='50%'
            iconName='calendar alternate'
            value={
              proposedDateFrom.toString().trim() !== ''
                ? `From: ${proposedDateFrom}`
                : `From: ${proposedByBrandDateFrom}`
            }
          />
        </Row>
        <Row>
          <RowField width='50%' value='' />
          <RowField
            width='50%'
            iconName='calendar alternate'
            value={proposedDateTo.toString().trim() !== '' ? `To: ${proposedDateTo}` : `To: ${proposedByBrandDateTo}`}
          />
        </Row>
        <Row>
          <RowField iconName='users' value={auditors.map(auditor => auditor.name).join(', ')} />
        </Row>
      </Card.Content>
    </Card>
  );
};

const Row = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <div css={style.bodyRow}>{children}</div>;
};
