import { toStore } from '../../../shared/model/store.model';
import { toContact } from '../../users/model/user.model';
import { AUDITOR_STAFF_ROLES, checkRole, checkRoles, Principal, ROLES } from '../../auth/model/principal.model';
import { AssessmentCreationFromProposalRequest, toAssessment, toAuditor } from '../../assessments/model/assessment';
import {
  GenericAuditProposal,
  INTERNAL_AUDIT_PROPOSAL_STATUS,
  ProposalNote,
  ProposalType,
  ProposedPeriod,
} from './genericAuditProposal';
import { getUTCDateString, getDateTimeString } from '../../../core/utils';

export const VISIBLE_PROPOSAL_STATUSES = [
  INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT,
  INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED,
  INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED,
  INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER,
  INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
  INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND,
  INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND,
];

export interface AuditProposalWithCampaignInfo extends GenericAuditProposal {
  type: ProposalType;
  campaignId: string;
  campaignName: string;
}

export interface AuditProposal extends GenericAuditProposal {
  status: INTERNAL_AUDIT_PROPOSAL_STATUS;
  type: 'internal';
}

export interface AuditProposalRequest {
  storeJdaCode: string;
  proposedDateFrom: string;
  proposedDateTo: string;
  brandSchedulers: string[];
  distributionList: string[];
  auditors: string[];
}

export interface UpdateProposalDateRequest {
  dateFrom: Date;
  dateTo: Date;
}

export interface UpdateProposalAuditorsRequest {
  auditors: string[];
}

export interface ChangeStatusRequest {
  status: INTERNAL_AUDIT_PROPOSAL_STATUS;
  note?: string;
  dateFrom?: string;
  dateTo?: string;
  proposedPeriods?: ProposedPeriod[];
  assessment?: AssessmentCreationFromProposalRequest;
}

export const toAuditProposal = (o: Record<string, unknown>): AuditProposal => {
  return {
    id: o.id as string,
    store: toStore(o.store as Record<string, unknown>),
    proposedDateFrom: getUTCDateString(o.proposedDateFrom as string),
    proposedDateTo: getUTCDateString(o.proposedDateTo as string),
    proposedByBrandDateFrom:
      o.proposedByBrandDateFrom == null ? '' : getUTCDateString(o.proposedByBrandDateFrom as string),
    proposedByBrandDateTo: o.proposedByBrandDateTo == null ? '' : getUTCDateString(o.proposedByBrandDateTo as string),
    proposedPeriods: o.proposedPeriods as ProposedPeriod[],
    brandSchedulers: (o.brandSchedulers as Record<string, unknown>[]).map(toContact),
    distributionList: (o.distributionList as Record<string, unknown>[]).map(toContact),
    auditors: (o.auditors as Record<string, unknown>[]).map(toAuditor),
    status: o.status as INTERNAL_AUDIT_PROPOSAL_STATUS,
    createdBy: toContact(o.createdBy as Record<string, unknown>),
    createdAt: getUTCDateString(o.createdAt as string),
    note: o.note ? toProposalNote(o.note as Record<string, unknown>) : undefined,
    brandNote: o.brandNote ? toProposalNote(o.brandNote as Record<string, unknown>) : undefined,
    auditorNote: o.auditorNote ? toProposalNote(o.auditorNote as Record<string, unknown>) : undefined,
    assessment: o.assessment ? toAssessment(o.assessment as Record<string, unknown>) : undefined,
    type: 'internal',
    passedByBrand: o.passedByBrand ? (o.passedByBrand as boolean) : undefined,
  };
};

export const toAuditProposalWithCampaignInfo = (o: Record<string, unknown>): AuditProposalWithCampaignInfo => {
  return {
    id: o.id as string,
    store: toStore(o.store as Record<string, unknown>),
    proposedDateFrom: getUTCDateString(o.proposedDateFrom as string),
    proposedDateTo: getUTCDateString(o.proposedDateTo as string),
    proposedByBrandDateFrom:
      o.proposedByBrandDateFrom == null ? '' : getUTCDateString(o.proposedByBrandDateFrom as string),
    proposedByBrandDateTo: o.proposedByBrandDateTo == null ? '' : getUTCDateString(o.proposedByBrandDateTo as string),
    proposedPeriods: o.proposedPeriods as ProposedPeriod[],
    brandSchedulers: (o.brandSchedulers as Record<string, unknown>[]).map(toContact),
    distributionList: (o.distributionList as Record<string, unknown>[]).map(toContact),
    auditors: (o.auditors as Record<string, unknown>[]).map(toAuditor),
    status: o.status as INTERNAL_AUDIT_PROPOSAL_STATUS,
    createdBy: toContact(o.createdBy as Record<string, unknown>),
    createdAt: getDateTimeString(o.createdAt as string),
    note: o.note ? toProposalNote(o.note as Record<string, unknown>) : undefined,
    type: o.campaignId != null ? 'internal' : 'brand',
    campaignId: o.campaignId as string,
    campaignName: o.campaignName as string,
  };
};

export const toProposalNote = (o: Record<string, unknown>): ProposalNote => ({
  author: toContact(o.author as Record<string, unknown>),
  created: getDateTimeString(o.createdAt as string),
  lastUpdate: getDateTimeString(o.lastUpdate as string),
  content: o.content as string,
});

export const isProposalVisible = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRoles(principal, AUDITOR_STAFF_ROLES)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.CLOSED:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        (isConfiguredBrandSchedulerOrDistributionList(principal, proposal) && proposal.passedByBrand) ||
        checkRoles(principal, AUDITOR_STAFF_ROLES)
      );
    default:
      return false;
  }
};

export const areAuditDatesVisible = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRoles(principal, AUDITOR_STAFF_ROLES)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.CLOSED:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        (isConfiguredBrandSchedulerOrDistributionList(principal, proposal) && proposal.passedByBrand) ||
        checkRoles(principal, AUDITOR_STAFF_ROLES)
      );
    default:
      return false;
  }
};

export const isProposalEditable = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRole(principal, ROLES.AUDIT_ADMIN)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return (
        isConfiguredAuditor(principal, proposal) ||
        isProposalAuthor(principal, proposal) ||
        checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN])
      );
    default:
      return false;
  }
};

export const isProposalDeletable = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return (
        checkRole(principal, ROLES.AUDIT_ADMIN) ||
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.CLOSED:
      return checkRoles(principal, [ROLES.AUDIT_ADMIN]);
    default:
      return false;
  }
};

export const isProposalUnderReviewByAuditors = (proposal: AuditProposal): boolean => {
  return [
    INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT,
    INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
    INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND,
    INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND,
  ].includes(proposal.status);
};

export const isProposalAuthor = (principal: Principal, proposal: AuditProposal): boolean => {
  return checkRole(principal, ROLES.AUDITOR) && principal.email === proposal.createdBy.email;
};

export const isConfiguredAuditor = (principal: Principal, proposal: AuditProposal): boolean => {
  return checkRole(principal, ROLES.AUDITOR) && proposal.auditors.some(auditor => auditor.email === principal.email);
};

export const isConfiguredBrandScheduler = (principal: Principal, proposal: AuditProposal): boolean => {
  return (
    checkRole(principal, ROLES.BRAND_SCHEDULER) && proposal.brandSchedulers.some(bs => bs.email === principal.email)
  );
};

export const isConfiguredBrandSchedulerOrDistributionList = (
  principal: Principal,
  proposal: AuditProposal
): boolean => {
  return (
    (checkRole(principal, ROLES.BRAND_SCHEDULER) &&
      proposal.brandSchedulers.some(bs => bs.email === principal.email)) ||
    proposal.brandSchedulers.some(bs => bs.email === principal.email) ||
    proposal.distributionList.some(dl => dl.email === principal.email)
  );
};

export const checkStatus = (proposal: AuditProposal, statuses: INTERNAL_AUDIT_PROPOSAL_STATUS[]): boolean => {
  return statuses.some(st => proposal.status === st);
};

export const canChangeProposalStatus = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return isConfiguredAuditor(principal, proposal) || isProposalAuthor(principal, proposal);
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return (
        isConfiguredAuditor(principal, proposal) ||
        isProposalAuthor(principal, proposal) ||
        isConfiguredBrandScheduler(principal, proposal)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return checkRole(principal, ROLES.AUDITOR_MANAGER);
    case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER:
      return isConfiguredBrandScheduler(principal, proposal);
    default:
      return false;
  }
};

export const canEditAuditDates = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRole(principal, ROLES.AUDIT_ADMIN)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);

    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return checkRoles(principal, [ROLES.AUDIT_ADMIN]);
    default:
      return false;
  }
};

export const canEditAuditors = (principal: Principal, proposal: AuditProposal): boolean => {
  switch (proposal.status) {
    case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND:
    case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRole(principal, ROLES.AUDIT_ADMIN)
      );
    case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return (
        isProposalAuthor(principal, proposal) ||
        isConfiguredAuditor(principal, proposal) ||
        checkRoles(principal, [ROLES.AUDIT_ADMIN, ROLES.AUDITOR_MANAGER])
      );
    default:
      return false;
  }
};
