import React from 'react';
import { useSelector } from 'react-redux';
import { selectPrincipal } from '../../features/auth/store/principalSlice';
import { getJwtToken } from '../services/webStorageService';
import { SyncAuditorUpdateRequests } from '../../features/assessments/components/SyncAuditorUpdateRequests';

export const SyncAdapter: React.FunctionComponent = (): JSX.Element => {
  const user = useSelector(selectPrincipal);
  return getJwtToken() ? user ? <SyncAuditorUpdateRequests /> : <></> : <></>;
};
