import { css } from '@emotion/react';
import style from '../../../../../shared/global.style';

const footerContainer = css`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--saa-white-color);
  flex-wrap: wrap;
`;

const footerContainerGroup = css`
  display: flex;
  gap: 5px;
  flex: 1;
  max-width: 50%;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
`;

const footerButtons = css`
  &&&&& {
    ${style.primaryButton};
    padding-top: 7px;
    padding-bottom: 7px;
    flex: 1;
  }
`;

const footerButtonEnd = css`
  ${footerButtons};
  &&& {
    margin-left: auto;
    margin-right: 0;
  }
`;
const footerButtonStart = css`
  ${footerButtons};
  &&& {
    margin-left: 0;
  }
`;
const fatFooterButton = css`
  ${style.primaryButton};
  &&&&& {
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 160px;
    min-height: 46px;
    flex: 1 1 0%;
    @media (min-width: 540px) {
      max-width: min(250px, 50%);
    }
  }
`;
const fatFooterButtonStart = css`
  ${fatFooterButton};
  &&& {
    margin-left: 0;
  }
`;
const fatFooterButtonEnd = css`
  ${fatFooterButton};
  &&& {
    margin-left: auto;
    margin-right: 0;
  }
`;
const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;
const acceptCalendarContainer = css`
  display: flex;
  align-items: center;
  margin-top: 10px;
  && {
    > div {
      flex: 1;
      border-bottom: 0;
    }
  }
`;

const modalTextContainer = css`
  &&& {
    ${style.bodyNormal};
    padding: 10px 20px;
  }
`;

const rowMultipleField = css`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

const noPadding = css`
  &&&&&& {
    padding: 0 !important;
  }
`;

const reproposeBrandDatesWarning = css`
  ${style.bodySmall};
  &&& {
    font-size: 0.9rem;
  }
  padding: 10px;
`;

export default {
  footerContainer,
  footerContainerGroup,
  footerButtons,
  modalConfirm,
  confirmButton,
  footerButtonEnd,
  footerButtonStart,
  fatFooterButtonEnd,
  fatFooterButtonStart,
  acceptCalendarContainer,
  modalTextContainer,
  rowMultipleField,
  noPadding,
  reproposeBrandDatesWarning,
};
