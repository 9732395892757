/** @jsxImportSource @emotion/react */
import React from 'react';
import { Image } from 'semantic-ui-react';
import style from './brandImage.style';

interface brandProps {
  src: string;
}

export const BrandImage = ({ src }: brandProps): JSX.Element => {
  return (
    <div css={style.brandContainer}>
      <Image css={style.brand} src={src} />
    </div>
  );
};
