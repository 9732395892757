import { css } from '@emotion/react';

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;

    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const buttonIcon = css`
  && {
    font-size: 1.5rem;
  }
`;

const sidebar = css`
  &&&& {
    top: 60px;
    height: calc(100% - 60px) !important;
    display: flex;
    background-color: var(--saa-primary-color-80);
    width: 100%;
    max-width: 450px;
  }
`;

const userContainer = css`
  && {
    padding: 20px;
    margin-top: auto;
    border-top: 2px solid var(--saa-primary-color);
    > div {
      display: flex;
      margin-bottom: 20px;
      color: var(--saa-white-color);
      > div {
        font-size: 1.286rem;
        margin: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    > button {
      color: var(--saa-white-color);
      background-color: var(--saa-primary-color);
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
      cursor: pointer;
      &&:hover,
      &&:focus {
        color: var(--saa-primary-color-40);
        background-color: var(--saa-primary-color);
      }
    }
  }
`;
const menuItem = css`
  &&&&& {
    display: flex;
    flex-direction: row;
    font-size: 1.429rem;
    border-bottom: solid 1px var(--saa-primary-color-80);
    > div {
      margin: auto;
    }
  }
`;

const menuContainer = css`
  margin-top: 10px;
`;

const menuContainerHeader = css`
  color: var(--saa-primary-color-20);
  padding-left: 5px;
  border-bottom: solid 1px var(--saa-primary-color-60);
  font-size: 0.9rem;
`;

export default {
  button,
  buttonIcon,
  sidebar,
  userContainer,
  menuItem,
  menuContainer,
  menuContainerHeader,
};
