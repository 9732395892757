import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field};
  cursor: pointer;
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const inputContainer = css`
  &&&& {
    textarea {
      ${style.textarea};
    }
  }
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  checkBoxContainer,
  editIcon,
  subTitle,
  valueContainer,
  value,
};
