/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './assessmentHeader.style';
import { BrandImage } from '../../../shared/BrandImage';
import { Assessment } from '../model/assessment';
import { AssessmentDetail } from '../model/assessmentDetail.model';

interface AssessmentHeaderProps {
  assessment: Assessment | AssessmentDetail;
}

export const AssessmentHeader = ({ assessment }: AssessmentHeaderProps): JSX.Element => {
  const { store, code } = assessment;
  const { brand, name } = store;
  return (
    <div css={style.store}>
      <BrandImage src={brand.logo} />
      <div css={style.titleContainer}>
        <span css={style.title}>{name}</span>
        <span css={style.subTitle}>{code}</span>
      </div>
    </div>
  );
};
