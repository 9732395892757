import { css, SerializedStyles } from '@emotion/react';
import { brandProposalStatusColor } from '../components/auditProposalCard.style';
import { BRAND_AUDIT_PROPOSAL_STATUS } from '../model/genericAuditProposal';

const container = css`
  overflow: auto;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const group = css`
  display: flex;
  overflow: auto;
  @media (min-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const header = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const addButton = (label: string): SerializedStyles => css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: var(--saa-primary-color-80);
    color: var(--saa-white-color);
  }

  @media (min-width: 1200px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: unset;
      background-color: var(--saa-primary-color-80);
      color: var(--saa-white-color);

      :before {
        content: '${label}';
        padding-right: 5px;
      }
    }
  }
`;

const searchBoxContainer = css`
  flex: 1;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

const cardsContainer = css`
  height: 100%;
  @media (min-width: 1200px) {
    overflow: auto;
    padding-right: 5px;
  }
`;

const sectionTitle = (status: BRAND_AUDIT_PROPOSAL_STATUS): SerializedStyles => {
  const border = `&& {box-shadow: inset 0 -2px 0 0 ${brandProposalStatusColor(status)}}`;
  return css`
    && {
      margin: 10px 0 10px;
      padding-bottom: 3px;
      ${border}
    }
  `;
};

const notFound = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
`;

export default {
  container,
  header,
  addButton,
  searchBoxContainer,
  cardsContainer,
  group,
  sectionTitle,
  notFound,
};
