import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const card = css`
  background-color: var(--saa-white-color);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 3px;
  box-shadow: 0px 1px 0px 0px var(--saa-primary-color-40);
`;
const info = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    > div {
      flex: 1 1 0;
    }
  }
`;
const nameField = css`
  div span:first-of-type {
    line-height: 36px;
  }
`;
const rolesField = css`
  && {
    width: 100%;
  }
`;

const isRegionalManagerField = css`
  && {
    //width: 70%;
  }
`;

const approvalRequiredField = css`
  && {
    //width: 30%;
  }
`;

const roles = css`
  border-bottom: solid 1px var(--saa-primary-color-10);

  div {
    border: none;
  }
`;

const approvalRequired = css`
  border-bottom: solid 1px var(--saa-primary-color-10);

  div {
    border: none;
  }
`;

const brandsField = css`
  && {
    width: 100%;
  }
`;

const brands = css`
  border-bottom: solid 1px var(--saa-primary-color-10);

  div {
    border: none;
  }
`;

const buttonsContainer = css`
  display: flex;
  margin-top: 20px;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
    && {
      button {
        width: 125px;
      }
    }
  }
`;

const saveButton = css`
  && {
    &:not(:disabled),
    :not([disabled]) {
      ${style.primaryButton};
    }
  }
`;

const dropDown = css`
  &&&& {
    margin: 0 0 5px 0px;
    word-break: break-word;

    .menu .item {
      margin: 0;
      white-space: normal;
    }
  }
`;

const dropDownRoles = css`
  ${dropDown};

  &&&& {
    .label {
      font-size: 1em;
    }

    .item {
      font-size: 1em;
    }
  }
`;

const dropDownBrands = css`
  ${dropDown};

  &&&& {
    .label {
      font-size: 1em;
    }

    .item {
      font-size: 1em;
    }
  }
`;

const dropDownIsRegionalManagerOf = css`
  ${dropDown};

  &&&& {
    .label {
      font-size: 1em;
    }

    .item {
      font-size: 1em;
    }
  }
`;

const rowMultipleField = css`
  display: flex;
  margin: 0;
  padding: 0;
`;

const field = css`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: left;
  i {
    flex-shrink: 0;
  }
`;

const editableField = css`
  ${field};
  cursor: pointer;
`;

const checkBoxContainer = css`
  && {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
`;
const checkBoxLabel = css`
  &&&&&&& {
    width: max-content;
  }
`;
const checkBox = css`
  width: max-content;
`;

export default {
  card,
  info,
  roles,
  brands,
  buttonsContainer,
  saveButton,
  nameField,
  rolesField,
  isRegionalManagerField,
  approvalRequiredField,
  brandsField,
  dropDownRoles,
  dropDownBrands,
  dropDownIsRegionalManagerOf,
  approvalRequired,
  rowMultipleField,
  editableField,
  checkBoxContainer,
  checkBoxLabel,
  checkBox,
};
