/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { User, UserCreationRequest, UserUpdateRequest } from '../model/user.model';
import { createUser, deleteUser, removeEmptyUser, updateUser } from '../store/usersSlice';
import { UserCardReadOnly } from './UserCardReadOnly';
import { UserCardWritable, UserFields } from './UserCardWritable';
import { useAppDispatch } from '../../../core/store';

interface UserCardProps {
  user: User;
  editable?: boolean;
}

export const UserCard = ({ user, editable = false }: UserCardProps): JSX.Element => {
  const [edit, setEdit] = useState(editable);
  const dispatch = useAppDispatch();
  const onDelete = (id: string) => dispatch(deleteUser(id));

  const onEdit = () => {
    setEdit(true);
  };

  const onSave = (userFields: UserFields) => {
    if (user.id === '') {
      const request: UserCreationRequest = {
        ...userFields,
        regions: userFields.regions.length > 0 ? userFields.regions : null,
        countries: userFields.countries.length > 0 ? userFields.countries : null,
      };
      dispatch(createUser(request));
    } else {
      const request: UserUpdateRequest = {
        roles: userFields.roles,
        brands: userFields.brands,
        approvalRequired: userFields.approvalRequired,
        isRegionalManagerOf: userFields.isRegionalManagerOf,
        regions: userFields.regions.length > 0 ? userFields.regions : null,
        countries: userFields.countries.length > 0 ? userFields.countries : null,
      };
      dispatch(updateUser(user.id, request));
    }
    setEdit(false);
  };

  const onCancel = () => {
    if (user.id === '') {
      dispatch(removeEmptyUser());
    }
    setEdit(false);
  };

  return edit || user.id === '' ? (
    <UserCardWritable user={user} onSave={onSave} onCancel={onCancel} />
  ) : (
    <UserCardReadOnly user={user} onEdit={onEdit} onDelete={onDelete} />
  );
};
