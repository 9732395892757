/** @jsxImportSource @emotion/react */

import React from 'react';
import { Checkbox, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './assessmentBulkUpdateTableRow.style';
import { AssessmentBulkUpdate } from '../../model/assessmentBulkUpdate';

interface AssessmentBulkUpdateTableRowProps {
  assessment: AssessmentBulkUpdate;
  onClickRow?: (assessment: AssessmentBulkUpdate) => void;
  rowHeight?: number;
  cellTitle?: boolean;
  selected: boolean;
  onCheckboxClick: (id: string) => void;
}

export const AssessmentBulkUpdateTableRow: React.FC<AssessmentBulkUpdateTableRowProps> = ({
  assessment,
  onClickRow,
  rowHeight,
  cellTitle = true,
  selected,
  onCheckboxClick,
}) => {
  const { t } = useTranslation();

  return (
    <Table.Row
      css={style.row}
      style={{ cursor: onClickRow ? 'pointer' : 'default', height: rowHeight || 'auto' }}
      verticalAlign='bottom'>
      <Table.Cell title={cellTitle ? t(`assessment.status.${assessment.status}`) : ''}>
        <Checkbox
          checked={selected}
          onClick={() => {
            onCheckboxClick(assessment.id);
          }}
        />
      </Table.Cell>
      <Table.Cell
        title={cellTitle ? t(`assessment.status.${assessment.status}`) : ''}
        onClick={() => onClickRow && onClickRow(assessment)}>
        {t(`assessment.status.${assessment.status}`) ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.brand : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.brand.description ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.region : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.region ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.country : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.country ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.city : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.city ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.hfmCode : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.hfmCode ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.jdaCode : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.jdaCode ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.locationName : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.locationName ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.year : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.year ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? assessment.month : ''} onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.month ?? '-'}
      </Table.Cell>
      <Table.Cell
        title={cellTitle ? t(`assessment.followUpStatus.${assessment.followUpStatus}`) : ''}
        onClick={() => onClickRow && onClickRow(assessment)}>
        {t(`assessment.followUpStatus.${assessment.followUpStatus}`) ?? '-'}
      </Table.Cell>
      <Table.Cell
        title={cellTitle ? assessment.auditors?.map(auditor => auditor.email).join(',') : ''}
        onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.auditors?.map(auditor => auditor.email).join(',') ?? '-'}
      </Table.Cell>
      <Table.Cell
        title={
          cellTitle ? assessment.actionPlanCoordinators?.map(brandCoordinator => brandCoordinator.email).join(',') : ''
        }
        onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.actionPlanCoordinators?.map(brandCoordinator => brandCoordinator.email).join(',') ?? '-'}
      </Table.Cell>
      <Table.Cell
        title={cellTitle ? assessment.distributionList?.map(ds => ds.email).join(',') : ''}
        onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.distributionList?.map(ds => ds.email).join(',') ?? '-'}
      </Table.Cell>
      <Table.Cell
        title={cellTitle ? assessment.readOnlyDistributionList?.map(ds => ds.email).join(',') : ''}
        onClick={() => onClickRow && onClickRow(assessment)}>
        {assessment.readOnlyDistributionList?.map(ds => ds.email).join(',') ?? '-'}
      </Table.Cell>
    </Table.Row>
  );
};
