/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import style from './multipleDateRangeField.style';
import { useCtrlEnterToRunCallback } from '../core/utils';
import { ProposedPeriod } from '../features/assessmentsPlanning/model/genericAuditProposal';

interface MultipleDateRangeFieldProps {
  defaultProposedPeriods: ProposedPeriod[];
  editable?: boolean;
  onEdited?: (defaultProposedPeriods: ProposedPeriod[]) => void;
  title?: string;
  minDate?: Date;
  maxDate?: Date;
}

export const MultipleDateRangeField = ({
  defaultProposedPeriods = [],
  editable = false,
  onEdited = () => null,
  title = '',
  minDate,
  maxDate,
}: MultipleDateRangeFieldProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [proposedPeriodsState, setProposedPeriodsState] = useState<string[][]>(
    defaultProposedPeriods.map(range => [range.dateFrom, range.dateTo])
  );

  useEffect(() => {
    setProposedPeriodsState(defaultProposedPeriods.map(range => [range.dateFrom, range.dateTo]));
  }, [defaultProposedPeriods]);

  const updateValue = useCallback((): void => {
    if (proposedPeriodsState && proposedPeriodsState.length > 0) {
      onEdited(
        proposedPeriodsState.map(range => {
          return { dateFrom: range[0], dateTo: range.length === 1 ? range[0] : range[1] };
        })
      );
      setOpen(false);
    }
  }, [proposedPeriodsState, onEdited]);

  const clearValue = useCallback((): void => {
    setProposedPeriodsState([]);
    onEdited([]);
    setOpen(false);
  }, [onEdited]);

  const cancelValue = useCallback((): void => {
    setProposedPeriodsState(defaultProposedPeriods.map(range => [range.dateFrom, range.dateTo]));
    setOpen(false);
  }, [defaultProposedPeriods]);

  const getFieldDescription = useCallback((): string => {
    if (!proposedPeriodsState || proposedPeriodsState.length === 0) {
      return '';
    }

    const formattedDates = proposedPeriodsState.map((dateRange: string[]) => {
      if (dateRange.length === 1 || new Date(dateRange[0]).getDate() === new Date(dateRange[1]).getDate()) {
        const dateObj = new Date(dateRange[0]);
        return `${dateObj.getDate()} ${dateObj.toLocaleDateString('en-US', { month: 'short' })}`;
      }

      const startRangeDate = new Date(dateRange[0]);
      const endRangeDate = new Date(dateRange[1]);

      return `${startRangeDate.getDate()}-${endRangeDate.getDate()} ${endRangeDate.toLocaleDateString('en-US', {
        month: 'short',
      })}`;
    });

    return formattedDates.join(', ');
  }, [proposedPeriodsState]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div
        title={title as string}
        css={editable ? style.editableField : style.field}
        onClick={() => editable && setOpen(true)}>
        <Icon name='calendar alternate' />
        <div css={style.valueContainer}>
          <span css={style.value}> {getFieldDescription()} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.inputContainer}>
            <Calendar
              multiple
              range
              css={style.calendar}
              minDate={minDate}
              maxDate={maxDate}
              value={proposedPeriodsState as unknown as DateObject[]}
              highlightToday={false}
              onChange={(selectedDates: unknown) => {
                const selectedDateRangeList = selectedDates as DateObject[][];

                const formatted: string[][] = selectedDateRangeList.map((dateRange: DateObject[]) => {
                  return dateRange.map((date: DateObject) => {
                    return new Date(date.year, date.monthIndex, date.day, date.hour).toISOString();
                  });
                });

                setProposedPeriodsState(formatted);
              }}
              plugins={[<DatePanel sort='date' />]}
            />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => clearValue()}>
              <Icon name='eraser' /> Clear
            </Button>
          )}
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export const getMinDateOfRange = (periods: ProposedPeriod[]): Date => {
  const dateList: number[] = [];

  periods.forEach(range => {
    dateList.push(new Date(range.dateFrom).getTime());
    dateList.push(new Date(range.dateTo).getTime());
  });

  return new Date(Math.min(...dateList));
};

export const getMaxDateOfRange = (periods: ProposedPeriod[]): Date => {
  const dateList: number[] = [];

  periods.forEach(range => {
    dateList.push(new Date(range.dateFrom).getTime());
    dateList.push(new Date(range.dateTo).getTime());
  });

  return new Date(Math.max(...dateList));
};
