import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&& {
    > div {
      overflow: hidden;
    }
  }
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
  && {
    padding-right: 0;
    button {
    margin-right: 0;
    }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;
const inputContainer = css`
  display: flex;
  width: 100%;
  &&&& {
    > div {
      flex: 1;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
    button {
      flex-shrink: 1;
      margin-left: 5px;
    }
  }
`;

const resultContainer = css`
  && {
    height: 190px;
    overflow: auto;
    margin-top: 10px;
  }
`;

const buttonsContainer = css`
  margin-top: 10px;
  display: flex;
  button {
    width: 50%;
  }
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const distributionListMemberInfo = css`
  padding-left: 5px;
`;

const distributionListMemberName = css`
  font-size: 0.8rem;
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  resultContainer,
  button,
  actions,
  checkBoxContainer,
  editIcon,
  buttonsContainer,
  subTitle,
  valueContainer,
  value,
  distributionListMemberInfo,
  distributionListMemberName,
};
