/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { ROLES } from '../../auth/model/principal.model';

interface RoleMultiSelectFieldProps {
  defaultValue?: string[];
  onUpdate?: (role: string[]) => void;
}

export function RoleMultiSelectField({ defaultValue = [], onUpdate }: RoleMultiSelectFieldProps) {
  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: item,
    };
  };

  return (
    <ModalMultiSelectField
      editable
      icon='cogs'
      defaultValue={defaultValue != null ? defaultValue.map(r => toSelectItem(r)) : []}
      list={Object.values(ROLES).map((r): SelectItem => toSelectItem(r) as SelectItem)}
      title={t('users.roles') || ''}
      onUpdateValue={(selectedRole: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedRole.map(i => i.code));
        }
      }}
    />
  );
}
