import { css } from '@emotion/react';

const brandContainer = css`
  padding-right: 10px;
  flex-shrink: 0;
`;

const brand = css`
  && {
    border: solid 1px var(--saa-primary-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export default {
  brand,
  brandContainer,
};
