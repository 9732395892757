/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Icon, Modal, SemanticICONS } from 'semantic-ui-react';
import Calendar from 'react-calendar';
import style from './calendarField.style';
import { getDate, getForcedDateFromUtc, getForcedUTCDateString, useCtrlEnterToRunCallback } from '../../../core/utils';

interface CalendarFieldProps {
  defaultValue?: string;
  editable?: boolean;
  onChange?: (value: string) => void;
  title: string;
  labeled?: boolean;
  isValid?: boolean;
  mutable?: boolean;
  minDate?: Date;
  maxDate?: Date;
  customIcon?: SemanticICONS;
}

export const CalendarField = ({
  defaultValue = '',
  editable = false,
  onChange = () => null,
  title,
  labeled = false,
  isValid = true,
  mutable = false,
  minDate,
  maxDate,
  customIcon,
}: CalendarFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [calendarState, setCalendarState] = React.useState(
    defaultValue == null || defaultValue === '' ? getDate() : getForcedDateFromUtc(defaultValue)
  );

  useEffect(() => {
    if (mutable)
      setCalendarState(defaultValue == null || defaultValue === '' ? getDate() : getForcedDateFromUtc(defaultValue));
  }, [defaultValue, mutable]);

  const updateValue = () => {
    onChange(getForcedUTCDateString(calendarState));
    setOpen(false);
  };
  const cancelValue = () => {
    setCalendarState(defaultValue == null || defaultValue === '' ? getDate() : getForcedDateFromUtc(defaultValue));
    setOpen(false);
  };

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <>
      {!labeled ? (
        <Field
          title={title}
          isValid={isValid}
          defaultValue={defaultValue}
          editable={editable}
          setOpen={setOpen}
          customIcon={customIcon}
        />
      ) : (
        <LabeledField
          setOpen={setOpen}
          title={title}
          isValid={isValid}
          defaultValue={defaultValue}
          editable={editable}
        />
      )}
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div>
              <Calendar
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(v: Date | Date[]) => setCalendarState(v as Date)}
                value={calendarState}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={cancelValue}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface FieldProps {
  defaultValue: string;
  editable: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  isValid: boolean;
  customIcon?: SemanticICONS;
}

const Field = ({ title, editable, setOpen, defaultValue, isValid, customIcon }: FieldProps): JSX.Element => {
  return (
    <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
      {customIcon ? (
        <Icon name={customIcon} css={!isValid && style.validationIcon} />
      ) : (
        <Icon name='calendar alternate' css={!isValid && style.validationIcon} style={{ visibility: 'hidden' }} />
      )}
      <div css={style.valueContainer}>
        <span css={style.value}>{defaultValue ? getForcedUTCDateString(defaultValue) : ' '}</span>
        <span css={style.subTitle}>{title}</span>
        {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}
      </div>
      {editable && (
        <div css={style.editIcon}>
          <Icon name='pencil' />
        </div>
      )}
    </div>
  );
};

interface LabeledFieldProps {
  defaultValue: string;
  editable: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  isValid: boolean;
}

const LabeledField = ({ title, editable, setOpen, defaultValue, isValid }: LabeledFieldProps): JSX.Element => {
  return (
    <div>
      <div css={style.freeTextTitle(isValid)} title={title}>
        {title}
      </div>
      <div css={editable ? style.labeledEditableField : style.labeledField} onClick={() => editable && setOpen(true)}>
        <div>
          {' '}
          {defaultValue ? getForcedUTCDateString(defaultValue) : ''}{' '}
          {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}{' '}
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
    </div>
  );
};
