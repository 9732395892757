import { css } from '@emotion/react';
import style from '../../../shared/global.style';
import fieldStyle from './field.style';

const topicContainer = css`
  && {
    display: flex;
    background-color: var(--saa-white-color);
    padding: 10px;
    border-bottom: solid 2px var(--saa-primary-color-10);
    cursor: pointer;
    align-items: center;
    position: relative;
  }
`;

const newTopicbutton = css`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--saa-primary-color);
    height: 40px;
    background-color: var(--saa-primary-color-20);
    cursor: pointer;
    position: relative;
    margin-bottom: 2px;
    padding: 10px;
  }
`;

const topicElement = css`
  && {
    color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--saa-primary-color-5);
    margin-top: 1px;
  }
`;

const plusIcon = css`
  && {
    margin-left: 10px;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: var(--saa-primary-color);
    color: var(--saa-primary-color-5);
  }
`;

const topicListContent = css`
  ${style.bodySmall}
  && {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
  }
`;
const processContent = css`
  &&&& {
    background-color: var(--saa-primary-color-10);
    margin: 5px 15px;
    color: var(--saa-primary-color);
    > div {
      padding: 0;
    }
  }
`;

const process = css`
  &&& {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: solid 1px var(--saa-primary-color-10);
  }
`;

const processTitle = css`
  ${style.bodySmall};

  &&&&& {
    background-color: var(--saa-white-color);
    display: flex;
    font-weight: 600;
    padding: 10px 2px;
    align-items: center;
    cursor: pointer;
    div {
      flex: 1;
    }
    i {
      font-size: 1.3rem;
    }
  }
`;

const successIcon = css`
  color: var(--saa-success);
  justify-content: flex-end;
`;
const errorIcon = css`
  color: var(--saa-error);
`;

const topicDescription = css`
  ${style.bodySmall}
  && {
    flex: 1;
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
`;

const topicDescriptionContent = css`
  && {
    display: flex;
    background-color: var(--saa-white-color);
    padding: 10px;
    border-bottom: solid 2px var(--saa-primary-color-10);
    cursor: pointer;
    align-items: center;
    position: relative;
  }
`;

const newTopicIcon = css`
  ${fieldStyle.editIcon};
  &&&& {
    position: relative;
    color: var(--saa-primary-color-10);
    background-color: var(--saa-primary-color);
  }
`;

const editTopicIcon = css`
  ${fieldStyle.editIcon};
  &&&& {
    position: relative;
  }
`;

export default {
  topicContainer,
  newTopicbutton,
  topicElement,
  plusIcon,
  topicListContent,
  processContent,
  process,
  processTitle,
  successIcon,
  errorIcon,
  topicDescription,
  topicDescriptionContent,
  editTopicIcon,
  newTopicIcon,
};
