import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const store = css`
  &&& {
    display: flex;
    height: 70px;
    padding: 10px;
    flex-grow: 0;
    width: 100%;
    align-items: center;
  }
`;

const title = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${style.subtitleNormal}
`;

const subTitle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ...${style.subtitleSmall};
    color: var(--saa-primary-color-60)
`;

const titleContainer = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
`;

export default {
  store,
  titleContainer,
  title,
  subTitle,
};
