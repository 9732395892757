/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import { useAppDispatch } from '../../../../../core/store';
import { AuditProposal } from '../../../model/auditProposal';
import { deleteProposal } from '../../../store/auditProposalDetailSlice';
import style from './auditProposalDetailHeader.style';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../../../model/genericAuditProposal';
import { StoreHeader } from '../../../../../shared/StoreHeader';

interface AuditProposalDetailHeaderProps {
  campaignId: string;
  proposal: AuditProposal;
  deletable: boolean;
}

export const AuditProposalDetailHeader = ({
  campaignId,
  proposal,
  deletable,
}: AuditProposalDetailHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div css={style.header}>
      <Icon css={style.backIcon} name='arrow left' onClick={() => navigate('/audit-campaigns')} />
      <div css={style.proposalHeader}>
        <StoreHeader store={proposal.store} />
      </div>
      {deletable ? (
        <StatusWithDelete campaignId={campaignId} proposal={proposal} />
      ) : (
        <StatusIcon status={proposal.status} />
      )}
    </div>
  );
};

const StatusIcon = ({ status }: { status: INTERNAL_AUDIT_PROPOSAL_STATUS }) => (
  <div css={style.statusIcon(status)}>
    <Icon name='square' />
  </div>
);

interface StatusWithMenuProps {
  campaignId: string;
  proposal: AuditProposal;
}

const StatusWithDelete = ({ campaignId, proposal }: StatusWithMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StatusDots onClick={() => setOpen(true)} statusCode={proposal.status} />
      <Modal css={style.modal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Actions</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <MenuDeleteSection campaignId={campaignId} proposal={proposal} onClose={() => setOpen(false)} />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => setOpen(false)}>
            <Icon name='cancel' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface StatusDotsProps {
  onClick: () => void;
  statusCode: INTERNAL_AUDIT_PROPOSAL_STATUS;
}

const StatusDots = ({ onClick, statusCode }: StatusDotsProps) => (
  <div css={style.smallDotsContainer} onClick={onClick}>
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
  </div>
);

interface MenuDeleteSectionProps {
  campaignId: string;
  proposal: AuditProposal;
  onClose: () => void;
}

const MenuDeleteSection = ({ campaignId, proposal, onClose }: MenuDeleteSectionProps) => {
  return (
    <div style={{ color: 'var( --saa-primary-color)' }}>
      <MenuDeleteItem campaignId={campaignId} proposal={proposal} onClose={onClose} />
    </div>
  );
};

interface MenuDeleteItemProps {
  campaignId: string;
  proposal: AuditProposal;
  onClose: () => void;
}

const MenuDeleteItem = ({ campaignId, proposal, onClose }: MenuDeleteItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);

  const deleteAuditProposal = useCallback(async () => {
    const isDeleted = await dispatch(deleteProposal(campaignId, proposal.id));
    if (isDeleted) {
      navigate('/audit-campaigns');
    }
  }, [dispatch, proposal.id, campaignId, navigate]);

  return (
    <>
      <div css={style.menuItemContainer} onClick={() => setDeleteConfirmIsOpen(true)}>
        <Icon name='trash alternate' css={style.fileIcon} />
        <span>{t('auditCampaigns.proposalDetail.deleteProposal')}</span>
      </div>
      <Confirm
        css={style.modalConfirm}
        open={deleteConfirmIsOpen}
        onCancel={() => {
          setDeleteConfirmIsOpen(false);
          onClose();
        }}
        content={t('auditCampaigns.proposalDetail.deleteProposalConfirm')}
        onConfirm={() => {
          setDeleteConfirmIsOpen(false);
          deleteAuditProposal();
          onClose();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};
