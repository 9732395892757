import axios from 'axios';
import qs from 'query-string';
import paths from './paths';
import { User, toUser, UserUpdateRequest, AdUser, toAdUser, UserCreationRequest } from '../model/user.model';
import { BrandCode } from '../../../shared/model/brand.model';
import { Region, toRegion } from '../../../shared/model/region.model';
import { Country, toCountry } from '../../../shared/model/country.model';
import { CountryQueryParams } from '../model/CountryQueryParams';

const api = {
  getUsers: async (): Promise<User[]> => {
    const response = await axios.get(paths.getUsers());
    return response.data.map(toUser);
  },
  deleteUser: async (id: string): Promise<string> => {
    const response = await axios.delete(paths.deleteUser(id));
    return response.data;
  },
  updateUser: async (id: string, request: UserUpdateRequest): Promise<User> => {
    const response = await axios.put(paths.putUser(id), request);
    return toUser(response.data);
  },
  getAdUsers: async (filter: string): Promise<AdUser[]> => {
    const response = await axios.get(`${paths.getAdUsers()}?filter=${filter}`);
    return response.data.map(toAdUser);
  },
  getMergedUsers: async (filter: string, brand: BrandCode | undefined = undefined): Promise<AdUser[]> => {
    const brandFilter = brand != null ? `&brand=${brand}` : '';
    const response = await axios.get(`${paths.getMergedUsers()}?filter=${filter}${brandFilter}`);
    return response.data.map(toAdUser);
  },
  createUser: async (request: UserCreationRequest): Promise<User> => {
    const response = await axios.post(paths.postUser(), request);
    return toUser(response.data);
  },
  getBrandSchedulers: async (): Promise<User[]> => {
    const response = await axios.get(paths.getBrandSchedulers());
    return response.data.map(toUser);
  },
  getKeringRegions: async (): Promise<Region[]> => {
    const response = await axios.get(paths.getKeringRegions());
    return response.data.map(toRegion);
  },
  getKeringCountries: async (params: CountryQueryParams): Promise<Country[]> => {
    const response = await axios.get(paths.getKeringCountries(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toCountry);
  },
};

export default api;
