/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FindingSuggestion, FindingSuggestionsForPriority, Priority } from '../model/assessmentDetail.model';
import { ModalPage } from '../../../shared/ModalPage';
import style from './checkList.style';
import { useAppDispatch } from '../../../core/store';
import { changeFindingSuggestions } from '../store/findingSuggestionsSlice';

interface PrioritySelectionHelperProps {
  subProcessCode?: string;
  subProcessDescription?: string;
  setPastExamplesOpen?: (value: boolean) => void;
  findingSuggestions?: FindingSuggestionsForPriority[];
  onClickFindingSuggestion?: (priority: Priority, finding: string, recommendation: string) => void;
}

export const PrioritySelectionHelper = ({
  subProcessCode,
  subProcessDescription,
  setPastExamplesOpen = () => null,
  findingSuggestions = [],
  onClickFindingSuggestion = (priority: Priority, finding: string, recommendation: string) => null,
}: PrioritySelectionHelperProps): JSX.Element => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const dispatch = useAppDispatch();

  const toggleAccordion = (i: number) => {
    if (activeIndex === i) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(i);
    }
  };

  return (
    <ModalPage
      unsavedChangesPopupDisabled
      title={t('assessment.audit.fixListTitle') || ''}
      onClose={() => {
        setPastExamplesOpen(false);
        dispatch(changeFindingSuggestions([]));
      }}>
      <div css={style.container} style={{ overflow: 'auto' }}>
        <div css={style.title}>{`${subProcessCode} ${subProcessDescription}`}</div>
        {findingSuggestions.map((findingSuggestionsForPriority, index) => {
          return (
            <FindingSuggestionsForPriorityItem
              index={index}
              onClickFindingSuggestion={onClickFindingSuggestion}
              findingSuggestionsForPriority={findingSuggestionsForPriority}
              toggleAccordion={toggleAccordion}
              activeIndex={activeIndex}
            />
          );
        })}
      </div>
    </ModalPage>
  );
};

interface FindingSuggestionsForPriorityItemProps {
  index: number;
  onClickFindingSuggestion: (priority: Priority, finding: string, recommendation: string) => void;
  findingSuggestionsForPriority: FindingSuggestionsForPriority;
  toggleAccordion: (index: number) => void;
  activeIndex: number;
}
const FindingSuggestionsForPriorityItem = ({
  index,
  onClickFindingSuggestion,
  findingSuggestionsForPriority,
  toggleAccordion,
  activeIndex,
}: FindingSuggestionsForPriorityItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Accordion css={style.priorityRow}>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={() => toggleAccordion(index)}
        css={style.priorityTitle}>
        <Icon name='dropdown' />
        <div>
          Priority: {t(`assessment.priority.${findingSuggestionsForPriority.priority}`)} (
          {findingSuggestionsForPriority.findingsCount})
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Accordion.Content active={activeIndex === index}>
          {findingSuggestionsForPriority.findingSuggestions.length > 0 ? (
            findingSuggestionsForPriority.findingSuggestions.map((findingSuggestion, findingSuggestionIndex) => {
              return (
                <FindingSuggestionItem
                  onClickFindingSuggestion={onClickFindingSuggestion}
                  findingSuggestionsForPriority={findingSuggestionsForPriority}
                  findingSuggestion={findingSuggestion}
                  findingSuggestionIndex={findingSuggestionIndex}
                />
              );
            })
          ) : (
            <div css={style.noSuggestionsLabel}>
              <i>{t('assessment.checkList.noSuggestions')}</i>
            </div>
          )}
        </Accordion.Content>
      </Accordion.Content>
    </Accordion>
  );
};

interface FindingSuggestionItemProps {
  onClickFindingSuggestion: (priority: Priority, finding: string, recommendation: string) => void;
  findingSuggestionsForPriority: FindingSuggestionsForPriority;
  findingSuggestion: FindingSuggestion;
  findingSuggestionIndex: number;
}
const FindingSuggestionItem = ({
  onClickFindingSuggestion,
  findingSuggestionsForPriority,
  findingSuggestion,
  findingSuggestionIndex,
}: FindingSuggestionItemProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div
      css={style.suggestionContainer}
      onClick={() => {
        onClickFindingSuggestion(
          findingSuggestionsForPriority.priority,
          findingSuggestion.finding,
          findingSuggestion.recommendation
        );
        dispatch(changeFindingSuggestions([]));
      }}>
      <div css={style.suggestionTitle} style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <div css={style.suggestionLabel}>Finding</div>
        <div css={style.suggestionIndex}>
          {findingSuggestionIndex + 1} of {findingSuggestionsForPriority.findingSuggestions.length}
        </div>
      </div>
      <div css={style.suggestionText}>{findingSuggestion.finding}</div>
      <div css={style.suggestionTitle} style={{ marginTop: '5px' }}>
        <div css={style.suggestionLabel}>Recommendation</div>
        <div css={style.suggestionIndex}>
          {findingSuggestionIndex + 1} of {findingSuggestionsForPriority.findingSuggestions.length}
        </div>
      </div>
      <div css={style.suggestionText} style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
        {findingSuggestion.recommendation}
      </div>
    </div>
  );
};
