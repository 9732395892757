/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import style from './commentsAssessmentField.style';
import { Comment } from '../model/assessmentDetail.model';
import { ModalPage } from '../../../shared/ModalPage';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface AssessmentFieldProps {
  defaultValue: Comment[];
  editable?: boolean;
  onEdited?: (value: Comment) => void;
  onNew?: (content: string) => void;
  title?: string;
}

export const CommentsAssessmentField = ({
  defaultValue = [],
  editable = false,
  onEdited = () => null,
  onNew = () => null,
  title = '',
}: AssessmentFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [openNewComment, setOpenNewComment] = React.useState(false);

  const { t } = useTranslation();

  const getDescription = useCallback(() => {
    return defaultValue.length === 0 ? t('assessment.noComment') : `${defaultValue.length} comments`;
  }, [defaultValue.length, t]);

  const addComment = useCallback(
    (content: string) => {
      setOpenNewComment(false);
      onNew(content);
    },
    [onNew]
  );

  return (
    <div>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => setOpen(true)}>
        <Icon name='comment outline' />
        <div css={style.valueContainer}>
          <span css={style.value}> {getDescription()} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable ? (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        ) : (
          <div css={style.editIcon}>
            <Icon name='search' />
          </div>
        )}
      </div>

      {open ? (
        <ModalPage onClose={() => setOpen(false)} title={t('assessment.internalComments') || ''}>
          <div css={style.modalContainer}>
            <div css={style.commentsContainer}>
              {defaultValue.length > 0 ? (
                defaultValue
                  .slice()
                  .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                  .map(c => <CommentComponent key={c.created} comment={c} editable={editable} onEdited={onEdited} />)
              ) : (
                <div css={style.noComments}>{t('assessment.noComment')}</div>
              )}
            </div>
            {editable && (
              <div css={style.addButtonContainer}>
                <Button onClick={() => setOpenNewComment(true)} css={style.addButton}>
                  {t('assessment.newComment')}
                </Button>
                {openNewComment && (
                  <CommentTextArea
                    comment=''
                    onEdited={addComment}
                    onClose={() => setOpenNewComment(false)}
                    title={t('assessment.newComment')}
                  />
                )}
              </div>
            )}
          </div>
        </ModalPage>
      ) : (
        <></>
      )}
    </div>
  );
};

interface CommentProps {
  comment: Comment;
  editable?: boolean;
  onEdited?: (value: Comment) => void;
}

export const CommentComponent = ({ comment, editable = false, onEdited = () => null }: CommentProps): JSX.Element => {
  const { t } = useTranslation();
  const [openEditComment, setOpenEditComment] = React.useState(false);

  const commentRef = React.useRef<HTMLTextAreaElement>(null);
  const user = useSelector(selectPrincipal);

  useEffect(() => {
    if (commentRef.current != null) {
      commentRef.current.style.height = `${commentRef.current.scrollHeight}px`;
    }
  }, [commentRef, comment]);

  const formatDate = useCallback((d: Date, human: boolean): string => {
    return human ? `${moment(d).fromNow(true)} ago` : moment(d).format('yyyy-MM-DD hh:mm');
  }, []);

  const isMyComment = useCallback((): boolean => {
    return user.email.toLowerCase() === comment.author.email;
  }, [comment.author.email, user.email]);

  return (
    <div css={style.commentContainer(comment.fromAuditor, isMyComment())}>
      <div css={style.commentHeader(comment.fromAuditor, isMyComment())}>
        <div css={style.commentAuthor}>{isMyComment() ? 'Me' : comment.author.name} </div>
        <div css={style.commentCreated} title={formatDate(new Date(comment.created), false)}>
          {formatDate(new Date(comment.created), true)}
        </div>
      </div>
      <div css={style.commentContent}>
        <textarea css={style.commentTextArea} value={comment.content} readOnly disabled ref={commentRef} />
      </div>
      <div css={style.commentFooter}>
        {openEditComment && (
          <CommentTextArea
            comment={comment.content}
            onEdited={value => {
              setOpenEditComment(false);
              onEdited({ ...comment, content: value });
            }}
            onClose={() => setOpenEditComment(false)}
            title={t('assessment.editComment')}
          />
        )}
        {comment.lastUpdate != null && (
          <div title={formatDate(new Date(comment.lastUpdate), false)}>
            Edited {formatDate(new Date(comment.lastUpdate), true)}
          </div>
        )}
        {editable && isMyComment() && (
          <Button icon css={style.commentFooterButton} onClick={() => setOpenEditComment(true)}>
            <Icon name='pencil' />
          </Button>
        )}
      </div>
    </div>
  );
};

interface CommentTextAreaProps {
  comment: string;
  onEdited?: (value: string) => void;
  onClose: () => void;
  title: string;
}

export const CommentTextArea = ({
  comment,
  onEdited = () => null,
  onClose,
  title,
}: CommentTextAreaProps): JSX.Element => {
  const [content, setContent] = React.useState(comment);
  useCtrlEnterToRunCallback(() => onEdited(content));

  const cancelValue = useCallback(() => {
    setContent(comment);
    onClose();
  }, [comment, onClose]);

  return (
    <>
      <Modal
        css={style.modal}
        onClose={() => {
          cancelValue();
        }}
        open>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <textarea value={content} onChange={event => setContent(event.target.value)} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => cancelValue()}>
            <Icon name='cancel' /> Cancel
          </Button>

          <Button css={style.button} onClick={() => onEdited(content)}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
