/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { Auditor } from '../model/assessment';
import { BrandCode } from '../../../shared/model/brand.model';

interface AuditorsSliceState {
  data: Auditor[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: AuditorsSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const auditorsSlice = createSlice({
  name: 'auditors',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditorsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<AuditorsSliceState>, { payload }: PayloadAction<Auditor[]>) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    errorFetch: (state: Draft<AuditorsSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<AuditorsSliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache } = auditorsSlice.actions;

export const fetchAuditors = (brand: BrandCode) => async (dispatch: any, state: any) => {
  if (!state().auditors.isFetching) {
    dispatch(startFetch());
    try {
      const auditors = (await api.getAuditors(brand)).sort((a1, a2) => a1.email.localeCompare(a2.email));
      dispatch(finishFetch(auditors));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const selectAuditors = (state: any) => state.auditors.data as Auditor[];

export const selectIsFetching = (state: any) => state.auditors.isFetching;

export default auditorsSlice.reducer;
