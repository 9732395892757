/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../core/store';
import { SearchBox } from '../../../shared/SearchBox';
import {
  fetchAuditCampaigns,
  onChangeShowOnlyAssignedProposalsChange,
  onFilterChange,
  selectFilter,
  selectFilteredSortedAuditCampaigns,
  selectIsFetching,
  selectIsToggleVisible,
  selectShowOnlyAssignedProposals,
} from '../store/auditCampaignsSlice';
import style from './auditCampaigns.style';
import { ProposalCard } from '../components/AuditProposalCard';
import { ModalPage } from '../../../shared/ModalPage';
import { AuditPlanningWizard } from '../components/internalAuditWizard/AuditPlanningWizard';
import { ToggleField } from '../../assessments/components/ToggleField';
import { reset, selectStep, WIZARD_STEP } from '../store/auditProposalWizardSlice';
import { AuditCampaign } from '../model/auditCampaign';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import { isProposalVisible } from '../model/auditProposal';

export function AuditCampaigns(): JSX.Element {
  const dispatch = useAppDispatch();
  const showVisibilityToggle = useSelector(selectIsToggleVisible);
  const auditCampaigns = useSelector(selectFilteredSortedAuditCampaigns);
  const isLoading = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(fetchAuditCampaigns());
  }, [dispatch]);
  return (
    <div css={style.container}>
      <Header />
      {showVisibilityToggle && <ProposalVisibilityToggle />}

      {isLoading ? (
        <Loader active />
      ) : (
        <div css={style.cardsContainer}>
          {auditCampaigns.map(camp => (
            <div key={camp.id} css={style.campaignContainer}>
              <CampaignHeader
                key={camp.id}
                campaignId={camp.id}
                campaignName={camp.name}
                dateFrom={camp.auditDateFrom}
                dateTo={camp.auditDateTo}
                region={camp.region.description}
                brandScheduler={camp.brandSchedulers?.[0]?.name || ''}
              />
              <CampaignProposals campaign={camp} />
            </div>
          ))}
          {auditCampaigns.length === 0 && <div css={style.notFound}>{t('auditCampaigns.noItemsFound')}</div>}
        </div>
      )}
    </div>
  );
}

interface CampaignProposalsProps {
  campaign: AuditCampaign;
}

export function CampaignProposals({ campaign }: CampaignProposalsProps): JSX.Element {
  const principal = useSelector(selectPrincipal);
  return (
    <div css={style.group}>
      {campaign.proposals
        .filter(proposal => isProposalVisible(principal, proposal))
        .map(proposal => (
          <ProposalCard key={proposal.id} proposal={proposal} campaignId={campaign.id} />
        ))}
    </div>
  );
}

function Header(): JSX.Element {
  const searchFilter = useSelector(selectFilter);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onFilterChange(value));
  }, 500);

  const closeCreation = () => {
    setIsModalOpen(false);
    dispatch(reset());
  };

  const principal = useSelector(selectPrincipal);
  const createButtonVisible = checkRole(principal, ROLES.AUDITOR);
  const wizardCurrentStep = useSelector(selectStep);

  const wizardTitle = useMemo(() => {
    if (wizardCurrentStep === WIZARD_STEP.SELECT_CAMPAIGN) {
      return t('auditCampaigns.createOrSelectCampaign');
    }
    if (wizardCurrentStep === WIZARD_STEP.CREATE_PROPOSAL) {
      return t('auditCampaigns.createProposal');
    }
    return t('auditCampaigns.createCampaign');
  }, [wizardCurrentStep]);

  return (
    <div css={style.header}>
      {createButtonVisible && (
        <Button
          css={style.addButton(t('auditCampaigns.createProposal'))}
          icon='plus'
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      )}
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={searchFilter} onChange={(filter: string) => debounced(filter.trim())} />
      </div>
      {isModalOpen && (
        <ModalPage onClose={closeCreation} title={wizardTitle}>
          <AuditPlanningWizard onSuccessfulCreation={closeCreation} />
        </ModalPage>
      )}
    </div>
  );
}

interface CampaignHeaderProps {
  campaignId: string;
  campaignName: string;
  dateFrom: string;
  dateTo: string;
  brandScheduler: string;
  region: string;
}
function CampaignHeader({
  campaignId,
  campaignName,
  dateFrom,
  dateTo,
  brandScheduler,
  region,
}: CampaignHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const goToCampaign = (id: string) => {
    navigate(`/audit-campaigns/${id}`);
  };

  return (
    <div
      key={campaignId}
      css={style.campaignHeader}
      className='campaign-header'
      onClick={() => goToCampaign(campaignId)}>
      <div css={style.campaignData}>
        <div style={{ width: 'fit-content' }}>
          <h5 css={style.campaignTitle}>{campaignName}</h5>
        </div>
        <div css={style.campaignInfo}>
          <div>
            <Icon className='fas fa-user-tie' />
            <span css={style.campaignDetail}>{brandScheduler}</span>
          </div>
          <div>
            <Icon name='calendar alternate' />
            <span css={style.campaignDetail}>
              {dateFrom} - {dateTo}
            </span>
          </div>
          <div>
            <Icon name='globe' />
            <span css={style.campaignDetail}>{region}</span>
          </div>
        </div>
      </div>
      <div css={style.editCampaignButton}>
        <Icon name='chevron right' size='large' />
      </div>
    </div>
  );
}

function ProposalVisibilityToggle(): JSX.Element {
  const dispatch = useAppDispatch();
  const showOnlyAssignedProposals = useSelector(selectShowOnlyAssignedProposals);

  return (
    <div css={style.visibilityToggle}>
      <ToggleField
        defaultValue={showOnlyAssignedProposals}
        editable
        onChange={value => {
          dispatch(onChangeShowOnlyAssignedProposalsChange(value));
        }}
        icon='filter'
        label={t('auditCampaigns.showMyProposals') || ''}
        title={t('auditCampaigns.showMyProposals')}
      />
    </div>
  );
}
