import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getJwtToken, setRedirectPagePath } from '../services/webStorageService';
import { selectPrincipal } from '../../features/auth/store/principalSlice';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Note if token is expired the interceptor redirect to login
export const PrivateRoute = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  const principal = useSelector(selectPrincipal);
  const location = useLocation();
  const path = location.pathname;
  setRedirectPagePath(path);
  if (!getJwtToken()) {
    return <Navigate state={{ from: location }} to='/login' />;
  }
  if (principal) {
    return children;
  }
  return null;
};
