import { useHotkeys } from 'react-hotkeys-hook';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect, useLayoutEffect, useState } from 'react';
import { HotkeyCallback } from 'react-hotkeys-hook/src/types';

export const scrollToTop = (element: HTMLElement | null, behavior: 'smooth' | 'auto' = 'smooth') => {
  if (element != null)
    element.scrollTo({
      top: 0,
      behavior,
    });
};

export const useCtrlEnterToRunCallback = (callback: HotkeyCallback, enabled?: boolean) => {
  return useHotkeys('ctrl+enter', callback, {
    enableOnFormTags: true,
    enabled: enabled != null ? enabled : true,
  });
};

export const useEscToRunCallback = (callback: HotkeyCallback, enabled?: boolean) => {
  return useHotkeys('esc', callback, {
    enableOnFormTags: true,
    enabled: enabled != null ? enabled : true,
  });
};

// Updates the height of a <textarea> when the value changes.
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
export const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px';

      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const getDateString = (date?: string | Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const getUTCDateString = (date?: string | Date) => {
  return moment.utc(date).format('YYYY-MM-DD');
};

export const getDateTimeString = (date?: string | Date) => {
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const getUTCDate = (date?: string | Date) => {
  return moment.utc(date).toDate();
};

export const getForcedDateFromUtc = (date?: string | Date) => {
  return moment(moment.utc(date).format('YYYY-MM-DD')).toDate();
};

export const getForcedUTCDateString = (date?: string | Date) => {
  return moment.utc(moment(date).format('YYYY-MM-DD')).format('YYYY-MM-DD');
};

export const getDate = (date?: string) => {
  return moment(date).toDate();
};
