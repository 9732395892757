import { toStore } from '../../../shared/model/store.model';
import { STATUS, StatusCode } from './assessmentStatus';
import {
  Assessment,
  Auditor,
  AuditScoreRating,
  BrandCoordinator,
  ChangeDetail,
  toAuditor,
  toBrandCoordinator,
} from './assessment';
import { FollowUpStatus } from './assessmentDetail.model';
import { getDateTimeString } from '../../../core/utils';
import { Contact } from '../../users/model/user.model';

export interface AssessmentFollowUp extends Assessment {
  followUpStatus: FollowUpStatus;
}

export const sortedFollowUpStatus: FollowUpStatus[] = [
  FollowUpStatus.UNDER_FOLLOWUP,
  FollowUpStatus.DONE,
  FollowUpStatus.PENDING,
  FollowUpStatus.COMPLETED,
];

export const toAssessmentFollowUp = (o: Record<string, unknown>): AssessmentFollowUp => {
  return {
    id: o.id as string,
    store: toStore(o.store as Record<string, unknown>),
    code: o.code as string,
    year: o.year as number,
    month: o.month as number,
    auditors: (o.auditors as Record<string, unknown>[]).map(toAuditor) as Auditor[],
    status: STATUS[o.status as StatusCode],
    creationDate: getDateTimeString(o.creationDate as string),
    distributionList: o.distributionList as Contact[],
    readOnlyDistributionList: o.readOnlyDistributionList as Contact[],
    brandCoordinatorList: (o.brandCoordinatorList as Record<string, unknown>[]).map(
      toBrandCoordinator
    ) as BrandCoordinator[],
    followUpStatus: o.followUpStatus == null ? FollowUpStatus.EMPTY : (o.followUpStatus as FollowUpStatus),
    scoreRating: o.scoreRating as AuditScoreRating,
    changesHistory: o.changesHistory as ChangeDetail[],
  };
};

export interface AssessmentUpdateFollowUpStatusRequest {
  status: FollowUpStatus;
  oldStatus: FollowUpStatus;
}

export const getNextFollowUpStatus = (
  status: FollowUpStatus,
  isAuditorOwner: boolean,
  isBrandCoordinator: boolean,
  isAuditAdmin: boolean
): FollowUpStatus | null => {
  switch (status) {
    case FollowUpStatus.UNDER_FOLLOWUP:
      return isBrandCoordinator || isAuditAdmin ? FollowUpStatus.DONE : null;
    case FollowUpStatus.DONE:
      return isAuditorOwner || isAuditAdmin ? FollowUpStatus.COMPLETED : null;
    case FollowUpStatus.EMPTY:
    case FollowUpStatus.PENDING:
    case FollowUpStatus.COMPLETED:
    default:
      return null;
  }
};

export const getPreviousFollowUpStatus = (
  status: FollowUpStatus,
  isAuditorOwner: boolean,
  isAuditAdmin: boolean
): FollowUpStatus | null => {
  switch (status) {
    case FollowUpStatus.DONE:
      return isAuditorOwner || isAuditAdmin ? FollowUpStatus.UNDER_FOLLOWUP : null;
    case FollowUpStatus.UNDER_FOLLOWUP:
    case FollowUpStatus.EMPTY:
    case FollowUpStatus.PENDING:
    case FollowUpStatus.COMPLETED:
    default:
      return null;
  }
};

export const isValidFollowUpStatus = (followUpStatus: FollowUpStatus) =>
  [FollowUpStatus.UNDER_FOLLOWUP, FollowUpStatus.PENDING, FollowUpStatus.COMPLETED, FollowUpStatus.DONE].includes(
    followUpStatus
  );
