import axios, { AxiosError, AxiosResponse, AxiosInterceptorOptions } from 'axios';
import { toastService } from '../../../core/services/toastService';
import {
  AssessmentDetail,
  AuditingResult,
  ResultUpdateRequest,
  toAssessmentDetail,
  toAuditingResult,
} from '../model/assessmentDetail.model';
import paths from './paths';
import { deleteJwtToken, getJwtToken, setRedirectPagePath } from '../../../core/services/webStorageService';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (config: AxiosInterceptorOptions) => {
    const token = getJwtToken();
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  // eslint-disable-next-line consistent-return
  (error: AxiosError) => {
    const { status } = error.response != null ? error.response : { status: '' };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMsg = error.response?.data?.message || error.response?.data?.error || 'Sorry, something went wrong!';
    if (status === 401) {
      deleteJwtToken();
      setRedirectPagePath(window.location.pathname);
      window.location.assign('/login');
      // todo check error
    } else if (!isNetworkError(error)) {
      toastService.error(errorMsg);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const networkErrors = ['ECONNABORTED', 'ERR_NETWORK', 'ETIMEDOUT'];
export const isNetworkError = (err: AxiosError): boolean => {
  return (err.code != null && networkErrors.includes(err.code)) || (err.isAxiosError && !err.response);
};

const offlineApi = {
  health: async (): Promise<AxiosResponse> => {
    return axiosInstance.get(paths.health());
  },
  getResults: async (assessmentId: string): Promise<AuditingResult[]> => {
    const response = await axiosInstance.get(paths.getAuditorResults(assessmentId));
    return response.data.map(toAuditingResult);
  },
  saveAuditingResult: async (id: string, request: ResultUpdateRequest, timeout = 4000): Promise<AxiosResponse> => {
    const r = {
      ...request,
      pictureIds: request.pictures.map(p => p.id),
      attachmentIds: request.attachments.map(p => p.id),
      pictures: [],
      attachments: [],
    };
    return axiosInstance.put(paths.saveAuditingResult(id), r, { timeout });
  },
  getAssessmentDetail: async (id: string): Promise<AssessmentDetail> => {
    const response = await axiosInstance.get(paths.getAssessment(id));
    return toAssessmentDetail(response.data);
  },
};

export const isOnline = async (): Promise<boolean> => {
  try {
    const resp = await offlineApi.health();
    return resp != null && resp.status != null && resp.status === 200;
  } catch (e) {
    return false;
  }
};

export default offlineApi;
