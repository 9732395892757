/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './auditorSelection.style';
import { useAppDispatch } from '../../../../core/store';
import { selectIsFetching } from '../../../assessments/store/assessmentsArchiveSlice';
import { AssessmentBulkUpdateWizardSelectedValue } from '../../model/assessmentBulkUpdate';
import { Auditor } from '../../../assessments/model/assessment';
import { fetchAllAuditors, selectAllAuditors } from '../../store/assessmentBulkUpdateSlice';

interface AuditorSelectionProps {
  defaultValue: AssessmentBulkUpdateWizardSelectedValue[] | null;
  onEdited: (value: AssessmentBulkUpdateWizardSelectedValue[] | null) => void;
}

export const AuditorSelection = ({ defaultValue, onEdited }: AuditorSelectionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auditors = useSelector(selectAllAuditors);
  const isFetching = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(fetchAllAuditors());
  }, [dispatch]);

  const isChecked = useCallback(
    (auditor: Auditor) => defaultValue?.some(value => value.email === auditor.email),
    [defaultValue]
  );

  const onItemSelection = useCallback(
    (auditor: Auditor) => {
      if (isChecked(auditor)) {
        onEdited(defaultValue?.filter(value => value.email !== auditor.email) || null);
      } else {
        onEdited([...(defaultValue || []), { name: auditor.name, email: auditor.email }]);
      }
    },
    [isChecked, defaultValue, onEdited]
  );

  return (
    <div>
      {isFetching ? (
        <Dimmer active inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      ) : (
        <div css={style.container}>
          {auditors.map(auditor => (
            <div css={style.checkBoxContainer}>
              <Checkbox
                css={style.checkBox}
                checked={isChecked(auditor)}
                label={auditor.name}
                onChange={() => onItemSelection(auditor)}
              />
            </div>
          ))}
          {auditors.length === 0 && <div css={style.noItems}>{t('noItems')}</div>}
        </div>
      )}
    </div>
  );
};
