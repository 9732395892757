/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clearTimeoutHandler,
  createComment,
  fetchAssessmentDetailLoop,
  selectAssessment,
  selectAssessmentTabs,
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  selectIsEditStoreUser,
  updateAuditors,
  updateBrandCoordinatorList,
  updateComment,
  updateDistributionList,
  updateEntityAudit,
  updateOverview,
  updateReadOnlyDistributionList,
  updateRegionalManager,
  updateRetailAreaManagerEmail,
  updateRetailProcedure,
  updateStoreManager,
  updateStoreManagerAssistant,
  updateStoreManagerSince,
  updateYearAndMonth,
} from '../store/assessmentDetailSlice';
import { Comment, FollowUpStatus } from '../model/assessmentDetail.model';
import style from './assessmentDetail.style';
import { Auditor, BrandCoordinator } from '../model/assessment';
import { AuditorsAssessmentField } from '../components/AuditorsAssessmentField';
import { DistributionListAssessmentField } from '../components/DistributionListAssessmentField';
import { AssessmentFooter } from '../components/AssessmentFooter';
import { SimpleField } from '../../../shared/SimpleField';
import { Store } from '../../../shared/model/store.model';
import { CommentsAssessmentField } from '../components/CommentsAssessmentField';
import { ToggleField } from '../components/ToggleField';
import { OverviewAssessmentField } from '../components/OverviewAssessmentField';
import { CalendarField } from '../components/CalendarField';
import { AuditDetail } from '../components/AuditDetail';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { AUDITOR_STAFF_ROLES, checkRole, checkRoles, ROLES } from '../../auth/model/principal.model';
import { Status, STATUS } from '../model/assessmentStatus';
import { toastService } from '../../../core/services/toastService';
import { Tabs } from '../../../shared/Tabs';
import { AuditSummary } from '../components/AuditSummary';
import { BrandCoordinatorField } from '../components/BrandCoordinatorField';
import { AssessmentDetailHeader } from '../components/AssessmentDetailHeader';
import { TextInputField } from '../components/TextInputField';
import { useAppDispatch } from '../../../core/store';
import { YearMonthField } from '../../../shared/YearMonthField';
import { BrandCode } from '../../../shared/model/brand.model';
import { AssessmentChangesHistory } from '../components/AssessmentChangesHistory';
import { TextInputFieldWithToggle } from '../components/TextInputFieldWithToggle';
import { Contact, emptyContact } from '../../users/model/user.model';
import { RegionalManagerAssessmentField } from '../components/RegionalManagerAssessmentField';

export const AssessmentDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const redirectPath = new URLSearchParams(useLocation().search).get('redirectPath') || undefined;
  const dispatch = useAppDispatch();
  const assessment = useSelector(selectAssessment);
  const [selectedTab, setSelectedTab] = useState<string>(t('assessment.tab.assessment') || '');
  const tabs = useSelector(selectAssessmentTabs).map(tabKey => t(tabKey));
  const user = useSelector(selectPrincipal);
  const isBrandCoordinator = useSelector(selectIsBrandCoordinator);
  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const isAuditAdmin = useMemo(() => checkRole(user, ROLES.AUDIT_ADMIN), [user]);
  const isAuditor = useCallback(() => checkRoles(user, AUDITOR_STAFF_ROLES), [user]);
  const isAuditorManager = useCallback(() => checkRoles(user, [ROLES.AUDITOR_MANAGER]), [user]);
  const isEditorStoreUser = useSelector(selectIsEditStoreUser);

  const canEditAsAuditAdmin = useMemo(() => {
    return assessment != null && isAuditAdmin && STATUS.CLOSED.code !== assessment.status.code;
  }, [assessment, isAuditAdmin]);

  const canEditAuditors = useCallback(() => {
    return (
      (checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]) &&
        assessment != null &&
        [
          STATUS.IN_PROGRESS.code,
          STATUS.MERGING.code,
          STATUS.APPROVED_TO_BE_SENT.code,
          STATUS.SENT_TO_THE_STORES.code,
          STATUS.ACTIONS_PLAN_UNDER_REVIEW.code,
        ].includes(assessment.status.code)) ||
      canEditAsAuditAdmin
    );
  }, [user, assessment, canEditAsAuditAdmin]);

  const isRegionalManagersAssessmentFieldVisible = useMemo(() => {
  return (assessment != null && 
      ((assessment.status.code === STATUS.MERGING.code && assessment?.regionalManager != null) || 
      assessment.status.code === STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER.code || 
      assessment.status.code === STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code
      )
  )
  }, [assessment])

  const canEditRegionalManager = useCallback(() => {
    return (
      (checkRoles(user, [ROLES.AUDIT_ADMIN]) &&
        assessment != null &&
        [
          STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER.code,
          STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code,
        ].includes(assessment.status.code)) 
    );
  }, [user, assessment]);

  useEffect(() => {
    dispatch(fetchAssessmentDetailLoop(id));
    return () => {
      dispatch(clearTimeoutHandler());
    };
  }, [dispatch, id]);

  const canEditDistributionList = useCallback(() => {
    return (
      (isAuditorOwner &&
        assessment != null &&
        [
          STATUS.IN_PROGRESS.code,
          STATUS.MERGING.code,
          STATUS.APPROVED_TO_BE_SENT.code,
          STATUS.SENT_TO_THE_STORES.code,
          STATUS.ACTIONS_PLAN_UNDER_REVIEW.code,
        ].includes(assessment.status.code)) ||
      (assessment != null &&
        checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]) &&
        STATUS.CLOSED.code === assessment.status.code) ||
      canEditAsAuditAdmin
    );
  }, [assessment, canEditAsAuditAdmin, isAuditorOwner, user]);

  const canEditCoordinatorList = useCallback(() => {
    return (
      (isAuditorOwner &&
        assessment != null &&
        [
          STATUS.IN_PROGRESS.code,
          STATUS.MERGING.code,
          STATUS.APPROVED_TO_BE_SENT.code,
          STATUS.SENT_TO_THE_STORES.code,
          STATUS.ACTIONS_PLAN_UNDER_REVIEW.code,
        ].includes(assessment.status.code)) ||
      (assessment != null &&
        checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]) &&
        STATUS.CLOSED.code === assessment.status.code) ||
      canEditAsAuditAdmin ||
      isBrandCoordinator
    );
  }, [assessment, canEditAsAuditAdmin, isAuditorOwner, user, isBrandCoordinator]);

  const setAuditorsState = useCallback(
    async (auditors: Auditor[]) => {
      const response = await dispatch(updateAuditors(id, auditors));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setRegionalManagerState = useCallback(
    async (regionalManager: Contact) => {
      if(assessment?.regionalManager != null) {
        const response = await dispatch(updateRegionalManager(id, assessment.regionalManager, regionalManager));
        if (response != null) toastService.success();
  
      }
    },
    [dispatch, id, assessment?.regionalManager]
  );

  const setYearAndMonth = useCallback(
    async (year: number, month: number) => {
      const response = await dispatch(updateYearAndMonth(id, year, month));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setDistributionListState = useCallback(
    async (distributionList: string[]) => {
      const response = await dispatch(updateDistributionList(id, distributionList));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setReadOnlyDistributionListState = useCallback(
    async (readOnlyDistributionList: string[]) => {
      const response = await dispatch(updateReadOnlyDistributionList(id, readOnlyDistributionList));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setOverviewState = useCallback(
    async (value: string) => {
      const response = await dispatch(updateOverview(id, value));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setCommentState = useCallback(
    async (comment: Comment) => {
      const response = await dispatch(updateComment(id, comment));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setNewCommentState = useCallback(
    async (content: string) => {
      const response = await dispatch(createComment(id, content));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setRetailProcedure = useCallback(
    async (value: boolean) => {
      const response = await dispatch(updateRetailProcedure(id, value));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setEntityAudit = useCallback(
    async (value: boolean) => {
      const response = await dispatch(updateEntityAudit(id, value));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setRetailAreaManager = useCallback(
    async (value: { name: string; email: string }) => {
      const response = await dispatch(updateRetailAreaManagerEmail(id, value));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  const setBrandCoordinatorList = useCallback(
    async (brandCoordinators: BrandCoordinator[]) => {
      const response = await dispatch(updateBrandCoordinatorList(id, brandCoordinators));
      if (response != null) toastService.success();
    },
    [dispatch, id]
  );

  return assessment != null && id === assessment.id ? (
    <div css={style.content}>
      <AssessmentDetailHeader assessmentDetail={assessment} redirectPath={redirectPath} />
      <Tabs onChange={setSelectedTab} tabs={tabs} defaultTab={selectedTab} />
      {selectedTab === t('assessment.tab.assessment') ? (
        <div css={style.assessment}>
          <div>
            <AssessmentStore
              assessmentId={assessment.id}
              store={assessment.store}
              storeManagerSince={assessment.storeManagerSince}
              storeManagerNotAvailable={assessment.storeManagerNotAvailable}
              assessmentStatus={assessment.status}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <YearMonthField
              defaultYear={assessment.year}
              defaultMonth={assessment.month}
              title={t('assessment.year-month')}
              editable={canEditAsAuditAdmin}
              onEdited={(year, month) => setYearAndMonth(year || 0, month || 0)}
            />
            <div css={style.rowMultipleField}>
              <SimpleField
                iconClassName='exchange'
                value={t(`assessment.status.${assessment.status.code}`) || ''}
                title={t('assessment.detail.assessmentStatus')}
              />
              {assessment.followUpStatus != null && assessment.followUpStatus !== FollowUpStatus.EMPTY && (
                <SimpleField
                  iconClassName='clipboard check'
                  value={t(`assessment.followUpStatus.${assessment.followUpStatus}`) || ''}
                  title={t('assessment.detail.followUpStatus')}
                />
              )}
            </div>
            <div css={style.rowMultipleField}>
              <SimpleField
                iconClassName='fas fa-hourglass-end'
                value={assessment.lastAudit.period}
                title={t('assessment.lastAssessmentDate')}
              />
              {isAuditor() && (
                <SimpleField
                  iconClassName='fas fa-star'
                  value={assessment.lastAudit.rating}
                  title={t('assessment.lastAssessmentRating')}
                />
              )}
            </div>
            {isRegionalManagersAssessmentFieldVisible && (
              <div css={style.rowMultipleField}>
                <RegionalManagerAssessmentField
                  defaultValue={assessment?.regionalManager ?? emptyContact()}
                  editable={canEditRegionalManager()}
                  onEdited={regionalManager => {
                    setRegionalManagerState(regionalManager);
                  }}
                  assessmentKeringRegion={assessment.store.keringRegion}
                />
              </div>
            )}
            <div css={style.rowMultipleField}>
              <AuditorsAssessmentField
                defaultValue={assessment.auditors}
                editable={canEditAuditors()}
                onEdited={auditors => {
                  setAuditorsState(auditors);
                }}
                title={t('assessment.auditors') || ''}
                assessmentBrand={assessment.store.brand.code as BrandCode}
              />
            </div>
            <BrandCoordinatorField
              className='fas fa-user-tie'
              defaultValue={assessment.brandCoordinatorList}
              title={t('assessment.brandCoordinators')}
              editable={canEditCoordinatorList()}
              onEdited={v => setBrandCoordinatorList(v)}
            />
            <DistributionListAssessmentField
              defaultValue={assessment.distributionList}
              editable={canEditDistributionList()}
              onEdited={values => {
                setDistributionListState(values.map(c => c.email));
              }}
              title={t('assessment.distributionList') || ''}
            />
            <DistributionListAssessmentField
              defaultValue={assessment.readOnlyDistributionList}
              title={t('assessment.readOnlyDistributionList') || ''}
              onEdited={values => {
                setReadOnlyDistributionListState(values.map(c => c.email));
              }}
              editable={canEditDistributionList()}
            />
            {isAuditor() && (
              <OverviewAssessmentField
                defaultValue={assessment.overview}
                editable={
                  (isAuditorOwner && [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessment.status.code)) ||
                  canEditAsAuditAdmin
                }
                onEdited={values => {
                  setOverviewState(values);
                }}
                title={t('assessment.overview') || ''}
              />
            )}
            {isAuditor() && (
              <CommentsAssessmentField
                defaultValue={assessment.notes}
                editable={isAuditorOwner || isAuditorManager() || isEditorStoreUser}
                onEdited={value => {
                  setCommentState(value);
                }}
                onNew={value => {
                  setNewCommentState(value);
                }}
                title={t('assessment.internalComments') || ''}
              />
            )}
            <TextInputField
              className='fas fa-user-tie'
              defaultValue={assessment.retailAreaManager != null ? assessment.retailAreaManager.name : ''}
              title={t('assessment.retailAreaManager')}
              editable={
                (isAuditorOwner && [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessment.status.code)) ||
                canEditAsAuditAdmin
              }
              placeholder='Name'
              label={{ icon: 'user' }}
              onEdited={v => setRetailAreaManager({ email: '', name: v })}
            />
            {isAuditor() && (
              <ToggleField
                icon='shopping bag'
                defaultValue={assessment.retailProcedure}
                title={t('assessment.retailProcedure') || ''}
                label={t('assessment.retailProcedure') || ''}
                onChange={value => setRetailProcedure(value)}
                editable={
                  (isAuditorOwner && [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessment.status.code)) ||
                  canEditAsAuditAdmin
                }
              />
            )}
            {isAuditor() && (
              <ToggleField
                icon='user secret'
                defaultValue={assessment.entityAudit}
                title={t('assessment.entityAudit') || ''}
                label={t('assessment.entityAudit') || ''}
                onChange={value => setEntityAudit(value)}
                editable={
                  (isAuditorOwner && [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessment.status.code)) ||
                  canEditAsAuditAdmin
                }
              />
            )}
          </div>
        </div>
      ) : selectedTab === t('assessment.tab.audit') ? (
        <AuditDetail
          assessmentStatus={assessment.status}
          audit={assessment.audit}
          followUpStatus={assessment.followUpStatus}
        />
      ) : selectedTab === t('assessment.tab.history') ? (
        <AssessmentChangesHistory changesHistory={assessment.changesHistory} />
      ) : (
        <AuditSummary auditScore={assessment.auditScore} />
      )}
      <AssessmentFooter assessment={assessment} />
    </div>
  ) : (
    <></>
  );
};

interface AssessmentStoreProps {
  store: Store;
  storeManagerSince: string;
  storeManagerNotAvailable: boolean;
  assessmentId: string;
  assessmentStatus: Status;
}

export const AssessmentStore = ({
  store,
  storeManagerSince,
  storeManagerNotAvailable,
  assessmentId,
  assessmentStatus,
}: AssessmentStoreProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const user = useSelector(selectPrincipal);
  const isAuditAdmin = useMemo(() => checkRole(user, ROLES.AUDIT_ADMIN), [user]);

  const canEditField = useCallback(() => {
    return (
      (isAuditorOwner &&
        [STATUS.IN_PROGRESS.code, STATUS.MERGING.code, STATUS.APPROVED_TO_BE_SENT.code].includes(
          assessmentStatus.code
        )) ||
      (isAuditAdmin && STATUS.CLOSED.code !== assessmentStatus.code)
    );
  }, [assessmentStatus.code, isAuditAdmin, isAuditorOwner]);

  const setStoreManagerSince = useCallback(
    async (value: string) => {
      const response = await dispatch(updateStoreManagerSince(assessmentId, value));
      if (response != null) toastService.success();
    },
    [dispatch, assessmentId]
  );

  const setStoreManager = useCallback(
    async (value: { name: string; email: string; storeManagerNotAvailable: boolean }) => {
      const response = await dispatch(updateStoreManager(assessmentId, value));
      if (response != null) toastService.success();
    },
    [dispatch, assessmentId]
  );

  const setStoreManagerAssistant = useCallback(
    async (value: { name: string; email: string }) => {
      const response = await dispatch(updateStoreManagerAssistant(assessmentId, value));
      if (response != null) toastService.success();
    },
    [dispatch, assessmentId]
  );

  return (
    <div>
      <SimpleField
        icon='copyright'
        value={`${store.brand.description} (${store.keringRegion})`}
        title={t('store.brand')}
      />
      <SimpleField icon='map marker' value={store.fullAddress} title={t('store.fullAddress')} />
      <div css={style.rowMultipleField}>
        <SimpleField icon='code' value={store.jdaCode} title={t('store.jdaCode')} />
        <SimpleField icon='code' value={store.hfmCode} title={t('store.hfmCode')} />
      </div>
      <div css={style.rowMultipleField}>
        <TextInputFieldWithToggle
          className='fas fa-user-tie'
          defaultValue={store.storeManager?.name || ''}
          defaultChecked={storeManagerNotAvailable}
          title={t('store.storeManager')}
          editable={canEditField()}
          placeholder='Name'
          label={{ icon: 'user' }}
          onEdited={(name, toggleChecked) =>
            setStoreManager({ email: '', name, storeManagerNotAvailable: toggleChecked })
          }
          toggleLabel={t('store.storeManagerNotAvailable') || ''}
        />
        <CalendarField
          defaultValue={storeManagerSince}
          editable={canEditField() && !storeManagerNotAvailable && store.storeManager?.name?.trim() !== ''}
          onChange={v => setStoreManagerSince(v)}
          title={t('assessment.storeManagerSince')}
        />
      </div>
      <TextInputField
        className='fas fa-user-tie'
        defaultValue={store.assistantManager ? store.assistantManager.name : ''}
        title={t('store.assistantManager')}
        editable={canEditField()}
        placeholder='Name'
        label={{ icon: 'user' }}
        onEdited={v => setStoreManagerAssistant({ email: '', name: v })}
      />
      <SimpleField
        iconClassName='fas fa-ruler'
        value={store.sellingSquareFeet ? `${store.sellingSquareFeet.toString()} sqft` : ''}
        title={t('store.sellingSquareFeet')}
      />
    </div>
  );
};
