import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ClassNames, Global } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './theme/colors.css';
import 'semantic-ui-css/semantic.min.css';
import './theme/override.css';
import './theme/font.css';
import 'react-calendar/dist/Calendar.css';
import { setErrorInterceptor, setJwtInterceptor } from './core/interceptors';
import store from './core/store';
import style from './shared/global.style';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { SyncAdapter } from './core/components/SyncAdapter';

setJwtInterceptor();
setErrorInterceptor();
const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ClassNames>
            {({ css, cx }) => (
              <ToastContainer
                className={css`.Toastify__toast-container--top-right&{@media (min-width: 480px) {max-width: 300px}; top: 0; right: 0; padding-right: 0; }`}
                toastClassName={css`&&{margin-left: auto; border-radius: 15px 0 0 15px; margin-top: 5px; @media (max-width: 480px) {width: 60%}; 
                                .Toastify__toast--warning&{background-color:  var(--saa-warning)} 
                                .Toastify__toast--success&{background:  var(--saa-success-dark)} 
                                .Toastify__toast--error&{background-color:  var(--saa-error)} 
                                .Toastify__toast--info&{background-color:  var(--saa-info)}`}
                position='top-right'
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
                style={{ textAlign: 'center' }}
              />
            )}
          </ClassNames>
          <Global styles={style.scrollbar} />
          <App />
          <SyncAdapter />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
