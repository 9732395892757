/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import style from '../footerButtons/detailFooter.style';
import { useAppDispatch } from '../../../../../core/store';
import { selectPrincipal } from '../../../../auth/store/principalSlice';
import {
  BrandAuditProposal,
  BrandProposalChangeStatusRequest,
  canChangeBrandProposalStatus,
  isConfiguredBrandScheduler,
} from '../../../model/brandAuditProposal';
import { brandProposalChangeStatus, selectBrandAuditProposal } from '../../../store/brandAuditProposalDetailSlice';
import { ApproveButton } from '../footerButtons/ApproveButton';
import { RejectButton } from '../footerButtons/RejectButton';
import { AssessmentCreationFromProposalRequest } from '../../../../assessments/model/assessment';
import { CreateAssessmentButton } from '../footerButtons/CreateAssessmentButton';
import { BRAND_AUDIT_PROPOSAL_STATUS, ProposedPeriod } from '../../../model/genericAuditProposal';
import { ReproposeBrandProposalButton } from '../footerButtons/ReproposeBrandProposalButton';
import { AcceptButton } from '../footerButtons/AcceptButton';
import { ReproposeBrandDatesButton } from '../footerButtons/ReproposeBrandDatesButton';
import { checkRole, ROLES } from '../../../../auth/model/principal.model';
import { RescheduleButton } from '../footerButtons/RescheduleButton';

export function BrandAuditProposalDetailFooter(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const brandAuditProposal = useSelector(selectBrandAuditProposal) as BrandAuditProposal;
  const principal = useSelector(selectPrincipal);

  const { proposedDateFrom, proposedDateTo } = brandAuditProposal;
  const areDatesInRange = useMemo((): boolean => {
    const rangeList = brandAuditProposal.proposedPeriods.map(range => {
      return {
        dateFrom: new Date(new Date(range.dateFrom).setHours(0)).getTime(),
        dateTo: new Date(new Date(range.dateTo).setHours(0)).getTime(),
      };
    });
    const dateTimeFrom = new Date(new Date(proposedDateFrom).setHours(0)).getTime();
    const dateTimeTo = new Date(new Date(proposedDateTo).setHours(0)).getTime();

    let result = false;
    rangeList.forEach(range => {
      if (
        dateTimeFrom >= range.dateFrom &&
        dateTimeFrom <= range.dateTo &&
        dateTimeTo >= dateTimeFrom &&
        dateTimeTo <= range.dateTo
      ) {
        result = true;
      }
    });

    return result;
  }, [brandAuditProposal.proposedPeriods, proposedDateFrom, proposedDateTo]);
  const isEmptyAuditDates = useMemo(
    () => proposedDateFrom === '' || proposedDateTo === '',
    [proposedDateFrom, proposedDateTo]
  );

  const handleChangeStatus = useCallback(
    (changeStatusRequest: BrandProposalChangeStatusRequest, onSuccess?: (proposal: BrandAuditProposal) => void) => {
      dispatch(brandProposalChangeStatus(brandAuditProposal.id, changeStatusRequest)).then(updatedProposal => {
        if (updatedProposal == null) return;
        if (onSuccess) {
          onSuccess(updatedProposal);
        }
      });
    },
    [brandAuditProposal.id, dispatch]
  );

  const onRepropose = useCallback(
    (note: string) => {
      const request: BrandProposalChangeStatusRequest = {
        status: BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER,
        note,
      };
      handleChangeStatus(request);
    },
    [handleChangeStatus]
  );

  const isAuditScheduler = useMemo(() => checkRole(principal, ROLES.AUDIT_SCHEDULER), [principal]);
  const isConfiguredBScheduler = useMemo(
    () => isConfiguredBrandScheduler(principal, brandAuditProposal),
    [brandAuditProposal, principal]
  );

  const onCreateAssessment = useCallback(
    (assessmentCreationRequest: AssessmentCreationFromProposalRequest) => {
      const request = { status: BRAND_AUDIT_PROPOSAL_STATUS.CLOSED, assessment: assessmentCreationRequest };
      const onSuccess = (updatedProposal: BrandAuditProposal) => {
        if (updatedProposal.assessment) {
          navigate(`/assessments/${updatedProposal.assessment?.id}`);
        }
      };
      handleChangeStatus(request, onSuccess);
    },
    [handleChangeStatus, navigate]
  );
  const onBrandRescheduleConfirm = useCallback(
    (proposedPeriods: ProposedPeriod[], note: string, isRepropose: boolean) => {
      handleChangeStatus({ status: BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED, note, proposedPeriods });
    },
    [handleChangeStatus]
  );

  const onReject = useCallback(
    (note: string) => {
      const request: BrandProposalChangeStatusRequest = {
        status: BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
        note,
      };
      handleChangeStatus(request);
    },
    [handleChangeStatus]
  );

  if (canChangeBrandProposalStatus(principal, brandAuditProposal)) {
    switch (brandAuditProposal.status) {
      case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED: {
        const approveRequest: BrandProposalChangeStatusRequest = { status: BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED };

        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              <ReproposeBrandProposalButton
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.reproposeButton')}
                onConfirm={onRepropose}
                showErrorAlert={areDatesInRange || isEmptyAuditDates}
                errorAlertMessage={t('brandAuditProposals.proposalDetail.footer.equalDatesError') || ''}
              />
            </div>
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.sendForApproval')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }

      case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED: {
        const approveRequest: BrandProposalChangeStatusRequest = { status: BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED };
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              <RejectButton
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.rejectButton')}
                onConfirm={onReject}
              />
            </div>
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.approveButton')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }
      case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER: {
        const acceptAuditDateRequest: BrandProposalChangeStatusRequest = {
          dateFrom: brandAuditProposal.proposedDateFrom,
          dateTo: brandAuditProposal.proposedDateTo,
          status: BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED,
        };
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              <ReproposeBrandDatesButton
                auditDateFrom={brandAuditProposal.proposedDateFrom}
                auditDateTo={brandAuditProposal.proposedDateTo}
                onConfirm={(dateFrom: string, dateTo: string) =>
                  handleChangeStatus({
                    dateFrom,
                    dateTo,
                    status: BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED,
                  } as BrandProposalChangeStatusRequest)
                }
              />
            </div>
            <div css={style.footerContainerGroup}>
              <AcceptButton
                auditDateFrom={brandAuditProposal.proposedDateFrom}
                auditDateTo={brandAuditProposal.proposedDateTo}
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.acceptButton')}
                onConfirm={() => handleChangeStatus(acceptAuditDateRequest)}
              />
            </div>
          </div>
        );
      }
      case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER: {
        const approveRequest: BrandProposalChangeStatusRequest = { status: BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED };
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup} />
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('brandAuditProposals.proposalDetail.footer.sendForApproval')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }

      case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED: {
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              {isAuditScheduler && (
                <ReproposeBrandProposalButton
                  buttonLabel={t('brandAuditProposals.proposalDetail.footer.rescheduleButton')}
                  onConfirm={onRepropose}
                />
              )}

              {isConfiguredBScheduler && (
                <RescheduleButton
                  buttonLabel={t('brandAuditProposals.proposalDetail.footer.rescheduleButton')}
                  onAuditConfirm={() => {
                    return undefined;
                  }}
                  onBrandConfirm={onBrandRescheduleConfirm}
                  confirmHeader={t('brandAuditProposals.proposalDetail.footer.rescheduleButton') || ''}
                  canReject={false}
                  defaultDateFrom={brandAuditProposal.proposedDateFrom}
                  defaultDateTo={brandAuditProposal.proposedDateTo}
                  isBrandContent
                />
              )}
            </div>
            <div css={style.footerContainerGroup}>
              {isAuditScheduler && (
                <CreateAssessmentButton
                  proposal={brandAuditProposal}
                  buttonLabel={t('brandAuditProposals.proposalDetail.footer.createAssessmentButton')}
                  onCreate={onCreateAssessment}
                />
              )}
            </div>
          </div>
        );
      }

      case BRAND_AUDIT_PROPOSAL_STATUS.CLOSED:
      case BRAND_AUDIT_PROPOSAL_STATUS.DELETED:
      default:
        return <></>;
    }
  }
  return <></>;
}
