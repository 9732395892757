import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';
import { StatusCode } from '../model/assessmentStatus';

const card = (status: StatusCode): SerializedStyles => {
  return css`
    &&&&&& {
      text-decoration: none;
      color: var(--saa-primary-color);
      width: 330px;
      height: 180px;
      border-radius: 0;
      flex-shrink: 0;
      margin: 3px 17px 0 0;
      @media (min-width: 1200px) {
        margin-top: 10px;
      }
      border-left: solid 5px ${style.statusColor(status)};

      :hover {
        && {
          box-shadow: 0 1px 3px 0 ${style.statusColor(status)}, 0 0 0 1px ${style.statusColor(status)};
        }
      }
    }
  `;
};

const body = css`
  &&& {
    min-height: 110px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const header = css`
  &&& {
    display: flex;
    padding: 0;
    width: 100%;
  }
`;

const headerContent = css`
  flex: 1;
  width: 0;
`;

const field = css`
  ${style.bodySmall};
  display: flex;
  flex: 1;
  min-width: 30px;
`;

const fieldValue = css`
  padding-left: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const iconContainer = css`
  &&& {
    width: 20px;
  }
`;

const bodyRow = css`
  display: flex;
  padding: 10px 0;
  overflow: hidden;
`;

const ribbon = css`
  &&& {
    padding-right: 0;
    padding-left: 0;
    padding-top: 1px;
    padding-bottom: 2px;
    width: 40px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ribbonContainer = css`
  margin-right: 14px;
  margin-top: -3px;
`;

const ribbonContent = css`
  position: absolute;
`;

const ribbonIcon = css`
  &&&& {
    color: rgba(0, 0, 0, 0.18);
    font-size: 22px;
    background-color: transparent;
    margin: 0;
  }
`;

export default {
  header,
  headerContent,
  card,
  field,
  body,
  fieldValue,
  iconContainer,
  bodyRow,
  ribbon,
  ribbonContainer,
  ribbonContent,
  ribbonIcon,
};
