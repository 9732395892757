/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';
import style from './assessmentsBulkUpdateWizard.style';
import { AssessmentBulkUpdate, AssessmentBulkUpdateWizardStep } from '../../model/assessmentBulkUpdate';

interface ConfirmUpdateStepProps {
  onClose: () => void;
  onConfirm: () => void;
  setValidUpdates: (updates: any[] | null) => void;
  setNotValidUpdates: (updates: any[] | null) => void;
  setStep: (step: AssessmentBulkUpdateWizardStep) => void;
  validAssessments: AssessmentBulkUpdate[];
  notValidAssessments: AssessmentBulkUpdate[];
}

export const ConfirmUpdateStep = ({
  onClose,
  setStep,
  validAssessments,
  notValidAssessments,
  onConfirm,
  setValidUpdates,
  setNotValidUpdates,
}: ConfirmUpdateStepProps): JSX.Element => {
  const { t } = useTranslation();

  const onClickPrev = () => {
    setValidUpdates(null);
    setNotValidUpdates(null);
    setStep(AssessmentBulkUpdateWizardStep.SELECT_NEW_VALUE);
  };

  return (
    <>
      <Modal.Header>
        <div css={style.header}>
          <div>{t('assessment.bulkUpdate.wizard.steps.CONFIRM_UPDATE')}</div>
          <div css={style.headerIcon} onClick={onClose}>
            <Icon className='fas fa-close' />
          </div>
        </div>
      </Modal.Header>

      <Modal.Content css={style.modalContent}>
        <div>
          <div css={style.updateConfirmListTitle}>{t('assessment.bulkUpdate.wizard.notValidAssessments')}</div>
          {notValidAssessments.map(assessment => (
            <li
              css={
                style.updateConfirmListElement
              }>{`${assessment.locationName} ${assessment.jdaCode}-${assessment.year}-${assessment.month}`}</li>
          ))}
          <div css={style.updateConfirmListTitle}>{t('assessment.bulkUpdate.wizard.validAssessments')}</div>
          {validAssessments.map(assessment => (
            <li
              css={
                style.updateConfirmListElement
              }>{`${assessment.locationName} ${assessment.jdaCode}-${assessment.year}-${assessment.month}`}</li>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button css={style.button} onClick={onClickPrev}>
          {t('assessment.bulkUpdate.wizard.buttons.prev')}
        </Button>
        <Button css={style.button} onClick={onConfirm}>
          {t('assessment.bulkUpdate.wizard.buttons.confirm')}
        </Button>
      </Modal.Actions>
    </>
  );
};
