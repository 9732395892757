/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Form, Radio, Segment, TextArea } from 'semantic-ui-react';
import { t } from 'i18next';
import { CalendarField } from '../../../../assessments/components/CalendarField';
import style from './rescheduleModalContent.style';
import { getDate } from '../../../../../core/utils';

interface RejectModalContentProps {
  onChange: (dateFrom: string, dateTo: string, note: string, isReschedule: boolean) => void;
  canReject: boolean;
}

export function RescheduleAuditModalContent({ onChange, canReject }: RejectModalContentProps): JSX.Element {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [note, setNote] = useState('');

  const [minDate, setMinDate] = useState(dateFrom !== '' ? getDate(dateFrom) : getDate());
  const [maxDate, setMaxDate] = useState(dateTo !== '' ? getDate(dateTo) : undefined);

  const today = getDate();

  const [isReschedule, setIsReschedule] = useState<boolean>(true);

  useEffect(() => {
    onChange(dateFrom, dateTo, note, isReschedule);
  }, [note, dateTo, dateFrom, onChange, isReschedule]);

  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const onDateUpdate = async (from: string, to: string) => {
    setDateFrom(from);
    setDateTo(to);
    setMinDate(getDate(from));
    setMaxDate(getDate(to));
  };

  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      {canReject && (
        <Form css={style.checkBoxContent}>
          <Form.Field css={style.RadioButtonField}>
            <Radio
              label='Reschedule'
              name='radioGroup'
              checked={isReschedule}
              onChange={() => {
                setIsReschedule(true);
              }}
            />
          </Form.Field>
          <Form.Field css={style.RadioButtonField}>
            <Radio
              label='Reject'
              name='radioGroup'
              checked={!isReschedule}
              onChange={() => {
                setIsReschedule(false);
              }}
            />
          </Form.Field>
        </Form>
      )}
      <Form css={style.fieldContent}>
        <h4>{t('auditCampaigns.proposalDetail.footer.rejectNote')}</h4>
        <TextArea
          placeholder={t('auditCampaigns.proposalDetail.footer.rescheduleNotePlaceHolder')}
          onChange={event => {
            onChangeNote(event);
          }}
        />
      </Form>
      {isReschedule && (
        <Segment css={style.fieldContent}>
          <h4>Audit dates</h4>
          <div css={style.CalendarField}>
            <CalendarField
              defaultValue={dateFrom}
              editable
              onChange={changedDateFrom => onDateUpdate(changedDateFrom, dateTo)}
              title={t('auditCampaigns.proposalDetail.auditDateFrom')}
              minDate={today}
              maxDate={maxDate}
            />

            <CalendarField
              defaultValue={dateTo}
              editable
              onChange={changedDateTo => onDateUpdate(dateFrom, changedDateTo)}
              title={t('auditCampaigns.proposalDetail.auditDateTo')}
              minDate={minDate}
            />
          </div>
        </Segment>
      )}
    </div>
  );
}
