/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { fetchAuditors, selectAuditors, selectIsFetching } from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';
import { Auditor } from '../../assessments/model/assessment';

interface AuditorMultiSelectFieldProps {
  filters: AssessmentBulkUpdateFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (value: string[]) => void;
}

export function AuditorMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AuditorMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchAuditors(filters));
    }
  }, [dispatch, editable, filters]);
  const { t } = useTranslation();

  const auditorList = useSelector(selectAuditors);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Auditor | undefined): SelectItem => {
    if (item == null) {
      return { code: '', description: '' };
    }

    return {
      code: item.email,
      description: item.name,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='users'
      defaultValue={
        defaultValue != null ? defaultValue.map(v => toSelectItem(auditorList.find((a: Auditor) => a.email === v))) : []
      }
      loading={isFetching}
      list={auditorList.map((b: any): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.bulkUpdate.filters.auditors') || ''}
      editable={editable}
      onUpdateValue={(selectedStore: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedStore.map(i => toString(i)));
        }
      }}
    />
  );
}
