/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { fetchBrandCoordinators, selectBrandCoordinators, selectIsFetching } from '../../store/assessmentsArchiveSlice';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { AssessmentArchiveFilters } from '../../model/assessmentArchiveQueryParams';
import { BrandCoordinator } from '../../model/assessment';

interface BrandCoordinatorMultiSelectFieldProps {
  filters: AssessmentArchiveFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brandCoordinator: string[]) => void;
}

export function BrandCoordinatorMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: BrandCoordinatorMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchBrandCoordinators(filters));
    }
  }, [dispatch, editable, filters]);

  const brandCoordinatorList = useSelector(selectBrandCoordinators);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: BrandCoordinator): SelectItem => {
    return {
      code: item.email,
      description: item.name,
    };
  };

  const toBrandCoordinator = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      iconClassName='fas fa-user-tie'
      loading={isFetching}
      defaultValue={
        defaultValue != null
          ? brandCoordinatorList.filter(a => defaultValue.includes(a.email)).map(v => toSelectItem(v))
          : []
      }
      list={brandCoordinatorList.map((b): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.archive.filters.brandCoordinators') || ''}
      editable={editable}
      onUpdateValue={(selectedBrandCoordinator: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrandCoordinator.map(i => toBrandCoordinator(i)));
        }
      }}
    />
  );
}
