/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../core/store';
import { AssessmentDetail, ResultStatus } from '../model/assessmentDetail.model';
import { STATUS, StatusCode } from '../model/assessmentStatus';
import style from './assessmentDetailHeader.style';
import { SyncAuditorRequestsIcon } from './SyncAuditorRequestsIcon';
import { AssessmentHeader } from './AssessmentHeader';
import { FindingsPdfDocument } from './FindingsPdfDocument';
import { checkRoles, ROLES } from '../../auth/model/principal.model';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { updateStatus } from '../store/assessmentDetailSlice';
import { exportFindingsAndTopicsXls, generatePdfDocument } from '../services/exportService';
import { toastService } from '../../../core/services/toastService';

interface DetailHeaderProps {
  assessmentDetail: AssessmentDetail;
  redirectPath?: string;
}

type DetailAction = 'delete' | 'exportExcel' | 'exportPdf';

export const AssessmentDetailHeader = ({ assessmentDetail, redirectPath }: DetailHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    if (redirectPath != null) {
      navigate(redirectPath);
    } else {
      navigate('/assessments');
    }
  }, [redirectPath, navigate]);
  const user = useSelector(selectPrincipal);
  const isAuditorManager = useCallback(() => checkRoles(user, [ROLES.AUDITOR_MANAGER]), [user]);

  const canDeleteAssessment = useCallback(
    () =>
      isAuditorManager() ||
      user.isRegionalManagerOf?.some(region => region.description === assessmentDetail.store.keringRegion),
    [user, assessmentDetail, isAuditorManager]
  );

  const actions: DetailAction[] = useMemo(() => {
    switch (assessmentDetail.status.code) {
      case STATUS.IN_PROGRESS.code:
        return canDeleteAssessment() ? ['delete'] : [];
      case STATUS.MERGING.code:
        return canDeleteAssessment() ? ['exportExcel', 'exportPdf', 'delete'] : ['exportExcel', 'exportPdf'];
      default:
        return ['exportExcel', 'exportPdf'];
    }
  }, [assessmentDetail.status.code, canDeleteAssessment]);

  return (
    <div css={style.header}>
      {assessmentDetail.status.code === STATUS.IN_PROGRESS.code ? (
        <SyncAuditorRequestsIcon goBack={goBack} />
      ) : (
        <Icon css={style.backIcon} name='arrow left' onClick={goBack} />
      )}

      <div css={style.assessmentHeader}>
        <AssessmentHeader assessment={assessmentDetail} />
      </div>

      {actions.length > 0 ? (
        <StatusWithMenu assessmentDetail={assessmentDetail} actions={actions} />
      ) : (
        <StatusDot statusCode={assessmentDetail.status.code} />
      )}
    </div>
  );
};

interface StatusDotProps {
  statusCode: StatusCode;
}

const StatusDot = ({ statusCode }: StatusDotProps) => {
  return (
    <div style={{ display: 'flex', margin: '0 10px 0 0', marginLeft: 'auto' }}>
      <span css={style.dot(statusCode)} />
    </div>
  );
};

interface StatusWithMenuProps {
  assessmentDetail: AssessmentDetail;
  actions: DetailAction[];
}

const StatusWithMenu = ({ assessmentDetail, actions }: StatusWithMenuProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StatusDots onClick={() => setOpen(true)} statusCode={assessmentDetail.status.code} />
      <Modal css={style.modal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Actions</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <MenuSection assessmentDetail={assessmentDetail} actions={actions} onClose={() => setOpen(false)} />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => setOpen(false)}>
            <Icon name='cancel' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface StatusDotsProps {
  onClick: () => void;
  statusCode: StatusCode;
}

const StatusDots = ({ onClick, statusCode }: StatusDotsProps) => (
  <div css={style.smallDotsContainer} onClick={onClick}>
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
  </div>
);

interface MenuSectionProps {
  assessmentDetail: AssessmentDetail;
  actions: DetailAction[];
  onClose: () => void;
}

const MenuSection = ({ assessmentDetail, actions, onClose }: MenuSectionProps) => {
  return (
    <div style={{ color: 'var( --saa-primary-color)' }}>
      {actions.includes('exportExcel') && <MenuExportExcelRow assessmentDetail={assessmentDetail} onClose={onClose} />}
      {actions.includes('exportPdf') && <MenuExportPdfRow assessmentDetail={assessmentDetail} onClose={onClose} />}
      {actions.includes('delete') && <MenuDeleteRow assessmentDetail={assessmentDetail} onClose={onClose} />}
    </div>
  );
};

interface MenuExportExcelRowProps {
  assessmentDetail: AssessmentDetail;
  onClose: () => void;
}

const MenuExportExcelRow = ({ assessmentDetail, onClose }: MenuExportExcelRowProps) => {
  const exportXls = () =>
    exportFindingsAndTopicsXls(
      assessmentDetail.audit.processes
        .flatMap(p => p.subProcesses)
        // siamo dopo il merge, il result è sempre presente
        .filter(sp => sp.result.status === ResultStatus.FAILED),
      assessmentDetail.audit.topicsForDiscussion,
      assessmentDetail.status,
      `${assessmentDetail.store.name}_${assessmentDetail.store.brand.description}`,
      assessmentDetail.store.jdaCode
    );
  return (
    <div
      css={style.menuRowContainer}
      onClick={() => {
        exportXls();
        onClose();
      }}>
      <Icon name='file excel' css={style.fileIcon} />
      <span>Export Excel</span>
    </div>
  );
};

interface MenuDeleteRowProps {
  assessmentDetail: AssessmentDetail;
  onClose: () => void;
}

const MenuDeleteRow = ({ assessmentDetail, onClose }: MenuDeleteRowProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);

  const deleteAssessment = useCallback(async () => {
    const response = await dispatch(updateStatus(assessmentDetail.id, STATUS.DELETED.code));
    if (response != null) {
      navigate('/assessments');
      toastService.success();
    }
  }, [dispatch, assessmentDetail.id, navigate]);
  return (
    <>
      <div css={style.menuRowContainer} onClick={() => setDeleteConfirmIsOpen(true)}>
        <Icon name='trash alternate' css={style.fileIcon} />
        <span>Delete assessment</span>
      </div>
      <Confirm
        css={style.modalConfirm}
        open={deleteConfirmIsOpen}
        onCancel={() => {
          setDeleteConfirmIsOpen(false);
          onClose();
        }}
        content='Are you sure you want to delete the assessment?'
        onConfirm={() => {
          setDeleteConfirmIsOpen(false);
          deleteAssessment();
          onClose();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};

interface MenuExportPdfRowProps {
  assessmentDetail: AssessmentDetail;
  onClose: () => void;
}

const MenuExportPdfRow = ({ assessmentDetail, onClose }: MenuExportPdfRowProps) => {
  const user = useSelector(selectPrincipal);
  return (
    <div
      css={style.menuRowContainer}
      onClick={() => {
        generatePdfDocument(
          assessmentDetail,
          <FindingsPdfDocument assessmentDetail={assessmentDetail} userName={`${user.firstName} ${user.surname}`} />,
          `${assessmentDetail.store.name}_${assessmentDetail.store.brand.description}`
        );
        onClose();
      }}>
      <Icon name='file pdf' css={style.fileIcon} />
      <span>Export PDF</span>
    </div>
  );
};
