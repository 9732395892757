/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Button, Confirm, ModalContentProps, SemanticICONS, SemanticShorthandItem } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import style from './wizardFooter.style';

export interface ButtonConfiguration {
  content: string;
  onClick: () => void;
  icon?: SemanticICONS;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
  confirm?: ModalConfirm;
}

interface ModalConfirm {
  isActive: boolean;
  content: string | Element;
  size: 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen' | undefined;
  header?: string;
  isConfirmButtonDisabled: () => boolean;
}

export function WizardFooter({ buttonsConfiguration }: { buttonsConfiguration: ButtonConfiguration[] }): JSX.Element {
  const navigate = useNavigate();
  const goBack = useCallback(() => (navigate.length > 1 ? navigate(-1) : navigate('/audit-campaigns')), [navigate]);
  const [confirmIsOpen, setconfirmIsOpen] = useState(false);
  const [actualButtonConfirmation, setActualButtonConfirmation] = useState<ButtonConfiguration | undefined>(undefined);

  return (
    <div css={style.footer}>
      {buttonsConfiguration.map((buttonConfiguration: ButtonConfiguration) => {
        return (
          <Button
            key={`button_${buttonConfiguration.content}`}
            css={style.footerButton}
            labelPosition={buttonConfiguration.labelPosition}
            content={buttonConfiguration.content}
            onClick={() => {
              if (buttonConfiguration.confirm != null && buttonConfiguration.confirm.isActive) {
                setActualButtonConfirmation(buttonConfiguration);
                setconfirmIsOpen(true);
              } else {
                buttonConfiguration.onClick();
              }
            }}
            icon={buttonConfiguration.icon}
            disabled={buttonConfiguration.disabled}
          />
        );
      })}
      {actualButtonConfirmation != null && (
        <Confirm
          header={t('auditCampaigns.proposalDetail.footer.confirmationHeader')}
          css={style.modalConfirm}
          open={confirmIsOpen}
          onCancel={() => {
            setconfirmIsOpen(false);
          }}
          content={(actualButtonConfirmation.confirm?.content || null) as SemanticShorthandItem<ModalContentProps>}
          onConfirm={() => {
            actualButtonConfirmation.onClick();
            setconfirmIsOpen(false);
            goBack();
          }}
          confirmButton={
            <Button disabled={actualButtonConfirmation.confirm?.isConfirmButtonDisabled()} css={style.confirmButton}>
              Confirm
            </Button>
          }
          size={actualButtonConfirmation.confirm?.size}
        />
      )}
    </div>
  );
}
