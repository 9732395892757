/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './storeHeader.style';
import { BrandImage } from './BrandImage';
import { Store } from './model/store.model';

interface StoreHeaderProps {
  store: Store;
}

export const StoreHeader = ({ store }: StoreHeaderProps): JSX.Element => {
  const { brand, name, jdaCode } = store;
  return (
    <div css={style.store}>
      <BrandImage src={brand.logo} />
      <div css={style.titleContainer}>
        <span css={style.title}>{name}</span>
        <span css={style.subTitle}>{jdaCode}</span>
      </div>
    </div>
  );
};
