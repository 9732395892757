import { css } from '@emotion/react';
import style from '../features/assessments/components/field.style';
import fieldStyle from './field.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent}
`;

const listItem = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  && {
    padding: 10px;
    :not(:last-of-type) {
      border-bottom: solid 1px var(--saa-primary-color-10);
    }
  }
`;
const itemInfo = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const description = css``;
const subDescription = css`
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const noItems = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  listItem,
  editIcon,
  subTitle,
  valueContainer,
  value,
  itemInfo,
  description,
  subDescription,
  noItems,
  actions,
  button,
};
