/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Dimmer, Icon, Loader, Modal, SemanticICONS } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './modalMonoSelectField.style';
import { useCtrlEnterToRunCallback } from '../core/utils';

export interface SelectItem {
  code: string;
  description: string;
  subdescription?: string;
  logo?: string;
}

interface ModalMonoSelectFieldProps {
    defaultValue: SelectItem;
    list: SelectItem[];
    editable: boolean;
    onUpdateValue: (value: SelectItem) => void;
    icon?: SemanticICONS;
    iconClassName?: string;
    title?: string;
    loading?: boolean;
  }


export const ModalMonoSelectField = ({
    defaultValue,
    list,
    editable = false,
    onUpdateValue,
    icon,
    title,
    iconClassName = '',
    loading = false,
  }: ModalMonoSelectFieldProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SelectItem | null>(defaultValue);
    const { t } = useTranslation();
  
    useEffect(() => {
      setSelectedItem(defaultValue || null);
    }, [defaultValue]);
  
    const isChecked = useCallback(
      (item: SelectItem) => selectedItem?.code === item.code,
      [selectedItem]
    );
  
    const onItemSelection = useCallback(
      (item: SelectItem) => {
        if (editable) {
          if (isChecked(item)) {
            setSelectedItem(null); 
          } else {
            setSelectedItem(item); 
          }
        }
      },
      [editable, isChecked]
    );
  
    const cancelValue = () => {
      setSelectedItem(defaultValue || null);
      setOpen(false);
    };
  
    const updateValue = () => {
      if (onUpdateValue != null && selectedItem != null) {
        onUpdateValue(selectedItem); 
      }
      setOpen(false);
    };
  
    useCtrlEnterToRunCallback(updateValue, open);
  
    return (
      <div>
        <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
          {(icon || iconClassName) && <Icon name={icon} className={iconClassName} />}
          <div css={style.valueContainer}>
            <span css={style.value}> {selectedItem?.description || t('noSelection')} </span>
            <span css={style.subTitle}>{t("assessment.regionalManager")}</span>
          </div>
          {editable && (
            <div css={style.editIcon}>
              <Icon name='pencil' />
            </div>
          )}
        </div>
        <Modal css={style.modal} onClose={() => cancelValue()} open={open}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content css={style.modalContent}>
            {loading ? (
              <Dimmer active inverted>
                <Loader inverted content='Loading' />
              </Dimmer>
            ) : (
              <div style={{ overflow: 'auto' }}>
                {list.map(item => (
                  <div css={style.checkBoxContainer} key={item.code}>
                    <Checkbox
                      css={item.subdescription ? style.checkBoxLarge : style.checkBox}
                      checked={isChecked(item)}
                      label={item.description}
                      onChange={() => onItemSelection(item)}
                    />
                    {item.subdescription && <span css={style.subDescription}>{item.subdescription}</span>}
                  </div>
                ))}
                {list.length === 0 && <div css={style.noItems}>{t('noItems')}</div>}
              </div>
            )}
          </Modal.Content>
          <Modal.Actions css={style.actions}>
            {editable && (
              <Button css={style.button} onClick={() => cancelValue()}>
                <Icon name='cancel' /> Cancel
              </Button>
            )}
            <Button css={style.button} onClick={() => updateValue()}>
              <Icon name='checkmark' /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  };
  