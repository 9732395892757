import { BrandCode } from '../../../shared/model/brand.model';
import { Region } from '../../../shared/model/region.model';

export interface Principal {
  id: string;
  name: string;
  pictureUrl: string;
  email: string;
  surname: string;
  firstName: string;
  policiesAccepted: boolean;
  roles: ROLES[];
  brands: BrandCode[];
  approvalRequired: boolean;
  isRegionalManagerOf: Region[];
}

export const toPrincipal = (o: Record<string, unknown>): Principal => ({
  id: o.id as string,
  name: o.name as string,
  pictureUrl: o.picture as string,
  email: o.email as string,
  surname: o.family_name as string,
  firstName: o.given_name as string,
  policiesAccepted: o.policiesAccepted as boolean,
  roles: o.roles as ROLES[],
  brands: o.brands as BrandCode[],
  approvalRequired: o.approvalRequired as boolean,
  isRegionalManagerOf: o.isRegionalManagerOf as Region[],
});

export enum ROLES {
  ADMIN = 'ADMIN',
  ARCHIVE_READER = 'ARCHIVE_READER',
  AUDITOR = 'AUDITOR',
  AUDITOR_MANAGER = 'AUDITOR_MANAGER',
  AUDIT_ADMIN = 'AUDIT_ADMIN',
  AUDIT_SCHEDULER = 'AUDIT_SCHEDULER',
  BASIC_USER = 'BASIC_USER',
  BRAND_SCHEDULER = 'BRAND_SCHEDULER',
}

export const AUDITOR_STAFF_ROLES = [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN];

export const checkRole = (user: Principal, role: ROLES): boolean => user.roles.includes(role);
export const checkRoles = (user: Principal, roles: ROLES[]): boolean => user.roles.some(r => roles.includes(r));
