import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { AuditProposal, isProposalVisible } from '../model/auditProposal';
import { AuditCampaign } from '../model/auditCampaign';
import { checkValueInclude } from '../../../shared/utils';
import { checkRoles, ROLES } from '../../auth/model/principal.model';
import { selectPrincipal } from '../../auth/store/principalSlice';

interface AuditCampaignsSliceState {
  campaigns: AuditCampaign[];
  isFetching: boolean;
  error: string;
  filter: string;
  showOnlyAssignedProposals: boolean;
}

const initialState: AuditCampaignsSliceState = {
  campaigns: [],
  isFetching: false,
  error: '',
  filter: '',
  showOnlyAssignedProposals: true,
};

export const auditCampaignsSlice = createSlice({
  name: 'auditCampaigns',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditCampaignsSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<AuditCampaignsSliceState>, { payload }: PayloadAction<AuditCampaign[]>) => ({
      ...state,
      isFetching: false,
      campaigns: payload,
      error: '',
    }),
    httpError: (state: Draft<AuditCampaignsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setFilter: (state: Draft<AuditCampaignsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    changeShowOnlyAssignedProposals: (state: Draft<AuditCampaignsSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showOnlyAssignedProposals: payload,
    }),
    addCampaign: (state: Draft<AuditCampaignsSliceState>, { payload }: PayloadAction<AuditCampaign>) => ({
      ...state,
      campaigns: [...state.campaigns, payload],
    }),
    addProposal: (
      state: Draft<AuditCampaignsSliceState>,
      { payload }: PayloadAction<{ campaignId: string; proposal: AuditProposal }>
    ) => {
      const campaign = state.campaigns.find(c => c.id === payload.campaignId);
      if (campaign) {
        campaign.proposals = [...campaign.proposals, payload.proposal];
      }
    },
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  setFilter,
  changeShowOnlyAssignedProposals,
  addCampaign,
  addProposal,
} = auditCampaignsSlice.actions;

export default auditCampaignsSlice.reducer;

export const fetchAuditCampaigns = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const campaigns = await api.getAuditCampaigns();
    dispatch(finishFetch(campaigns));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onFilterChange =
  (filter: string): AppThunk =>
  (dispatch: any) => {
    dispatch(setFilter(filter));
  };

export const onChangeShowOnlyAssignedProposalsChange =
  (value: boolean): AppThunk =>
  (dispatch: any) => {
    dispatch(changeShowOnlyAssignedProposals(value));
  };

export const selectShowOnlyAssignedProposals = (state: RootState): boolean =>
  state.auditCampaigns.showOnlyAssignedProposals;

export const selectIsToggleVisible = createSelector(selectPrincipal, (principal): boolean => {
  return checkRoles(principal, [ROLES.AUDITOR, ROLES.AUDIT_ADMIN]);
});

export const selectIsFetching = (state: RootState): boolean => state.auditCampaigns.isFetching;

const selectSortedCampaigns = (state: RootState): AuditCampaign[] =>
  [...state.auditCampaigns.campaigns].sort((a, b) => a.auditDateFrom.localeCompare(b.auditDateFrom));

export const selectVisibleCampaignProposals = createSelector(
  selectSortedCampaigns,
  selectPrincipal,
  (campaigns, principal): AuditCampaign[] => {
    return campaigns.map(campaign => {
      const proposals = campaign.proposals.filter(proposal => isProposalVisible(principal, proposal));
      return {
        ...campaign,
        proposals,
      };
    });
  }
);

export const selectAssignedProposals = createSelector(
  selectPrincipal,
  selectVisibleCampaignProposals,
  selectShowOnlyAssignedProposals,
  (principal, campaigns, onlyAssignedProposals): AuditCampaign[] => {
    return campaigns
      .map(campaign => {
        if (onlyAssignedProposals && principal.roles.includes(ROLES.AUDITOR)) {
          return {
            ...campaign,
            proposals: campaign.proposals.filter(
              proposal =>
                campaign.owner.email.toLowerCase() === principal.email.toLowerCase() ||
                proposal.createdBy.email.toLowerCase() === principal.email.toLowerCase() ||
                proposal.auditors.map(a => a.email.toLowerCase()).includes(principal.email.toLowerCase())
            ),
          };
        }
        return campaign;
      })
      .filter(campaign => campaign.proposals.length > 0);
  }
);
export const selectFilter = (state: RootState): string => state.auditCampaigns.filter;

export const selectFilteredSortedAuditCampaigns = createSelector(
  selectAssignedProposals,
  selectFilter,
  (campaigns, filter) => {
    const filterValue = filter.toLowerCase();

    if (filterValue === '') {
      return campaigns;
    }

    const filteredCampaigns: AuditCampaign[] = [];
    campaigns.forEach(campaign => {
      if (checkCampaignFilter(campaign, filterValue)) {
        filteredCampaigns.push(campaign);
      } else {
        const filteredProposals: AuditProposal[] = [];
        campaign.proposals.forEach(proposal => {
          if (checkProposalFilter(proposal, filterValue)) {
            filteredProposals.push(proposal);
          }
        });
        if (filteredProposals.length > 0) {
          filteredCampaigns.push({
            ...campaign,
            proposals: filteredProposals,
          });
        }
      }
    });
    return filteredCampaigns;
  }
);

const checkCampaignFilter = (campaign: AuditCampaign, filterValue: string): boolean => {
  return (
    checkValueInclude(campaign.name, filterValue) ||
    campaign.brandSchedulers.some(brandScheduler => checkValueInclude(brandScheduler.name, filterValue)) ||
    checkValueInclude(campaign.region.description, filterValue)
  );
};

const checkProposalFilter = (proposal: AuditProposal, filterValue: string): boolean => {
  return (
    checkValueInclude(proposal.store.name, filterValue) ||
    checkValueInclude(proposal.store.brand.description, filterValue) ||
    checkValueInclude(proposal.store.jdaCode, filterValue) ||
    checkValueInclude(proposal.store.hfmCode, filterValue) ||
    checkValueInclude(proposal.store.city, filterValue) ||
    checkValueInclude(proposal.status, filterValue) ||
    proposal.auditors.some(auditor => checkValueInclude(auditor.email, filterValue)) ||
    proposal.auditors.some(auditor => checkValueInclude(auditor.name, filterValue)) ||
    proposal.distributionList.some(distributionListValue =>
      checkValueInclude(distributionListValue.email, filterValue)
    ) ||
    checkDateInInterval(filterValue, proposal.proposedDateFrom, proposal.proposedDateTo)
  );
};

const checkDateInInterval = (value: string, from: string, to: string): boolean => {
  const dateString = value.replace(/[-/]*$/, '');
  const date = new Date(dateString);
  if (date.toDateString() === 'Invalid Date') {
    return false;
  }

  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (dateString.length === 4) {
    return fromDate.getFullYear() >= date.getFullYear() && date.getFullYear() <= toDate.getFullYear();
  }
  if (dateString.length === 6 || dateString.length === 7) {
    return (
      fromDate.getFullYear() >= date.getFullYear() &&
      date.getFullYear() <= toDate.getFullYear() &&
      date.getMonth() >= fromDate.getMonth() &&
      date.getMonth() <= toDate.getMonth()
    );
  }

  return date >= fromDate && date <= toDate;
};
