import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';

const container = css`
  overflow: auto;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const group = css`
  display: flex;
  overflow: auto;
  @media (min-width: 1200px) {
    flex-wrap: wrap;
  }
  padding-top: 5px;
  @media (min-width: 540px) {
    padding: 10px 5px;
  }
`;

const header = css`
  display: flex;
  justify-content: space-between;
`;

const addButton = (label: string): SerializedStyles => css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: var(--saa-primary-color-80);
    color: var(--saa-white-color);
  }

  @media (min-width: 1200px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: unset;
      background-color: var(--saa-primary-color-80);
      color: var(--saa-white-color);

      :before {
        content: '${label}';
        padding-right: 5px;
      }
    }
  }
`;

const searchBoxContainer = css`
  flex: 1;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

const visibilityToggle = css`
  && {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  background-color: var(--saa-primary-color-40);
  ${style.subtitleSmall}
  > div {
    background-color: var(--saa-blue-20);
  }
`;

const cardsContainer = css`
  height: 100%;
  @media (min-width: 1200px) {
    overflow: auto;
    padding-right: 5px;
  }
`;
const campaignContainer = css`
  cursor: pointer;
  padding-top: 5px;
  margin-bottom: 10px;
  background-color: var(--saa-primary-color-10);

  :hover {
    filter: brightness(103%);

    .campaign-header {
      background-color: var(--saa-primary-color-5);
      box-shadow: inset 0 -2px 0 0 var(--saa-primary-color-80);
      i {
        color: var(--saa-primary-color);
      }
    }
    h5 {
      text-decoration: underline;
    }
  }
`;

const campaignHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 -2px 0 0 var(--saa-primary-color-60);
  margin: 0;
  @media (min-width: 1200px) {
    justify-content: flex-start;
  }
  @media (min-width: 540px) {
    padding: 0 5px 0;
  }
`;

const editCampaignButton = css`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    height: 100%;
    padding: 5px 5px 5px 10px;
    color: var(--saa-primary-color-80);
    i {
      width: max-content;
      height: max-content;
    }
  }
`;

const campaignData = css`
  flex: 1 1 auto;
  margin-right: 15px;
  @media (min-width: 1200px) {
    flex: 0 0 auto;
  }
`;
const campaignTitle = css`
  display: inline;
  cursor: pointer;
  && {
    margin-bottom: 0;
  }
`;

const campaignInfo = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  flex-wrap: wrap;
  @media (min-width: 600px) {
    justify-content: flex-start;
    div {
      margin-right: 10px;
    }
  }
`;

const campaignDetail = css`
  font-size: 0.7rem;
  color: var(--saa-primary-color-80);
`;

const notFound = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
`;

export default {
  container,
  header,
  addButton,
  searchBoxContainer,
  visibilityToggle,
  cardsContainer,
  campaignContainer,
  editCampaignButton,
  campaignData,
  campaignTitle,
  campaignInfo,
  campaignDetail,
  campaignHeader,
  group,
  notFound,
};
