/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { fetchAuditors, selectAuditors, selectIsFetching } from '../../store/assessmentsArchiveSlice';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { convertSharedFilterInAssessmentArchiveFilter } from '../../model/assessmentArchiveQueryParams';
import { Auditor } from '../../model/assessment';
import { AssessmentSharedFilter } from '../../model/assessmentFilter.model';

interface AuditorMultiSelectFieldProps {
  filters: AssessmentSharedFilter;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (auditor: string[]) => void;
}

export function AuditorMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AuditorMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchAuditors(convertSharedFilterInAssessmentArchiveFilter(filters)));
    }
  }, [dispatch, editable, filters]);

  const auditorList = useSelector(selectAuditors);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Auditor): SelectItem => {
    return {
      code: item.email,
      description: item.name,
    };
  };

  const toAuditor = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='users'
      loading={isFetching}
      defaultValue={
        defaultValue != null ? auditorList.filter(a => defaultValue.includes(a.email)).map(v => toSelectItem(v)) : []
      }
      list={auditorList.map(toSelectItem)}
      title={t('assessment.archive.filters.auditors') || ''}
      editable={editable}
      onUpdateValue={(selectedAuditor: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedAuditor.map(toAuditor));
        }
      }}
    />
  );
}
