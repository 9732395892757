import { css } from '@emotion/react';

const modal = css`
  && {
    max-width: 400px;
  }
`;

const modalContent = css`
  &&& {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    overflow: auto;
    > div {
      overflow: auto;
      flex: 1;
    }
    max-height: 300px;
    > div {
      padding: 0px;
    }
  }
`;

const modalContentActionPlanCoordinators = css`
  ${modalContent}
  &&& {
    overflow: unset;
    max-height: 450px;
    > div {
      overflow: unset;
    }
  }
`;

const radioGroup = css`
  display: flex;
  flex-direction: column;
`;

const radioButton = css`
  padding: 15px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--saa-primary-color-20);
`;

const radioButtonSubdescription = css`
  ${radioButton};
  padding: 10px;
`;

const radioLabel = css`
  margin-left: 10px;
`;

const radioCheckbox = css`
  margin-top: 10px;
`;

const radioSubdescription = css`
  font-size: 0.8rem;
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const closeButton = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
  flex: 1;
  max-width: max-content;
`;

const header = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const headerIcon = css`
  cursor: pointer;
`;

const noValuesLabel = css`
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 300px;
  display: table-cell;
`;

const disclaimer = css`
  font-size: 0.7rem;
  font-weight: 600;
  margin: 2px 10px;
  display: flex;
  align-items: flex-end;
`;

const updateConfirmListTitle = css`
  padding: 20px 20px 0 !important;
  font-weight: 800;
`;

const updateConfirmListElement = css`
  padding: 10px 20px 0 !important;
`;

export default {
  modal,
  modalContent,
  actions,
  button,
  header,
  closeButton,
  headerIcon,
  radioGroup,
  radioButton,
  noValuesLabel,
  disclaimer,
  radioLabel,
  radioCheckbox,
  radioSubdescription,
  modalContentActionPlanCoordinators,
  radioButtonSubdescription,
  updateConfirmListElement,
  updateConfirmListTitle,
};
