/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import { useAppDispatch } from '../../../../core/store';
import style from './auditCampaignDetailHeader.style';
import { AuditCampaign } from '../../model/auditCampaign';
import { deleteCampaign } from '../../store/auditCampaignDetailSlice';

interface AuditCampaignDetailHeaderProps {
  campaign: AuditCampaign;
  deletable: boolean;
}

export const AuditCampaignDetailHeader = ({ campaign, deletable }: AuditCampaignDetailHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div css={style.header}>
      <Icon css={style.backIcon} name='arrow left' onClick={() => navigate('/audit-campaigns')} />
      <div css={style.title}>{t('auditCampaigns.detail')}</div>
      {deletable && <ThreeDotsMenu campaign={campaign} />}
    </div>
  );
};

interface ThreeDotsMenuProps {
  campaign: AuditCampaign;
}

const ThreeDotsMenu = ({ campaign }: ThreeDotsMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div css={style.smallDotsContainer} onClick={() => setOpen(true)}>
        <span css={style.smallDot} />
        <span css={style.smallDot} />
        <span css={style.smallDot} />
      </div>
      <Modal css={style.modal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Actions</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div style={{ color: 'var( --saa-primary-color)' }}>
              <MenuDeleteItem campaign={campaign} onClose={() => setOpen(false)} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => setOpen(false)}>
            <Icon name='cancel' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface MenuDeleteItemProps {
  campaign: AuditCampaign;
  onClose: () => void;
}

const MenuDeleteItem = ({ campaign, onClose }: MenuDeleteItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goBack = useCallback(() => (navigate.length > 1 ? navigate(-1) : navigate('/audit-campaigns')), [navigate]);
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);

  const deleteAuditCampaign = useCallback(async () => {
    const isDeleted = await dispatch(deleteCampaign(campaign.id));
    if (isDeleted) {
      goBack();
    }
  }, [dispatch, campaign.id, goBack]);

  return (
    <>
      <div css={style.menuItemContainer} onClick={() => setDeleteConfirmIsOpen(true)}>
        <Icon name='trash alternate' css={style.fileIcon} />
        <span>{t('auditCampaigns.deleteCampaign')}</span>
      </div>
      <Confirm
        css={style.modalConfirm}
        open={deleteConfirmIsOpen}
        onCancel={() => {
          setDeleteConfirmIsOpen(false);
          onClose();
        }}
        content={<ConfirmationText campaign={campaign} />}
        onConfirm={async () => {
          setDeleteConfirmIsOpen(false);
          await deleteAuditCampaign();
          onClose();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};

const ConfirmationText = ({ campaign }: { campaign: AuditCampaign }): JSX.Element => (
  <div css={style.confirmationTextContainer}>
    {campaign.proposals.length > 0 && (
      <p>
        <Icon name='warning sign' color='orange' />{' '}
        <span style={{ color: 'orange' }}>{t('auditCampaigns.deleteCampaignWarning')}</span>.
      </p>
    )}
    <div>{t('auditCampaigns.deleteCampaignConfirm')}</div>
  </div>
);
