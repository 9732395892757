/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { Brand } from '../../../../shared/model/brand.model';
import { ModalSelectField, SelectItem } from '../../../../shared/ModalSelectField';

interface BrandFieldProps {
  defaultValue?: Brand;
  brandList: Brand[];
  editable?: boolean;
  onSelect?: (brand: Brand) => void;
  loading?: boolean;
}

export function BrandField({ defaultValue, brandList, editable = false, onSelect, loading = false }: BrandFieldProps) {
  const toSelectItem = (item: Brand): SelectItem | undefined => {
    if (item)
      return {
        code: item.code,
        description: item.description,
        logo: item.logo,
      };
    return undefined;
  };

  const toBrand = (item: SelectItem): Brand => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo || '',
    };
  };

  return (
    <ModalSelectField
      icon='copyright'
      defaultValue={defaultValue != null ? toSelectItem(defaultValue) : undefined}
      loading={loading}
      list={brandList.map((b): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('auditCampaigns.creation.brand') || ''}
      editable={editable}
      onSelect={(selectedBrand: SelectItem) => {
        if (onSelect) {
          onSelect(toBrand(selectedBrand));
        }
      }}
    />
  );
}
