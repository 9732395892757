import { API_URL } from '../../../constants';

const ASSESSMENT_REPORT_RESULTS_PATH = `${API_URL}/assessment-report-results`;

const paths = {
  getAssessmentReportResults: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}`,
  getStatuses: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/statuses`,
  getBrands: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/brands`,
  getRegions: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/regions`,
  getCountries: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/countries`,
  getCities: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/cities`,
  getStores: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/stores`,
  getFollowUpStatuses: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/followup-statuses`,
  getPriorities: (): string => `${ASSESSMENT_REPORT_RESULTS_PATH}/priorities`,
};

export default paths;
