/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Confirm, Form } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './detailFooter.style';
import { CalendarField } from '../../../../assessments/components/CalendarField';

interface AcceptButtonProps {
  buttonLabel: string;
  onConfirm: () => void;
  auditDateFrom: string;
  auditDateTo: string;
}

export function AcceptButton({ buttonLabel, onConfirm, auditDateFrom, auditDateTo }: AcceptButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        css={style.fatFooterButtonEnd}
        labelPosition='right'
        icon='forward'
        content={buttonLabel}
        onClick={() => setConfirmIsOpen(true)}
      />
      <Confirm
        header={t('brandAuditProposals.proposalDetail.footer.acceptDateHeader')}
        open={confirmIsOpen}
        onCancel={() => setConfirmIsOpen(false)}
        content={<ModalContent auditDateFrom={auditDateFrom} auditDateTo={auditDateTo} />}
        onConfirm={() => {
          setConfirmIsOpen(false);
          onConfirm();
        }}
        size='tiny'
      />
    </>
  );
}

const ModalContent = ({ auditDateFrom, auditDateTo }: ModalContentProps): JSX.Element => {
  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      <Form>
        <div css={style.acceptCalendarContainer}>
          <CalendarField defaultValue={auditDateFrom} title={t('auditCampaigns.proposalDetail.auditDateFrom')} />
          <CalendarField defaultValue={auditDateTo} title={t('auditCampaigns.proposalDetail.auditDateTo')} />
        </div>
        <div css={style.modalTextContainer}>{t('brandAuditProposals.proposalDetail.footer.confirmationMessage')}</div>
      </Form>
    </div>
  );
};

interface ModalContentProps {
  auditDateFrom: string;
  auditDateTo: string;
}
