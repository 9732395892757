import { css } from '@emotion/react';

const row = css`
  td {
    white-space: pre;
  }
  &&&&:hover {
    background-color: var(--saa-blue-20);
  }
`;

const wrappedCell = css`
  white-space: pre-wrap;
  max-width: 1000px;
  height: 100%;
  width: max-content;
`;

const checkboxCell = css`
  &&&& {
    padding: 0;
  }
`;

const checkboxContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const checkbox = css`
  padding: 3px;
`;
export default {
  row,
  checkboxCell,
  checkboxContainer,
  wrappedCell,
  checkbox,
};
