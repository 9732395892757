/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { BrandCode } from '../../../shared/model/brand.model';
import { FindingSuggestionsForPriority } from '../model/assessmentDetail.model';

interface FindingSuggestionsForPrioritySliceState {
  data: FindingSuggestionsForPriority[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: FindingSuggestionsForPrioritySliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const findingSuggestionsSlice = createSlice({
  name: 'findingSuggestions',
  initialState,
  reducers: {
    startFetch: (state: Draft<FindingSuggestionsForPrioritySliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<FindingSuggestionsForPrioritySliceState>,
      { payload }: PayloadAction<FindingSuggestionsForPriority[]>
    ) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    changeFindingSuggestions: (
      state: Draft<FindingSuggestionsForPrioritySliceState>,
      { payload }: PayloadAction<FindingSuggestionsForPriority[]>
    ) => ({
      ...state,
      data: payload,
    }),
    errorFetch: (state: Draft<FindingSuggestionsForPrioritySliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<FindingSuggestionsForPrioritySliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, changeFindingSuggestions, invalidateCache } =
  findingSuggestionsSlice.actions;

export const fetchFindingSuggestionsForPriority =
  (processCode?: string, subProcessCode?: string, brand?: BrandCode, region?: string, country?: string) =>
  async (dispatch: any, state: any) => {
    if (!state().findingSuggestions.isFetching) {
      dispatch(startFetch());
      try {
        const findingSuggestions = await api.getFindingSuggestionsForPriority(
          processCode,
          subProcessCode,
          brand,
          region,
          country
        );
        dispatch(finishFetch(findingSuggestions));
      } catch (error) {
        dispatch(errorFetch(JSON.stringify(error)));
      }
    }
  };

export const selectFindingSuggestionsForPriority = (state: any) =>
  state.findingSuggestions.data as FindingSuggestionsForPriority[];

export const selectIsFetching = (state: any) => state.findingSuggestions.isFetching;

export default findingSuggestionsSlice.reducer;
