import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const process = css`
  &&& {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: solid 1px var(--saa-primary-color-10);
  }
`;

const processTitle = css`
  ${style.bodySmall};

  &&&&& {
    background-color: var(--saa-white-color);
    display: flex;
    font-weight: 600;
    padding: 10px 2px;
    align-items: center;
    cursor: pointer;
    div {
      flex: 1;
    }
    i {
      font-size: 1.3rem;
    }
  }
`;
const processContent = css`
  &&&& {
    background-color: var(--saa-primary-color-10);
    margin: 5px 15px;
    color: var(--saa-primary-color);
    > div {
      padding: 0;
    }
  }
`;

export default {
  process,
  processTitle,
  processContent,
};
