/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import style from './auditDetail.style';
import {
  ACTION_PLAN_STATUS,
  Audit,
  AuditingResult,
  FollowUpStatus,
  Process,
  ResultStatus,
  ResultUpdateRequest,
  SubProcess,
} from '../model/assessmentDetail.model';
import {
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  selectIsInDistributionList,
} from '../store/assessmentDetailSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { CheckList } from './CheckList';
import { ProcessItem } from './ProcessItem';
import { ResultItem } from './ResultItem';
import { STATUS } from '../model/assessmentStatus';
import {
  saveAuditingResultAction,
  selectAuditingResult,
  selectAuditingResults,
  selectSubProcessAuditingResults,
} from '../store/auditorResultsSlice';
import { toastService } from '../../../core/services/toastService';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import featureStyle from './feature.style';
import { useAppDispatch } from '../../../core/store';
import { AuditMultiToggle } from './AuditMultiToggle';
import { TopicContainer } from './TopicContainer';
import { isValidFollowUpStatus } from '../model/assessmentFollowUp.model';

enum processStatus {
  TODO = 'TODO',
  SUCCESS = 'SUCCESS',
}

const getInProgressSubProcessStatus = (subProcessAuditingResults: AuditingResult[]) => {
  const resultsLength = subProcessAuditingResults.filter(r => r.status !== ResultStatus.TO_DO).length;
  if (resultsLength > 0) {
    return processStatus.SUCCESS;
  }
  return processStatus.TODO;
};

const getInProgressProcessStatus = (process: Process, auditingResults: AuditingResult[]) => {
  const compiled = process.subProcesses
    .map(sp => auditingResults.filter(ar => ar.processCode === process.code && ar.subProcessCode === sp.code))
    .map(subAr => getInProgressSubProcessStatus(subAr))
    .filter(s => s !== processStatus.TODO);

  if (process.subProcesses.length === compiled.length) {
    return processStatus.SUCCESS;
  }
  return processStatus.TODO;
};

interface Props {
  audit: Audit | null;
  followUpStatus: FollowUpStatus;
}

export const InProgressAuditDetail = ({ audit, followUpStatus }: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isTopicContainerActive, setIsTopicContainerActive] = useState<boolean>(false);
  const auditingResults = useSelector(selectAuditingResults);
  const [onlyFindingsFilter, setOnlyFindingsFilter] = useState(false);
  const [onlyFollowUpFilter, setOnlyFollowUpFilter] = useState(false);
  const [onlyFollowUpToFillInFilter, setOnlyFollowUpToFillInFilter] = useState(false);
  const isFollowUpToggleVisible = isValidFollowUpStatus(followUpStatus);
  const isFollowUpToFillInToggleVisible = followUpStatus === FollowUpStatus.UNDER_FOLLOWUP;
  const principal = useSelector(selectPrincipal);
  const isAuditAdmin = checkRole(principal, ROLES.AUDIT_ADMIN);
  const isAPCoordinator = useSelector(selectIsBrandCoordinator);
  const isInDistributionList = useSelector(selectIsInDistributionList);

  const filterByFindings = useCallback(
    (p: Process, sp: SubProcess): boolean => {
      return (
        auditingResults
          .filter(ar => ar.processCode === p.code && ar.subProcessCode === sp.code)
          .some(ar => ar.status !== ResultStatus.PASSED) ||
        auditingResults.filter(ar => ar.processCode === p.code && ar.subProcessCode === sp.code).length === 0
      );
    },
    [auditingResults]
  );

  const filterByFollowUp = useCallback((subProcess: SubProcess): boolean => {
    return subProcess.followUp != null && subProcess.followUp.result != null;
  }, []);

  const canEditFollowUp = useCallback(
    (subProcess: SubProcess): boolean => {
      const isAPEditor = principal.email != null && subProcess.actionPlan?.editor?.email === principal.email;
      return (
        subProcess.followUp != null &&
        isValidFollowUpStatus(followUpStatus) &&
        subProcess.actionPlan?.status === ACTION_PLAN_STATUS.SCHEDULED &&
        followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
        (isAPCoordinator || isAPEditor || isAuditAdmin || isInDistributionList) &&
        !subProcess.followUp?.completed
      );
    },
    [followUpStatus, isAPCoordinator, isAuditAdmin, isInDistributionList, principal.email]
  );

  const filterByFollowUpToFillIn = useCallback(
    (subProcess: SubProcess): boolean => {
      return canEditFollowUp(subProcess);
    },
    [canEditFollowUp]
  );

  const getFilteredProcesses = useCallback(() => {
    if (audit == null) {
      return [];
    }
    const hasFilters = onlyFindingsFilter || onlyFollowUpFilter || onlyFollowUpToFillInFilter;
    if (!hasFilters) {
      return audit.processes;
    }
    return audit.processes.filter(p =>
      p.subProcesses.some(
        sp =>
          (onlyFindingsFilter ? filterByFindings(p, sp) : true) &&
          (onlyFollowUpFilter ? filterByFollowUp(sp) : true) &&
          (onlyFollowUpToFillInFilter ? filterByFollowUpToFillIn(sp) : true)
      )
    );
  }, [
    audit,
    onlyFindingsFilter,
    onlyFollowUpFilter,
    onlyFollowUpToFillInFilter,
    filterByFindings,
    filterByFollowUp,
    filterByFollowUpToFillIn,
  ]);

  const filterSubProcess = useCallback(
    (p: Process, sp: SubProcess) => {
      const hasFilters = onlyFindingsFilter || onlyFollowUpFilter || onlyFollowUpToFillInFilter;
      if (!hasFilters) {
        return true;
      }

      return (
        (onlyFindingsFilter ? filterByFindings(p, sp) : true) &&
        (onlyFollowUpFilter ? filterByFollowUp(sp) : true) &&
        (onlyFollowUpToFillInFilter ? filterByFollowUpToFillIn(sp) : true)
      );
    },
    [
      filterByFindings,
      filterByFollowUp,
      filterByFollowUpToFillIn,
      onlyFindingsFilter,
      onlyFollowUpFilter,
      onlyFollowUpToFillInFilter,
    ]
  );

  const getFilteredSubProcess = useCallback(
    (p: Process) => p.subProcesses.filter(sp => filterSubProcess(p, sp)),
    [filterSubProcess]
  );

  const onChangeTopicActive = () => {
    setIsTopicContainerActive(!isTopicContainerActive);
    setActiveIndex(-1);
  };

  const onChangeProcessActive = (index: number) => {
    setIsTopicContainerActive(false);
    setActiveIndex(index);
  };

  return audit != null ? (
    <>
      <div css={style.findingsFilter}>
        <AuditMultiToggle
          editable
          defaultFindingsValue={onlyFindingsFilter}
          defaultFollowUpValue={onlyFollowUpFilter}
          defaultFollowUpToFillInValue={onlyFollowUpToFillInFilter}
          defaultPriorityFilter={[]}
          showFindings
          showFollowUp={isFollowUpToggleVisible}
          showFollowUpToFillIn={isFollowUpToFillInToggleVisible}
          showPriorityFilter={false}
          onFindingsChange={value => {
            setOnlyFindingsFilter(value);
          }}
          onFollowUpChange={value => {
            setOnlyFollowUpFilter(value);
          }}
          onFollowUpToFillInChange={value => {
            setOnlyFollowUpToFillInFilter(value);
          }}
        />
      </div>
      <div css={style.body}>
        {getFilteredProcesses().map((p, index) => (
          <ProcessItem
            process={p}
            index={index}
            key={p.code}
            activeIndex={activeIndex}
            setActiveIndex={onChangeProcessActive}
            icon={
              getInProgressProcessStatus(p, auditingResults) === processStatus.SUCCESS ? (
                <Icon name='check circle' css={style.successIcon} />
              ) : (
                <></>
              )
            }>
            {getFilteredSubProcess(p).map(sp => (
              <InProgressSubProcessContainer key={sp.code} subProcess={sp} />
            ))}
          </ProcessItem>
        ))}
        <TopicContainer onChangeTopicActive={onChangeTopicActive} isTopicContainerActive={isTopicContainerActive} />
      </div>
    </>
  ) : (
    <></>
  );
};

interface SubProcessItemProps {
  subProcess: SubProcess;
}

const InProgressSubProcessContainer = ({ subProcess }: SubProcessItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isCheckListOpen, setCheckListOpen] = useState(false);
  const user = useSelector(selectPrincipal);
  const isAuditAdmin = useMemo(() => checkRole(user, ROLES.AUDIT_ADMIN), [user]);

  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const canEditChecklist = useMemo(() => isAuditorOwner || isAuditAdmin, [isAuditAdmin, isAuditorOwner]);

  const myResult = useSelector(
    selectAuditingResult(subProcess.processCode, subProcess.code, subProcess.availableKeyBusinessRisks)
  );
  const auditingResults = useSelector(selectSubProcessAuditingResults(subProcess.processCode, subProcess.code));

  const handleOnSave = useCallback(
    async (request: ResultUpdateRequest) => {
      await dispatch(saveAuditingResultAction(myResult, request));
      toastService.success();
      setCheckListOpen(false);
    },
    [dispatch, myResult]
  );

  return (
    <>
      {isCheckListOpen ? (
        <ModalPage onClose={() => setCheckListOpen(false)} title={`${subProcess.code} ${subProcess.description}`}>
          <CheckList
            result={myResult}
            assessmentStatus={STATUS.IN_PROGRESS}
            subProcessCode={subProcess.code}
            processCode={subProcess.processCode}
            subProcessDescription={subProcess.description}
            onSave={handleOnSave}
            readonly={false}
            subProcessInfo={subProcess.info}
            subProcessAvailableKeyBusinessRisks={subProcess.availableKeyBusinessRisks}
          />
        </ModalPage>
      ) : (
        <div css={style.subProcess} onClick={() => canEditChecklist && setCheckListOpen(true)}>
          {canEditChecklist && <Icon name='pencil' css={style.subProcessActionIcon} />}
          <div css={style.subProcessDescription}>
            {subProcess.code} - {subProcess.description}
          </div>

          {auditingResults
            .filter(r => r.status !== ResultStatus.TO_DO && user.email === r.auditor.email)
            .map(r =>
              r.status === ResultStatus.FAILED ? (
                <Icon key={r.id} className='fas fa-circle' css={featureStyle.priorityIconColor(r.priority)} />
              ) : (
                <Icon key={r.id} className='clipboard check' css={style.successIcon} />
              )
            )}

          {getInProgressSubProcessStatus(auditingResults) === processStatus.SUCCESS && (
            <Icon name='check circle' css={style.successIcon} />
          )}
        </div>
      )}

      {auditingResults
        .filter(r => r.status !== ResultStatus.TO_DO && user.email !== r.auditor.email)
        .map(r => (
          <ResultItem
            key={r.id}
            subProcessCode={subProcess.code}
            result={r}
            subProcessInfo={subProcess.info}
            subProcessDescription={subProcess.description}
          />
        ))}
    </>
  );
};
