/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card, Icon, Label, SemanticICONS } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import style from './assessmentCard.style';
import { Assessment } from '../model/assessment';
import { AssessmentHeader } from './AssessmentHeader';

interface AssessmentCardProps {
  assessment: Assessment;
}

export const AssessmentCard = ({ assessment }: AssessmentCardProps): JSX.Element => {
  const { store, auditors, year, month } = assessment;
  const { city } = store;
  const location = useLocation();
  const getRibbonColor = () => {
    switch (assessment.scoreRating) {
      case 'A':
        return 'green';
      case 'B':
        return 'yellow';
      case 'C':
        return 'orange';
      case 'D':
        return 'red';
      default:
        return 'grey';
    }
  };
  return (
    <Card
      as={Link}
      to={`/assessments/${assessment.id}?redirectPath=${location.pathname}`}
      css={style.card(assessment.status.code)}>
      <Card.Content css={style.header}>
        <div css={style.headerContent}>
          <AssessmentHeader assessment={assessment} />
        </div>
        {assessment.scoreRating && (
          <div css={style.ribbonContainer}>
            <Label css={style.ribbon} color={getRibbonColor()} ribbon='right'>
              <span css={style.ribbonContent}>{assessment.scoreRating}</span>
              <Icon css={style.ribbonIcon} name='dashboard' />
            </Label>
          </div>
        )}
      </Card.Content>

      <Card.Content css={style.body}>
        <Row>
          <RowField width='60%' iconName='map marker' value={city} />
          <RowField width='40%' iconName='calendar alternate' value={`${year}-${month}`} />
        </Row>
        <Row>
          <RowField iconName='users' value={auditors.map(a => a.name || a.email).join(', ')} />
        </Row>
      </Card.Content>
    </Card>
  );
};

export const Row = (props: { children?: JSX.Element[] | JSX.Element }): JSX.Element => {
  const { children } = props;
  return <div css={style.bodyRow}>{children}</div>;
};

interface rowFieldProps {
  iconName?: SemanticICONS;
  value: string;
  width?: string | number;
}

export const RowField = ({ iconName, value, width = '100%' }: rowFieldProps): JSX.Element => {
  return (
    <div css={style.field} style={{ width }}>
      <div css={style.iconContainer}>
        <Icon name={iconName} />
      </div>
      <span css={style.fieldValue} title={value}>
        {value}
      </span>
    </div>
  );
};
