import { API_URL } from '../../../constants';

const ASSESSMENT_BULK_UPDATE_PATH = `${API_URL}/assessments-bulk-update`;

const paths = {
  getAssessments: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}`,
  getStatuses: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/statuses`,
  getBrands: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/brands`,
  getRegions: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/regions`,
  getCountries: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/countries`,
  getCities: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/cities`,
  getStores: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/stores`,
  getFollowUpStatuses: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/followup-statuses`,
  getAuditors: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/auditors`,
  getAllAuditors: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/all-auditors`,
  updateAuditors: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/auditors`,
  getActionPlanCoordinators: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/action-plan-coordinators`,
  updateActionPlanCoordinators: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/action-plan-coordinators`,
  getDistributionListMembers: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/distribution-list-members`,
  updateDistributionListMembers: (): string => `${ASSESSMENT_BULK_UPDATE_PATH}/distribution-list-members`,
  getReadOnlyDistributionListMembers: (): string =>
    `${ASSESSMENT_BULK_UPDATE_PATH}/read-only-distribution-list-members`,
  updateReadOnlyDistributionListMembers: (): string =>
    `${ASSESSMENT_BULK_UPDATE_PATH}/read-only-distribution-list-members`,
};

export default paths;
