/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, LabelGroup, Loader } from 'semantic-ui-react';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import _ from 'lodash';
import { AssessmentCard } from '../components/AssessmentCard';
import style from './assessmentsArchive.style';
import { SearchBox } from '../../../shared/SearchBox';
import {
  changeFilter,
  fetchAssessmentsArchive,
  selectAssessmentsFilter,
  selectFilteredAssessmentsArchive,
  selectFilters,
  selectIsFetching,
  selectShowResults,
  setFilters,
  setShowResults,
} from '../store/assessmentsArchiveSlice';
import { useAppDispatch } from '../../../core/store';

import { Brand } from '../../../shared/model/brand.model';
import { useCtrlEnterToRunCallback, useEscToRunCallback } from '../../../core/utils';
import { BrandMultiSelectField } from '../components/archive/BrandMultiSelectField';
import { AssessmentArchiveFilters, emptyAssessmentArchiveFilters } from '../model/assessmentArchiveQueryParams';
import { RegionMultiSelectField } from '../components/archive/RegionMultiSelectField';
import { CountryMultiSelectField } from '../components/archive/CountryMultiSelectField';
import { CityMultiSelectField } from '../components/archive/CityMultiSelectField';
import { StoreMultiSelectField } from '../components/archive/StoreMultiSelectField';
import { AuditorMultiSelectField } from '../components/archive/AuditorMultiSelectField';
import { BrandCoordinatorMultiSelectField } from '../components/archive/BrandCoordinatorMultiSelectField';
import { DistributionListMultiSelectField } from '../components/archive/DistributionListMultiSelectField';
import { FilterBadge } from '../../../shared/FilterBadge';
import { ReadOnlyDistributionListMultiSelectField } from '../components/archive/ReadOnlyDistributionListMultiSelectField';
import { YearMonthRangeField } from '../../../shared/YearMonthRangeField';

const AssessmentsArchiveFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectFilters);

  const [brands, setBrands] = useState<Brand[]>(filters.brands);
  const [regions, setRegions] = useState<string[]>(filters.regions);
  const [countries, setCountries] = useState<string[]>(filters.countries);
  const [cities, setCities] = useState<string[]>(filters.cities);
  const [jdaCodes, setJdaCodes] = useState<string[]>(filters.jdaCodes);
  const [auditors, setAuditors] = useState<string[]>(filters.auditors);
  const [brandCoordinators, setBrandCoordinators] = useState<string[]>(filters.brandCoordinators);
  const [distributionListMembers, setDistributionListMembers] = useState<string[]>(filters.distributionListMembers);
  const [readOnlyDistributionListMembers, setReadOnlyDistributionListMembers] = useState<string[]>(
    filters.readOnlyDistributionListMembers
  );
  const [yearFrom, setYearFrom] = useState<number | undefined>(filters.yearFrom);
  const [monthFrom, setMonthFrom] = useState<number | undefined>(filters.monthFrom);
  const [yearTo, setYearTo] = useState<number | undefined>(filters.yearTo);
  const [monthTo, setMonthTo] = useState<number | undefined>(filters.monthTo);

  const getFilters = useCallback(
    (): AssessmentArchiveFilters => ({
      brands,
      regions,
      countries,
      cities,
      jdaCodes,
      yearFrom,
      monthFrom,
      yearTo,
      monthTo,
      auditors,
      brandCoordinators,
      distributionListMembers,
      readOnlyDistributionListMembers,
    }),
    [
      auditors,
      brandCoordinators,
      brands,
      cities,
      countries,
      distributionListMembers,
      readOnlyDistributionListMembers,
      jdaCodes,
      monthFrom,
      monthTo,
      regions,
      yearFrom,
      yearTo,
    ]
  );

  const search = useCallback(() => {
    dispatch(setFilters(getFilters()));
    dispatch(setShowResults(true));
    dispatch(fetchAssessmentsArchive(getFilters()));
  }, [dispatch, getFilters]);

  const clear = useCallback(() => {
    setBrands([]);
    setRegions([]);
    setCountries([]);
    setCities([]);
    setJdaCodes([]);
    setAuditors([]);
    setBrandCoordinators([]);
    setDistributionListMembers([]);
    setReadOnlyDistributionListMembers([]);
    setYearFrom(undefined);
    setMonthFrom(undefined);
    setYearTo(undefined);
    setMonthTo(undefined);
  }, []);

  useCtrlEnterToRunCallback(search);

  return (
    <div css={style.filtersContainer}>
      <div>
        <BrandMultiSelectField
          filters={emptyAssessmentArchiveFilters}
          defaultValue={brands}
          editable
          onUpdate={selectedValues => {
            setBrands(selectedValues);
          }}
        />
        <RegionMultiSelectField
          filters={emptyAssessmentArchiveFilters}
          defaultValue={regions}
          editable
          onUpdate={selectedValues => {
            setRegions(selectedValues);
            setCountries(emptyAssessmentArchiveFilters.countries);
            setCities(emptyAssessmentArchiveFilters.cities);
            setJdaCodes(emptyAssessmentArchiveFilters.jdaCodes);
          }}
        />
        <CountryMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            regions: getFilters().regions,
          }}
          defaultValue={countries}
          editable={regions.length > 0}
          onUpdate={selectedValues => {
            setCountries(selectedValues);
            setCities(emptyAssessmentArchiveFilters.cities);
            setJdaCodes(emptyAssessmentArchiveFilters.jdaCodes);
          }}
        />
        <CityMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
          }}
          defaultValue={cities}
          editable={countries.length > 0}
          onUpdate={selectedValues => {
            setCities(selectedValues);
            setJdaCodes(emptyAssessmentArchiveFilters.jdaCodes);
          }}
        />
        <StoreMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
          }}
          defaultValue={jdaCodes}
          editable={cities.length > 0}
          onUpdate={selectedValues => setJdaCodes(selectedValues)}
        />
        <AuditorMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            brands: getFilters().brands,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
            jdaCodes: getFilters().jdaCodes,
          }}
          defaultValue={auditors}
          editable
          onUpdate={selectedValues => {
            setAuditors(selectedValues);
          }}
        />
        <BrandCoordinatorMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            brands: getFilters().brands,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
            jdaCodes: getFilters().jdaCodes,
          }}
          defaultValue={brandCoordinators}
          editable
          onUpdate={selectedValues => {
            setBrandCoordinators(selectedValues);
          }}
        />
        <DistributionListMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            brands: getFilters().brands,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
            jdaCodes: getFilters().jdaCodes,
          }}
          defaultValue={distributionListMembers}
          editable
          onUpdate={selectedValues => {
            setDistributionListMembers(selectedValues);
          }}
        />
        <ReadOnlyDistributionListMultiSelectField
          filters={{
            ...emptyAssessmentArchiveFilters,
            brands: getFilters().brands,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
            jdaCodes: getFilters().jdaCodes,
          }}
          defaultValue={readOnlyDistributionListMembers}
          editable
          onUpdate={selectedValues => {
            setReadOnlyDistributionListMembers(selectedValues);
          }}
        />
        <YearMonthRangeField
          yearFrom={yearFrom}
          monthFrom={monthFrom}
          yearTo={yearTo}
          monthTo={monthTo}
          title={t('assessment.archive.filters.yearMonthRange')}
          editable
          onEdited={(yf, mf, yt, mt) => {
            setYearFrom(yf);
            setMonthFrom(mf);
            setYearTo(yt);
            setMonthTo(mt);
          }}
          clearable
        />
      </div>

      <div css={style.buttonsContainer}>
        <Button css={style.button} onClick={clear} icon='eraser' content={t('assessment.archive.clear')} />
        <Button css={style.button} onClick={search} icon='search' content={t('assessment.archive.search')} />
      </div>
    </div>
  );
};

const FiltersBadgesContainer = () => {
  const { t } = useTranslation();
  const filters = useSelector(selectFilters);
  const dispatch = useAppDispatch();

  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState<number>(0);

  useEffect(() => {
    const n =
      (filters.brands.length > 0 ? 1 : 0) +
      (filters.regions.length > 0 ? 1 : 0) +
      (filters.countries.length > 0 ? 1 : 0) +
      (filters.cities.length > 0 ? 1 : 0) +
      (filters.jdaCodes.length > 0 ? 1 : 0) +
      (filters.auditors.length > 0 ? 1 : 0) +
      (filters.brandCoordinators.length > 0 ? 1 : 0) +
      (filters.distributionListMembers.length > 0 ? 1 : 0) +
      (filters.readOnlyDistributionListMembers.length > 0 ? 1 : 0) +
      (filters.yearFrom != null ? 1 : 0) +
      (filters.monthFrom != null ? 1 : 0) +
      (filters.yearTo != null ? 1 : 0) +
      (filters.monthTo != null ? 1 : 0);
    setNumberOfFiltersApplied(n);
  }, [filters]);

  const getFiltersArray = useCallback(() => {
    const filtersBadges = {
      brands: filters.brands.map(b => b.description).join(', '),
      regions: filters.regions.join(', '),
      countries: filters.countries.join(', '),
      cities: filters.cities.join(', '),
      jdaCodes: filters.jdaCodes.join(', '),
      auditors: filters.auditors.join(', '),
      brandCoordinators: filters.brandCoordinators.join(', '),
      distributionListMembers: filters.distributionListMembers.join(', '),
      readOnlyDistributionListMembers: filters.readOnlyDistributionListMembers.join(', '),
      yearFrom: filters.yearFrom,
      monthFrom: filters.monthFrom,
      yearTo: filters.yearTo,
      monthTo: filters.monthTo,
    };

    return Object.keys(filtersBadges)
      .map(k => {
        const realValue = (filters as any)[k];

        return {
          show: !_.isEqual(realValue, (emptyAssessmentArchiveFilters as any)[k]),
          field: k,
          description: (filtersBadges as any)[k],
          label: t(`assessment.archive.filters.${k}`),
        };
      })
      .filter(f => f.show);
  }, [filters, t]);

  const onFilterButtonClick = useCallback(() => {
    dispatch(setShowResults(false));
  }, [dispatch]);

  useEscToRunCallback(onFilterButtonClick);

  return (
    <div css={style.badgeGroup}>
      <div css={style.badges}>
        <LabelGroup circular>
          {getFiltersArray().map(f => (
            <FilterBadge value={f.description} label={f.label} />
          ))}
        </LabelGroup>
      </div>
      <div css={style.filterButtonContainer}>
        <Button css={style.filterButton} onClick={onFilterButtonClick}>
          <div css={style.icon}>
            <Icon name='filter' />
          </div>
          <span>{t('assessment.archive.filtersText')}</span>
        </Button>
        {numberOfFiltersApplied > 0 && (
          <Label css={style.filterButtonBadge} floating circular>
            {numberOfFiltersApplied}
          </Label>
        )}
      </div>
    </div>
  );
};

const AssessmentsArchiveCards = (): JSX.Element => {
  const { t } = useTranslation();

  const assessments = useSelector(selectFilteredAssessmentsArchive);
  const isFetching = useSelector(selectIsFetching);
  const archiveListRef = useRef<List>(null);
  const CARD_HEIGHT = 190;
  const CARD_WIDTH = 350;

  return (
    <div css={style.cardsContainer}>
      {isFetching ? (
        <Loader active />
      ) : assessments.length === 0 ? (
        <div css={style.noItemsMessage}>{t('assessment.archive.noItems')}</div>
      ) : (
        <AutoSizer>
          {({ height, width }: any) => {
            const itemsPerRow = Math.floor(width / CARD_WIDTH);
            const rowCount = Math.ceil(assessments.length / itemsPerRow);

            return (
              <List
                ref={archiveListRef}
                height={height}
                width={width}
                itemCount={rowCount}
                itemSize={() => CARD_HEIGHT}
                overscanCount={10}>
                {(rowProps: ListChildComponentProps) => {
                  const convertedIndex = rowProps.index * itemsPerRow;
                  const rowCards = assessments.slice(convertedIndex, convertedIndex + itemsPerRow);
                  return (
                    <div style={rowProps.style}>
                      <div css={style.cardsRowContainer}>
                        {rowCards.map(a => (
                          <AssessmentCard key={a.id} assessment={a} />
                        ))}
                      </div>
                    </div>
                  );
                }}
              </List>
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};

export const AssessmentsArchive = (): JSX.Element => {
  const showFilters = !useSelector(selectShowResults);

  return (
    <div css={style.container}>
      {showFilters ? (
        <AssessmentsArchiveFilters />
      ) : (
        <>
          <div css={style.header}>
            <FiltersBadgesContainer />
            <SearchAssessmentsArchiveBox />
          </div>
          <AssessmentsArchiveCards />
        </>
      )}
    </div>
  );
};

const SearchAssessmentsArchiveBox = (): JSX.Element => {
  const filter = useSelector(selectAssessmentsFilter);
  const dispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeFilter(value));
  }, 500);

  return <SearchBox defaultValue={filter} onChange={value => debounced(value)} />;
};
