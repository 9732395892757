/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Loader, Pagination as Pager, PaginationProps, Table } from 'semantic-ui-react';
import { AssessmentBulkUpdateTableRow } from './AssessmentBulkUpdateTableRow';
import { AssessmentBulkUpdateTableHeaderRow } from './AssessmentBulkUpdateTableHeaderRow';
import style from './assessmentBulkUpdateTable.style';
import { AssessmentBulkUpdate } from '../../model/assessmentBulkUpdate';

interface AssessmentBulkUpdateTableProps {
  elements: AssessmentBulkUpdate[];
  totalPages?: number;
  currentPage?: number;
  sortColumn?: string;
  direction?: 'ascending' | 'descending';

  onChangePage?(page: number): void;

  handleSort?(clickedColumn: string): void;

  onClickRow?(result: AssessmentBulkUpdate): void;

  rowHeight?: number;
  pageSize?: number;
  totalCount?: number;
  isLoadingData?: boolean;
  selectedAssessments: string[];
  onChangeSelectedAssessments: (assessments: string[]) => void;
  allIds: string[];
}

export const AssessmentBulkUpdateTable: React.FC<AssessmentBulkUpdateTableProps> = ({
  elements,
  totalPages,
  sortColumn,
  currentPage,
  direction,
  handleSort,
  onChangePage,
  onClickRow,
  rowHeight,
  pageSize,
  totalCount,
  isLoadingData,
  selectedAssessments,
  onChangeSelectedAssessments,
  allIds,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRowCheckboxClick = (clickedId: string) => {
    if (selectedAssessments.includes(clickedId)) {
      onChangeSelectedAssessments(selectedAssessments.filter(id => id !== clickedId));
    } else {
      onChangeSelectedAssessments([...selectedAssessments, clickedId]);
    }
  };

  const onHeaderCheckboxClick = () => {
    if (allIds.length !== selectedAssessments.length) {
      onChangeSelectedAssessments(allIds);
    } else {
      onChangeSelectedAssessments([]);
    }
  };

  const rows = elements.map((assessment, index) => (
    <AssessmentBulkUpdateTableRow
      key={`${assessment.jdaCode}-${assessment.year}-${assessment.month}`}
      assessment={assessment}
      onClickRow={() => navigate(`/assessments/${assessment.id}?redirectPath=${location.pathname}`)}
      rowHeight={rowHeight}
      cellTitle={false}
      selected={selectedAssessments.includes(assessment.id)}
      onCheckboxClick={onRowCheckboxClick}
    />
  ));
  const handleChangePage = (event: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
    if (onChangePage != null) onChangePage(activePage as number);
  };

  return (
    <>
      <div css={style.tableContainer}>
        <Table celled selectable={onClickRow != null} sortable={elements.length > 0} striped compact>
          <AssessmentBulkUpdateTableHeaderRow
            sortColumn={sortColumn}
            direction={direction}
            handleSort={handleSort}
            checked={allIds.length === selectedAssessments.length}
            onCheckboxClick={onHeaderCheckboxClick}
          />
          {!isLoadingData && <Table.Body>{rows}</Table.Body>}
        </Table>
        {isLoadingData ? (
          <Loader active style={{ margin: 'auto' }} />
        ) : (
          elements.length === 0 && <div style={{ margin: 'auto', fontWeight: 500 }}>No data</div>
        )}
      </div>
      {elements.length > 0 && totalPages && currentPage && onChangePage && pageSize && totalCount && (
        <div css={style.footer}>
          <Pager
            css={style.paginationContainer}
            totalPages={totalPages}
            activePage={currentPage}
            onPageChange={handleChangePage}
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          <div css={style.counter}>
            <span>
              {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)}
            </span>
            <span style={{ fontWeight: 400 }}> of</span>
            <span> {totalCount}</span>
            <span style={{ fontWeight: 400 }}> items</span>
          </div>
        </div>
      )}
    </>
  );
};

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}
