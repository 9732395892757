import { css } from '@emotion/react';

const container = css`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  && {
    color: var(--saa-primary-color-80);
  }
`;

export default {
  container,
};
