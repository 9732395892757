/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next'
import { Button, Icon, LabelGroup, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import style from './assessmentsReport.style';
import { AppDispatch, useAppDispatch } from '../../../core/store';
import {
  fetchAssessmentReportResults,
  onChangePage,
  selectGeneralFilters,
  selectIsFetching,
  selectPagination,
  selectShowResults,
  selectSort,
  selectFilteredAndSortedResults,
  selectSortedAndPaginatedResults,
  selectTotalPagesAndCount,
  setShowResults,
  reset,
} from '../store/assessmentReportResultsSlice';
import { AssessmentReportTable } from '../components/assessmentReportTable/AssessmentReportTable';
import { emptyAssessmentReportFilters } from '../model/assessmentReportQueryParams';
import { BrandMultiSelectField } from '../components/BrandMultiSelectField';
import { Brand } from '../../../shared/model/brand.model';
import { RegionMultiSelectField } from '../components/RegionMultiSelectField';
import { StatusMultiSelectField } from '../components/StatusMultiSelectField';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { checkRoles, ROLES } from '../../auth/model/principal.model';
import { CountryMultiSelectField } from '../components/CountryMultiSelectField';
import { CityMultiSelectField } from '../components/CityMultiSelectField';
import { StoreMultiSelectField } from '../components/StoreMultiSelectField';
import { exportAssessmentReportResultsXls } from '../services/exportService';
import { useCtrlEnterToRunCallback, useEscToRunCallback } from '../../../core/utils';
import { FollowUpStatusMultiSelectField } from '../components/FollowUpStatusMultiSelectField';
import { FilterBadge } from '../../../shared/FilterBadge';
import { YearMonthRangeField } from '../../../shared/YearMonthRangeField';
import { PriorityMultiSelectField } from '../components/PriorityMultiSelectField';

export const AssessmentsReport = (): JSX.Element => {
  const showFilters = !useSelector(selectShowResults);

  return (
    <div css={style.container}>
      {showFilters ? <AssessmentsReportGeneralFilters /> : <AssessmentsReportResultsTableContainer />}
    </div>
  );
};

export const FiltersBadgesContainer = () => {
  const { t } = useTranslation();
  const generalFilters = useSelector(selectGeneralFilters);
  const results = useSelector(selectFilteredAndSortedResults);
  const user = useSelector(selectPrincipal);
  const dispatch = useAppDispatch();

  const exportExcel = useCallback(() => {
    exportAssessmentReportResultsXls(results, `assessment_report`, user);
  }, [results, user]);

  const getFiltersArray = useCallback(() => {
    const filtersBadges = {
      brands: generalFilters.brands.map(b => b.description).join(', '),
      regions: generalFilters.regions.join(', '),
      countries: generalFilters.countries.join(', '),
      cities: generalFilters.cities.join(', '),
      jdaCodes: generalFilters.jdaCodes.join(', '),
      yearFrom: generalFilters.yearFrom,
      monthFrom: generalFilters.monthFrom,
      yearTo: generalFilters.yearTo,
      monthTo: generalFilters.monthTo,
      assessmentStatuses: generalFilters.assessmentStatuses.map(s => t(`assessment.status.${s}`)).join(', '),
      followUpStatuses: generalFilters.followUpStatuses.map(s => t(`assessment.followUpStatus.${s}`)).join(', '),
    };

    return Object.keys(filtersBadges)
      .map(k => {
        const realValue = (generalFilters as any)[k];

        return {
          show: !_.isEqual(realValue, (emptyAssessmentReportFilters as any)[k]),
          field: k,
          description: (filtersBadges as any)[k],
          label: t(`assessment.report.filters.${k}`),
        };
      })
      .filter(f => f.show);
  }, [generalFilters, t]);

  const onGeneralFilterButtonClick = useCallback(() => {
    dispatch(setShowResults(false));
  }, [dispatch]);

  useEscToRunCallback(onGeneralFilterButtonClick);

  return (
    <div css={style.badgeGroup}>
      <LabelGroup circular>
        {getFiltersArray().map(f => (
          <FilterBadge value={f.description} label={f.label} />
        ))}
      </LabelGroup>
      <Button css={style.filterButton} onClick={onGeneralFilterButtonClick}>
        <Icon name='filter' />
        <span>{t('assessment.report.filtersText')}</span>
      </Button>
      <Button css={style.exportButton} onClick={exportExcel}>
        <Icon name='file excel' />
        <span>{t('assessment.report.export')}</span>
      </Button>
    </div>
  );
};

const AssessmentsReportResultsTableContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const paginatedResults = useSelector(selectSortedAndPaginatedResults);
  const isFetching = useSelector(selectIsFetching);
  const sort = useSelector(selectSort);
  const pagination = useSelector(selectPagination);
  const { totalPages, totalCount } = useSelector(selectTotalPagesAndCount);
  const handleChangePage = useCallback((page: number) => dispatch(onChangePage(page)), [dispatch]);
  return (
    <Segment css={style.segmentContainer}>
      <FiltersBadgesContainer />
      <div css={style.tableContainer}>
        <AssessmentReportTable
          paginatedElements={paginatedResults}
          sortColumn={sort.sortColumn}
          direction={sort.sortOrder}
          totalPages={totalPages}
          currentPage={pagination.page}
          onChangePage={handleChangePage}
          pageSize={pagination.limit}
          totalCount={totalCount}
          isLoadingData={isFetching}
        />
      </div>
    </Segment>
  );
};

const AssessmentsReportGeneralFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useSelector(selectPrincipal);

  const generalFilters = useSelector(selectGeneralFilters);

  const [assessmentStatuses, setAssessmentStatuses] = useState<string[]>(generalFilters.assessmentStatuses);
  const [brands, setBrands] = useState<Brand[]>(generalFilters.brands);
  const [regions, setRegions] = useState<string[]>(generalFilters.regions);
  const [countries, setCountries] = useState<string[]>(generalFilters.countries);
  const [cities, setCities] = useState<string[]>(generalFilters.cities);
  const [jdaCodes, setJdaCodes] = useState<string[]>(generalFilters.jdaCodes);
  const [yearFrom, setYearFrom] = useState<number | undefined>(generalFilters.yearFrom);
  const [monthFrom, setMonthFrom] = useState<number | undefined>(generalFilters.monthFrom);
  const [yearTo, setYearTo] = useState<number | undefined>(generalFilters.yearTo);
  const [monthTo, setMonthTo] = useState<number | undefined>(generalFilters.monthTo);
  const [followUpStatuses, setFollowUpStatuses] = useState(generalFilters.followUpStatuses);
  const [priorities, setPriorities] = useState(generalFilters.priorities);

  const getFilters = useCallback(
    () => ({
      assessmentStatuses,
      brands,
      regions,
      countries,
      cities,
      jdaCodes,
      yearFrom,
      monthFrom,
      yearTo,
      monthTo,
      followUpStatuses,
      priorities,
    }),
    [
      brands,
      cities,
      countries,
      jdaCodes,
      monthFrom,
      monthTo,
      regions,
      assessmentStatuses,
      yearFrom,
      yearTo,
      followUpStatuses,
      priorities,
    ]
  );

  const search = useCallback(() => {
    dispatch(reset())
    dispatch(fetchAssessmentReportResults(getFilters()));
    dispatch(setShowResults(true));
  }, [dispatch, getFilters]);

  const clear = useCallback(() => {
    setAssessmentStatuses([]);
    setBrands([]);
    setRegions([]);
    setCountries([]);
    setCities([]);
    setJdaCodes([]);
    setYearFrom(undefined);
    setMonthFrom(undefined);
    setYearTo(undefined);
    setMonthTo(undefined);
    setFollowUpStatuses([]);
    setPriorities([]);
  }, []);

  useCtrlEnterToRunCallback(search);

  return (
    <div css={style.filtersContainer}>
      <div>
        <BrandMultiSelectField
          filters={emptyAssessmentReportFilters}
          defaultValue={brands}
          editable
          onUpdate={selectedValues => {
            setBrands(selectedValues);
          }}
        />
        <RegionMultiSelectField
          filters={emptyAssessmentReportFilters}
          defaultValue={regions}
          editable
          onUpdate={selectedValues => {
            setRegions(selectedValues);
            setCountries(emptyAssessmentReportFilters.countries);
            setCities(emptyAssessmentReportFilters.cities);
            setJdaCodes(emptyAssessmentReportFilters.jdaCodes);
          }}
        />
        <CountryMultiSelectField
          filters={{
            ...emptyAssessmentReportFilters,
            regions: getFilters().regions,
          }}
          defaultValue={countries}
          editable={regions.length > 0}
          onUpdate={selectedValues => {
            setCountries(selectedValues);
            setCities(emptyAssessmentReportFilters.cities);
            setJdaCodes(emptyAssessmentReportFilters.jdaCodes);
          }}
        />
        <CityMultiSelectField
          filters={{
            ...emptyAssessmentReportFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
          }}
          defaultValue={cities}
          editable={countries.length > 0}
          onUpdate={selectedValues => {
            setCities(selectedValues);
            setJdaCodes(emptyAssessmentReportFilters.jdaCodes);
          }}
        />
        <StoreMultiSelectField
          filters={{
            ...emptyAssessmentReportFilters,
            regions: getFilters().regions,
            countries: getFilters().countries,
            cities: getFilters().cities,
          }}
          defaultValue={jdaCodes}
          editable={cities.length > 0}
          onUpdate={selectedValues => setJdaCodes(selectedValues)}
        />
        <YearMonthRangeField
          yearFrom={yearFrom}
          monthFrom={monthFrom}
          yearTo={yearTo}
          monthTo={monthTo}
          title={t('assessment.report.filters.yearMonthRange')}
          editable
          onEdited={(yf, mf, yt, mt) => {
            setYearFrom(yf);
            setMonthFrom(mf);
            setYearTo(yt);
            setMonthTo(mt);
          }}
          clearable
        />
        {checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]) && (
          <StatusMultiSelectField
            filters={emptyAssessmentReportFilters}
            defaultValue={assessmentStatuses}
            editable
            onUpdate={selectedValues => setAssessmentStatuses(selectedValues)}
          />
        )}
        <FollowUpStatusMultiSelectField
          filters={emptyAssessmentReportFilters}
          defaultValue={followUpStatuses}
          editable
          onUpdate={selectedValues => setFollowUpStatuses(selectedValues)}
        />
        <PriorityMultiSelectField
          filters={emptyAssessmentReportFilters}
          defaultValue={priorities}
          editable
          onUpdate={selectedValues => setPriorities(selectedValues)}
        />
      </div>

      <div css={style.buttonsContainer}>
        <Button css={style.button} onClick={clear}>
          <Icon name='eraser' />
          {t('assessment.report.clear')}
        </Button>
        <Button css={style.button} onClick={search}>
          <Icon name='search' />
          {t('assessment.report.search')}
        </Button>
      </div>
    </div>
  );
};
