import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field}
`;

const editableField = css`
  ${style.editableField}
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&&& {
    max-height: 500px;
    > div {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const inputContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const calendar = css`
  &&& {
    width: 100%;
    display: flex;

    .rmdp-top-class {
      width: 100%;
    }

    .rmdp-calendar {
      width: 100%;
    }

    .rmdp-month-picker {
      width: 100%;
    }
  }
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  checkBoxContainer,
  editIcon,
  subTitle,
  valueContainer,
  value,
  calendar,
};
