/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';

interface AssessmentRegionMultiSelectFieldProps {
  options: string[];
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function AssessmentRegionMultiSelectField({
  options,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AssessmentRegionMultiSelectFieldProps) {
  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: item,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='world'
      defaultValue={defaultValue.map(toSelectItem) || []}
      list={options.map(toSelectItem)}
      title={t('assessment.archive.filters.regions') || ''}
      editable={editable}
      onUpdateValue={(selectedItems: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedItems.map(i => toString(i)));
        }
      }}
    />
  );
}
