/** @jsxImportSource @emotion/react */

import { Button, Modal } from 'semantic-ui-react';
import React from 'react';

export const ModalAlert = ({
  open,
  onClose,
  message,
}: {
  open: boolean;
  onClose: () => void;
  message: string;
}): JSX.Element => {
  return (
    <Modal open={open} onClose={onClose} size='mini'>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Ok</Button>
      </Modal.Actions>
    </Modal>
  );
};
