import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleField } from './SimpleField';
import { Store } from './model/store.model';

interface StoreFieldsProps {
  store: Store;
}

export const StoreFields = ({ store }: StoreFieldsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleField icon='copyright' value={store.brand.description} title={t('store.brand')} />
      <SimpleField icon='map marker' value={store.fullAddress} title={t('store.fullAddress')} />
      <SimpleField icon='code' value={store.fullCode} title={t('store.fullCode')} />
      <SimpleField iconClassName='fas fa-user-tie' value={store.storeManager.name} title={t('store.storeManager')} />
    </div>
  );
};
