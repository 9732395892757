import axios from 'axios';
import qs from 'query-string';
import paths from './paths';
import {
  Assessment,
  AssessmentCreationRequest,
  Auditor,
  BrandCoordinator,
  toAssessment,
  toAuditor,
  toBrandCoordinator,
} from '../model/assessment';
import { Store, toStore } from '../../../shared/model/store.model';
import {
  ActionPlanAttachmentsCheckedRequest,
  ActionPlanEditorUpdateRequest,
  ActionPlanSubmitRequest,
  ActionPlanUpdateRequest,
  AssessmentDetail,
  AssessmentUpdateAuditorsRequest,
  AssessmentUpdateDistributionListRequest,
  AssessmentUpdateFreeTextFieldRequest,
  AssessmentUpdateReadOnlyDistributionListRequest,
  AssessmentUpdateStatusRequest,
  AssessmentUpdateYearAndMonthRequest,
  AttachmentBase64CreationRequest,
  AttachmentRef,
  Comment,
  CreateCommentRequest,
  CreateTopicCommentRequest,
  FollowUpAttachmentsCheckedRequest,
  FollowUpStatus,
  FollowUpSubmitRequest,
  FollowUpUpdateRequest,
  PictureBase64CreationRequest,
  PictureRef,
  ResultUpdateRequest,
  SubProcessNoteRequest,
  toAssessmentDetail,
  TopicForDiscussionRequest,
  TopicForDiscussionUpsertRequest,
  TopicForDiscussionUpsertResponse,
  toTopicForDiscussionUpsertResponse,
  UpdateBrandCoordinatorRequest,
  UpdateCommentRequest,
  UpdateContactRequest,
  UpdateEntityAuditRequest,
  UpdateNumberFieldRequest,
  UpdateRetailProcedureRequest,
  UpdateStoreManagerSinceRequest,
  UpdateStoreManagerRequest,
  FindingSuggestionsForPriority,
  toFindingSuggestionsForPriority,
  AssessmentUpdateRegionalManagerRequest,
  FollowUpAuditorNoteRequest,
} from '../model/assessmentDetail.model';
import { StatusCode } from '../model/assessmentStatus';
import {
  AssessmentFollowUp,
  AssessmentUpdateFollowUpStatusRequest,
  toAssessmentFollowUp,
} from '../model/assessmentFollowUp.model';
import { Brand, BrandCode, toBrand } from '../../../shared/model/brand.model';
import { AssessmentArchiveQueryParams } from '../model/assessmentArchiveQueryParams';
import { Contact, toContact } from '../../users/model/user.model';

const api = {
  getAssessments: async (): Promise<Assessment[]> => {
    const response = await axios.get(paths.getAssessments());
    return response.data.map(toAssessment);
  },
  getAvailableStores: async (text: string, brandCode?: string, region?: string, country?: string): Promise<Store[]> => {
    const params = {
      text,
      ...(brandCode && { brand: brandCode }),
      ...(region && { region }),
      ...(country && { country }),
    };
    const response = await axios.get(paths.getAvailableStores(), { params });
    return response.data.map(toStore);
  },
  getAuditors: async (brand: BrandCode): Promise<Auditor[]> => {
    const response = await axios.get(paths.getAuditors(brand));
    return response.data.map(toAuditor);
  },
  getRegionalManagers: async (keringRegion: string): Promise<Contact[]> => {
    const response = await axios.get(paths.getRegionalManagers(keringRegion));
    return response.data.map(toContact);
  },
  getFindingSuggestionsForPriority: async (
    processCode?: string,
    subProcessCode?: string,
    brand?: BrandCode,
    region?: string,
    country?: string
  ): Promise<FindingSuggestionsForPriority[]> => {
    const response = await axios.get(
      paths.getFindingSuggestionsForPriority(processCode, subProcessCode, brand, region, country)
    );
    return response.data.map(toFindingSuggestionsForPriority);
  },
  createAssessments: async (request: AssessmentCreationRequest): Promise<Assessment> => {
    const response = await axios.post(paths.getAssessments(), request);
    return toAssessment(response.data);
  },
  updateYearAndMonth: async (id: string, year: number, month: number): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateYearAndMonthRequest = { year, month };
    const response = await axios.put(paths.updateYearAndMonth(id), request);
    return toAssessmentDetail(response.data);
  },
  updateAuditors: async (id: string, auditors: Auditor[]): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateAuditorsRequest = { auditorEmails: auditors.map(a => a.email) };
    const response = await axios.put(paths.updateAuditors(id), request);
    return toAssessmentDetail(response.data);
  },
  updateRegionalManager: async (
    id: string,
    currentRegionalManager: Contact,
    newRegionalManager: Contact
  ): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateRegionalManagerRequest = {
      currentRegionalManagerEmail: currentRegionalManager.email,
      newRegionalManagerEmail: newRegionalManager.email,
    };
    const response = await axios.put(paths.updateRegionalManager(id), request);
    return toAssessmentDetail(response.data);
  },
  updateDistributionList: async (id: string, distributionList: string[]): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateDistributionListRequest = { distributionList };
    const response = await axios.put(paths.updateDistributionList(id), request);
    return toAssessmentDetail(response.data);
  },
  updateReadOnlyDistributionList: async (id: string, readOnlyDistributionList: string[]): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateReadOnlyDistributionListRequest = {
      readOnlyDistributionList,
    };
    const response = await axios.put(paths.updateReadOnlyDistributionList(id), request);
    return toAssessmentDetail(response.data);
  },
  createComment: async (id: string, content: string): Promise<AssessmentDetail> => {
    const request: CreateCommentRequest = { content };
    const response = await axios.post(paths.saveComment(id), request);
    return toAssessmentDetail(response.data);
  },
  updateComment: async (id: string, comment: Comment): Promise<AssessmentDetail> => {
    const request: UpdateCommentRequest = { content: comment.content, created: comment.created };
    const response = await axios.put(paths.saveComment(id), request);
    return toAssessmentDetail(response.data);
  },
  updateOverview: async (id: string, overview: string): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateFreeTextFieldRequest = { content: overview };
    const response = await axios.put(paths.updateOverview(id), request);
    return toAssessmentDetail(response.data);
  },
  updateStatus: async (id: string, status: StatusCode): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateStatusRequest = { status };
    const response = await axios.post(paths.updateStatus(id), request);
    return toAssessmentDetail(response.data);
  },
  updateStoreManagerSince: async (id: string, storeManagerSince: string): Promise<AssessmentDetail> => {
    const request: UpdateStoreManagerSinceRequest = { storeManagerSince };
    const response = await axios.put(paths.updateStoreManagerSince(id), request);
    return toAssessmentDetail(response.data);
  },
  updateRetailProcedure: async (id: string, retailProcedure: boolean): Promise<AssessmentDetail> => {
    const request: UpdateRetailProcedureRequest = { value: retailProcedure };
    const response = await axios.put(paths.updateRetailProcedure(id), request);
    return toAssessmentDetail(response.data);
  },
  updateEntityAudit: async (id: string, entityAudit: boolean): Promise<AssessmentDetail> => {
    const request: UpdateEntityAuditRequest = { value: entityAudit };
    const response = await axios.put(paths.updateEntityAudit(id), request);
    return toAssessmentDetail(response.data);
  },
  updateStoreManager: async (id: string, request: UpdateStoreManagerRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.updateStoreManager(id), request);
    return toAssessmentDetail(response.data);
  },
  updateStoreManagerAssistant: async (id: string, request: UpdateContactRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.updateStoreManagerAssistant(id), request);
    return toAssessmentDetail(response.data);
  },
  updateRetailAreaManager: async (id: string, request: UpdateContactRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.updateRetailAreaManager(id), request);
    return toAssessmentDetail(response.data);
  },
  updateBrandCoordinatorList: async (
    id: string,
    request: UpdateBrandCoordinatorRequest[]
  ): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.updateBrandCoordinators(id), request);
    return toAssessmentDetail(response.data);
  },
  updateEmployees: async (id: string, employees: number): Promise<AssessmentDetail> => {
    const request: UpdateNumberFieldRequest = { number: employees };
    const response = await axios.put(paths.updateEmployees(id), request);
    return toAssessmentDetail(response.data);
  },
  saveSubProcessResult: async (id: string, request: ResultUpdateRequest): Promise<AssessmentDetail> => {
    const r = {
      ...request,
      pictureIds: request.pictures.map(p => p.id),
      attachmentIds: request.attachments.map(p => p.id),
    };
    const response = await axios.put(paths.saveSubProcessResult(id), r);
    return toAssessmentDetail(response.data);
  },
  submitActionPlan: async (id: string, request: ActionPlanSubmitRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.submitActionPlan(id), request);
    return toAssessmentDetail(response.data);
  },
  saveActionPlanAttachmentsChecked: async (
    id: string,
    request: ActionPlanAttachmentsCheckedRequest
  ): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveActionPlanAttachmentsChecked(id), request);
    return toAssessmentDetail(response.data);
  },
  saveFollowUpAttachmentsChecked: async (
    id: string,
    request: FollowUpAttachmentsCheckedRequest
  ): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveFollowUpAttachmentsChecked(id), request);
    return toAssessmentDetail(response.data);
  },
  saveFollowUpAuditorNote: async (id: string, request: FollowUpAuditorNoteRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveFollowUpAuditorNote(id), request);
    return toAssessmentDetail(response.data);
  },
  saveAuditManagerNote: async (id: string, request: SubProcessNoteRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveSubProcessManagerNoteRequest(id), request);
    return toAssessmentDetail(response.data);
  },
  saveRegionalManagerNote: async (id: string, request: SubProcessNoteRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveSubProcessRegionalManagerNoteRequest(id), request);
    return toAssessmentDetail(response.data);
  },
  uploadPictures: async (pictures: PictureBase64CreationRequest[]): Promise<PictureRef[]> => {
    const response = await axios.post(paths.uploadPictures(), pictures);
    return response.data as PictureRef[];
  },
  uploadAttachments: async (attachments: AttachmentBase64CreationRequest[]): Promise<AttachmentRef[]> => {
    const response = await axios.post(paths.uploadAttachments(), attachments);
    return response.data as AttachmentRef[];
  },
  downloadPicture: async (id: string): Promise<string> => {
    return axios.get(paths.getPicture(id)).then(r => r.data);
  },
  downloadAttachment: async (id: string): Promise<string> => {
    return axios.get(paths.getAttachment(id)).then(r => r.data);
  },
  downloadPictureThumbnail: async (id: string): Promise<string> => {
    return axios.get(paths.getPictureThumbnail(id)).then(r => r.data);
  },
  saveActionPlan: async (id: string, request: ActionPlanUpdateRequest): Promise<AssessmentDetail> => {
    const r = {
      ...request,
      pictureIds: request.pictures.map(p => p.id),
      attachmentIds: request.attachments.map(p => p.id),
    };
    const response = await axios.put(paths.saveActionPlan(id), r);
    return toAssessmentDetail(response.data);
  },
  saveActionPlanAuditorNote: async (id: string, request: SubProcessNoteRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveActionPlanAuditorNote(id), request);
    return toAssessmentDetail(response.data);
  },
  saveActionPlanBrandNote: async (id: string, request: SubProcessNoteRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveActionPlanBrandNote(id), request);
    return toAssessmentDetail(response.data);
  },
  getAssessmentsArchive: async (params: AssessmentArchiveQueryParams): Promise<Assessment[]> => {
    const response = await axios.get(paths.getAssessmentsArchive(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toAssessment);
  },
  saveActionPlanEditor: async (id: string, request: ActionPlanEditorUpdateRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveActionPlanEditor(id), request);
    return toAssessmentDetail(response.data);
  },

  getArchiveStatuses: async (params: AssessmentArchiveQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getArchiveStatuses(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getArchiveBrands: async (params: AssessmentArchiveQueryParams): Promise<Brand[]> => {
    const response = await axios.get(paths.getArchiveBrands(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrand);
  },
  getArchiveRegions: async (params: AssessmentArchiveQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getArchiveRegions(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getArchiveCountries: async (params: AssessmentArchiveQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getArchiveCountries(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getArchiveCities: async (params: AssessmentArchiveQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getArchiveCities(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getArchiveStores: async (params: AssessmentArchiveQueryParams): Promise<Store[]> => {
    const response = await axios.get(paths.getArchiveStores(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toStore);
  },
  getArchiveAuditors: async (params: AssessmentArchiveQueryParams): Promise<Auditor[]> => {
    const response = await axios.get(paths.getArchiveAuditors(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toAuditor);
  },
  getArchiveBrandCoordinators: async (params: AssessmentArchiveQueryParams): Promise<BrandCoordinator[]> => {
    const response = await axios.get(paths.getArchiveBrandCoordinators(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrandCoordinator);
  },
  getArchiveDistributionListMembers: async (params: AssessmentArchiveQueryParams): Promise<Contact[]> => {
    const response = await axios.get(paths.getArchiveDistributionListMembers(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toContact);
  },
  getArchiveReadOnlyDistributionListMembers: async (params: AssessmentArchiveQueryParams): Promise<Contact[]> => {
    const response = await axios.get(paths.getArchiveReadOnlyDistributionListMembers(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toContact);
  },
  getAssessmentsFollowUp: async (): Promise<AssessmentFollowUp[]> => {
    const response = await axios.get(paths.getAssessmentsFollowUp());
    return response.data.map(toAssessmentFollowUp);
  },
  updateFollowUpStatus: async (
    id: string,
    status: FollowUpStatus,
    oldStatus: FollowUpStatus
  ): Promise<AssessmentDetail> => {
    const request: AssessmentUpdateFollowUpStatusRequest = { status, oldStatus };
    const response = await axios.post(paths.updateFollowUpStatus(id), request);
    return toAssessmentDetail(response.data);
  },
  saveFollowUp: async (id: string, request: FollowUpUpdateRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.saveFollowUp(id), request);
    return toAssessmentDetail(response.data);
  },
  submitFollowUp: async (id: string, request: FollowUpSubmitRequest): Promise<AssessmentDetail> => {
    const response = await axios.put(paths.submitFollowUp(id), request);
    return toAssessmentDetail(response.data);
  },
  createTopicForDiscussion: async (
    assessmentId: string,
    request: TopicForDiscussionUpsertRequest
  ): Promise<TopicForDiscussionUpsertResponse> => {
    const response = await axios.post(paths.createTopicForDiscussion(assessmentId), request);
    return toTopicForDiscussionUpsertResponse(response.data);
  },
  editTopicForDiscussion: async (
    assessmentId: string,
    request: TopicForDiscussionRequest
  ): Promise<TopicForDiscussionUpsertResponse> => {
    const response = await axios.put(paths.editTopicForDiscussion(assessmentId, request.id), {
      title: request.title,
    } as TopicForDiscussionUpsertRequest);
    return toTopicForDiscussionUpsertResponse(response.data);
  },
  deleteTopicForDiscussion: async (assessmentId: string, topicId: string): Promise<AssessmentDetail> => {
    const response = await axios.delete(paths.deleteTopicForDiscussion(assessmentId, topicId));
    return toAssessmentDetail(response.data);
  },
  createTopicComment: async (
    id: string,
    topicId: string,
    message: string
  ): Promise<TopicForDiscussionUpsertResponse> => {
    const request: CreateTopicCommentRequest = { message };
    const response = await axios.post(paths.createTopicComment(id, topicId), request);
    return toTopicForDiscussionUpsertResponse(response.data);
  },
  updateTopicComment: async (
    id: string,
    topicId: string,
    commentId: string,
    message: string
  ): Promise<TopicForDiscussionUpsertResponse> => {
    const request: CreateTopicCommentRequest = { message };
    const response = await axios.put(paths.updateTopicComment(id, topicId, commentId), request);
    return toTopicForDiscussionUpsertResponse(response.data);
  },
};

export default api;
