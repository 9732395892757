/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './brandAuditProposalDetailHeader.style';
import { BrandAuditProposal } from '../../../model/brandAuditProposal';
import { useAppDispatch } from '../../../../../core/store';
import { deleteBrandProposal } from '../../../store/brandAuditProposalDetailSlice';
import { BRAND_AUDIT_PROPOSAL_STATUS } from '../../../model/genericAuditProposal';
import { StoreHeader } from '../../../../../shared/StoreHeader';

interface BrandAuditProposalDetailHeaderProps {
  proposal: BrandAuditProposal;
  deletable: boolean;
}

export const BrandAuditProposalDetailHeader = ({
  proposal,
  deletable,
}: BrandAuditProposalDetailHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div css={style.header}>
      <Icon css={style.backIcon} name='arrow left' onClick={() => navigate('/brand-audit-proposals')} />
      <div css={style.proposalHeader}>
        <StoreHeader store={proposal.store} />
      </div>
      {deletable ? <StatusWithDelete proposal={proposal} /> : <StatusIcon status={proposal.status} />}
    </div>
  );
};

const StatusIcon = ({ status }: { status: BRAND_AUDIT_PROPOSAL_STATUS }) => (
  <div css={style.statusIcon(status)}>
    <Icon name='square' />
  </div>
);

interface StatusWithMenuProps {
  proposal: BrandAuditProposal;
}

const StatusWithDelete = ({ proposal }: StatusWithMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StatusDots onClick={() => setOpen(true)} statusCode={proposal.status} />
      <Modal css={style.modal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Actions</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <MenuDeleteSection proposal={proposal} onClose={() => setOpen(false)} />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => setOpen(false)}>
            <Icon name='cancel' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

interface StatusDotsProps {
  onClick: () => void;
  statusCode: BRAND_AUDIT_PROPOSAL_STATUS;
}

const StatusDots = ({ onClick, statusCode }: StatusDotsProps) => (
  <div css={style.smallDotsContainer} onClick={onClick}>
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
    <span css={style.smallDot(statusCode)} />
  </div>
);

interface MenuDeleteSectionProps {
  proposal: BrandAuditProposal;
  onClose: () => void;
}

const MenuDeleteSection = ({ proposal, onClose }: MenuDeleteSectionProps) => {
  return (
    <div style={{ color: 'var( --saa-primary-color)' }}>
      <MenuDeleteItem proposal={proposal} onClose={onClose} />
    </div>
  );
};

interface MenuDeleteItemProps {
  proposal: BrandAuditProposal;
  onClose: () => void;
}

const MenuDeleteItem = ({ proposal, onClose }: MenuDeleteItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);

  const deleteAuditProposal = useCallback(async () => {
    const isDeleted = await dispatch(deleteBrandProposal(proposal.id));
    if (isDeleted) {
      navigate('/brand-audit-proposals');
    }
  }, [dispatch, proposal.id, navigate]);

  return (
    <>
      <div css={style.menuItemContainer} onClick={() => setDeleteConfirmIsOpen(true)}>
        <Icon name='trash alternate' css={style.fileIcon} />
        <span>{t('brandAuditProposals.proposalDetail.deleteProposal')}</span>
      </div>
      <Confirm
        css={style.modalConfirm}
        open={deleteConfirmIsOpen}
        onCancel={() => {
          setDeleteConfirmIsOpen(false);
          onClose();
        }}
        content={t('brandAuditProposals.proposalDetail.deleteProposalConfirm')}
        onConfirm={() => {
          setDeleteConfirmIsOpen(false);
          deleteAuditProposal();
          onClose();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};
