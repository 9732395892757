import { css, SerializedStyles } from '@emotion/react';
import style from '../../../shared/global.style';
import { brandProposalStatusColor, proposalStatusColor } from './auditProposalCard.style';
import {
  BRAND_AUDIT_PROPOSAL_STATUS,
  INTERNAL_AUDIT_PROPOSAL_STATUS,
  ProposalType,
} from '../model/genericAuditProposal';

const store = css`
  &&&&&&& {
    display: flex;
    flex: 1 1 0;
    width: 100%;
    height: 70px;
    padding: 10px;
  }
`;

const title = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${style.subtitleNormal}
`;

const titleContainer = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
`;
const line2 = css`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
`;

const subTitle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ...${style.subtitleSmall};
    color: var(--saa-primary-color-60)
`;

const status = css`
  margin-right: 0;
  font-style: italic;
`;

const statusLabel = css`
  margin-right: 2px;
  font-size: 0.82rem;
`;

const statusIcon = (
  type: ProposalType,
  proposalStatus: INTERNAL_AUDIT_PROPOSAL_STATUS | BRAND_AUDIT_PROPOSAL_STATUS
): SerializedStyles => {
  const color =
    type === 'internal'
      ? proposalStatusColor(proposalStatus as INTERNAL_AUDIT_PROPOSAL_STATUS)
      : brandProposalStatusColor(proposalStatus as BRAND_AUDIT_PROPOSAL_STATUS);
  return css`
    color: ${color};
  `;
};

export default {
  store,
  titleContainer,
  title,
  line2,
  subTitle,
  status,
  statusLabel,
  statusIcon,
};
