import { Country } from '../../../shared/model/country.model';
import { Region } from '../../../shared/model/region.model';

export interface BrandScheduler {
  name: string;
  email: string;
  regions?: Region[];
  countries?: Country[];
}

export interface BrandSchedulerQueryParams {
  brand?: string;
  region?: string;
  country?: string;
}

export const toBrandScheduler = (o: Record<string, unknown>): BrandScheduler => {
  return {
    name: o.name as string,
    email: o.email as string,
    regions: o.regions as Region[],
    countries: o.countries as Region[],
  };
};
