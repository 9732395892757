/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useAppDispatch } from '../../../../../core/store';
import { updateProposalAuditors, updateProposedDate } from '../../../store/auditProposalDetailSlice';
import { StoreHeader } from '../../../../../shared/StoreHeader';
import { StoreFields } from '../../../../../shared/StoreFields';
import { CalendarField } from '../../../../assessments/components/CalendarField';
import { AuditorsAssessmentField } from '../../../../assessments/components/AuditorsAssessmentField';
import { BrandSchedulersField } from '../../internalAuditWizard/BrandSchedulersField';
import { DistributionListAssessmentField } from '../../../../assessments/components/DistributionListAssessmentField';
import {
  areAuditDatesVisible,
  AuditProposal,
  canEditAuditDates,
  checkStatus,
  isConfiguredAuditor,
  isConfiguredBrandScheduler,
} from '../../../model/auditProposal';
import style from './auditProposalDetailFields.style';
import { ReproposedDates } from '../footerButtons/ReproposedDates';
import { AuditNotes } from '../../../../assessments/components/AuditNotes';
import { checkRole, ROLES } from '../../../../auth/model/principal.model';
import { selectPrincipal } from '../../../../auth/store/principalSlice';
import { isAuthor } from '../../../model/brandAuditProposal';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../../../model/genericAuditProposal';
import { SimpleField } from '../../../../../shared/SimpleField';
import { BrandCode } from '../../../../../shared/model/brand.model';
import { selectBrandSchedulers, selectIsFetchingBrandSchedulers } from '../../../store/brandSchedulersSlice';
import { getDate } from '../../../../../core/utils';
import { Auditor } from '../../../../assessments/model/assessment';

interface AuditProposalDetailFieldsProps {
  campaignId: string;
  proposal: AuditProposal;
  editable?: boolean;
  onCalendarChange?: (from: string, to: string) => void;
  onAuditorsChange?: (auditors: Auditor[]) => void;
}
export const AuditProposalDetailFields = ({
  campaignId,
  proposal,
  editable = false,
  onCalendarChange,
  onAuditorsChange,
}: AuditProposalDetailFieldsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const brandSchedulerContactList = useSelector(selectBrandSchedulers);
  const isFetching = useSelector(selectIsFetchingBrandSchedulers);

  const { store, distributionList, brandSchedulers } = proposal;
  const campaignBrandScheduler = brandSchedulers[0];

  const principal = useSelector(selectPrincipal);

  const isCreationMode = editable && proposal.id === '';
  const canUserEditAuditDates = isCreationMode || (editable && canEditAuditDates(principal, proposal));

  const minDate = getDate();

  const onDateUpdate = async (from: string, to: string) => {
    const toValue = to === '' ? moment(from).add(1, 'day').format('YYYY-MM-DD') : to;

    if (isCreationMode && onCalendarChange) {
      onCalendarChange(from, toValue);
    } else if (editable) {
      dispatch(updateProposedDate(campaignId, proposal.id, from, toValue));
    }
  };

  const onAuditorsUpdate = (changedAuditors: Auditor[]) => {
    if (isCreationMode && onAuditorsChange) {
      onAuditorsChange(changedAuditors);
    } else if (editable) {
      dispatch(updateProposalAuditors(campaignId, proposal.id, changedAuditors));
    }
  };

  const isNoteVisible =
    (isAuthor(principal, proposal) ||
      isConfiguredAuditor(principal, proposal) ||
      checkRole(principal, ROLES.AUDITOR_MANAGER)) &&
    checkStatus(proposal, [
      INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT,
      INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND,
    ]);

  const isBrandNoteVisible =
    (isAuthor(principal, proposal) ||
      isConfiguredAuditor(principal, proposal) ||
      isConfiguredBrandScheduler(principal, proposal) ||
      checkRole(principal, ROLES.AUDITOR_MANAGER)) &&
    checkStatus(proposal, [
      INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT,
      INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED,
      INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND,
      INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND,
    ]);

  return (
    <div css={style.body}>
      <div css={style.content}>
        <div css={style.storeDetail}>
          <StoreHeader store={store} />
          <StoreFields store={store} />
        </div>
        <div css={style.proposalDetail}>
          <SimpleField
            iconClassName='exchange'
            value={t(`auditCampaigns.status.${proposal.status}`) || ''}
            title={t('auditCampaigns.proposalDetail.proposalStatus')}
          />
          {proposal.proposedPeriods && proposal.proposedPeriods.length > 0 && (
            <ReproposedDates reproposedPeriods={proposal.proposedPeriods} />
          )}
          {areAuditDatesVisible(principal, proposal) && (
            <div css={style.rowMultipleField}>
              <CalendarField
                defaultValue={proposal.proposedDateFrom}
                editable={canUserEditAuditDates}
                onChange={changedDateFrom => onDateUpdate(changedDateFrom, proposal.proposedDateTo)}
                title={t('auditCampaigns.proposalDetail.auditDateFrom')}
                minDate={minDate}
                mutable
              />
              <CalendarField
                defaultValue={proposal.proposedDateTo}
                editable={canUserEditAuditDates}
                onChange={changedDateTo => onDateUpdate(proposal.proposedDateFrom, changedDateTo)}
                title={t('auditCampaigns.proposalDetail.auditDateTo')}
                minDate={minDate}
                mutable
              />
            </div>
          )}
          <AuditorsAssessmentField
            defaultValue={proposal.auditors}
            editable={editable}
            onEdited={changedAuditors => onAuditorsUpdate(changedAuditors)}
            title={t('auditCampaigns.proposalDetail.auditors') || ''}
            mutable
            assessmentBrand={proposal.store.brand.code as BrandCode}
          />
          <BrandSchedulersField
            brandSchedulers={brandSchedulerContactList}
            defaultValue={campaignBrandScheduler}
            loading={isFetching}
            assessmentBrand={proposal.store.brand.code as BrandCode}
          />
          <DistributionListAssessmentField
            defaultValue={distributionList}
            title={t('auditCampaigns.proposalDetail.distributionList') || ''}
          />
        </div>
        {(proposal.note || proposal.brandNote || proposal.auditorNote) && (
          <div css={style.noteContainer}>
            {proposal.note && isNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('auditCampaigns.proposalDetail.auditManagerNote')}
                  defaultValue={proposal.note.content}
                  type='auditManagerNote'
                />
              </div>
            )}
            {proposal.brandNote && isBrandNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('auditCampaigns.proposalDetail.brandNote')}
                  defaultValue={proposal.brandNote.content}
                  type='brandNote'
                />
              </div>
            )}
            {proposal.auditorNote && isNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('auditCampaigns.proposalDetail.auditorNote')}
                  defaultValue={proposal.auditorNote.content}
                  type='auditorNote'
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
