/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './reproposeModalContent.style';

interface RejectModalContentProps {
  onChange: (note: string) => void;
}

export function RejectModalContent({ onChange }: RejectModalContentProps): JSX.Element {
  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      <Form css={style.fieldContent}>
        <h4>{t('auditCampaigns.proposalDetail.footer.rejectNote')}</h4>
        <TextArea
          placeholder={t('auditCampaigns.proposalDetail.footer.rescheduleNotePlaceHolder')}
          onChange={event => {
            onChangeNote(event);
          }}
        />
      </Form>
    </div>
  );
}
