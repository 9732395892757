import { css } from '@emotion/react';
import globalStyle from '../../../../../shared/global.style';

const storeDetail = css`
  margin-bottom: 10px;
  background-color: var(--saa-white-color);
`;

const proposalDetail = css`
  background-color: var(--saa-white-color);
`;

const rowMultipleField = css`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

const rowClone = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 5px;
  border-bottom: solid 1px var(--saa-primary-color-10);
`;

const content = css`
  background-color: var(--saa-primary-color-10);
  color: var(--saa-primary-color);
  height: 100%;
`;

const body = css`
  height: calc(100% - 60px);
  overflow: auto;
  margin: 10px 0;
`;

const calendarCheckButton = css`
  ${globalStyle.secondaryButton};
  width: 50%;
  max-width: 150px;
  @media (max-width: 540px) {
    width: 48%;
    max-width: 48%;
  }
  &&&&& {
    color: var(--saa-primary-color-70);
    font-size: 11px !important;
    background-color: var(--saa-primary-color-10);
  }
`;
const cloneIcon = css`
  color: var(--saa-primary-color-90);
`;

const noteContainer = css`
  padding-top: 10px;
`;

const note = css`
  padding-bottom: 10px;
`;

export default {
  body,
  content,
  storeDetail,
  proposalDetail,
  rowMultipleField,
  rowClone,
  calendarCheckButton,
  cloneIcon,
  noteContainer,
  note,
};
