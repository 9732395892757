/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Dimmer, Icon, Loader, Modal, SemanticICONS } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './modalSelectField.style';
import { BrandImage } from './BrandImage';

export interface SelectItem {
  code: string;
  description: string;
  subdescription?: string;
  logo?: string;
  additionalInfo?: string;
}

interface ModalSelectFieldProps {
  defaultValue?: SelectItem;
  list: SelectItem[];
  editable?: boolean;
  onSelect?: (value: SelectItem) => void;
  icon?: SemanticICONS;
  iconClassName?: string;
  title?: string;
  loading?: boolean;
}

export const ModalSelectField = ({
  defaultValue,
  list = [],
  editable = false,
  onSelect,
  icon,
  title,
  iconClassName = '',
  loading = false,
}: ModalSelectFieldProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onItemSelection = (item: SelectItem) => {
    if (editable && onSelect != null) {
      onSelect(item);
    }
    setOpen(false);
  };
  const cancelValue = () => {
    setOpen(false);
  };

  return (
    <div>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        {(icon || iconClassName) && <Icon name={icon} className={iconClassName} />}
        <div css={style.valueContainer}>
          <span css={style.value}> {defaultValue?.description} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          ) : (
            <div style={{ overflow: 'auto' }}>
              {list.map(item => (
                <div css={style.listItem} key={item.code} onClick={() => onItemSelection(item)}>
                  {item.logo && <BrandImage src={item.logo} />}
                  <div css={style.itemInfo}>
                    <span css={style.description}>{item.description}</span>
                    {item.subdescription && <span css={style.subDescription}>{item.subdescription}</span>}
                  </div>
                </div>
              ))}
              {list.length === 0 && <div css={style.noItems}>{t('noItems')}</div>}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => setOpen(false)}>
            <Icon name='cancel' /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
