import { css } from '@emotion/react';
import style from '../../../../shared/global.style';
import fieldStyle from '../../../assessments/components/field.style';

const header = css`
  display: flex;
  align-items: center;
  background-color: var(--saa-primary-color);
  color: var(--saa-white-color);
  width: 100%;
  height: 60px;
`;

const backIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    margin: 0 0 0 10px;
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const title = css`
  && {
    ${style.subtitleNormal}
    margin: auto;
    color: var(--saa-white-color);
  }
`;

const smallDotsContainer = css`
  margin-right: 10px;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

const smallDot = css`
  && {
    height: 10px;
    width: 10px;
    margin-left: 3px;
    background-color: var(--saa-primary-color-60);
    border-radius: 50%;
    display: inline-block;
  }
`;

const menuItemContainer = css`
  && {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-40);
      > i {
        color: var(--saa-primary-color-40);
      }
    }
  }
`;

const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;

const fileIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;
const confirmationTextContainer = css`
  &&& {
    padding: 1rem;
    font-size: 1em;
    line-height: 1.4;
  }
`;
const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

export default {
  header,
  backIcon,
  title,
  modal,
  modalContent,
  fileIcon,
  smallDotsContainer,
  smallDot,
  menuItemContainer,
  modalConfirm,
  confirmButton,
  confirmationTextContainer,
  actions,
  button,
};
