import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

const content = css`
  flex-grow: 1;
  color: var(--saa-primary-color);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const notesContainer = css`
  padding-top: 10px;
`;

const auditNoteContainer = css`
  padding-bottom: 10px;
`;

const brandNoteContainer = css`
  padding-bottom: 10px;
`;

const freeTextContainer = css`
  padding-bottom: 10px;
`;

const statusRadioBox = css`
  display: flex;
  background-color: white;
  padding: 15px 10px;
`;
const statusRadioElement = css`
  && {
    line-height: 17px;
    font-weight: 500;
    margin-right: 8px;
    flex-grow: 1;
  }
`;
const statusTitle = css`
  ${globalStyle.subtitleNormal};
  && {
    padding: 5px 10px;
    background-color: var(--saa-blue-20);
    font-weight: bold;
  }
`;
const statusContainer = css`
  color: var(--saa-primary-color);
  text-align: left;
`;

const disableField = (readonly: boolean): SerializedStyles =>
  css`
    padding-bottom: 10px;
    opacity: ${readonly ? 0.7 : 1};
  `;

const assignedTo = css`
  padding: 10px;
  font-weight: 500;
`;

const assigneeDetail = css`
  padding-left: 10px;
  padding-top: 10px;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: var(--saa-primary-color-10);
`;
const assigneeDetailTitle = css`
  font-weight: 600;
  color: var(--saa-primary-color);
`;
const assigneeDetailAt = css`
  margin-left: 8px;
  color: var(--saa-primary-color);
`;

const assigneeDetailItem = css`
  color: var(--saa-primary-color);
  font-style: italic;
  font-size: 14px;
`;

const assigneeLastUpdate = css`
  padding-top: 3px;
`;

const attachmentsNotMandatoryContainer = css`
  display: flex;
  padding: 5px 5px 5px 10px;
  background-color: var(--saa-white-color);
  align-items: center;
  border-top: 1px solid var(--saa-primary-color-20);
`;
const attachmentsNotMandatoryToggle = css`
  margin-left: auto;
`;
const attachmentsNotMandatoryLabelContainer = css`
  display: flex;
  flex-direction: column;
`;
const attachmentsNotMandatoryLabel = css`
  ${globalStyle.subtitleNormal};
`;
const attachmentsNotMandatorySubLabel = css`
  ${globalStyle.subtitleSmall};
  font-style: italic;
  color: var(--saa-primary-color-80);
`;

export default {
  container,
  content,
  notesContainer,
  auditNoteContainer,
  brandNoteContainer,
  freeTextContainer,
  statusRadioBox,
  statusRadioElement,
  statusTitle,
  statusContainer,
  disableField,
  assignedTo,
  assigneeDetail,
  assigneeDetailTitle,
  assigneeDetailItem,
  assigneeDetailAt,
  assigneeLastUpdate,
  attachmentsNotMandatoryContainer,
  attachmentsNotMandatoryToggle,
  attachmentsNotMandatoryLabelContainer,
  attachmentsNotMandatoryLabel,
  attachmentsNotMandatorySubLabel,
};
