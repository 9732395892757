import { css } from '@emotion/react';

const search = css`
  &&&& {
    width: 100%;
    max-width: 400px;
    max-height: 40px;
    display: flex;
    input {
      border: none;
    }

    i {
      background-color: var(--saa-primary-color-80);
      color: var(--saa-white-color);
      opacity: 1;
    }
  }
`;

export default {
  search,
};
