/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Process } from '../model/assessmentDetail.model';
import style from './processItem.style';

interface ProcessItemProps {
  children?: JSX.Element[] | JSX.Element;
  process: Process;
  index: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  icon: ReactElement;
}

export const ProcessItem: React.FunctionComponent<ProcessItemProps> = ({
  process,
  index,
  activeIndex,
  setActiveIndex,
  icon,
  children,
}): JSX.Element => {
  const toggleAccordion = (i: number) => {
    if (activeIndex === i) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(i);
    }
  };

  return (
    <Accordion css={style.process}>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={() => toggleAccordion(index)}
        css={style.processTitle}>
        <Icon name='dropdown' />
        <div>
          {process.code} - {process.description}
        </div>
        {icon}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index} css={style.processContent}>
        <Accordion.Content active={activeIndex === index}>{children}</Accordion.Content>
      </Accordion.Content>
    </Accordion>
  );
};
