import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { Store } from '../../../shared/model/store.model';
import { toastService } from '../../../core/services/toastService';
import { addBrandProposal } from './brandAuditProposalsSlice';
import { BrandAuditProposalRequest } from '../model/brandAuditProposal';

export enum WIZARD_STEP {
  SELECT_STORE = 'SELECT_STORE',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  LOADING = 'LOADING',
}

interface BrandAuditProposalWizardSliceState {
  store: Store | null;
  stores: Store[];
  step: WIZARD_STEP;
  isFetching: boolean;
  error: string;
  filter: string;
}

const initialState: BrandAuditProposalWizardSliceState = {
  step: WIZARD_STEP.SELECT_STORE,
  isFetching: false,
  error: '',
  store: null,
  stores: [],
  filter: '',
};
export const brandAuditProposalWizardSlice = createSlice({
  name: 'auditProposalWizard',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandAuditProposalWizardSliceState>) => ({
      ...state,
      isFetching: true,
    }),

    finishStoresFetch: (state: Draft<BrandAuditProposalWizardSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      error: '',
      stores: action.payload,
    }),
    httpError: (state: Draft<BrandAuditProposalWizardSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setStoreFilter: (state: Draft<BrandAuditProposalWizardSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    setStore: (state: Draft<BrandAuditProposalWizardSliceState>, action: PayloadAction<Store>) => ({
      ...state,
      store: action.payload,
    }),
    setStep: (state: Draft<BrandAuditProposalWizardSliceState>, action: PayloadAction<WIZARD_STEP>) => ({
      ...state,
      step: action.payload,
    }),
    reset: (state: Draft<BrandAuditProposalWizardSliceState>) => initialState,
  },
});
export const { startFetch, httpError, setStoreFilter, finishStoresFetch, setStep, reset, setStore } =
  brandAuditProposalWizardSlice.actions;

export const fetchStoresByName =
  (locationName: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getAvailableStores(locationName);
      const validStores = stores.filter(store => store.jdaCode != null);
      dispatch(finishStoresFetch(validStores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export default brandAuditProposalWizardSlice.reducer;

export const onStoreFilterChange =
  (filter: string): AppThunk =>
  (dispatch: any) => {
    dispatch(setStoreFilter(filter));
    if (filter.length >= 3) {
      dispatch(fetchStoresByName(filter));
    }
    if (filter.length === 0) {
      dispatch(finishStoresFetch([]));
    }
  };

export const onStoreSelection =
  (store: Store): AppThunk =>
  (dispatch: any) => {
    dispatch(setStore(store));
  };

export const onStepChange =
  (step: WIZARD_STEP): AppThunk =>
  (dispatch: any) => {
    dispatch(setStep(step));
  };

export const createBrandProposal =
  (request: BrandAuditProposalRequest): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const proposal = await api.createBrandAuditProposals(request);
      await dispatch(addBrandProposal(proposal));
      dispatch(reset());
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const selectIsFetchingStores = (state: RootState): boolean => state.brandAuditProposalWizard.isFetching;

export const selectStores = (state: RootState): Store[] => state.brandAuditProposalWizard.stores;

export const selectStoreFilter = (state: RootState): string => state.brandAuditProposalWizard.filter;

export const selectStep = (state: RootState): WIZARD_STEP => state.brandAuditProposalWizard.step;

export const selectSelectedStore = (state: RootState): Store | null => state.brandAuditProposalWizard.store;
