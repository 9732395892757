/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACTION_PLAN_STATUS,
  ActionPlanSubmitRequest,
  ActionPlanUpdateRequest,
  emptyActionPlan,
  emptyFollowUp,
  FollowUpStatus,
  FollowUpSubmitRequest,
  FollowUpUpdateRequest,
  ResultUpdateRequest,
  SubProcess,
} from '../model/assessmentDetail.model';
import { STATUS, Status } from '../model/assessmentStatus';
import { Tabs } from '../../../shared/Tabs';
import { CheckList } from './CheckList';
import style from './checkListAndActionPlanContainer.style';
import { ActionPlan } from './ActionPlan';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import { selectPrincipal } from '../../auth/store/principalSlice';
import {
  saveActionPlanAttachmentsChecked,
  saveFollowUpAttachmentsChecked,
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  selectIsEditStoreUser,
  selectIsInDistributionList,
  selectIsInReadOnlyDistributionList,
} from '../store/assessmentDetailSlice';
import { FollowUpTab } from './FollowUpTab';
import { isValidFollowUpStatus } from '../model/assessmentFollowUp.model';
import { useAppDispatch } from '../../../core/store';

interface CheckListAndActionPlanContainerProps {
  subProcess: SubProcess;
  assessmentStatus: Status;
  onActionPlanSubmit?: (saveActionPlanRequest: ActionPlanUpdateRequest, request: ActionPlanSubmitRequest) => void;
  onActionPlanSave?: (request: ActionPlanUpdateRequest) => void;
  onFollowUpSave?: (request: FollowUpUpdateRequest) => void;
  onFollowUpSubmit?: (updateRequest: FollowUpUpdateRequest, submitRequest: FollowUpSubmitRequest) => void;
  onChecklistSave?: (request: ResultUpdateRequest) => void;
  followUpStatus: FollowUpStatus;
}

export const CheckListAndActionPlanContainer = ({
  subProcess,
  assessmentStatus,
  onActionPlanSubmit = () => null,
  onActionPlanSave = () => null,
  onFollowUpSave = () => null,
  onFollowUpSubmit = () => null,
  onChecklistSave = () => null,
  followUpStatus,
}: CheckListAndActionPlanContainerProps): JSX.Element => {
  const { t } = useTranslation();

  const followUpTabVisible = subProcess.followUp != null && isValidFollowUpStatus(followUpStatus);
  const [selectedTab, setSelectedTab] = useState<string>(t('assessment.tab.findings') || '');
  const dispatch = useAppDispatch();

  const principal = useSelector(selectPrincipal);
  const isAuditor = checkRole(principal, ROLES.AUDITOR);
  const isAuditorManager = checkRole(principal, ROLES.AUDITOR_MANAGER);
  const isEditStoreUser = useSelector(selectIsEditStoreUser);
  const isAPEditor = principal.email != null && subProcess.actionPlan?.editor?.email === principal.email;
  const isAuditAdmin = checkRole(principal, ROLES.AUDIT_ADMIN);
  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const isAPCoordinator = useSelector(selectIsBrandCoordinator);
  const isInDistributionList = useSelector(selectIsInDistributionList);
  const isInReadOnlyDistributionList = useSelector(selectIsInReadOnlyDistributionList);

  const tabs = useMemo(() => {
    return [
      t('assessment.tab.findings'),
      t('assessment.tab.actionPlan'),
      ...(followUpTabVisible ? [t('assessment.tab.followUp')] : []),
    ];
  }, [followUpTabVisible, t]);

  const canEditCheckList = useMemo(
    () => assessmentStatus.code !== STATUS.CLOSED.code && isAuditAdmin,
    [assessmentStatus.code, isAuditAdmin]
  );

  const canEditActionPlan = useMemo(
    () =>
      (assessmentStatus.code === STATUS.SENT_TO_THE_STORES.code && (isEditStoreUser || isAPEditor)) ||
      (assessmentStatus.code === STATUS.CLOSED.code && isAuditAdmin),
    [assessmentStatus.code, isAPEditor, isAuditAdmin, isEditStoreUser]
  );

  const canEditFollowUp = useMemo(
    () =>
      followUpTabVisible &&
      subProcess.actionPlan?.status === ACTION_PLAN_STATUS.SCHEDULED &&
      followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
      (isAPCoordinator || isAPEditor || isAuditAdmin || isInDistributionList) &&
      !subProcess.followUp?.completed,
    [
      followUpStatus,
      followUpTabVisible,
      isAPCoordinator,
      isAPEditor,
      isAuditAdmin,
      isInDistributionList,
      subProcess.actionPlan?.status,
      subProcess.followUp?.completed,
    ]
  );

  const canSubmitFollowUp = useMemo(
    () => followUpStatus === FollowUpStatus.UNDER_FOLLOWUP && isAPEditor,
    [followUpStatus, isAPEditor]
  );

  const followUpValuesVisible =
    [FollowUpStatus.DONE, FollowUpStatus.PENDING, FollowUpStatus.COMPLETED].includes(followUpStatus) ||
    (followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
      (isAuditAdmin || isAPEditor || isAPCoordinator || isInDistributionList || isInReadOnlyDistributionList));

  const setActionPlanAttachmentsChecked = useCallback(() => {
    if (
      (isAuditorOwner || isAuditAdmin) &&
      assessmentStatus.code === STATUS.ACTIONS_PLAN_UNDER_REVIEW.code &&
      subProcess.actionPlan != null &&
      subProcess.actionPlan.attachmentsToBeChecked
    ) {
      dispatch(
        saveActionPlanAttachmentsChecked({
          processCode: subProcess.processCode,
          subProcessCode: subProcess.code,
          attachmentsChecked: true,
        })
      );
    }
  }, [
    assessmentStatus.code,
    dispatch,
    isAuditAdmin,
    isAuditorOwner,
    subProcess.actionPlan,
    subProcess.code,
    subProcess.processCode,
  ]);

  const setFollowUpAttachmentsChecked = useCallback(() => {
    if (
      (isAuditorOwner || isAuditAdmin) &&
      followUpStatus === FollowUpStatus.DONE &&
      subProcess.followUp != null &&
      subProcess.followUp.attachmentsToBeChecked
    ) {
      dispatch(
        saveFollowUpAttachmentsChecked({
          processCode: subProcess.processCode,
          subProcessCode: subProcess.code,
          attachmentsChecked: true,
        })
      );
    }
  }, [
    dispatch,
    isAuditAdmin,
    isAuditorOwner,
    subProcess.followUp,
    subProcess.code,
    subProcess.processCode,
    followUpStatus,
  ]);

  const onChangeTab = (tab: string) => {
    if (tab === t('assessment.tab.actionPlan')) {
      setActionPlanAttachmentsChecked();
    }
    if (tab === t('assessment.tab.followUp')) {
      setFollowUpAttachmentsChecked();
    }
    setSelectedTab(tab);
  };

  return (
    <div css={style.container}>
      <Tabs onChange={onChangeTab} tabs={tabs} defaultTab={selectedTab} />
      <div css={[style.tabContentContainer, selectedTab !== t('assessment.tab.findings') && style.displayNone]}>
        <CheckList
          result={subProcess.result}
          subProcessInfo={isAuditor || isAuditorManager ? subProcess.info : ''}
          assessmentStatus={assessmentStatus}
          processCode={subProcess.processCode}
          subProcessCode={subProcess.code}
          subProcessDescription={subProcess.description}
          readonly={!canEditCheckList}
          isVisibleResultBox={isAuditor || isAuditorManager || isAuditAdmin}
          onSave={canEditCheckList ? onChecklistSave : undefined}
          subProcessAvailableKeyBusinessRisks={subProcess.availableKeyBusinessRisks}
        />
      </div>
      <div css={[style.tabContentContainer, selectedTab !== t('assessment.tab.actionPlan') && style.displayNone]}>
        <ActionPlan
          onSubmit={onActionPlanSubmit}
          actionPlan={subProcess.actionPlan || emptyActionPlan()}
          assessmentStatus={assessmentStatus}
          processCode={subProcess.processCode}
          subProcessCode={subProcess.code}
          onSave={onActionPlanSave}
          readonly={!canEditActionPlan}
          subProcessResultPriority={subProcess.result?.priority}
        />
      </div>
      {followUpTabVisible && (
        <div css={[style.tabContentContainer, selectedTab !== t('assessment.tab.followUp') && style.displayNone]}>
          <FollowUpTab
            processCode={subProcess.processCode}
            subProcessCode={subProcess.code}
            onSave={onFollowUpSave}
            onSubmit={onFollowUpSubmit}
            editable={canEditFollowUp}
            submittable={canSubmitFollowUp}
            valuesVisibility={followUpValuesVisible}
            followUp={subProcess.followUp || emptyFollowUp()}
            actionPlanEditor={subProcess.actionPlan?.editor || undefined}
            followUpStatus={followUpStatus}
            actionPlanStatus={subProcess.actionPlan?.status}
          />
        </div>
      )}
    </div>
  );
};
