/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Button, Loader } from 'semantic-ui-react';
import { useAppDispatch } from '../../../core/store';
import {
  fetchBrandAuditProposals,
  onFilterChange,
  selectBrandProposalsFilter,
  selectBrandProposalsGroupedByStatus,
  selectIsFetching,
} from '../store/brandAuditProposalsSlice';
import style from './brandAuditProposal.style';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { checkRole, ROLES } from '../../auth/model/principal.model';
import { SearchBox } from '../../../shared/SearchBox';
import { ModalPage } from '../../../shared/ModalPage';
import { ProposalCard } from '../components/AuditProposalCard';
import { BrandAuditPlanningWizard } from '../components/brandAuditWizard/BrandAuditPlanningWizard';
import { BRAND_AUDIT_PROPOSAL_STATUS } from '../model/genericAuditProposal';
import { reset } from '../store/brandAuditProposalWizardSlice';

export function BrandAuditProposals(): JSX.Element {
  const dispatch = useAppDispatch();
  const groupedBrandProposals = useSelector(selectBrandProposalsGroupedByStatus);
  const isLoading = useSelector(selectIsFetching);
  useEffect(() => {
    dispatch(fetchBrandAuditProposals());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <Header />
      {isLoading ? (
        <Loader active />
      ) : (
        <div css={style.cardsContainer}>
          {Object.entries(groupedBrandProposals).map(([groupKey, proposals]) => (
            <div key={groupKey}>
              <h5 css={style.sectionTitle(groupKey as BRAND_AUDIT_PROPOSAL_STATUS)}>
                {t(`brandAuditProposals.status.${groupKey}`)}
              </h5>
              <div css={style.group}>
                {proposals.map(p => (
                  <ProposalCard key={p.id} proposal={p} />
                ))}
              </div>
            </div>
          ))}
          {Object.entries(groupedBrandProposals).length === 0 && (
            <div css={style.notFound}>{t('brandAuditProposals.noItemsFound')}</div>
          )}
        </div>
      )}
    </div>
  );
}

function Header(): JSX.Element {
  const dispatch = useAppDispatch();
  const principal = useSelector(selectPrincipal);
  const searchFilter = useSelector(selectBrandProposalsFilter);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onFilterChange(value));
  }, 500);

  const closeCreation = () => {
    setIsModalOpen(false);
    dispatch(reset());
  };

  return (
    <div css={style.header}>
      {checkRole(principal, ROLES.BRAND_SCHEDULER) && (
        <Button
          css={style.addButton(t('brandAuditProposals.createProposalButton'))}
          icon='plus'
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      )}
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={searchFilter} onChange={(filter: string) => debounced(filter.trim())} />
      </div>
      {isModalOpen && (
        <ModalPage onClose={closeCreation} title={t('brandAuditProposals.createProposal') || ''}>
          <BrandAuditPlanningWizard
            onSuccessfulCreation={() => {
              closeCreation();
            }}
          />
        </ModalPage>
      )}
    </div>
  );
}
