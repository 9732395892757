/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Radio } from 'semantic-ui-react';
import style from './bestCasePracticeField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface BestCasePracticeProps {
  defaultValue: { bestPractice: boolean; note: string };
  editable?: boolean;
  onChange?: (value: { bestPractice: boolean; note: string }) => void;
  isValid?: boolean;
  mutable?: boolean;
  isNoteVisible?: boolean;
}

export const BestCasePractice = ({
  defaultValue,
  onChange = () => null,
  editable = false,
  isValid = true,
  mutable = false,
  isNoteVisible = true,
}: BestCasePracticeProps): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [isSelectedBestPracticeState, setIsSelectedBestPracticeState] = React.useState(defaultValue.bestPractice);
  const [noteState, setNoteState] = React.useState(defaultValue.note);

  const onRadioChange = (state: boolean) => {
    setIsSelectedBestPracticeState(state);
    setNoteState('');
  };

  useEffect(() => {
    if (mutable) {
      setIsSelectedBestPracticeState(defaultValue.bestPractice);
      setNoteState(defaultValue.note);
    }
  }, [defaultValue, mutable]);

  const updateValue = () => {
    onChange({
      bestPractice: isSelectedBestPracticeState,
      note: isSelectedBestPracticeState === true ? noteState : '',
    });
    setOpen(false);
  };

  const cancelValue = () => {
    setIsSelectedBestPracticeState(defaultValue.bestPractice);
    setNoteState(defaultValue.note);
    setOpen(false);
  };

  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteState(event.target.value);
  };

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div css={style.priorityTitle(isValid)} title={t('assessment.checkList.bestCasePractice') || ''}>
        {t('assessment.checkList.bestCasePractice')}
        <span css={style.priorityLabel}>{t(`assessment.checkList.${isSelectedBestPracticeState ? 'yes' : 'no'}`)}</span>
      </div>

      <div css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <div> {isNoteVisible && noteState} </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{t('assessment.checkList.bestCasePractice')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <div css={style.priorityRadioContainer}>
                <Radio
                  key={1}
                  name='rate'
                  checked={isSelectedBestPracticeState}
                  onChange={() => onRadioChange(true)}
                  css={style.priorityRadioButton}
                  label={t('assessment.checkList.yes')}
                />
                <Radio
                  key={2}
                  name='rate'
                  checked={!isSelectedBestPracticeState}
                  onChange={() => onRadioChange(false)}
                  css={style.priorityRadioButton}
                  label={t('assessment.checkList.no')}
                />
              </div>
              <textarea
                disabled={!isSelectedBestPracticeState}
                value={noteState}
                onChange={event => onChangeNote(event)}
                placeholder={t('assessment.checkList.bestCasePractice') || ''}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
