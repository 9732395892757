/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Form, Radio, Segment, TextArea } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './reproposeModalContent.style';
import { getDate } from '../../../../../core/utils';
import {
  MultipleDateRangeField,
  getMaxDateOfRange,
  getMinDateOfRange,
} from '../../../../../shared/MultipleDateRangeField';
import { ProposedPeriod } from '../../../model/genericAuditProposal';

interface RejectModalContentProps {
  onChange: (proposedPeriods: ProposedPeriod[], note: string, isRepropose: boolean) => void;
}

export function ReproposeModalContent({ onChange }: RejectModalContentProps): JSX.Element {
  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>([]);
  const [note, setNote] = useState('');

  const [minDate, setMinDate] = useState<Date>(getDate());
  const [maxDate, setMaxDate] = useState<Date>();

  const [isRepropose, setIsRepropose] = useState<boolean>(true);

  useEffect(() => {
    onChange(proposedPeriodsState, note, isRepropose);
  }, [note, onChange, isRepropose, proposedPeriodsState]);

  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const onEdit = (proposedPeriods: ProposedPeriod[]): void => {
    setProposedPeriodsState(proposedPeriods);
    setMinDate(getMinDateOfRange(proposedPeriods));
    setMaxDate(getMaxDateOfRange(proposedPeriods));
  };

  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      <Form css={style.checkBoxContent}>
        <Form.Field css={style.RadioButtonField}>
          <Radio
            label='Repropose'
            name='radioGroup'
            checked={isRepropose}
            onChange={() => {
              setIsRepropose(true);
            }}
          />
        </Form.Field>
        <Form.Field css={style.RadioButtonField}>
          <Radio
            label='Reject'
            name='radioGroup'
            checked={!isRepropose}
            onChange={() => {
              setIsRepropose(false);
            }}
          />
        </Form.Field>
      </Form>
      <Form css={style.fieldContent}>
        <h4>{t('auditCampaigns.proposalDetail.footer.rejectNote')}</h4>
        <TextArea
          placeholder={t('auditCampaigns.proposalDetail.footer.rescheduleNotePlaceHolder')}
          onChange={event => {
            onChangeNote(event);
          }}
        />
      </Form>
      {isRepropose && (
        <Segment css={style.fieldContent}>
          <h4>Audit dates</h4>
          <div css={style.CalendarField}>
            <MultipleDateRangeField
              defaultProposedPeriods={proposedPeriodsState}
              editable
              onEdited={(proposedPeriods: ProposedPeriod[]) => onEdit(proposedPeriods)}
              title={t('brandAuditProposals.proposalDetail.availabilityPeriods') || ''}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </Segment>
      )}
    </div>
  );
}
