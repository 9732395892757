/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { fetchAuditors, selectAuditors } from '../store/auditorsSlice';
import { Auditor } from '../model/assessment';
import { useAppDispatch } from '../../../core/store';
import { ModalMultiSelectField } from '../../../shared/ModalMultiSelectField';
import { selectIsFetching } from '../store/assessmentsArchiveSlice';
import { SelectItem } from '../../../shared/ModalSelectField';
import { BrandCode } from '../../../shared/model/brand.model';

interface AuditorsAssessmentFieldProps {
  defaultValue?: Auditor[];
  editable?: boolean;
  onEdited?: (id: Auditor[]) => void;
  title?: string;
  mutable?: boolean;
  assessmentBrand: BrandCode;
}

export const AuditorsAssessmentField = ({
  defaultValue = [],
  editable = false,
  onEdited = () => null,
  title = '',
  mutable = false,
  assessmentBrand,
}: AuditorsAssessmentFieldProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auditors = useSelector(selectAuditors);
  const isFetching = useSelector(selectIsFetching);

  const extendedAuditors = useMemo(() => {
    const list = _.uniqBy([...auditors, ...defaultValue], e => e.email);
    return list.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  }, [auditors, defaultValue]);

  useEffect(() => {
    if (editable) {
      dispatch(fetchAuditors(assessmentBrand));
    }
  }, [dispatch, editable, assessmentBrand]);

  const toSelectItem = (item: Auditor): SelectItem => {
    return {
      code: item.email,
      description: item.name || item.email,
      additionalInfo: item.approvalRequired ? 'true' : 'false',
    };
  };
  const toAuditor = (item: SelectItem): Auditor => {
    return { email: item.code, name: item.description, approvalRequired: item.additionalInfo === 'true' };
  };

  return (
    <ModalMultiSelectField
      defaultValue={defaultValue.map(toSelectItem)}
      list={extendedAuditors.map(toSelectItem)}
      editable={editable}
      title={t('assessment.auditors') || ''}
      icon='users'
      loading={isFetching}
      onUpdateValue={items => onEdited(items.map(toAuditor))}
    />
  );
};
