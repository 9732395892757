import { Brand, toBrand } from './brand.model';
import { Contact, toContact } from '../../features/users/model/user.model';
import { Country } from './country.model';

export interface Store {
  city: string;
  address: string;
  country: string;
  region: string;
  keringRegion: string;
  keringCountry: Country;
  fullAddress: string;
  jdaCode: string;
  hfmCode: string;
  fullCode: string;
  name: string;
  brand: Brand;
  // todo refactor without email
  storeManager: Contact;
  // todo refactor without email
  assistantManager: Contact;
  employees: number;
  sellingSquareFeet: number;
}

export const toStore = (o: Record<string, unknown>): Store => {
  return {
    city: o.city as string,
    address: o.address as string,
    country: o.country as string,
    region: o.region as string,
    keringRegion: o.keringRegion as string,
    keringCountry: o.keringCountry as Country,
    fullAddress: `${o.address}, ${o.city}, ${(o.keringCountry as Country).description}`,
    jdaCode: o.jdaCode != null ? (o.jdaCode as string) : '',
    hfmCode: o.hfmCode != null ? (o.hfmCode as string) : '',
    fullCode: `${o.jdaCode}, ${o.hfmCode}`,
    name: o.locationName as string,
    brand: toBrand(o.brand as string),
    storeManager: toContact(o.storeManager as Record<string, unknown>),
    assistantManager: toContact(o.assistantManager as Record<string, unknown>),
    employees: o.employees as number,
    sellingSquareFeet: o.sellingSquareFeet as number,
  };
};
