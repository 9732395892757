import { css } from '@emotion/react';

const itemColor = css`
  && div {
    i {
      color: var(--saa-error-dark);
    }
    font-weight: 500;
  }
`;

export default {
  itemColor,
};
