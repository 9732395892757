import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const footerButton = css`
  && {
    ${style.primaryButton};
  }
  &&&&& {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;

const footer = css`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--saa-white-color);
  margin-top: 10px;
`;

const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;

export default {
  footer,
  footerButton,
  modalConfirm,
  confirmButton,
};
