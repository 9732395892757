/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { emptyRegion, Region } from '../../../../shared/model/region.model';
import { TextInputField } from '../../../assessments/components/TextInputField';
import { DistributionListAssessmentField } from '../../../assessments/components/DistributionListAssessmentField';
import { BrandField } from './BrandField';
import { RegionField } from './RegionField';
import { BrandSchedulersField } from './BrandSchedulersField';
import { Contact, emptyContact } from '../../../users/model/user.model';
import { AuditCampaign, emptyCampaign } from '../../model/auditCampaign';
import { useAppDispatch } from '../../../../core/store';
import { fetchBrands, selectBrands, selectCampaign } from '../../store/auditProposalWizardSlice';
import { Brand, BrandCode, emptyBrand } from '../../../../shared/model/brand.model';
import {
  fetchBrandSchedulers,
  selectBrandSchedulers,
  selectIsFetchingBrandSchedulers,
} from '../../store/brandSchedulersSlice';
import { ButtonConfiguration } from '../WizardFooter';
import { StepSegment } from '../StepSegment';
import { selectIsFetching } from '../../../users/store/usersSlice';
import { BrandScheduler } from '../../model/brandScheduler';
import { fetchKeringRegions, selectKeringRegions } from '../../../users/store/keringRegionsSlice';
import { Country } from '../../../../shared/model/country.model';
import { CountryField } from './CountryField';
import { fetchKeringCountries, selectKeringCountries } from '../../../users/store/keringCountriesSlice';

interface CampaignCreationStepProps {
  onCampaignSave: (data: AuditCampaign) => void;
  onBack: () => void;
}

export function CampaignCreationStep({ onCampaignSave, onBack }: CampaignCreationStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const initCampaign: AuditCampaign = emptyCampaign();
  const campaign = useSelector(selectCampaign);

  const [campaignName, setCampaignName] = useState(campaign?.name || '');
  const [campaignBrand, setCampaignBrand] = useState<Brand>(campaign?.brand || emptyBrand());
  const [campaignRegion, setCampaignRegion] = useState<Region>(campaign?.region || emptyRegion());
  const [campaignCountry, setCampaignCountry] = useState<Country | undefined>(campaign?.country);
  const [campaignBrandScheduler, setCampaignBrandScheduler] = useState(
    campaign != null && campaign.brandSchedulers.length > 0 ? campaign.brandSchedulers[0] : emptyContact()
  );
  const [campaignDistributionList, setCampaignDistributionList] = useState<Contact[]>(campaign?.distributionList || []);

  const regions: Region[] = useSelector(selectKeringRegions);
  const brands: Brand[] = useSelector(selectBrands);
  const countries: Country[] = useSelector(selectKeringCountries);
  const brandSchedulerList: BrandScheduler[] = useSelector(selectBrandSchedulers);
  const isFetchingBrandSchedulers = useSelector(selectIsFetchingBrandSchedulers);
  const isFetching = useSelector(selectIsFetching);

  const getBrandCampaignShortDescription = (code: BrandCode): string => {
    switch (code) {
      case BrandCode.Gucci:
        return 'GUC';
      case BrandCode.YvesSaintlaurent:
        return 'YSL';
      case BrandCode.BottegaVeneta:
        return 'BVE';
      case BrandCode.Balenciaga:
        return 'BAL';
      case BrandCode.AlexanderMcQueen:
        return 'AMQ';
      case BrandCode.Brioni:
        return 'BRI';
      case BrandCode.Pomellato:
        return 'PML';
      case BrandCode.Boucheron:
        return 'BOU';
      case BrandCode.Qeelin:
        return 'QEE';
      case BrandCode.Dodo:
        return 'DOD';
      default:
        return '';
    }
  };

  const isValidStep = useMemo(() => {
    return (
      campaignName !== '' &&
      campaignBrand.code !== '' &&
      campaignRegion.code !== '' &&
      campaignBrandScheduler.email !== ''
    );
  }, [campaignName, campaignBrand, campaignRegion, campaignBrandScheduler]);

  const isCampaignNameEditable = useMemo(
    () => campaignBrand.code !== '' && campaignRegion.code !== '',
    [campaignBrand.code, campaignRegion.code]
  );

  const retrieveBrandSchedulers = async (brandCode: string, regionDescription: string) => {
    await dispatch(fetchBrandSchedulers({ brand: brandCode, region: regionDescription }));
  };

  const newCampaign: AuditCampaign = useMemo(() => {
    return {
      ...initCampaign,
      name: campaignName,
      brandSchedulers: [campaignBrandScheduler],
      brand: campaignBrand,
      region: campaignRegion,
      country: campaignCountry,
      distributionList: campaignDistributionList,
    };
  }, [
    initCampaign,
    campaignName,
    campaignBrandScheduler,
    campaignBrand,
    campaignRegion,
    campaignCountry,
    campaignDistributionList,
  ]);

  const autoCampaignName = useMemo(
    () =>
      [
        new Date().toISOString().slice(0, 7).replace('-', '.'),
        getBrandCampaignShortDescription(campaignBrand.code as BrandCode),
        campaignRegion.description !== '' ? campaignRegion.description : '',
        campaignCountry?.description.toUpperCase().replaceAll(' ', '_') ?? '',
      ]
        .filter(v => v !== '')
        .join('.'),
    [campaignBrand.code, campaignCountry?.description, campaignRegion.description]
  );

  const buttonConfig = useMemo((): ButtonConfiguration[] => {
    return [
      {
        labelPosition: 'left',
        content: t('auditCampaigns.creation.back'),
        icon: 'angle left',
        onClick: onBack,
      },
      {
        labelPosition: 'right',
        disabled: !isValidStep,
        content: t('auditCampaigns.creation.next'),
        icon: 'angle right',
        onClick: () => onCampaignSave(newCampaign),
      },
    ];
  }, [onBack, onCampaignSave, newCampaign, isValidStep]);

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchKeringRegions());
  }, [dispatch]);

  useEffect(() => {
    setCampaignName(autoCampaignName);
  }, [isCampaignNameEditable, autoCampaignName]);

  return (
    <StepSegment footerConfiguration={buttonConfig}>
      <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
        <BrandField
          brandList={brands}
          loading={isFetching}
          defaultValue={campaignBrand}
          editable
          onSelect={(b: Brand) => {
            setCampaignBrand(b);
            retrieveBrandSchedulers(b.code, emptyRegion().description);
          }}
        />
        <RegionField
          regionList={regions}
          loading={isFetching}
          defaultValue={campaignRegion}
          editable
          onSelect={(r: Region) => {
            setCampaignRegion(r);
            setCampaignCountry(undefined);
            retrieveBrandSchedulers(campaignBrand.code, r.description);
            dispatch(fetchKeringCountries([r.description]));
          }}
        />
        <CountryField
          editable
          countryList={countries}
          loading={isFetching}
          onSelect={c => setCampaignCountry(c)}
          defaultValue={campaignCountry}
        />
        <BrandSchedulersField
          brandSchedulers={brandSchedulerList}
          loading={isFetchingBrandSchedulers}
          defaultValue={campaignBrandScheduler}
          editable={campaignBrand.code !== '' && campaignRegion.code !== ''}
          onSelect={brandScheduler => setCampaignBrandScheduler(brandScheduler)}
          assessmentBrand={campaign?.brand.code}
        />
        <DistributionListAssessmentField
          defaultValue={campaignDistributionList}
          editable
          onEdited={(selectedDistributionList: Contact[]) => {
            setCampaignDistributionList(selectedDistributionList);
          }}
          title={t('auditCampaigns.creation.distributionList') || ''}
        />
        <TextInputField
          icon='folder'
          defaultValue={isCampaignNameEditable ? campaignName : autoCampaignName}
          mutable
          editable={isCampaignNameEditable}
          title={t('auditCampaigns.creation.name')}
          placeholder='Name'
          label={{ icon: 'folder' }}
          onEdited={value => setCampaignName(value)}
        />
      </div>
    </StepSegment>
  );
}
