/** @jsxImportSource @emotion/react */

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import style from './tabs.style';

interface TabsProps {
  onChange: (tab: string) => void;
  tabs: string[];
  defaultTab: string;
  vertical?: boolean;
}

export const Tabs = ({ onChange, tabs, defaultTab, vertical = false }: TabsProps): JSX.Element => {
  const { t } = useTranslation();
  const tabsWithIcon = [t('assessment.tab.history')];
  const getIconForTab = (tab: string) => {
    switch (tab) {
      case t('assessment.tab.history'):
        return 'clock';
      default:
        return 'warning';
    }
  };

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const areSelected = useCallback((tab: string) => selectedTab === tab, [selectedTab]);
  const changeTab = useCallback(
    (tab: any) => {
      setSelectedTab(tab);
      onChange(tab);
    },
    [onChange]
  );
  return (
    <div style={!vertical ? { display: 'flex' } : {}}>
      {tabs.map((tab, index) => (
        <>
          {tabsWithIcon.includes(tab) && (
            <div
              css={style.tab(areSelected(tab), index === tabs.length - 1, true)}
              onClick={() => changeTab(tab)}
              key={tab}>
              <Icon css={style.icon} name={getIconForTab(tab)} />
            </div>
          )}
          <div
            css={style.tab(areSelected(tab), index === tabs.length - 1, false, tabsWithIcon.includes(tab))}
            onClick={() => changeTab(tab)}
            key={tab}>
            {tab}
          </div>
        </>
      ))}
    </div>
  );
};
