/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { AssessmentArchiveFilters } from '../../model/assessmentArchiveQueryParams';
import {
  fetchReadOnlyDistributionListMembers,
  selectReadOnlyDistributionListMembers,
  selectIsFetching,
} from '../../store/assessmentsArchiveSlice';
import { Contact } from '../../../users/model/user.model';

interface ReadOnlyDistributionListMultiSelectFieldProps {
  filters: AssessmentArchiveFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function ReadOnlyDistributionListMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: ReadOnlyDistributionListMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchReadOnlyDistributionListMembers(filters));
    }
  }, [dispatch, editable, filters]);

  const items = useSelector(selectReadOnlyDistributionListMembers);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Contact): SelectItem => {
    return {
      code: item.email,
      description: item.email,
      subdescription: item.name,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='mail'
      loading={isFetching}
      defaultValue={
        defaultValue != null ? items.filter(c => defaultValue.includes(c.email)).map(c => toSelectItem(c)) : []
      }
      list={items.map((c): SelectItem => toSelectItem(c) as SelectItem)}
      title={t('assessment.archive.filters.readOnlyDistributionListMembers') || ''}
      editable={editable}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(i => toString(i)));
        }
      }}
    />
  );
}
