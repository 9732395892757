/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice } from '@reduxjs/toolkit';

export const assessmentsFilterSlice = createSlice({
  name: 'assessmentsFilter',
  initialState: {
    value: '',
  },
  reducers: {
    change: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
  },
});

export const { change } = assessmentsFilterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAssessmentsFilter = (state: any) => state.assessmentsFilter.value;

export default assessmentsFilterSlice.reducer;
