import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const checkBoxContainer = css`
  && {
    border-bottom: solid 1px var(--saa-primary-color-10);
    padding: 10px;
  }
`;

const container = css`
  padding-right: 20px;
  padding-left: 20px;
  overflow: auto;
`;

const checkBox = css`
  && {
    width: 100%;
    ${globalStyle.bodyNormal}
    input {
      top: 4px;
    }
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      :before {
        top: 3px;
      }
    }
  }
`;

const noItems = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  checkBoxContainer,
  checkBox,
  container,
  noItems,
};
