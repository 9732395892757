import { css } from '@emotion/react';

const component = css`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const storeDetail = css`
  margin-bottom: 10px;
  background-color: var(--saa-white-color);
`;
const assessmentDetail = css`
  background-color: var(--saa-white-color);
`;

const content = css`
  background-color: var(--saa-primary-color-10);
  color: var(--saa-primary-color);
  height: 100%;
`;

const body = css`
  height: calc(100% - 60px);
  overflow: auto;
  margin: 10px 0;
`;

const footer = css`
  display: flex;
  margin-top: auto;
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  padding: 16px;
  justify-content: flex-end;
  height: 60px;
`;

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

export default {
  component,
  body,
  content,
  footer,
  button,
  storeDetail,
  assessmentDetail,
};
