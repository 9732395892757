/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuditorUpdateRequest } from '../model/assessmentDetail.model';
import offlineApi, { isNetworkError } from '../utils/offlineApi';

interface AuditorUpdateRequestsSliceState {
  value: AuditorUpdateRequest[];
  error: string;
  synchronizing: boolean;
}

const initialState = {
  value: [],
  error: '',
  synchronizing: false,
} as AuditorUpdateRequestsSliceState;

export const auditorUpdateRequestsSlice = createSlice({
  name: 'auditorUpdateRequests',
  initialState,
  reducers: {
    add: (state: Draft<AuditorUpdateRequestsSliceState>, { payload }: PayloadAction<AuditorUpdateRequest>) => {
      const newValue = [...state.value];
      const elementIndex = newValue.findIndex(
        v =>
          v.processCode === payload.processCode &&
          v.subProcessCode === payload.subProcessCode &&
          v.assessmentId === payload.assessmentId &&
          v.auditorEmail === payload.auditorEmail
      );
      if (elementIndex >= 0) {
        newValue.splice(elementIndex, 1, payload);
      } else {
        newValue.push(payload);
      }
      return {
        ...state,
        value: newValue,
      };
    },
    remove: (state: Draft<AuditorUpdateRequestsSliceState>, { payload }: PayloadAction<AuditorUpdateRequest>) => {
      const newValue = [...state.value];
      const elementIndex = newValue.findIndex(
        v =>
          v.assessmentId === payload.assessmentId &&
          v.processCode === payload.processCode &&
          v.subProcessCode === payload.subProcessCode &&
          v.auditorEmail === payload.auditorEmail
      );
      if (elementIndex >= 0) {
        newValue.splice(elementIndex, 1);
      }
      return {
        ...state,
        value: newValue,
      };
    },
    clear: () => initialState,
    setSynchronizing: (state, { payload }: PayloadAction<boolean>) => ({ ...state, synchronizing: payload }),
  },
});

export const { add, remove, setSynchronizing } = auditorUpdateRequestsSlice.actions;

export const addAndSyncRequests =
  (auditorUpdateRequest: AuditorUpdateRequest) =>
  async (dispatch: any, state: () => any): Promise<void> => {
    dispatch(add(auditorUpdateRequest));
    dispatch(saveAuditorUpdateRequests());
  };

export const saveAuditorUpdateRequests =
  () =>
  async (dispatch: any, state: () => any): Promise<void> => {
    const currentUser = state().principal.data;

    // removes eventually requests of another user
    const invalidRequests = state().auditorUpdateRequests.value.filter(
      (aur: AuditorUpdateRequest) => aur.auditorEmail !== currentUser.email
    ) as AuditorUpdateRequest[];
    invalidRequests.forEach(ir => dispatch(remove(ir)));

    const requests = state().auditorUpdateRequests.value;

    if (requests.length > 0) {
      dispatch(setSynchronizing(true));
    }

    await Promise.all(
      requests.map(async (aur: AuditorUpdateRequest) => {
        await offlineApi
          .saveAuditingResult(aur.assessmentId, aur)
          .then(response => dispatch(remove(aur)))
          .catch((e: AxiosError) => {
            if (!isNetworkError(e)) {
              dispatch(remove(aur));
              dispatch(setSynchronizing(false));
              throw e;
            }
          });
      })
    );

    if (state().auditorUpdateRequests.synchronizing) {
      setTimeout(() => dispatch(setSynchronizing(false)), 2000);
    }
  };

export const selectAuditorUpdateRequests = (state: any) => state.auditorUpdateRequests.value;

export const selectAllRequestsSynchronized = createSelector(
  selectAuditorUpdateRequests,
  (auditorUpdateRequests: AuditorUpdateRequest[]) => auditorUpdateRequests.length === 0
);

export const selectSynchronizing = (state: any) => state.auditorUpdateRequests.synchronizing;

export default auditorUpdateRequestsSlice.reducer;
