/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react';
import parse from 'html-react-parser';
import style from './checkList.style';
import { ActionPlanEditor } from '../model/assessment';
import { ActionPlanEditorSelector } from './ActionPlanEditorSelector';

interface FooterProps {
  isActive?: boolean;
  onSave: () => void;
  onSubmit?: () => void;
  info?: string;
  canSave?: boolean;
  actionPlanEditor?: ActionPlanEditor | null;
  canSubmit?: boolean;
  canAssign?: boolean;
  onAssign?: (value: ActionPlanEditor) => void;
  confirmationEnable?: boolean;
  confirmationText?: string;
  showPastExamplesButton?: boolean;
  onClickPastExamplesButton?: () => void;
}

export const SubProcessFooter = ({
  isActive = true,
  onSave,
  onSubmit = () => null,
  info = '',
  canSave = false,
  actionPlanEditor = null,
  canSubmit = false,
  canAssign = false,
  onAssign = () => null,
  confirmationEnable = false,
  confirmationText = '',
  showPastExamplesButton = false,
  onClickPastExamplesButton = () => null,
}: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const onSaveClick = () => {
    if (confirmationEnable) {
      setConfirmIsOpen(true);
    } else {
      onSave();
    }
  };

  return (
    <>
      <div css={style.footer}>
        {info !== '' && (
          <Button css={style.infoButton} onClick={() => setOpen(true)} icon>
            <Icon css={style.infoIcon} className='fas fa-info-circle' />
          </Button>
        )}

        {canAssign && <ActionPlanEditorSelector editor={actionPlanEditor} onSelect={onAssign} />}

        {canSubmit ? (
          <Button css={style.submitAndSave} onClick={onSubmit} disabled={!isActive}>
            <Icon name='check' />
            {t('assessment.checkList.submitAuthor')}
          </Button>
        ) : (
          <></>
        )}
        {showPastExamplesButton && (
          <Button css={style.pastExamplesButton} onClick={onClickPastExamplesButton}>
            <Icon name='clock' />
            {t('assessment.checkList.pastExamples')}
          </Button>
        )}
        {canSave ? (
          <>
            <Button css={style.saveButton} onClick={onSaveClick}>
              <Icon name='save' />
              {t('assessment.checkList.save')}
            </Button>
            <Confirm
              css={style.modalConfirm}
              open={confirmIsOpen}
              onCancel={() => setConfirmIsOpen(false)}
              content={confirmationText}
              onConfirm={() => {
                setConfirmIsOpen(false);
                onSave();
              }}
              size='small'
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {info !== '' && (
        <Modal css={style.infoModal} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
          <Modal.Header>{t('assessment.checkList.info')}</Modal.Header>
          <Modal.Content css={style.infoModalContent}>
            <div style={{ overflow: 'auto' }}>{parse(info)}</div>
          </Modal.Content>
          <Modal.Actions css={style.actions}>
            <Button css={style.button} onClick={() => setOpen(false)}>
              <Icon name='cancel' /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
