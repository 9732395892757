/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Icon, Modal, Radio } from 'semantic-ui-react';
import { selectSelectedAssessments, selectSortedAndFilteredResults } from '../../store/assessmentBulkUpdateSlice';
import style from './assessmentsBulkUpdateWizard.style';
import { AssessmentBulkUpdateWizardField, AssessmentBulkUpdateWizardStep } from '../../model/assessmentBulkUpdate';

interface SelectFieldToEditStepProps {
  onClose: () => void;
  setStep: (step: AssessmentBulkUpdateWizardStep) => void;
  field: AssessmentBulkUpdateWizardField | null;
  setField: (value: AssessmentBulkUpdateWizardField) => void;
}

export const SelectFieldToEditStep = ({
  onClose,
  setStep,
  field,
  setField,
}: SelectFieldToEditStepProps): JSX.Element => {
  const { t } = useTranslation();
  const selectedAssessments = useSelector(selectSelectedAssessments);
  const assessments = useSelector(selectSortedAndFilteredResults);

  const isOnlyOneBrandSelected = useCallback((): boolean => {
    const brands = assessments.filter(a => selectedAssessments.includes(a.id)).map(a => a.brand);
    return _.uniqBy(brands, 'code').length <= 1;
  }, [assessments, selectedAssessments]);

  return (
    <>
      <Modal.Header>
        <div css={style.header}>
          <div>{t('assessment.bulkUpdate.wizard.steps.SELECT_FIELD_TO_EDIT')}</div>
          <div css={style.headerIcon} onClick={onClose}>
            <Icon className='fas fa-close' />
          </div>
        </div>
      </Modal.Header>

      <Modal.Content css={style.modalContent}>
        <div css={style.radioGroup}>
          {Object.values(AssessmentBulkUpdateWizardField).map(f => (
            <Radio
              label={t(`assessment.bulkUpdate.wizard.fields.${f}`)}
              css={style.radioButton}
              checked={f === field}
              onChange={() => setField(f)}
              disabled={f === AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS && !isOnlyOneBrandSelected()}
            />
          ))}
        </div>
        {!isOnlyOneBrandSelected() && (
          <div css={style.disclaimer}>{t('assessment.bulkUpdate.wizard.brandCoordinatorsDisclaimer')}</div>
        )}
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button
          css={style.button}
          onClick={() => setStep(AssessmentBulkUpdateWizardStep.SELECT_ACTION)}
          disabled={field == null}>
          {t('assessment.bulkUpdate.wizard.buttons.next')}
        </Button>
      </Modal.Actions>
    </>
  );
};
