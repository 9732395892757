import { css } from '@emotion/react';

const container = css`
  background-color: var(--saa-primary-color-10);
  height: 100%;
`;

export default {
  container,
};
