/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { fetchFollowUpStatuses, selectFollowUpStatuses, selectIsFetching } from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';

interface StatusMultiSelectFieldProps {
  filters: AssessmentBulkUpdateFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (brand: string[]) => void;
}

export function FollowUpStatusMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: StatusMultiSelectFieldProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (editable) {
      dispatch(fetchFollowUpStatuses(filters));
    }
  }, [dispatch, editable, filters]);

  const items = useSelector(selectFollowUpStatuses);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = useCallback(
    (item: string): SelectItem => {
      return {
        code: item,
        description: t(`assessment.followUpStatus.${item}`),
      };
    },
    [t]
  );

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='clipboard check'
      loading={isFetching}
      defaultValue={defaultValue != null ? defaultValue.map(toSelectItem) : []}
      list={items.map(toSelectItem)}
      title={t('assessment.bulkUpdate.filters.followUpStatuses') || ''}
      editable={editable}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(toString));
        }
      }}
    />
  );
}
