/** @jsxImportSource @emotion/react */
import React from 'react';
import { Audit, FollowUpStatus } from '../model/assessmentDetail.model';
import { STATUS, Status } from '../model/assessmentStatus';
import { InProgressAuditDetail } from './InProgressAuditDetail';
import { MergingAuditDetail } from './MergingAuditDetail';
import { StandardAuditDetail } from './StandardAuditDetail';
import { SentToTheStoresAuditDetail } from './SentToTheStoresAuditDetail';

interface AuditDetailProps {
  assessmentStatus: Status;
  audit: Audit;
  followUpStatus: FollowUpStatus;
}

export const AuditDetail = ({ assessmentStatus, audit, followUpStatus }: AuditDetailProps): JSX.Element => {
  switch (assessmentStatus.code) {
    case STATUS.IN_PROGRESS.code:
      return <InProgressAuditDetail audit={audit} followUpStatus={followUpStatus} />;
    case STATUS.MERGING.code:
      return <MergingAuditDetail audit={audit} followUpStatus={followUpStatus} />;
    case STATUS.SENT_TO_THE_STORES.code:
      return (
        <SentToTheStoresAuditDetail audit={audit} assessmentStatus={assessmentStatus} followUpStatus={followUpStatus} />
      );
    default:
      return <StandardAuditDetail audit={audit} assessmentStatus={assessmentStatus} followUpStatus={followUpStatus} />;
  }
};
