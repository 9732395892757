/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import './App.css';
import './i18n';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Login } from './features/auth/pages/Login';
import { OAUTH_CLIENT_ID, REFRESH_OAUTH_TOKEN, OAUTH_TOKEN_URL, REDIRECT_URI } from './constants';
import {
  getJwtToken,
  getRedirectPagePath,
  resetRedirectPagePath,
  setJwtToken,
} from './core/services/webStorageService';
import api from './features/auth/utils/api';
import { OauthReceiver } from './features/auth/components/OauthReceiver';
import { PrivateRoute } from './core/components/PrivateRoute';
import { Page } from './shared/Page';
import { fetchPrincipal } from './features/auth/store/principalSlice';
import { Assessments } from './features/assessments/pages/Assessments';
import { AssessmentDetail } from './features/assessments/pages/AssessmentDetail';
import { PageNoHeader } from './shared/PageNoHeader';
import { Users } from './features/users/pages/Users';
import { useAppDispatch } from './core/store';
import { AssessmentsArchive } from './features/assessments/pages/AssessmentsArchive';
import { AuditCampaigns } from './features/assessmentsPlanning/pages/AuditCampaigns';
import { AuditProposalDetail } from './features/assessmentsPlanning/pages/AuditProposalDetail';
import { AuditCampaignDetail } from './features/assessmentsPlanning/pages/AuditCampaignDetail';
import { AssessmentsReport } from './features/assessmentsReport/pages/AssessmentsReport';
import { BrandAuditProposals } from './features/assessmentsPlanning/pages/BrandAuditProposals';
import { AuditProposalsAgenda } from './features/assessmentsPlanning/pages/AuditProposalsAgenda';
import { BrandAuditProposalDetail } from './features/assessmentsPlanning/pages/BrandAuditProposalDetail';
import { AssessmentsFollowUp } from './features/assessments/pages/AssessmentsFollowUp';
import { AssessmentsBulkUpdate } from './features/assessmentsBulkUpdate/pages/AssessmentsBulkUpdate';

const App = (): JSX.Element => {
  const [refreshEnabled, setRefreshEnabled] = useState(false);
  const dispatchUser = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const refreshAndSetTimeout = async () => {
    const response = await api.refreshToken();
    setTokenAndTimeout(response.data.access_token);
  };

  const setTokenAndTimeout = (token: string) => {
    setJwtToken(token);
    setTimeout(async () => {
      await refreshAndSetTimeout();
    }, REFRESH_OAUTH_TOKEN);
    setRefreshEnabled(true);
    dispatchUser(fetchPrincipal());
  };

  useEffect(() => {
    const token = getJwtToken();
    if (!refreshEnabled && token) {
      refreshAndSetTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Navigate to='/assessments' />
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments'
        element={
          <PrivateRoute>
            <Page title={t('assessment.title')}>
              <Assessments />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments-archive'
        element={
          <PrivateRoute>
            <Page title={t('assessment.archive.title')}>
              <AssessmentsArchive />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments/:id'
        element={
          <PrivateRoute>
            <PageNoHeader>
              <AssessmentDetail />
            </PageNoHeader>
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments-report'
        element={
          <PrivateRoute>
            <Page title={t('assessment.report.title')}>
              <AssessmentsReport />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments-bulk-update'
        element={
          <PrivateRoute>
            <Page title={t('assessment.bulkUpdate.title')}>
              <AssessmentsBulkUpdate />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/assessments-followup'
        element={
          <PrivateRoute>
            <Page title={t('assessment.followUp.title')}>
              <AssessmentsFollowUp />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/audit-campaigns'
        element={
          <PrivateRoute>
            <Page title={t('auditCampaigns.title')}>
              <AuditCampaigns />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/audit-campaigns/:campaignId'
        element={
          <PrivateRoute>
            <PageNoHeader>
              <AuditCampaignDetail />
            </PageNoHeader>
          </PrivateRoute>
        }
      />
      <Route
        path='/audit-campaigns/:campaignId/internal-audit-proposals/:proposalId'
        element={
          <PrivateRoute>
            <PageNoHeader>
              <AuditProposalDetail />
            </PageNoHeader>
          </PrivateRoute>
        }
      />
      <Route
        path='/brand-audit-proposals'
        element={
          <PrivateRoute>
            <Page title={t('brandAuditProposals.title')}>
              <BrandAuditProposals />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/audit-proposals-agenda'
        element={
          <PrivateRoute>
            <Page title={t('auditProposalsAgenda.title')}>
              <AuditProposalsAgenda />
            </Page>
          </PrivateRoute>
        }
      />
      <Route
        path='/brand-audit-proposals/:proposalId'
        element={
          <PrivateRoute>
            <PageNoHeader>
              <BrandAuditProposalDetail />
            </PageNoHeader>
          </PrivateRoute>
        }
      />
      <Route
        path='/users'
        element={
          <PrivateRoute>
            <Page title={t('users.title')}>
              <Users />
            </Page>
          </PrivateRoute>
        }
      />
      <Route path='/login' element={<Login />} />
      <Route
        path='/login-callback'
        element={
          <OauthReceiver
            location={location}
            tokenUrl={OAUTH_TOKEN_URL}
            redirectUri={REDIRECT_URI}
            clientId={OAUTH_CLIENT_ID}
            onAuthSuccess={(token: string) => {
              setTokenAndTimeout(token);
            }}
            onAuthError={err => {
              navigate('login-error');
            }}
            render={(processing: boolean) => {
              const redirectPath = getRedirectPagePath();
              setTimeout(resetRedirectPagePath, 2000);
              return processing ? (
                <Dimmer active>
                  <Loader size='big' />
                </Dimmer>
              ) : (
                <Navigate to={redirectPath} />
              );
            }}
          />
        }
      />
      <Route path='/login-error' element={<LoginError />} />
    </Routes>
  );
};

const LoginError = (): JSX.Element => {
  const container = css`
            width: 100%;  
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--saa-primary-color-10);
            div{
              font-size: 1.2rem;
              padding: 20px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--saa-white-color);
              height: 220px;
              width: 100%;
              max-width: 1200px;
            }
          `;
  return (
    <div css={container}>
      <div>
        Your account is not authorized to login Boutique Audit
        <br />
        <br />
        Contact the administrator
      </div>
    </div>
  );
};

export default App;
