/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import style from '../footerButtons/detailFooter.style';
import {
  AuditProposal,
  canChangeProposalStatus,
  ChangeStatusRequest,
  isConfiguredAuditor,
  isConfiguredBrandScheduler,
  isProposalAuthor,
} from '../../../model/auditProposal';
import { changeStatus, selectAuditProposal, selectCampaignId } from '../../../store/auditProposalDetailSlice';
import { useAppDispatch } from '../../../../../core/store';
import { selectPrincipal } from '../../../../auth/store/principalSlice';
import { ApproveButton } from '../footerButtons/ApproveButton';
import { RejectButton } from '../footerButtons/RejectButton';
import { ReproposeButton } from '../footerButtons/ReproposeButton';
import { CreateAssessmentButton } from '../footerButtons/CreateAssessmentButton';
import { AssessmentCreationFromProposalRequest } from '../../../../assessments/model/assessment';
import { INTERNAL_AUDIT_PROPOSAL_STATUS, ProposedPeriod } from '../../../model/genericAuditProposal';
import { RescheduleButton } from '../footerButtons/RescheduleButton';

export function AuditProposalDetailFooter(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const campaignId = useSelector(selectCampaignId);
  const proposal = useSelector(selectAuditProposal) as AuditProposal;
  const principal = useSelector(selectPrincipal);

  const areDatesInRange = useMemo((): boolean => {
    if (proposal.proposedPeriods.length === 0) return true;

    const rangeList = proposal.proposedPeriods.map(range => {
      return {
        dateFrom: new Date(new Date(range.dateFrom).setHours(0)).getTime(),
        dateTo: new Date(new Date(range.dateTo).setHours(0)).getTime(),
      };
    });
    const dateTimeFrom = new Date(new Date(proposal.proposedDateFrom).setHours(0)).getTime();
    const dateTimeTo = new Date(new Date(proposal.proposedDateTo).setHours(0)).getTime();

    let result = false;
    rangeList.forEach(range => {
      if (
        dateTimeFrom >= range.dateFrom &&
        dateTimeFrom <= range.dateTo &&
        dateTimeTo >= dateTimeFrom &&
        dateTimeTo <= range.dateTo
      ) {
        result = true;
      }
    });

    return result;
  }, [proposal.proposedDateFrom, proposal.proposedDateTo, proposal.proposedPeriods]);

  const isConfiguredAuditorOrAuthor = useMemo(
    () => isConfiguredAuditor(principal, proposal) || isProposalAuthor(principal, proposal),
    [principal, proposal]
  );
  const isConfiguredBScheduler = useMemo(() => isConfiguredBrandScheduler(principal, proposal), [principal, proposal]);

  const handleChangeStatus = useCallback(
    (changeStatusRequest: ChangeStatusRequest, onSuccess?: (proposal: AuditProposal) => void) => {
      dispatch(changeStatus(campaignId, proposal.id, changeStatusRequest)).then(updatedProposal => {
        if (updatedProposal == null) return;
        if (onSuccess) {
          onSuccess(updatedProposal);
        }
      });
    },
    [campaignId, dispatch, proposal.id]
  );

  const onAuditorRescheduleConfirm = useCallback(
    (from: string, to: string, note: string, isRepropose: boolean) => {
      handleChangeStatus({ status: INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED, note, dateFrom: from, dateTo: to });
    },
    [handleChangeStatus]
  );

  const onBrandRescheduleConfirm = useCallback(
    (proposedPeriods: ProposedPeriod[], note: string, isRepropose: boolean) => {
      let request: ChangeStatusRequest;
      if (isRepropose) {
        request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND, note, proposedPeriods };
      } else {
        request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND, note };
      }
      handleChangeStatus(request);
    },
    [handleChangeStatus]
  );

  const onReproposeConfirm = useCallback(
    (proposedPeriods: ProposedPeriod[], note: string, isRepropose: boolean) => {
      let request: ChangeStatusRequest;
      if (isRepropose) {
        request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND, note, proposedPeriods };
      } else {
        request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND, note };
      }
      handleChangeStatus(request);
    },
    [handleChangeStatus]
  );

  const onCreateAssessment = useCallback(
    (assessmentCreationRequest: AssessmentCreationFromProposalRequest) => {
      const request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.CLOSED, assessment: assessmentCreationRequest };
      const onSuccess = (updatedProposal: AuditProposal) => {
        if (updatedProposal.assessment) {
          navigate(`/assessments/${updatedProposal.assessment?.id}`);
        }
      };
      handleChangeStatus(request, onSuccess);
    },
    [handleChangeStatus, navigate]
  );

  if (proposal && canChangeProposalStatus(principal, proposal)) {
    switch (proposal.status) {
      case INTERNAL_AUDIT_PROPOSAL_STATUS.DRAFT: {
        const approveRequest: ChangeStatusRequest = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED };
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup} />
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('auditCampaigns.proposalDetail.footer.proposeButton')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }

      case INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED: {
        const onRejectConfirm = (note: string) => {
          const request = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER, note };
          handleChangeStatus(request);
        };

        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              <RejectButton
                buttonLabel={t('auditCampaigns.proposalDetail.footer.rejectButton')}
                onConfirm={onRejectConfirm}
              />
            </div>
            <div css={style.footerContainerGroup} />
          </div>
        );
      }

      case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      case INTERNAL_AUDIT_PROPOSAL_STATUS.REJECTED_BY_BRAND:
      case INTERNAL_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_BRAND: {
        const approveRequest: ChangeStatusRequest = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED };

        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup} />
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('auditCampaigns.proposalDetail.footer.proposeButton')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }

      case INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER: {
        const approveRequest: ChangeStatusRequest = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED };

        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              <ReproposeButton
                buttonLabel={t('auditCampaigns.proposalDetail.footer.reproposeOrRejectButton')}
                onConfirm={onReproposeConfirm}
              />
            </div>
            <div css={style.footerContainerGroup}>
              <ApproveButton
                buttonLabel={t('auditCampaigns.proposalDetail.footer.approveButton')}
                onConfirm={() => handleChangeStatus(approveRequest)}
                confirmMessage={
                  !areDatesInRange
                    ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage') || ''
                    : t('auditCampaigns.proposalDetail.footer.confirmationMessage') || ''
                }
              />
            </div>
          </div>
        );
      }

      case INTERNAL_AUDIT_PROPOSAL_STATUS.SCHEDULED: {
        return (
          <div css={style.footerContainer}>
            <div css={style.footerContainerGroup}>
              {isConfiguredAuditorOrAuthor && (
                <RescheduleButton
                  buttonLabel={t('auditCampaigns.proposalDetail.footer.rescheduleButton')}
                  onBrandConfirm={onBrandRescheduleConfirm}
                  onAuditConfirm={onAuditorRescheduleConfirm}
                  confirmHeader={t('auditCampaigns.proposalDetail.footer.rescheduleButton') || ''}
                  canReject={false}
                  defaultDateFrom={proposal.proposedDateFrom}
                  defaultDateTo={proposal.proposedDateTo}
                  isBrandContent={false}
                />
              )}
              {isConfiguredBScheduler && (
                <RescheduleButton
                  buttonLabel={t('auditCampaigns.proposalDetail.footer.rescheduleButton')}
                  onBrandConfirm={onBrandRescheduleConfirm}
                  onAuditConfirm={onAuditorRescheduleConfirm}
                  confirmHeader={t('auditCampaigns.proposalDetail.footer.rescheduleButton') || ''}
                  canReject
                  defaultDateFrom={proposal.proposedDateFrom}
                  defaultDateTo={proposal.proposedDateTo}
                  isBrandContent
                />
              )}
            </div>
            {isConfiguredAuditorOrAuthor && (
              <div css={style.footerContainerGroup}>
                <CreateAssessmentButton
                  proposal={proposal}
                  buttonLabel={t('auditCampaigns.proposalDetail.footer.createAssessmentButton')}
                  onCreate={onCreateAssessment}
                />
              </div>
            )}
          </div>
        );
      }
      default:
        return <></>;
    }
  }
  return <></>;
}
