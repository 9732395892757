/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { StoreHeader } from '../../../../../shared/StoreHeader';
import { StoreFields } from '../../../../../shared/StoreFields';
import { CalendarField } from '../../../../assessments/components/CalendarField';
import { DistributionListAssessmentField } from '../../../../assessments/components/DistributionListAssessmentField';
import style from './brandAuditProposalDetailFields.style';
import {
  BrandAuditProposal,
  isAuthor,
  isConfiguredBrandScheduler,
  checkStatus,
  canEditAuditDates,
  canEditBrandSchedulerOrAuditors,
  isConfiguredAuditor,
} from '../../../model/brandAuditProposal';
import { SimpleField } from '../../../../../shared/SimpleField';
import { useAppDispatch } from '../../../../../core/store';
import {
  onSelectBrandScheduler,
  onUpdateDistributionList,
  updateBrandProposalAuditors,
  updateBrandProposalDate,
  updateProposedByBrandDate,
} from '../../../store/brandAuditProposalDetailSlice';
import { selectPrincipal } from '../../../../auth/store/principalSlice';
import { checkRole, ROLES } from '../../../../auth/model/principal.model';
import { BrandSchedulersField } from '../../internalAuditWizard/BrandSchedulersField';
import { Contact } from '../../../../users/model/user.model';
import { AuditorsAssessmentField } from '../../../../assessments/components/AuditorsAssessmentField';
import { AuditNotes } from '../../../../assessments/components/AuditNotes';
import { BRAND_AUDIT_PROPOSAL_STATUS, ProposedPeriod } from '../../../model/genericAuditProposal';
import { BrandCode } from '../../../../../shared/model/brand.model';
import { selectBrandSchedulers, selectIsFetchingBrandSchedulers } from '../../../store/brandSchedulersSlice';
import { getDate } from '../../../../../core/utils';
import { MultipleDateRangeField } from '../../../../../shared/MultipleDateRangeField';

interface BrandAuditProposalDetailFieldsProps {
  proposal: BrandAuditProposal;
  editable?: boolean;
  onCalendarChange?: (from: string, to: string) => void;
  onBrandCalendarChange?: (proposedPeriods: ProposedPeriod[]) => void;
  onDistributionListChange?: (values: Contact[]) => void;
}

export const BrandAuditProposalDetailFields = ({
  proposal,
  editable = false,
  onCalendarChange,
  onBrandCalendarChange,
  onDistributionListChange,
}: BrandAuditProposalDetailFieldsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { store } = proposal;
  const brandSchedulerList = useSelector(selectBrandSchedulers);
  const isFetching = useSelector(selectIsFetchingBrandSchedulers);
  const principal = useSelector(selectPrincipal);

  const isEditMode = editable && proposal.id !== '';
  const isCreationMode = editable && proposal.id === '';

  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>(proposal.proposedPeriods);
  const isAuditDatesEditable = editable && canEditAuditDates(principal, proposal);
  const isDistributionListEditable = editable;
  const isBrandSchedulerEditable = editable && canEditBrandSchedulerOrAuditors(principal, proposal);
  const isAuditorsEditable = editable && canEditBrandSchedulerOrAuditors(principal, proposal);

  const minDate = getDate();
  const [distributionList, setDistributionList] = useState<Contact[]>([]);

  useEffect(() => {
    if (proposal.proposedPeriods && proposal.proposedPeriods.length > 0) {
      setProposedPeriodsState(proposal.proposedPeriods);
    }
  }, [proposal]);

  const onDateUpdate = async (from?: string, to?: string) => {
    if (isCreationMode && onCalendarChange) {
      onCalendarChange(from || '', to || '');
    } else if (editable) {
      dispatch(updateBrandProposalDate(from || '', to || ''));
    }
  };

  const onBrandDateUpdate = async (proposedPeriods: ProposedPeriod[]) => {
    setProposedPeriodsState(proposedPeriods);

    if (isCreationMode && onBrandCalendarChange) {
      onBrandCalendarChange(proposedPeriods);
    } else if (isEditMode) {
      dispatch(updateProposedByBrandDate(proposedPeriods));
    }
  };

  const onDistributionListUpdate = (changedDL: Contact[]) => {
    setDistributionList(changedDL);
    if (isCreationMode && onDistributionListChange) {
      onDistributionListChange(changedDL);
    } else if (isEditMode) {
      dispatch(
        onUpdateDistributionList(
          proposal.id,
          changedDL.map(c => c.email)
        )
      );
    }
  };

  const onAuditorsUpdate = (changedAuditors: Contact[]) => {
    dispatch(updateBrandProposalAuditors(proposal.id, changedAuditors));
  };

  const isNoteVisible =
    (isAuthor(principal, proposal) ||
      isConfiguredAuditor(principal, proposal) ||
      checkRole(principal, ROLES.AUDITOR_MANAGER)) &&
    checkStatus(proposal, [
      BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER,
      BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED,
      BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED,
      BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER,
    ]);

  const isAuditorNoteVisible =
    (isAuthor(principal, proposal) ||
      isConfiguredAuditor(principal, proposal) ||
      isConfiguredBrandScheduler(principal, proposal) ||
      checkRole(principal, ROLES.AUDITOR_MANAGER)) &&
    checkStatus(proposal, [
      BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER,
      BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED,
    ]);

  return (
    <div css={style.body}>
      <div css={style.content}>
        <div css={style.storeDetail}>
          <StoreHeader store={store} />
          <StoreFields store={store} />
        </div>
        <div css={style.proposalDetail}>
          <SimpleField
            iconClassName='exchange'
            value={t(`brandAuditProposals.status.${proposal.status}`) || ''}
            title={t('brandAuditProposals.proposalDetail.proposalStatus')}
          />
          <ProposedByBrandDates
            proposedPeriods={proposedPeriodsState}
            minDate={minDate}
            onChange={(proposedPeriods: ProposedPeriod[]) => onBrandDateUpdate(proposedPeriods)}
            editable={isCreationMode}
          />
          {isEditMode && (
            <div css={style.rowMultipleField}>
              <CalendarField
                customIcon='calendar alternate'
                defaultValue={proposal.proposedDateFrom}
                editable={isAuditDatesEditable}
                onChange={changedDateFrom => onDateUpdate(changedDateFrom, undefined)}
                title={t('brandAuditProposals.proposalDetail.auditDateFrom')}
                minDate={minDate}
                mutable
              />
              <CalendarField
                defaultValue={proposal.proposedDateTo}
                editable={isAuditDatesEditable}
                onChange={changedDateTo => onDateUpdate(proposal.proposedDateFrom, changedDateTo)}
                title={t('brandAuditProposals.proposalDetail.auditDateTo')}
                minDate={minDate}
                mutable
              />
            </div>
          )}
          <AuditorsAssessmentField
            defaultValue={proposal.auditors}
            editable={isEditMode && isAuditorsEditable}
            onEdited={changedAuditors => onAuditorsUpdate(changedAuditors)}
            title={t('brandAuditProposals.proposalDetail.auditors') || ''}
            mutable
            assessmentBrand={proposal.store.brand.code as BrandCode}
          />
          <BrandSchedulersField
            editable={isEditMode && isBrandSchedulerEditable}
            brandSchedulers={brandSchedulerList}
            loading={isFetching}
            defaultValue={
              isCreationMode
                ? { name: `${principal.firstName} ${principal.surname}`, email: principal.email }
                : proposal?.brandSchedulers[0]
            }
            onSelect={(selectedBrandScheduler: Contact) => {
              dispatch(onSelectBrandScheduler(selectedBrandScheduler));
            }}
            assessmentBrand={proposal.store.brand.code as BrandCode}
            assessmentRegion={proposal.store.keringRegion}
            assessmentCountry={proposal.store.keringCountry.code}
          />
          <DistributionListAssessmentField
            editable={isDistributionListEditable}
            defaultValue={distributionList}
            title={t('brandAuditProposals.proposalDetail.distributionList') || ''}
            onEdited={values => onDistributionListUpdate(values)}
          />
        </div>
        {(proposal.note || proposal.auditorNote || proposal.brandNote) && (
          <div css={style.noteContainer}>
            {proposal.note && isNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('brandAuditProposals.proposalDetail.auditManagerNote')}
                  defaultValue={proposal.note.content}
                  type='auditManagerNote'
                />
              </div>
            )}
            {proposal.auditorNote && isAuditorNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('brandAuditProposals.proposalDetail.auditorNote')}
                  defaultValue={proposal.auditorNote.content}
                  type='auditorNote'
                />
              </div>
            )}
            {proposal.brandNote && isAuditorNoteVisible && (
              <div css={style.note}>
                <AuditNotes
                  title={t('auditCampaigns.proposalDetail.brandNote')}
                  defaultValue={proposal.brandNote.content}
                  type='brandNote'
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function ProposedByBrandDates({
  proposedPeriods,
  minDate,
  maxDate,
  onChange,
  editable = false,
}: {
  proposedPeriods: ProposedPeriod[];
  minDate?: Date;
  maxDate?: Date;
  onChange: (proposedPeriods: ProposedPeriod[]) => Promise<void>;
  editable?: boolean;
}) {
  return editable ? (
    <div css={style.rowMultipleField}>
      <MultipleDateRangeField
        defaultProposedPeriods={proposedPeriods}
        editable
        onEdited={(_proposedPeriods: ProposedPeriod[]) => onChange(_proposedPeriods)}
        title={t('brandAuditProposals.proposalDetail.availabilityPeriods') || ''}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  ) : (
    <div css={style.rowMultipleField}>
      <MultipleDateRangeField
        defaultProposedPeriods={proposedPeriods}
        title={t('brandAuditProposals.proposalDetail.availabilityPeriods') || ''}
      />
    </div>
  );
}
