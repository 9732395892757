import { css } from '@emotion/react';

const dropDown = css`
  &&&& {
    margin: 0 0 5px 0;
    word-break: break-word;

    .menu .item {
      margin: 0;
      white-space: normal;
    }
  }
`;

const dropDownCornerIcon = css`
  ${dropDown};
  &&&& {
    margin: 0;
    line-height: 1.21428571em;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    font-size: 1.143rem;
    min-height: unset;
  }
`;

const adUserDropdown = css`
  ${dropDown};
  &&&& {
    input.search {
      font-size: 1em;
    }
    .divider.text {
      margin: 0;
      padding: 0;
    }
    .menu .item .text {
      margin: 0;
      padding: 0;
      div {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

const adUserDropdownCornerIcon = css`
  ${dropDownCornerIcon};
  &&&& {
    position: relative;
    input.search {
      margin-left: 24px;
    }
    .divider.text {
      margin: 0;
      left: 24px;
    }
    .menu .item .text {
      left: 38px;
      margin: 0;
      padding: 0;
      div {
        left: 38px;
        margin: 0;
        padding: 0;
      }
    }
    > i.icon {
      position: absolute;
      left: 3px;
      top: 2px;
      font-size: 0.7345rem;
      border-radius: 0 1rem 0 0;
      color: #5d5d5d;
      :after {
        position: absolute;
        content: '';
        left: -3px;
        top: -2px;
        z-index: -1;
        width: 0;
        height: 0;
        background-color: transparent !important;
        border-top: 36px solid transparent;
        border-right: 36px solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 0 solid transparent;
        border-top-color: #e8e8e8;
      }
      &.search {
        padding: 0;
        margin: 0;
        width: 1.18em;
        height: 1em;
        top: 5px;
        :after {
          top: -5px;
        }
      }
    }
  }
`;
const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;

export default {
  dropdownItemName,
  adUserDropdown,
  adUserDropdownCornerIcon,
};
