import { css, SerializedStyles } from '@emotion/react';
import { Priority } from '../model/assessmentDetail.model';
import globalStyle from '../../../shared/global.style';
import fieldStyle from './field.style';
import { getPriorityColor } from './feature.style';

const priorityTitle = (priority: Priority | '', isValid: boolean): SerializedStyles => css`
  ${globalStyle.subtitleNormal}
  &&& {
    padding: 0 10px;
    background-color: var(--saa-blue-20);
    color: ${isValid ? '' : 'var(--saa-error-dark)'};
    font-weight: ${isValid ? '' : 600};
    display: flex;
    align-items: center;
    > i {
      color: var(${getPriorityColor(priority)});
      padding-left: 10px;
    }
  }
`;

const priorityLabel = css`
  font-size: 0.714rem;
  padding-left: 5px;
  font-style: italic;
  font-weight: 500;
`;

const validationIcon = css`
  &&&& {
    font-size: 1.071rem;
    color: var(--saa-error-dark);
  }
`;

const field = css`
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 40px;
  div {
    margin-right: 10px;
    flex-grow: 1;
    text-align: left;
    ${globalStyle.bodyNormal}
  }
`;

const editableField = css`
  && {
    ${field};
    cursor: pointer;
    position: relative;
    div {
      padding-right: 30px;
    }
  }
`;

const editIcon = css`
  ${fieldStyle.editIcon}
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

const inputContainer = css`
  &&&& {
    textarea {
      border-color: var(--saa-primary-color-10);
      outline: none;
      width: 100%;
      height: 170px;
      max-height: 170px;
      min-width: 250px;
      min-height: 170px;
      overflow: auto;
      resize: none;
    }
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const priorityRadioContainer = (priority: Priority | ''): SerializedStyles => css`
  background-color: var(--saa-primary-color-10);
  color: var(${getPriorityColor(priority)});
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const priorityRadioButton = css`
  &&& {
    min-height: 15px;
    font-size: 0.857rem;
    padding: 10px 5px;
  }
`;

export default {
  priorityTitle,
  field,
  editableField,
  editIcon,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  priorityRadioButton,
  priorityRadioContainer,
  validationIcon,
  priorityLabel,
};
