/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Confirm, Dimmer, Icon } from 'semantic-ui-react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import style from './modalPage.style';
import { selectUnsavedChangesPresence } from '../core/unsavedChangesSlice';

interface ModalPageProps {
  children?: JSX.Element | JSX.Element[];
  onClose: () => void;
  title?: string;
  unsavedChangesPopupDisabled?: boolean;
}

export const ModalPage: React.FunctionComponent<ModalPageProps> = (props): JSX.Element => {
  const { children, onClose, title, unsavedChangesPopupDisabled } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [initialModalHash] = useState(location.hash);
  const [modalHash, setModalHash] = useState(location.hash);
  const [closeConfirmIsOpen, setCloseConfirmIsOpen] = useState(false);
  const isPresetAnUnsavedChange = useSelector(selectUnsavedChangesPresence);

  const { t } = useTranslation();
  const closePage = useCallback((): boolean => {
    if (isPresetAnUnsavedChange && !unsavedChangesPopupDisabled) {
      setCloseConfirmIsOpen(true);
      return false;
    }
    onClose();
    return true;
  }, [isPresetAnUnsavedChange, onClose, unsavedChangesPopupDisabled]);

  const handleClose = () => {
    navigate(-1);
    closePage();
  };

  useEffect(() => {
    if (navigationType === 'POP' && initialModalHash === location.hash && modalHash !== location.hash) {
      const res = closePage();
      if (!res) {
        const newModalHash = `${initialModalHash}#modal`;
        navigate(`${location.pathname}${newModalHash}`, {});
      }
    }
  }, [navigationType, initialModalHash, location.hash, onClose, modalHash, closePage, location.pathname, navigate]);

  useEffect(() => {
    const newModalHash = `${initialModalHash}#modal`;
    setModalHash(newModalHash);
    if (location.hash !== newModalHash && initialModalHash === location.hash) {
      navigate(`${location.pathname}${newModalHash}`, {});
    }

    return () => {
      if (location.hash === newModalHash && initialModalHash !== location.hash) {
        navigate(-1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dimmer active css={style.dimmer}>
      <Header onClose={handleClose} title={title} />
      <div css={style.container}>{children}</div>
      <Confirm
        css={style.modalConfirm}
        open={closeConfirmIsOpen}
        onCancel={() => {
          setCloseConfirmIsOpen(false);
        }}
        content={t('unsavedChanges')}
        onConfirm={() => {
          setCloseConfirmIsOpen(false);
          navigate(-1);
          onClose();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </Dimmer>
  );
};

interface HeaderProps {
  onClose: () => void;
  title?: string;
}

const Header = ({ onClose, title }: HeaderProps): JSX.Element => {
  return (
    <div css={style.header}>
      <div css={style.title}>{title}</div>
      <Icon name='times' onClick={onClose} css={style.icon} />
    </div>
  );
};
