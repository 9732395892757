import { css } from '@emotion/react';
import style from '../../../shared/global.style';
import fieldStyle from '../../../shared/field.style';

const container = css`
  overflow: auto;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;

  &&&&&& {
    .fc {
      background-color: var(--saa-primary-color-10);
    }
    .fc-list {
      border: none;
    }
    .fc-list-event-graphic {
      display: none;
    }

    .fc-list-day-cushion.fc-cell-shaded {
      background-color: var(--saa-primary-color);
      a {
        color: var(--saa-white-color);
      }
    }

    .fc-list-event-time {
      display: none;
    }

    .fc-list-day.fc-day {
      margin-top: 10px;
      background-color: var(--saa-primary-color);
      z-index: 100;
      position: sticky;
      top: 0;
      width: 100%;
      display: block;
      th {
        z-index: 100;
        display: block;
        width: 100%;
        border: solid 1px var(--saa-primary-color);
      }
    }
    .fc-list-day.fc-day:first-child {
      margin-top: 0;
    }

    .fc-col-header-cell {
      background-color: var(--saa-primary-color);
      border: none;
      padding: 5px;
    }

    .fc-col-header-cell-cushion {
      color: var(--saa-white-color);
    }

    .fc-daygrid-day-number {
      color: var(--saa-primary-color);
    }

    .fc-list-event-title {
      width: max-content;
      display: block;
      padding: 0;
      border: none;
      height: 100%;
    }

    .fc-list-event.fc-event.fc-event-start.fc-event-today.fc-event-future {
      display: block;
    }

    .fc-list-table {
      display: block;
    }

    .fc-list-table tbody {
      display: flex;
      flex-wrap: wrap;
    }

    .fc-theme-standard td {
      border-color: var(--saa-primary-color-40);
    }

    .fc .fc-daygrid-day.fc-day-today {
      background-color: var(--saa-blue-20);
    }
  }
`;

const calendarToolbar = css`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 540px) {
    &&&& {
      flex-direction: column-reverse;
      padding-bottom: 0;
    }
  }
`;
const changeDateButtons = css`
  display: flex;
  background-color: var(--saa-primary-color);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  @media (max-width: 540px) {
    &&&& {
      width: 100%;
      height: 40px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 5px 0 0 var(--saa-primary-color);
      z-index: 101;
    }
  }
`;

const currentDateRange = css`
  ${style.primaryButton};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--saa-white-color);
  width: 170px;
`;

const changeViewButtons = css`
  &&& .ui.borderless.menu {
    border-radius: 4px;
    background-color: var(--saa-primary-color);
    border-color: var(--saa-primary-color);
  }

  &&& .item {
    background-color: var(--saa-primary-color);
    color: var(--saa-white-color);
    border-color: var(--saa-primary-color);
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &&& .item:hover {
    color: var(--saa-primary-color-20);
  }
  &&& .active.item {
    background-color: var(--saa-primary-color-80);
  }

  @media (max-width: 800px) {
    &&&& {
      display: none;
    }
  }
`;

const searchBoxContainer = css`
  display: flex;
  width: 250px;
  justify-content: flex-end;
  @media (max-width: 540px) {
    &&&& {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const iconButton = css`
  ${style.primarySmallButton};
  &&&&&&&& {
    margin: 0;
  }
`;

const filterButton = css`
  ${style.primaryButton};
  && {
    margin-left: 5px;
    height: 100%;
    margin-right: 0;
  }
`;
const filterButtonBadge = css`
  &&& {
    background-color: var(--saa-blue-60);
    color: var(--saa-white-color);
    top: unset;
    right: unset;
    bottom: -8px;
    left: 17px;
  }
`;

const filterButtonContainer = css`
  position: relative;
  cursor: pointer;
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const eventContent = (viewType: string) => {
  const width = viewType === 'listMonth' ? '340px' : '100%';
  const padding = viewType === 'listMonth' ? '5px' : '0 0 0 5px';
  return css`
    width: ${width};
    padding: ${padding};
    height: 100%;
  `;
};

const calendarProposalCard = css`
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  height: 100%;
  :hover {
    && {
      box-shadow: 0 1px 3px 0 var(--saa-primary-color-60), 0 0 0 1px var(--saa-primary-color-60);
    }
  }
`;

const calendarProposalCardContent = css`
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;

const monthCalendarProposalCardContent = css`
  padding-top: 5px;
  padding-left: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    > div {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
`;

const monthBrandContainer = css`
  &&& {
    width: max-content;
    &&&& img {
      width: 25px;
      height: 25px;
    }
  }
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
  &&& {
    max-height: 450px;
    > div {
      padding: 0px;
    }
  }
`;

const filterButtonsContainer = css`
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  gap: 5px;
`;

const applyFiltersButton = css`
  ${style.primaryButton};
  width: 120px;
  @media (max-width: 540px) {
    flex: 1;
  }
`;

const badgeGroup = css`
  height: max-content;
  display: flex;
  align-items: center;

  .ui.labels {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;
const filterContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--saa-white-color);
  padding: 15px;
  color: var(--saa-primary-color);
`;

const filterItem = css`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px var(--saa-primary-color-10);
`;

const checkboxLabel = css`
  ${style.bodyNormal};
  &&& {
    color: var(--saa-primary-color);
    display: flex;
    align-items: center;
  }
`;

export default {
  container,
  calendarToolbar,
  changeDateButtons,
  currentDateRange,
  changeViewButtons,
  iconButton,
  actions,
  button,
  eventContent,
  searchBoxContainer,
  calendarProposalCard,
  calendarProposalCardContent,
  monthCalendarProposalCardContent,
  modal,
  modalContent,
  monthBrandContainer,
  filterButton,
  filterButtonBadge,
  filterButtonsContainer,
  applyFiltersButton,
  badgeGroup,
  filterContainer,
  filterItem,
  checkboxLabel,
  filterButtonContainer,
};
