import { css } from '@emotion/react';
import style from './field.style';

const field = css`
  ${style.field}
  overflow: hidden;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const editableField = css`
  ${style.editableField}
  overflow: hidden;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
  &&& {
    max-height: 450px;
    > div {
      padding: 0px;
    }
  }
`;

const inputContainer = css`
  &&&& {
    > div {
      width: 100%;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const subTitle = css`
  ${style.subTitle}
`;

const valueContainer = css`
  ${style.valueContainer}
`;

const value = css`
  ${style.value}
`;

const checkboxContainer = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const checkboxLabel = css`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default {
  field,
  editableField,
  modal,
  modalContent,
  actions,
  button,
  editIcon,
  inputContainer,
  subTitle,
  valueContainer,
  value,
  checkboxContainer,
  checkboxLabel,
};
