import { css } from '@emotion/react';

const badge = css`
  &&&& {
    background-color: var(--saa-blue-90);
    color: var(--saa-white-color);
    line-height: 11px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 0.25em 0.5em 0.25em 0;
  }
`;

const badgeTitle = css`
  &&& {
    text-decoration: underline;
    opacity: 0.9;
    font-weight: 600;
  }
`;

const badgeValue = css`
  &&& {
    margin-left: 5px;
  }
`;

export default {
  badge,
  badgeTitle,
  badgeValue,
};
