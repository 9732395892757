import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditCampaign,
  UpdateCampaignBrandSchedulersRequest,
  UpdateCampaignDistributionListRequest,
  UpdateCampaignNameRequest,
} from '../model/auditCampaign';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../core/services/toastService';
import { ChangeStatusRequest } from '../model/auditProposal';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from '../model/genericAuditProposal';

interface AuditCampaignDetailSliceState {
  campaign: AuditCampaign | null;
  isFetching: boolean;
  error: string;
}

const initialState: AuditCampaignDetailSliceState = {
  campaign: null,
  isFetching: false,
  error: '',
};

export const auditCampaignDetailSlice = createSlice({
  name: 'auditCampaignDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditCampaignDetailSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<AuditCampaignDetailSliceState>, { payload }: PayloadAction<AuditCampaign>) => ({
      ...state,
      isFetching: false,
      campaign: payload,
      error: '',
    }),
    httpError: (state: Draft<AuditCampaignDetailSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    updateCampaign: (state: Draft<AuditCampaignDetailSliceState>, { payload }: PayloadAction<AuditCampaign>) => ({
      ...state,
      campaign: payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError, updateCampaign } = auditCampaignDetailSlice.actions;

export default auditCampaignDetailSlice.reducer;

export const fetchAuditCampaign =
  (campaignId: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const campaign = await api.getAuditCampaign(campaignId);
      dispatch(finishFetch(campaign));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const deleteCampaign =
  (campaignId: string): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      await api.deleteAuditCampaign(campaignId);
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const updateCampaignDistributionList =
  (campaignId: string, distributionList: string[]): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const request: UpdateCampaignDistributionListRequest = { distributionList };
      const updatedCampaign = await api.updateAuditCampaignDistributionList(campaignId, request);
      dispatch(updateCampaign(updatedCampaign));
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const updateCampaignBrandSchedulers =
  (campaignId: string, brandSchedulers: string[]): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const request: UpdateCampaignBrandSchedulersRequest = { brandSchedulers };
      const updatedCampaign = await api.updateAuditCampaignBrandSchedulers(campaignId, request);
      dispatch(updateCampaign(updatedCampaign));
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const updateCampaignName =
  (campaignId: string, name: string): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const request: UpdateCampaignNameRequest = { name };
      const updatedCampaign = await api.updateAuditCampaignName(campaignId, request);
      dispatch(updateCampaign(updatedCampaign));
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const approveCampaignProposals =
  (campaignId: string): AppThunk<Promise<boolean>> =>
  async (dispatch: any): Promise<boolean> => {
    try {
      const request: ChangeStatusRequest = { status: INTERNAL_AUDIT_PROPOSAL_STATUS.APPROVED_BY_AUDITOR_MANAGER };
      const updatedCampaign = await api.approveCampaignProposals(campaignId, request);
      dispatch(updateCampaign(updatedCampaign));
      toastService.success();
      return true;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return false;
    }
  };

export const selectCampaign = (state: RootState) => state.auditCampaignDetail.campaign;

export const selectIsFetchingCampaign = (state: RootState) => state.auditCampaignDetail.isFetching;
