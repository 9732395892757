import axios, { AxiosError } from 'axios';
import { toastService } from './services/toastService';
import { deleteJwtToken, getJwtToken, setRedirectPagePath } from './services/webStorageService';

export const setJwtInterceptor = (): void => {
  axios.interceptors.request.use(
    config => {
      const token = getJwtToken();
      if (token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign,
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export const setErrorInterceptor = (): void => {
  axios.interceptors.response.use(
    response => response,
    // eslint-disable-next-line consistent-return
    (error: AxiosError) => {
      const { status } = error.response != null ? error.response : { status: '' };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorMsg = error.response?.data?.message || error.response?.data?.error || 'Sorry, something went wrong!';

      if (status === 401) {
        deleteJwtToken();
        setRedirectPagePath(window.location.pathname);
        window.location.assign('/login');
      } else if (status === 403) {
        toastService.error('Unauthorized');
        return Promise.reject(error);
      } else if (status === 404) {
        toastService.error('Not Found!');
        return Promise.reject(error);
      } else if (error.code === 'ECONNABORTED' || (error.isAxiosError && !error.response)) {
        toastService.error('Network error: please try again later');
        return Promise.reject(error);
      } else {
        toastService.error(errorMsg);
        return Promise.reject(error);
      }
    }
  );
};
