import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  Contact,
  emptyUsersFilters,
  getEmptyUser,
  User,
  UserCreationRequest,
  UserFilters,
  UserUpdateRequest,
} from '../model/user.model';
import api from '../utils/api';
import { toastService } from '../../../core/services/toastService';
import { ROLES } from '../../auth/model/principal.model';

interface UsersSlice {
  data: User[];
  isFetching: boolean;
  error: string;
  filter: string;
  showResults: boolean;
  filters: UserFilters;
}
const initialState: UsersSlice = {
  data: [],
  isFetching: false,
  error: '',
  filter: '',
  showResults: true,
  filters: emptyUsersFilters,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startFetch: (state: Draft<UsersSlice>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<UsersSlice>, action: PayloadAction<User[]>) => ({
      ...state,
      isFetching: false,
      data: [...action.payload],
      error: '',
    }),
    errorFetch: (state: Draft<UsersSlice>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setFilter: (state: Draft<UsersSlice>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    removeUser: (state: Draft<UsersSlice>, { payload: id }: PayloadAction<string>) => {
      const users = state.data;
      const newList = users.filter(user => user.id !== id);
      return {
        ...state,
        data: newList,
      };
    },
    editUser: (state: Draft<UsersSlice>, { payload: user }: PayloadAction<User>) => {
      const users = state.data;
      const updatedUsers = users.map(u => (u.id === user.id ? user : u));
      return {
        ...state,
        data: updatedUsers,
      };
    },
    removeEmptyUser: (state: Draft<UsersSlice>) => {
      return {
        ...state,
        data: state.data.filter((user: User) => user.id !== ''),
      };
    },
    addEmptyUser: (state: Draft<UsersSlice>) => {
      return {
        ...state,
        data: state.data.find(user => user.id === '') != null ? [...state.data] : [getEmptyUser(), ...state.data],
      };
    },
    addUser: (state: Draft<UsersSlice>, { payload: user }: PayloadAction<User>) => {
      return {
        ...state,
        data: [...state.data, user],
      };
    },
    setShowResults: (state: Draft<UsersSlice>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showResults: payload,
    }),
    setFilters: (state: Draft<UsersSlice>, { payload }: PayloadAction<UserFilters>) => ({
      ...state,
      filters: payload,
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  errorFetch,
  setFilter,
  removeUser,
  editUser,
  addEmptyUser,
  removeEmptyUser,
  addUser,
  setShowResults,
  setFilters,
} = usersSlice.actions;

export default usersSlice.reducer;

const sortByEmail = (a: User, b: User): number => {
  if (a.email > b.email) {
    return 1;
  }
  if (a.email < b.email) {
    return -1;
  }
  return 0;
};

export const selectUsers = (state: any): User[] => state.users.data;
export const selectFilter = (state: any): string => state.users.filter;
export const selectIsFetching = (state: any): boolean => state.users.isFetching;
export const selectSortedUsers = createSelector(selectUsers, (users: User[]): User[] => {
  return [...users].sort(sortByEmail);
});
export const selectShowResults = (state: any) => state.users.showResults;

export const selectFilters = (state: any) => state.users.filters;

export const selectSortedFilteredUsers = createSelector(
  selectSortedUsers,
  selectFilter,
  selectFilters,
  (users: User[], filter: string, filters: UserFilters): User[] => {
    return users.filter(
      user =>
        (user.id === '' ||
          user.email?.toLowerCase().includes(filter.toLowerCase()) ||
          user.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
          user.familyName?.toLowerCase().includes(filter.toLowerCase()) ||
          user.roles?.some(role => role.toLowerCase().includes(filter.toLowerCase())) ||
          user.brands?.some(brand => brand.toLowerCase().includes(filter.toLowerCase()))) &&
        (filters.brands.length === 0 || user.brands?.some(brand => filters.brands.includes(brand.valueOf()))) &&
        (filters.roles.length === 0 || user.roles?.some(role => filters.roles.includes(role.valueOf()))) &&
        (filters.regions.length === 0 ||
          Object.values(ROLES)
            .filter(role => role !== ROLES.BRAND_SCHEDULER && role !== ROLES.ARCHIVE_READER)
            .some(role => user.roles.includes(role) && filters.roles.includes(role)) ||
          user.regions?.some(region => filters.regions.includes(region)) ||
          user.regions == null) &&
        (filters.countries.length === 0 ||
          Object.values(ROLES)
            .filter(role => role !== ROLES.BRAND_SCHEDULER && role !== ROLES.ARCHIVE_READER)
            .some(role => user.roles.includes(role) && filters.roles.includes(role)) ||
          user.countries?.some(country => filters.countries.map(c => c.code).includes(country.code)) ||
          user.countries == null)
    );
  }
);

export const selectBrandSchedulerContactList = createSelector(selectSortedUsers, (users): Contact[] => {
  return users
    .filter(user => user.roles.includes(ROLES.BRAND_SCHEDULER))
    .map(user => ({ name: `${user.firstName} ${user.familyName}`, email: user.email }));
});

export const fetchUsers = () => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const users = await api.getUsers();
    dispatch(finishFetch(users));
  } catch (e) {
    dispatch(errorFetch(JSON.stringify(e)));
  }
};

export const deleteUser = (id: string) => async (dispatch: any) => {
  try {
    const userId = await api.deleteUser(id);
    dispatch(removeUser(userId));
    toastService.success();
  } catch (e) {
    dispatch(errorFetch(JSON.stringify(e)));
  }
};

export const updateUser = (id: string, request: UserUpdateRequest) => async (dispatch: any) => {
  try {
    const userUpdated = await api.updateUser(id, request);
    dispatch(editUser(userUpdated));
    toastService.success();
  } catch (e) {
    dispatch(errorFetch(JSON.stringify(e)));
  }
};

export const createUser = (request: UserCreationRequest) => async (dispatch: any) => {
  try {
    const userCreated = await api.createUser(request);
    dispatch(addUser(userCreated));
    dispatch(removeEmptyUser());
    toastService.success();
  } catch (e) {
    dispatch(errorFetch(JSON.stringify(e)));
  }
};
