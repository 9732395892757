import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import fieldStyle from './field.style';

const cameraContainer = css`
  height: 100%;
  width: 100%;
  background-color: var(--saa-primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 10px;
  }
`;

const camera = (show: boolean): SerializedStyles => css`
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: ${show ? 'flex' : 'none'};
  max-height: calc(100% - 50px);
  video {
    width: 100%;
    max-height: 100%;
    flex: 1;
  }
  div {
    display: flex;
    width: 100%;
    align-items: center;
    div {
      margin: 0;
    }
  }
`;

const cameraPreview = css`
  flex: 1;
  width: 100%;
  max-height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--saa-primary-color);
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const cameraButtons = (show: boolean): SerializedStyles => css`
  width: 100%;
  display: ${show ? 'flex' : 'none'};
  && {
    button {
      margin: 0;
      border-radius: 0;
      flex: 1;
      height: 50px;
      i {
        font-size: 1.429rem;
      }
    }
  }
`;

const picturesButtons = css`
  background-color: var(--saa-white-color);
  padding: 10px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  //input {
  //  display: none;
  //}
`;

const picturesButton = css`
  ${fieldStyle.editIcon}
  &&& {
    width: 40px;
    height: 40px;
    position: relative;
    font-size: 1.429rem;
    cursor: pointer;
  }
`;

const offlineIcon = css`
  ${fieldStyle.editIcon}
  &&& {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.429rem;
    cursor: pointer;
    i {
      position: absolute;
      color: var(--saa-error-dark);
    }
  }
`;

const picturesContainer = css`
  display: flex;
  flex-direction: column;
`;
const picturesCarouselContainer = css`
  display: flex;
  background-color: var(--saa-white-color);
`;

const pictureCarouselHeader = css`
  ${globalStyle.subtitleNormal}
  && {
    padding: 0 10px;
    background-color: var(--saa-blue-20);
    display: flex;
    align-items: center;
  }
`;

const pictureCarousel = css`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--saa-white-color);
  padding: 5px;
  min-height: 50px;
`;

const pictureCarouselItem = css`
  && {
    width: 130px;
    height: 130px;
    overflow: hidden;
    background-color: var(--saa-primary-color-20);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 1px;
    position: relative;
    cursor: pointer;
    margin: 5px;
    align-items: center;
    justify-content: center;
`;

const attachmentCarouselItem = css`
  && {
    width: 130px;
    height: 130px;
    overflow: hidden;
    background-color: var(--saa-primary-color-20);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 1px;
    position: relative;
    cursor: pointer;
    margin: 5px;
    align-items: center;
    justify-content: center;
`;

const pictureCarouselImageContainer = css`
  && { 
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--saa-primary-color);
    img {
      width: 100%;
      height: auto;
    }
`;

const attachmentCarouselContainer = css`
  && { 
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: white;
    img {
      width: 100%;
      height: auto;
    }
`;

const carouselItemDeleteIcon = css`
  ${fieldStyle.editIcon};

  &&& {
    ${globalStyle.secondaryButton};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: 0;
    padding: 5px;
    position: relative;
    margin: 5px;
  }
`;

const previewContainer = css`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--saa-primary-color);
    padding-bottom: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const fileInput = (content: string): SerializedStyles => {
  return css`
    width: 40px;
    height: 40px;
    background-color: var(--saa-primary-color-10);
    border-radius: 30px;
    padding: 0;
    display: flex;
    font-size: 1.429rem;
    align-items: center;
    justify-content: center;
    position: relative;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
    }
    input::-webkit-file-upload-button {
      visibility: hidden;
    }
    input::file-selector-button {
      visibility: hidden;
    }
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '${content}';
      outline: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  `;
};

const attachmentType = (content: string): SerializedStyles => {
  return css`
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-size: 1.429rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
    }
    input::-webkit-file-upload-button {
      visibility: hidden;
    }
    input::file-selector-button {
      visibility: hidden;
    }
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '${content}';
      outline: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `;
};

const attachmentName = (): SerializedStyles => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-weight: 400;
  `;
};

const iosFileInput = (content: string): SerializedStyles => {
  return css`
    width: 40px;
    height: 40px;
    background-color: var(--saa-primary-color-10);
    border-radius: 30px;
    padding: 0;
    display: flex;
    font-size: 1.429rem;
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '${content}';
      outline: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  `;
};

const disableField = (readonly: boolean): SerializedStyles =>
  css`
    padding-bottom: 10px;
    opacity: ${readonly ? 0.7 : 1};
  `;

const validTitle = (isValid: boolean): SerializedStyles => css`
  ${fieldStyle.freeTextTitle(isValid)}
`;

const validationIcon = css`
  ${fieldStyle.validationIcon}
  padding:10px
`;

export default {
  cameraContainer,
  camera,
  cameraPreview,
  cameraButtons,
  picturesContainer,
  picturesCarouselContainer,
  pictureCarousel,
  pictureCarouselItem,
  pictureCarouselImageContainer,
  attachmentCarouselContainer,
  carouselItemDeleteIcon,
  previewContainer,
  pictureCarouselHeader,
  picturesButtons,
  picturesButton,
  offlineIcon,
  fileInput,
  iosFileInput,
  attachmentType,
  attachmentCarouselItem,
  attachmentName,
  disableField,
  validTitle,
  validationIcon,
};
