import React, { useCallback, useEffect } from 'react';

import { saveAuditorUpdateRequests } from '../store/auditorUpdateRequestsSlice';
import { useAppDispatch } from '../../../core/store';

const loopTime = 30000; // ms

export const SyncAuditorUpdateRequests = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const saveAuditorUpdateRequestsLoop = useCallback(async () => {
    await dispatch(saveAuditorUpdateRequests());
    setTimeout(saveAuditorUpdateRequestsLoop, loopTime);
  }, [dispatch]);

  useEffect(() => {
    saveAuditorUpdateRequestsLoop();
  }, [saveAuditorUpdateRequestsLoop]);

  return <></>;
};
