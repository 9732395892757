/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';
import style from './assessmentsBulkUpdateWizard.style';
import {
  AssessmentBulkUpdateWizardAction,
  AssessmentBulkUpdateWizardField,
  AssessmentBulkUpdateWizardSelectedValue,
  AssessmentBulkUpdateWizardStep,
} from '../../model/assessmentBulkUpdate';
import { DistributionListSelection } from './DistributionListSelection';
import { ActionPlanCoordinatorSelection } from './ActionPlanCoordinatorSelection';
import { AuditorSelection } from './AuditorSelection';

interface SelectNewValueStepProps {
  onClose: () => void;
  setStep: (step: AssessmentBulkUpdateWizardStep) => void;
  action: AssessmentBulkUpdateWizardAction | null;
  field: AssessmentBulkUpdateWizardField | null;
  newValues: AssessmentBulkUpdateWizardSelectedValue[] | null;
  setNewValues: (newValues: AssessmentBulkUpdateWizardSelectedValue[] | null) => void;
  onConfirm: () => void;
}

export const SelectNewValueStep = ({
  onClose,
  setStep,
  action,
  field,
  newValues,
  setNewValues,
  onConfirm,
}: SelectNewValueStepProps): JSX.Element => {
  const { t } = useTranslation();

  const onClickPrev = () => {
    if (action === AssessmentBulkUpdateWizardAction.FIND_AND_REPLACE) {
      setStep(AssessmentBulkUpdateWizardStep.SELECT_VALUE_TO_REPLACE);
    } else {
      setStep(AssessmentBulkUpdateWizardStep.SELECT_ACTION);
    }
    setNewValues(null);
  };

  const onClickConfirm = () => {
    onConfirm();
  };
  return (
    <>
      <Modal.Header>
        <div css={style.header}>
          <div>{t(`assessment.bulkUpdate.wizard.fields.${field}`)}</div>
          <div css={style.headerIcon} onClick={onClose}>
            <Icon className='fas fa-close' />
          </div>
        </div>
      </Modal.Header>

      <Modal.Content
        css={
          field === AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS
            ? style.modalContentActionPlanCoordinators
            : style.modalContent
        }>
        <StepContent field={field} newValues={newValues} setNewValues={setNewValues} />
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button css={style.button} onClick={onClickPrev}>
          {t('assessment.bulkUpdate.wizard.buttons.prev')}
        </Button>
        <Button css={style.button} onClick={onClickConfirm} disabled={newValues == null || newValues.length === 0}>
          {t('assessment.bulkUpdate.wizard.buttons.confirm')}
        </Button>
      </Modal.Actions>
    </>
  );
};

interface StepContentProps {
  field: AssessmentBulkUpdateWizardField | null;
  newValues: AssessmentBulkUpdateWizardSelectedValue[] | null;
  setNewValues: (newValues: AssessmentBulkUpdateWizardSelectedValue[] | null) => void;
}
const StepContent = ({ newValues, setNewValues, field }: StepContentProps): JSX.Element => {
  switch (field) {
    case AssessmentBulkUpdateWizardField.DISTRIBUTION_LIST:
    case AssessmentBulkUpdateWizardField.READ_ONLY_DISTRIBUTION_LIST:
      return (
        <DistributionListSelection
          onEdited={values => setNewValues(values)}
          defaultValue={newValues?.map(value => {
            return { name: value.name, email: value.email };
          })}
        />
      );
    case AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS:
      return <ActionPlanCoordinatorSelection onEdited={values => setNewValues(values)} defaultValue={newValues} />;
    case AssessmentBulkUpdateWizardField.AUDITORS:
      return <AuditorSelection defaultValue={newValues} onEdited={values => setNewValues(values)} />;
    default:
      return <></>;
  }
};
