import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 5px;
`;

const header = css`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const addButton = (label: string): SerializedStyles => css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: var(--saa-primary-color-80);
    color: var(--saa-white-color);
  }

  @media (min-width: 1200px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: unset;
      border-radius: unset;
      background-color: var(--saa-primary-color-80);
      color: var(--saa-white-color);

      :before {
        content: '${label}';
        padding-right: 5px;
      }
    }
  }
`;

const searchBoxContainer = css`
  @media (min-width: 540px) {
    margin-left: 10px;
    flex: 1;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    height: max-content;
    max-width: 400px;
  }
`;

const buttonAndSearch = css`
  display: flex;
`;

const userList = css`
  overflow: auto;
  margin-top: 10px;
  flex-grow: 1;
`;
const userNotFound = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const badgeGroup = css`
  flex: 1;
  display: flex;
  @media (min-width: 540px) {
    margin: 0px 10px 0 5px;
    border-bottom: 1px solid var(--saa-primary-color-60);
    padding-bottom: 5px;
    justify-content: space-between;
    min-height: 37px;
    height: max-content;
    align-items: center;
  }
  .ui.labels {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;

const badges = css`
  @media (max-width: 540px) {
    display: none;
  }
`;

const icon = css`
  && {
    margin: 0;
  }
`;

const filterButton = css`
  && {
    @media (min-width: 540px) {
      display: flex;
      ${globalStyle.primaryButton};
    }
    @media (max-width: 540px) {
      && {
        color: var(--saa-white-color);
        background-color: var(--saa-primary-color);
        cursor: pointer;
        margin-right: 5px;
        &&:hover,
        &&:focus {
          color: var(--saa-primary-color-40);
          background-color: var(--saa-primary-color);
        }
      }
      width: 50px;
      height: 100%;
      span {
        display: none;
      }
    }
  }
`;

const buttonsContainer = css`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 15px;
  position: sticky;
  bottom: 0;
  background-color: var(--saa-primary-color-5);
  gap: 5px;
`;

const button = css`
  ${globalStyle.primaryButton};
  width: 120px;
  @media (max-width: 540px) {
    flex: 1;
  }
`;

const filterButtonBadge = css`
  &&& {
    background-color: var(--saa-blue-60);
    color: var(--saa-white-color);
    top: unset;
    right: unset;
    bottom: -6px;
    left: 16px;
    @media (min-width: 540px) {
      display: none;
    }
  }
`;

const filterButtonContainer = css`
  cursor: pointer;
  position: relative;
`;

const filtersContainer = css`
  margin: auto;
  display: flex;
  max-width: 600px;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding: 15px 15px 0;
  background-color: var(--saa-primary-color-5);
  border-radius: 4px;

  @media (max-width: 540px) {
    height: 100%;
  }
`;
export default {
  container,
  header,
  addButton,
  userList,
  userNotFound,
  searchBoxContainer,
  badgeGroup,
  filterButton,
  button,
  buttonsContainer,
  filtersContainer,
  filterButtonBadge,
  filterButtonContainer,
  badges,
  buttonAndSearch,
  icon,
};
