/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import Webcam from 'react-webcam';
import Resizer from 'react-image-file-resizer';
import { isIOS } from 'react-device-detect';
import api from '../utils/api';
import style from './AttachmentsListView.style';
import { ModalPage } from '../../../shared/ModalPage';
import { isOnline } from '../utils/offlineApi';
import { PictureRef, AttachmentRef } from '../model/assessmentDetail.model';
import loadingImg from '../../../assets/loading.gif';
import { downloadAttachments } from '../store/assessmentsSlice';
import { useAppDispatch } from '../../../core/store';
import { getDate } from '../../../core/utils';

interface AttachmentsWrapper {
  type: 'picture' | 'attachment';
  attachmentData: PictureRef | AttachmentRef;
}

interface AttachmentsListViewProps {
  attachments: AttachmentRef[];
  onDeleteAttachment: (attachment: AttachmentRef) => void;
  onAddAttachments: (attachments: AttachmentRef[]) => void;
  pictures: PictureRef[];
  onDeletePicture: (pic: PictureRef) => void;
  onAddPictures: (pics: PictureRef[]) => void;
  editable: boolean;
  disabled?: boolean;
  isValid?: boolean;
}

const base64toBlob = (dataURI: string): File => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab]) as File;
};

const resizeFile = (file: File | string): Promise<string> => {
  const f = typeof file === 'string' ? base64toBlob(file as string) : file;
  const quality = f.size > 1000000 ? 80 : 99;
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      f,
      1920,
      1920,
      'JPEG',
      quality,
      0,
      uri => {
        resolve(uri as string);
      },
      'base64'
    );
  });
};

export const AttachmentsListView = ({
  pictures,
  onDeletePicture,
  onAddPictures,
  attachments,
  onDeleteAttachment,
  onAddAttachments,
  editable,
  disabled = false,
  isValid = true,
}: AttachmentsListViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [previewActive, setPreviewActive] = useState(false);
  const [picturePreview, setPicturePreview] = useState<string | null>(null);
  const [attachmentsPictures, setAttachmentsPictures] = useState<AttachmentsWrapper[]>([]);
  const [cameraActive, setCameraActive] = useState(false);
  const uploadInput = useRef<HTMLInputElement>(null);
  const uploadAttachmentInput = useRef<HTMLInputElement>(null);
  const [online, setOnline] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const checkIsOnline = useCallback(async () => {
    const r = await isOnline();
    setOnline(r);
    return r;
  }, []);

  const mergeAttachmentsPictures = useCallback(
    (Pictures: PictureRef[], Attachments: AttachmentRef[]): AttachmentsWrapper[] => {
      const pics: AttachmentsWrapper[] = Pictures.map(p => ({ type: 'picture', attachmentData: p }));
      const attachs: AttachmentsWrapper[] = Attachments.map(p => ({ type: 'attachment', attachmentData: p }));
      return [...pics, ...attachs].sort((a, b) => (a.attachmentData.id > b.attachmentData.id ? 1 : -1));
    },
    []
  );

  useEffect(() => {
    checkIsOnline();
    const attachment: AttachmentsWrapper[] = mergeAttachmentsPictures(pictures, attachments);
    setAttachmentsPictures(attachment);
  }, [checkIsOnline, pictures, attachments, mergeAttachmentsPictures]);

  const downloadAttachment = useCallback(
    async (id: string, filename: string) => {
      await dispatch(downloadAttachments(id, filename));
    },
    [dispatch]
  );

  const showPreview = useCallback(
    async (id: string) => {
      if (await checkIsOnline()) {
        const picture = await api.downloadPicture(id);
        setPicturePreview(picture);
        setPreviewActive(true);
      }
    },
    [checkIsOnline]
  );

  const deletePicture = useCallback(
    (p: PictureRef) => {
      onDeletePicture(p);
    },
    [onDeletePicture]
  );

  const onSelectPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (online) {
      const reader = new FileReader();
      const file = e.target.files != null ? e.target.files[0] : null;

      if (reader != null && file != null) {
        reader.onload = async (readerEvt: ProgressEvent<FileReader>) => {
          e.target.value = '';
          uploadPicture(file);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const uploadPicture = async (pictureData: string | File) => {
    const loading = { id: `${getDate().getTime()}`, data: '' };
    onAddPictures([loading]);
    const base64Image = await resizeFile(pictureData);
    try {
      const pxs = await api.uploadPictures([{ data: base64Image, contentType: 'image/jpeg' }]);
      onDeletePicture(loading);
      onAddPictures(pxs);
    } catch {
      onDeletePicture(loading);
    }
  };

  const deleteAttachment = useCallback(
    (p: AttachmentRef) => {
      onDeleteAttachment(p);
    },
    [onDeleteAttachment]
  );

  const onSelectAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (online) {
      const reader = new FileReader();
      const file = e.target.files != null ? e.target.files[0] : null;

      if (reader != null && file != null) {
        reader.onload = async (readerEvt: ProgressEvent<FileReader>) => {
          e.target.value = '';
          const base64data = reader.result as string;
          uploadAttachment(base64data, file.name);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const uploadAttachment = async (attachmentData: string, fileName: string) => {
    const loading = { id: `${getDate().getTime()}`, data: '', filename: '' };
    onAddAttachments([loading]);
    try {
      const attach = await api.uploadAttachments([{ data: attachmentData, filename: fileName, contentType: 'pdf' }]);
      onDeleteAttachment(loading);
      onAddAttachments(attach);
    } catch {
      onDeleteAttachment(loading);
    }
  };

  const FileInput = () => {
    return (
      <>
        <input
          type='file'
          css={style.iosFileInput('\\f07c')}
          accept='pdf, xls, doc, docs, dot'
          onChange={onSelectAttachment}
        />
        <input type='file' css={style.iosFileInput('\\f030')} accept='image/*' onChange={onSelectPicture} />
      </>
    );
  };

  const getIcon = (type: string): string => {
    switch (type) {
      case 'pdf':
        return '\\f1c1';
      case 'xls':
        return '\\f1c3';
      case 'xlsx':
        return '\\f1c3';
      case 'doc':
        return '\\f1c2';
      case 'docs':
        return '\\f1c2';
      case 'docx':
        return '\\f1c2';
      default:
        return '\\f15c';
    }
  };

  const AttachmentView = ({ id, filename }: AttachmentRef): JSX.Element => {
    const attachmentType: string = filename.substring(filename.lastIndexOf('.') + 1, filename.length);

    return (
      <div key={id} css={style.attachmentCarouselItem}>
        <div css={style.attachmentCarouselContainer} onClick={() => downloadAttachment(id, filename)}>
          <div css={style.attachmentType(getIcon(attachmentType))} />
          {filename !== '' ? <div css={style.attachmentName}>{filename}</div> : <Image src={loadingImg} size='mini' />}
        </div>
        {editable && (
          <div css={style.carouselItemDeleteIcon} onClick={() => deleteAttachment({ id, filename })}>
            <Icon name='times' />
          </div>
        )}
      </div>
    );
  };

  const PictureView = ({ id, data }: PictureRef): JSX.Element => {
    return (
      <div key={id} css={style.pictureCarouselItem}>
        <div css={style.pictureCarouselImageContainer}>
          {data !== '' ? (
            <Image src={`data:image/jpeg;base64,${data}`} onClick={() => showPreview(id)} />
          ) : (
            <Image src={loadingImg} size='mini' />
          )}
        </div>
        {editable && (
          <div css={style.carouselItemDeleteIcon} onClick={() => deletePicture({ id, data })}>
            <Icon name='times' />
          </div>
        )}
      </div>
    );
  };

  return (
    <div css={style.picturesContainer}>
      <div css={[style.pictureCarouselHeader, style.disableField(disabled), style.validTitle(isValid)]}>
        {t('assessment.checkList.pictures')}
      </div>
      <div css={style.picturesCarouselContainer}>
        {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}
        <div css={style.pictureCarousel}>
          {attachmentsPictures.map((wrapper: AttachmentsWrapper) =>
            wrapper.type === 'picture' ? (
              <PictureView
                key={wrapper.attachmentData.id}
                id={wrapper.attachmentData.id}
                data={(wrapper.attachmentData as PictureRef).data}
              />
            ) : (
              <AttachmentView
                key={wrapper.attachmentData.id}
                id={wrapper.attachmentData.id}
                filename={(wrapper.attachmentData as AttachmentRef).filename}
              />
            )
          )}
        </div>
        {editable &&
          (online ? (
            <div css={style.picturesButtons} onClick={checkIsOnline}>
              {isIOS ? (
                <FileInput />
              ) : (
                <>
                  <div css={style.fileInput('\\f07c')}>
                    <input
                      type='file'
                      accept='application/pdf, .xlsx, .xls, .doc, .docx'
                      onChange={onSelectAttachment}
                      ref={uploadAttachmentInput}
                    />
                  </div>
                  <div css={style.fileInput('\\f03e')}>
                    <input type='file' accept='image/*' onChange={onSelectPicture} ref={uploadInput} />
                  </div>
                  <div
                    css={style.picturesButton}
                    onClick={async () => (await checkIsOnline()) && setCameraActive(true)}>
                    <Icon name='camera' />
                  </div>
                  {cameraActive && (
                    <CameraModal
                      onConfirmPicture={pictureData => {
                        setCameraActive(false);
                        uploadPicture(pictureData);
                      }}
                      onClose={() => setCameraActive(false)}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div css={style.picturesButtons} onClick={checkIsOnline}>
              <div css={style.offlineIcon}>
                <Icon className='fas fa-slash' />
                <Icon name='wifi' />
              </div>
            </div>
          ))}
        {previewActive && (
          <ModalPage onClose={() => setPreviewActive(false)} title={t('assessment.checkList.picturePreview') || ''}>
            <div css={style.previewContainer}>
              <Image src={`data:image/jpeg;base64,${picturePreview}`} alt='loading...' />
            </div>
          </ModalPage>
        )}
      </div>
    </div>
  );
};

interface CameraModalProps {
  onConfirmPicture: (pictureData: string) => void;
  onClose: () => void;
}

export const CameraModal = ({ onConfirmPicture, onClose }: CameraModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [cameraPreviewSrc, setCameraPreviewSrc] = useState<string | null>(null);
  const webcamRef = React.useRef<Webcam>(null);
  const videoConstraints = {
    facingMode: 'environment',
  };
  const takePicture = useCallback(() => {
    if (webcamRef.current != null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCameraPreviewSrc(imageSrc);
    }
  }, [webcamRef]);

  const clearPicture = useCallback(() => {
    setCameraPreviewSrc(null);
  }, []);

  const uploadPicture = useCallback(async () => {
    if (cameraPreviewSrc != null) {
      onConfirmPicture(cameraPreviewSrc);
      setCameraPreviewSrc(null);
    }
  }, [cameraPreviewSrc, onConfirmPicture]);
  return (
    <ModalPage
      onClose={() => {
        setCameraPreviewSrc(' ');
        onClose();
      }}
      title={t('assessment.checkList.camera') || ''}>
      <div css={style.cameraContainer}>
        {cameraPreviewSrc != null && (
          <>
            <div css={style.cameraPreview}>
              <Image src={cameraPreviewSrc} />
            </div>
            <div css={style.cameraButtons(true)}>
              <Button onClick={() => clearPicture()} icon>
                <Icon name='cancel' />
              </Button>
              <Button onClick={() => uploadPicture()} icon>
                <Icon name='check' />
              </Button>
            </div>
          </>
        )}

        <>
          <div css={style.camera(cameraPreviewSrc == null)}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              videoConstraints={videoConstraints}
              minScreenshotWidth={1920}
              screenshotQuality={1}
            />
          </div>
          <div css={style.cameraButtons(cameraPreviewSrc == null)}>
            <Button onClick={() => takePicture()} icon>
              <Icon name='camera' />
            </Button>
          </div>
          <div />
        </>
      </div>
    </ModalPage>
  );
};
