import BottegaVenetaCircular from '../../assets/brands/bottegaVenetaCircular.png';
import GucciCircular from '../../assets/brands/gucciCircular.png';
import YvesSaintLaurentCircular from '../../assets/brands/saintLaurentCircular.png';
import AlexanderMcQueenCircular from '../../assets/brands/alexanderMcQueenCircular.png';
import BalenciagaCircular from '../../assets/brands/balenciagaCircular.png';
import BrioniCircular from '../../assets/brands/brioniCircular.png';
import QeelinCircular from '../../assets/brands/qeelinCircular.png';
import BoucheronCircular from '../../assets/brands/boucheronCircular.png';
import PomellatoCircular from '../../assets/brands/pomellatoCircular.png';
import DodoCircular from '../../assets/brands/dodoCircular.png';
import UnCircular from '../../assets/brands/unCircular.png';
import KeringCircular from '../../assets/brands/keringCircular.png';
import KeringEyewearCircular from '../../assets/brands/keringEyewearCircular.png';

export interface Brand {
  logo: string;
  code: string;
  description: string;
}
const getDescription = (code: string): string => {
  switch (code) {
    case 'ALEXANDER_MCQUEEN':
      return 'Alexander McQueen';
    case 'BALENCIAGA':
      return 'Balenciaga';
    case 'BOTTEGA_VENETA':
      return 'Bottega Veneta';
    case 'BOUCHERON':
      return 'Boucheron';
    case 'BRIONI':
      return 'Brioni';
    case 'DODO':
      return 'Dodo';
    case 'GUCCI':
      return 'Gucci';
    case 'POMELLATO':
      return 'Pomellato';
    case 'QEELIN':
      return 'Qeelin';
    case 'MCQ':
      return 'McQ';
    case 'YVES_SAINT_LAURENT':
      return 'Yves Saint Laurent';
    case 'ULYSSE_NARDIN':
      return 'Ulysse Nardin';
    case 'KERING':
      return 'Kering';
    default:
      return '';
  }
};

export const emptyBrand = (): Brand => ({
  code: '',
  logo: '',
  description: '',
});

export const toBrand = (code: string): Brand => ({
  code,
  logo: getLogo(code),
  description: getDescription(code),
});

export const addLogoToBrand = (brand: Brand): Brand => ({
  code: brand.code,
  logo: getLogo(brand.code),
  description: brand.description,
});

export const toAuditBrand = (brand: Record<string, unknown>): Brand => ({
  code: brand.code as string,
  description: brand.description as string,
  logo: getLogo(brand.code as string),
});

export const getLogo = (code: string): string => {
  switch (code) {
    case 'ALEXANDER_MCQUEEN':
      return AlexanderMcQueenCircular;
    case 'BALENCIAGA':
      return BalenciagaCircular;
    case 'BOTTEGA_VENETA':
      return BottegaVenetaCircular;
    case 'BOUCHERON':
      return BoucheronCircular;
    case 'BRIONI':
      return BrioniCircular;
    case 'DODO':
      return DodoCircular;
    case 'GUCCI':
      return GucciCircular;
    case 'POMELLATO':
      return PomellatoCircular;
    case 'QEELIN':
      return QeelinCircular;
    case 'MCQ':
      return AlexanderMcQueenCircular;
    case 'YVES_SAINT_LAURENT':
      return YvesSaintLaurentCircular;
    case 'ULYSSE_NARDIN':
      return UnCircular;
    case 'KERING_EYEWEAR':
      return KeringEyewearCircular;
    default:
      return KeringCircular;
  }
};

export enum BrandCode {
  Gucci = 'GUCCI',
  YvesSaintlaurent = 'YVES_SAINT_LAURENT',
  BottegaVeneta = 'BOTTEGA_VENETA',
  Balenciaga = 'BALENCIAGA',
  AlexanderMcQueen = 'ALEXANDER_MCQUEEN',
  Brioni = 'BRIONI',
  Pomellato = 'POMELLATO',
  Boucheron = 'BOUCHERON',
  Qeelin = 'QEELIN',
  Dodo = 'DODO',
}

export enum BrandDescription {
  Gucci = 'Gucci',
  YvesSaintlaurent = 'Yves Saint Laurent',
  BottegaVeneta = 'Bottega Veneta',
  Balenciaga = 'Balenciaga',
  AlexanderMcQueen = 'Alexander McQueen',
  Brioni = 'Brioni',
  Pomellato = 'Pomellato',
  Boucheron = 'Boucheron',
  Qeelin = 'Qeelin',
  Dodo = 'Dodo',
}

export const brandCodeList: BrandCode[] = Object.values(BrandCode);

export const sortBrandList = (a: Brand, b: Brand) =>
  brandCodeList.indexOf(a.code as BrandCode) - brandCodeList.indexOf(b.code as BrandCode);


export const brandDescriptionList: BrandDescription[] = Object.values(BrandDescription)

export const sortBrandDescriptionList = (a: string | number, b: string | number) => 
  brandDescriptionList.indexOf(a as BrandDescription) - brandDescriptionList.indexOf(b as BrandDescription)
