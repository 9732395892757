import { css } from '@emotion/react';
import style from '../../../../shared/global.style';

const filterAndPageSizeContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
  > form {
    margin-left: auto;
  }
`;

const tableContainer = css`
  position: relative;
  background-color: var(--saa-white-color);
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  && {
    > table {
      border: none;
      box-shadow: none;

      > tbody {
        > tr:last-child {
          box-shadow: 0 1px 0 0 var(--saa-primary-color-10);
        }
      }
    }
  }
`;

const paginationContainer = css`
  && {
    border: none;
    box-shadow: none;
    border-radius: 0;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`;

const counter = css`
  ${style.subtitleSmall};
  margin: auto;
  padding: 0 10px;
  @media (max-width: 760px) {
    display: none;
  }
`;

const footer = css`
  display: flex;
  border-top: 1px solid var(--saa-primary-color-20);
  border-bottom: 1px solid var(--saa-primary-color-20);
  background-color: var(--saa-white-color);
`;

export default {
  filterAndPageSizeContainer,
  tableContainer,
  paginationContainer,
  footer,
  counter,
};
