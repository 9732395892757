/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../core/store';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';
import { AssessmentArchiveFilters } from '../../model/assessmentArchiveQueryParams';
import { fetchStores, selectIsFetching, selectStores } from '../../store/assessmentsArchiveSlice';
import { Store } from '../../../../shared/model/store.model';

interface StoreMultiSelectFieldProps {
  filters: AssessmentArchiveFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (value: string[]) => void;
}

export function StoreMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: StoreMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchStores(filters));
    }
  }, [dispatch, editable, filters]);

  const storeList = useSelector(selectStores);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Store | undefined): SelectItem => {
    if (item == null) {
      return { code: '', description: '' };
    }

    return {
      code: item.jdaCode,
      description: `${item.jdaCode} - ${item.name}`,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='shopping bag'
      defaultValue={
        defaultValue != null ? defaultValue.map(v => toSelectItem(storeList.find(s => s.jdaCode === v))) : []
      }
      loading={isFetching}
      list={storeList.map((b): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.archive.filters.jdaCodes') || ''}
      editable={editable}
      onUpdateValue={(selectedStore: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedStore.map(i => toString(i)));
        }
      }}
    />
  );
}
