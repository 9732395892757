import { css } from '@emotion/react';
import fieldStyle from './field.style';
import style from '../../../shared/global.style';

const auditingResult = css`
  && {
    display: flex;
    background-color: var(--saa-primary-color-20);
    padding: 5px 10px;
    border-bottom: solid 2px var(--saa-primary-color-10);
    cursor: pointer;
    align-items: center;
    position: relative;
  }
`;

const auditingResultIcon = css`
  ${fieldStyle.editIcon};
  &&&& {
    position: relative;
  }
`;

const auditingResultDescription = css`
  ${style.bodySmall}
  && {
    flex: 1;
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--saa-primary-color-80);
  }
`;

export default {
  auditingResult,
  auditingResultIcon,
  auditingResultDescription,
};
