import { css } from '@emotion/react';

const container = css`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: var(--saa-primary-color);
`;

const title = css`
  && {
    margin: auto;
    color: var(--saa-white-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 540px) {
      padding-left: 50px;
    }
  }
`;

const menuButtonContainer = css`
  && {
    position: absolute;
    top: 10px;
  }
`;

export default {
  container,
  title,
  menuButtonContainer,
};
