import axios from 'axios';
import qs from 'query-string';
import paths from './paths';

import { Brand, toBrand } from '../../../shared/model/brand.model';
import { AssessmentReportResult, toAssessmentReportResults } from '../model/assessmentReportResult';
import { AssessmentReportQueryParams } from '../model/assessmentReportQueryParams';
import { Store, toStore } from '../../../shared/model/store.model';

const api = {
  getAssessmentReportResults: async (params: AssessmentReportQueryParams): Promise<AssessmentReportResult[]> => {
    const response = await axios.get(paths.getAssessmentReportResults(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.flatMap(toAssessmentReportResults);
  },
  getStatuses: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getStatuses(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getFollowUpStatuses: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getFollowUpStatuses(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getPriorities: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getPriorities(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getBrands: async (params: AssessmentReportQueryParams): Promise<Brand[]> => {
    const response = await axios.get(paths.getBrands(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrand);
  },
  getRegions: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getRegions(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getCountries: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getCountries(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getCities: async (params: AssessmentReportQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getCities(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getStores: async (params: AssessmentReportQueryParams): Promise<Store[]> => {
    const response = await axios.get(paths.getStores(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toStore);
  },
};

export default api;
