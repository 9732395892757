import { css } from '@emotion/react';

const inputContainer = css`
  &&&& {
    > div {
      width: 100%;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
  }
`;

const input = css`
  &&&&&& {
    input {
      line-height: 1.21428571em;
      padding: 0.67857143em 1em 0.67857143em 1em;
      font-size: 1.143rem;
    }
  }
`;

const nameContainer = css`
  ${input};
  ${inputContainer};
  padding-top: 10px;
  .ui.left.corner.label:after {
    top: -1px;
  }
`;

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const actionPlanCoordinatorList = css`
  padding: 0 10px;
  margin: 5px 0;
  min-height: 123px;
`;
const actionPlanCoordinatorItem = css`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--saa-primary-color-20);
  }

  && {
    button {
      margin-left: auto;
      border-radius: 20px;
      background-color: var(--saa-white-color);
    }
  }
`;

const actionPlanCoordinatorInfo = css`
  padding-left: 5px;
`;
const actionPlanCoordinatorEmail = css`
  word-break: break-word;
`;
const actionPlanCoordinatorName = css`
  font-size: 0.8rem;
`;
const actionPlanCoordinatorRole = css`
  font-size: 0.8rem;
`;

const addCoordinatorButton = css`
  display: flex;
  justify-content: center;
`;

const formButtons = css`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`;
const addCoordinatorForm = css`
  &&& {
    padding: 10px;
  }
  border-bottom: 1px solid var(--saa-primary-color-40);
`;

const disclaimer = css`
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0 10px 5px;
`;

const formContainer = css`
  display: flex;
  flex-direction: column;
`;

export default {
  button,
  nameContainer,
  actionPlanCoordinatorList,
  actionPlanCoordinatorItem,
  actionPlanCoordinatorInfo,
  actionPlanCoordinatorEmail,
  actionPlanCoordinatorName,
  actionPlanCoordinatorRole,
  addCoordinatorButton,
  formButtons,
  addCoordinatorForm,
  input,
  disclaimer,
  formContainer,
};
