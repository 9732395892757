import { Brand } from '../../../shared/model/brand.model';
import { AssessmentSharedFilter } from './assessmentFilter.model';

export interface AssessmentArchiveQueryParams {
  brands: string[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  yearFrom?: number;
  monthFrom?: number;
  yearTo?: number;
  monthTo?: number;
  auditors: string[];
  brandCoordinators: string[];
  distributionListMembers: string[];
  readOnlyDistributionListMembers: string[];
}

export interface AssessmentArchiveFilters {
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  yearFrom?: number;
  monthFrom?: number;
  yearTo?: number;
  monthTo?: number;
  auditors: string[];
  brandCoordinators: string[];
  distributionListMembers: string[];
  readOnlyDistributionListMembers: string[];
}

export const emptyAssessmentArchiveFilters = {
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  jdaCodes: [],
  monthFrom: undefined,
  monthTo: undefined,
  yearFrom: undefined,
  yearTo: undefined,
  auditors: [],
  brandCoordinators: [],
  distributionListMembers: [],
  readOnlyDistributionListMembers: [],
};

export const covertFiltersInQueryParams = (filters: AssessmentArchiveFilters): AssessmentArchiveQueryParams => ({
  ...filters,
  brands: filters.brands.map(b => b.code),
});

export const convertSharedFilterInAssessmentArchiveFilter = (
  filters: AssessmentSharedFilter
): AssessmentArchiveFilters => ({
  brands: filters.brands,
  regions: filters.regions,
  countries: filters.countries,
  cities: filters.cities || [],
  jdaCodes: filters.jdaCodes || [],
  yearFrom: filters.yearFrom,
  monthFrom: filters.monthFrom,
  yearTo: filters.yearTo,
  monthTo: filters.monthTo,
  auditors: filters.auditors,
  brandCoordinators: filters.brandCoordinators || [],
  distributionListMembers: filters.distributionListMembers || [],
  readOnlyDistributionListMembers: filters.readOnlyDistributionListMembers || [],
});
