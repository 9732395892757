/** @jsxImportSource @emotion/react */
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import style from './auditPlanningWizard.style';
import { CampaignCreationStep } from './CampaignCreationStep';
import { CampaignSelectionStep } from './CampaignSelectionStep';
import { AuditCampaign } from '../../model/auditCampaign';
import {
  onCampaignCreation,
  onCampaignSelection,
  onStepChange,
  resetToInitialState,
  selectCampaign,
  selectStep,
  WIZARD_STEP,
} from '../../store/auditProposalWizardSlice';
import { useAppDispatch } from '../../../../core/store';
import { ProposalCreationStep } from './ProposalCreationStep';

interface AuditPlanningWizardProps {
  onSuccessfulCreation: () => void;
}

export function AuditPlanningWizard({ onSuccessfulCreation }: AuditPlanningWizardProps): JSX.Element {
  return (
    <div css={style.container}>
      <WizardStep onSuccessfulCreation={onSuccessfulCreation} />
    </div>
  );
}

interface WizardStepProps {
  onSuccessfulCreation: () => void;
}

function WizardStep({ onSuccessfulCreation }: WizardStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const step = useSelector(selectStep);
  const campaign = useSelector(selectCampaign);
  switch (step) {
    case WIZARD_STEP.CREATE_CAMPAIGN:
      return (
        <CampaignCreationStep
          onCampaignSave={(newCampaign: AuditCampaign) => {
            dispatch(onCampaignCreation(newCampaign));
            dispatch(onStepChange(WIZARD_STEP.CREATE_PROPOSAL));
          }}
          onBack={() => {
            dispatch(resetToInitialState());
            dispatch(onStepChange(WIZARD_STEP.SELECT_CAMPAIGN));
          }}
        />
      );
    case WIZARD_STEP.CREATE_PROPOSAL:
      return (
        <ProposalCreationStep
          onSuccessfulCreation={() => {
            onSuccessfulCreation();
          }}
          onBack={() => {
            if (campaign != null && campaign.id === '') {
              dispatch(onStepChange(WIZARD_STEP.CREATE_CAMPAIGN));
            } else {
              dispatch(onStepChange(WIZARD_STEP.SELECT_CAMPAIGN));
            }
          }}
        />
      );
    case WIZARD_STEP.LOADING:
      return (
        <div>
          <Loader size='large' active />
        </div>
      );
    case WIZARD_STEP.SELECT_CAMPAIGN:
    default:
      return (
        <CampaignSelectionStep
          onCampaignSelection={(campaignId: string) => {
            dispatch(onCampaignSelection(campaignId));
            dispatch(onStepChange(WIZARD_STEP.CREATE_PROPOSAL));
          }}
          onCampaignCreation={() => {
            dispatch(resetToInitialState());
            dispatch(onStepChange(WIZARD_STEP.CREATE_CAMPAIGN));
          }}
        />
      );
  }
}
