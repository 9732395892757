/** @jsxImportSource @emotion/react */
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import style from './detailFooter.style';
import { ReproposeModalContent } from './ReproposeModalContent';
import { ProposedPeriod } from '../../../model/genericAuditProposal';

interface ReproposeButtonProps {
  buttonLabel: string;
  onConfirm: (proposedPeriods: ProposedPeriod[], note: string, isReproposed: boolean) => void;
  confirmHeader?: string;
}

export function ReproposeButton({
  buttonLabel,
  onConfirm,
  confirmHeader = t('auditCampaigns.proposalDetail.footer.reproposedHeader') || '',
}: ReproposeButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);

  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>([]);
  const [note, setNote] = useState('');
  const [isReproposed, setIsReproposed] = useState(true);

  useEffect(() => {
    if (isReproposed) {
      setIsConfirmDisabled(note === '' || proposedPeriodsState.length === 0);
    } else {
      setIsConfirmDisabled(note === '');
    }
  }, [note, isReproposed, proposedPeriodsState.length]);

  const onContentChange = (
    contentProposedPeriods: ProposedPeriod[],
    contentNote: string,
    contentIsRepropose: boolean
  ) => {
    setProposedPeriodsState(contentProposedPeriods);
    setNote(contentNote);
    setIsReproposed(contentIsRepropose);
  };

  return (
    <>
      <Button
        css={style.fatFooterButtonStart}
        labelPosition='left'
        icon='backward'
        content={buttonLabel}
        onClick={() => setConfirmIsOpen(true)}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        header={confirmHeader}
        onCancel={() => setConfirmIsOpen(false)}
        content={<ReproposeModalContent onChange={onContentChange} />}
        onConfirm={() => {
          setConfirmIsOpen(false);
          onConfirm(proposedPeriodsState, note, isReproposed);
        }}
        confirmButton={
          <Button disabled={isConfirmDisabled} css={style.confirmButton}>
            Confirm
          </Button>
        }
        size='mini'
      />
    </>
  );
}
