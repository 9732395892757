/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { Contact } from '../../users/model/user.model';

interface RegionalManagersSliceState {
  data: Contact[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: RegionalManagersSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const regionalManagersSlice = createSlice({
  name: 'regionalManagers',
  initialState,
  reducers: {
    startFetch: (state: Draft<RegionalManagersSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<RegionalManagersSliceState>, { payload }: PayloadAction<Contact[]>) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    errorFetch: (state: Draft<RegionalManagersSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<RegionalManagersSliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache } = regionalManagersSlice.actions;

export const fetchRegionalManagers = (keringRegion: string) => async (dispatch: any, state: any) => {
  if (!state().regionalManagers.isFetching) {
    dispatch(startFetch());
    try {
      const regionalManagers = (await api.getRegionalManagers(keringRegion)).sort((a1, a2) => a1.email.localeCompare(a2.email));
      dispatch(finishFetch(regionalManagers));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const selectRegionalManagers = (state: any) => state.regionalManagers.data as Contact[];

export const selectIsFetching = (state: any) => state.regionalManagers.isFetching;

export default regionalManagersSlice.reducer;