/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from '../../../../shared/model/store.model';
import { useAppDispatch } from '../../../../core/store';

import { ButtonConfiguration } from '../WizardFooter';
import { StepSegment } from '../StepSegment';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { createBrandProposal, selectSelectedStore } from '../../store/brandAuditProposalWizardSlice';
import { BrandAuditProposalDetailFields } from '../proposalDetail/brandAuditProposalDetail/BrandAuditProposalDetailFields';
import { BrandAuditProposal, BrandAuditProposalRequest } from '../../model/brandAuditProposal';
import { BRAND_AUDIT_PROPOSAL_STATUS, ProposedPeriod } from '../../model/genericAuditProposal';
import { Contact } from '../../../users/model/user.model';

interface BrandProposalCreationStepProps {
  onSuccessfulCreation: () => void;
  onBack: () => void;
}

export const BrandProposalCreationStep = ({
  onSuccessfulCreation,
  onBack,
}: BrandProposalCreationStepProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const principal = useSelector(selectPrincipal);
  const selectedStore = useSelector(selectSelectedStore);
  const [distributionList, setDistributionList] = useState<Contact[]>([]);
  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>([]);
  const [isCreating, setIsCreating] = useState(false);

  const proposal: BrandAuditProposal = useMemo(
    () => ({
      id: '',
      store: selectedStore as Store,
      proposedByBrandDateFrom: '',
      proposedByBrandDateTo: '',
      proposedDateFrom: '',
      proposedDateTo: '',
      proposedPeriods: proposedPeriodsState,
      brandSchedulers: [],
      distributionList: [],
      auditors: [],
      status: BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED,
      createdBy: { name: principal.name, email: principal.email },
      createdAt: '',
      type: 'brand',
    }),
    [selectedStore, proposedPeriodsState, principal.name, principal.email]
  );

  const buildProposalRequest = useCallback((): BrandAuditProposalRequest => {
    return {
      storeJdaCode: (selectedStore as Store).jdaCode,
      proposedPeriods: proposedPeriodsState,
      distributionList: distributionList.map(c => c.email),
    };
  }, [selectedStore, proposedPeriodsState, distributionList]);

  const createProposal = useCallback(async () => {
    setIsCreating(true);
    const result = await dispatch(createBrandProposal(buildProposalRequest()));
    setIsCreating(false);
    if (result) {
      onSuccessfulCreation();
    }
  }, [dispatch, onSuccessfulCreation, buildProposalRequest]);

  const canCreate = useMemo(
    () => selectedStore != null && proposedPeriodsState.length > 0,
    [proposedPeriodsState.length, selectedStore]
  );

  const setCalendar = (proposedPeriods: ProposedPeriod[]) => {
    setProposedPeriodsState(proposedPeriods);
  };

  const buttonsConfig = useMemo((): ButtonConfiguration[] => {
    return [
      {
        content: t('brandAuditProposals.actions.back'),
        onClick: onBack,
        icon: 'angle left',
        labelPosition: 'left',
      },
      {
        content: t('brandAuditProposals.actions.sendToAuditor'),
        onClick: createProposal,
        icon: 'save',
        labelPosition: 'left',
        disabled: !canCreate,
      },
    ];
  }, [t, createProposal, canCreate, onBack]);

  return (
    <StepSegment footerConfiguration={buttonsConfig}>
      {isCreating ? (
        <Dimmer active inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
      ) : (
        <></>
      )}
      <BrandAuditProposalDetailFields
        editable
        proposal={proposal}
        onBrandCalendarChange={setCalendar}
        onDistributionListChange={setDistributionList}
      />
    </StepSegment>
  );
};
