/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../../../shared/model/store.model';
import { reset } from './availableStoresSlice';

interface onCreationAssessmentSliceState {
  isCreating: boolean;
  selectedStore: Store | null;
}

const initialState: onCreationAssessmentSliceState = {
  isCreating: false,
  selectedStore: null,
};

export const onCreationAssessmentSlice = createSlice({
  name: 'onCreationAssessment',
  initialState,
  reducers: {
    startCreation: (state: Draft<onCreationAssessmentSliceState>) => ({
      ...state,
      isCreating: true,
    }),
    selectStore: (state: Draft<onCreationAssessmentSliceState>, action: PayloadAction<Store>) => ({
      ...state,
      selectedStore: action.payload,
    }),
    resetSelectedStore: (state: Draft<onCreationAssessmentSliceState>) => ({
      ...state,
      selectedStore: null,
    }),
    endCreation: (state: Draft<onCreationAssessmentSliceState>) => ({
      ...state,
      isCreating: false,
    }),
  },
});

export const { startCreation, selectStore, resetSelectedStore, endCreation } = onCreationAssessmentSlice.actions;

export const onCloseCreation = () => (dispatch: any, state: any) => {
  dispatch(endCreation());
  dispatch(resetSelectedStore());
  dispatch(reset());
};

export const selectIsCreating = (state: any): boolean => state.onCreationAssessment.isCreating;

export const selectSelectedStore = (state: any): Store | null => state.onCreationAssessment.selectedStore;

export default onCreationAssessmentSlice.reducer;
