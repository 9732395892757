/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import style from './resultItemField.style';
import { Priority, ResultStatus } from '../model/assessmentDetail.model';
import fetaureStyle from './feature.style';

interface ErrorResultItemProps {
  onClick: () => void;
  iconName?: SemanticICONS;
  className?: string;
  value: string;
  status?: ResultStatus | '';
  priority?: Priority | '';
}

export const ResultItemField = ({
  onClick,
  iconName,
  className = '',
  value,
  status = '',
  priority = '',
}: ErrorResultItemProps): JSX.Element => {
  return (
    <div css={style.auditingResult} onClick={onClick}>
      <Icon className={className} name={iconName} css={style.auditingResultIcon} />
      <div css={style.auditingResultDescription}>{value}</div>
      {status === ResultStatus.FAILED && (
        <Icon className='fas fa-circle' css={fetaureStyle.priorityIconColor(priority as Priority)} />
      )}
    </div>
  );
};
