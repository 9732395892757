import { css, SerializedStyles } from '@emotion/react';
import fieldStyle from './field.style';
import globalStyle from '../../../shared/global.style';

const textTitle = (isValid: boolean): SerializedStyles => css`
  ${fieldStyle.freeTextTitle(isValid)}
`;

const validationIcon = css`
  ${fieldStyle.validationIcon}
`;

const field = css`
  ${fieldStyle.labeledField}
`;

const editableField = css`
  ${fieldStyle.labeledEditableField}
`;

const editIcon = css`
  ${fieldStyle.editIcon}
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

const inputContainer = css`
  &&&& {
    textarea {
      border-color: var(--saa-primary-color-10);
      outline: none;
      width: 100%;
      height: 235px;
      max-height: 235px;
      min-width: 250px;
      min-height: 235px;
      overflow: auto;
      resize: none;
    }
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const checkBoxContainer = css`
  && {
    border-bottom: solid 1px var(--saa-primary-color-10);
    padding: 10px;
  }
`;

const checkBox = css`
  && {
    width: 100%;
    ${globalStyle.bodyNormal}
    input {
      top: 4px;
    }
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      :before {
        top: 4px;
      }
    }
  }
`;

const value = css`
  line-height: 16px;
`;

export default {
  textTitle,
  field,
  editableField,
  editIcon,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  validationIcon,
  checkBoxContainer,
  checkBox,
  value,
};
