/** @jsxImportSource @emotion/react */
import { t } from 'i18next';
import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import style from './detailFooter.style';
import { RejectModalContent } from './RejectModalContent';

interface RejectButtonProps {
  buttonLabel: string;
  onConfirm: (note: string) => void;
  confirmHeader?: string;
}

export function RejectButton({
  buttonLabel,
  onConfirm,
  confirmHeader = t('auditCampaigns.proposalDetail.footer.rejectHeader') || '',
}: RejectButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);

  const [note, setNote] = useState('');

  const onContentChange = (rejectNote: string) => {
    setNote(rejectNote);
    setIsConfirmDisabled(rejectNote === '');
  };

  return (
    <>
      <Button
        css={style.fatFooterButtonStart}
        labelPosition='left'
        icon='backward'
        content={buttonLabel}
        onClick={() => setConfirmIsOpen(true)}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        header={confirmHeader}
        onCancel={() => setConfirmIsOpen(false)}
        content={<RejectModalContent onChange={onContentChange} />}
        onConfirm={() => {
          setConfirmIsOpen(false);
          onConfirm(note);
        }}
        confirmButton={
          <Button disabled={isConfirmDisabled} css={style.confirmButton}>
            Confirm
          </Button>
        }
        size='small'
      />
    </>
  );
}
