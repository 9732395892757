import { css } from '@emotion/react';

const CalendarField = css`
  && {
    display: flex;
    overflow: hidden;
    > div {
      flex: 1;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

const RadioButtonField = css`
  &&& {
    display: flex;
    margin: 0px;
    padding 15px;
    width: 180px;
    text-align: center;
    align-items: center;
    margin-left: 10px;
  }
`;

const fieldContent = css`
  &&& {
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 0px;
    margin-top: 0px;
  }
`;

const checkBoxContent = css`
  background-color: var(--saa-white-color);
  padding: 10px;
  display: flex;

  align-items: stretch;
  justify-content: space-around;

  border-top: 3px solid var(--saa-primary-color-10);
  border-bottom: 4px solid var(--saa-primary-color-10);
`;

export default {
  CalendarField,
  fieldContent,
  checkBoxContent,
  RadioButtonField,
};
