import { css } from '@emotion/react';

const header = css`
  @media (min-width: 760px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  background-color: var(--saa-primary-color-80);
  &&&&& {
    th {
      color: var(--saa-white-color);
      border-radius: 0;
      background-color: var(--saa-primary-color-80);
    }
    th:hover {
      color: var(--saa-white-color);
      border-radius: 0;
      background-color: var(--saa-primary-color-60);
    }
  }
`;

const checkbox = css`
  margin-top: 5px;
`;

export default {
  header,
  checkbox,
};
