import { css } from '@emotion/react';

const field = css`
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: solid 1px var(--saa-primary-color-10);
  i {
    flex-shrink: 0;
  }
`;

const editableField = css`
  ${field};
  cursor: pointer;
`;

const valueContainer = css`
  margin: 0 10px;
  padding-right: 10px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const checkBoxContainer = css`
  display: flex;
  margin-left: auto;
`;

export default {
  field,
  editableField,
  valueContainer,
  checkBoxContainer,
};
