import { css, SerializedStyles } from '@emotion/react';
import style from '../../../../../shared/global.style';
import fieldStyle from '../../../../assessments/components/field.style';
import { brandProposalStatusColor } from '../../auditProposalCard.style';
import { BRAND_AUDIT_PROPOSAL_STATUS } from '../../../model/genericAuditProposal';

const header = css`
  display: flex;
  align-items: center;
  background-color: var(--saa-primary-color);
  color: var(--saa-white-color);
`;

const backIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    margin: 0 0 0 10px;
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const proposalHeader = css`
  flex: 1 1 0;
  width: 287px;
`;

const modal = css`
  ${fieldStyle.modal}
`;

const modalContent = css`
  ${fieldStyle.modalContent};
`;

const statusIcon = (status: BRAND_AUDIT_PROPOSAL_STATUS): SerializedStyles => {
  const color = brandProposalStatusColor(status);

  return css`
    && {
      display: flex;
      justify-content: center;
      margin-right: 10px;
      color: ${color};
    }
  `;
};

const smallDotsContainer = css`
  display: flex;
  margin: 0 10px 0 auto;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const smallDot = (status: BRAND_AUDIT_PROPOSAL_STATUS): SerializedStyles => {
  const color = brandProposalStatusColor(status);
  return css`
    && {
      height: 10px;
      width: 10px;
      margin-left: 3px;
      background-color: ${color};
      border-radius: 50%;
      display: inline-block;
    }
  `;
};

const menuItemContainer = css`
  && {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-40);
      > i {
        color: var(--saa-primary-color-40);
      }
    }
  }
`;

const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;

const fileIcon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    color: var(--saa-primary-color);
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;
export default {
  header,
  backIcon,
  proposalHeader,
  modal,
  modalContent,
  fileIcon,
  statusIcon,
  smallDotsContainer,
  smallDot,
  menuItemContainer,
  modalConfirm,
  confirmButton,
  actions,
  button,
};
