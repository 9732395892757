/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './overviewAssessmentField.style';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface AssessmentFieldProps {
  defaultValue?: string;
  editable?: boolean;
  onEdited?: (values: string) => void;
  title?: string;
}

export const OverviewAssessmentField = ({
  defaultValue = '',
  editable = false,
  onEdited = () => null,
  title = '',
}: AssessmentFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [overviewState, setOverviewState] = React.useState(defaultValue);
  const { t } = useTranslation();
  const updateValue = () => {
    if (editable) {
      onEdited(overviewState);
    }
    setOpen(false);
  };
  const cancelValue = () => {
    setOverviewState(defaultValue);
    setOpen(false);
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editable) {
      setOverviewState(event.target.value);
    }
  };

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => setOpen(true)}>
        <Icon className='fas fa-file-alt' />
        <div css={style.valueContainer}>
          <span css={style.value}> {defaultValue} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable ? (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        ) : (
          <div css={style.editIcon}>
            <Icon name='search' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{t('assessment.overview')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <textarea value={overviewState} onChange={event => onChangeInput(event)} disabled={!editable} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
