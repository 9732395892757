import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../core/store';
import api from '../utils/api';
import { AssessmentFollowUp, sortedFollowUpStatus } from '../model/assessmentFollowUp.model';
import { checkValueInclude, groupBy } from '../../../shared/utils';
import { FollowUpStatus } from '../model/assessmentDetail.model';

interface AssessmentsFollowUpSliceState {
  data: AssessmentFollowUp[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
  filter: string;
}

const initialState: AssessmentsFollowUpSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
  filter: '',
};

export const assessmentsFollowUpSlice = createSlice({
  name: 'assessmentsFollowUp',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentsFollowUpSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<AssessmentsFollowUpSliceState>, action: PayloadAction<AssessmentFollowUp[]>) => {
      return {
        ...state,
        isFetching: false,
        data: [...action.payload],
        error: '',
        isValidCache: true,
      };
    },
    httpError: (state: Draft<AssessmentsFollowUpSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setFilter: (state: Draft<AssessmentsFollowUpSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
  },
});

export default assessmentsFollowUpSlice.reducer;

export const { startFetch, finishFetch, httpError, setFilter } = assessmentsFollowUpSlice.actions;

export const fetchAssessmentsFollowUp = () => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const assessments = await api.getAssessmentsFollowUp();
    dispatch(finishFetch(assessments));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onChangeFilter = (filterValue: string) => async (dispatch: any) => {
  dispatch(setFilter(filterValue));
};

export const selectIsFetching = (state: RootState): boolean => state.assessmentsFollowUp.isFetching;

export const selectAssessmentsFollowUpFilter = (state: RootState): string => state.assessmentsFollowUp.filter;

const selectAssessmentsFollowUp = (state: RootState): AssessmentFollowUp[] => state.assessmentsFollowUp.data;

const selectFilteredAssessmentsFollowUp = createSelector(
  selectAssessmentsFollowUp,
  selectAssessmentsFollowUpFilter,
  (assessments, filter) => {
    return assessments.filter(
      a =>
        checkValueInclude(a.code, filter) ||
        checkValueInclude(a.store.hfmCode, filter) ||
        checkValueInclude(a.store.brand.description, filter) ||
        checkValueInclude(a.store.keringRegion, filter) ||
        checkValueInclude(a.store.city, filter) ||
        checkValueInclude(a.store.name, filter) ||
        a.auditors.some(auditor => checkValueInclude(auditor.email, filter)) ||
        a.auditors.some(auditor => checkValueInclude(auditor.name, filter))
    );
  }
);

const selectSortedAndFilteredAssessmentsFollowUp = createSelector(
  selectFilteredAssessmentsFollowUp,
  assessmentsFollowUp => {
    return [...assessmentsFollowUp].sort((a: AssessmentFollowUp, b: AssessmentFollowUp) =>
      sortedFollowUpStatus.indexOf(a.followUpStatus) > sortedFollowUpStatus.indexOf(b.followUpStatus) ? 1 : -1
    );
  }
);

export const selectGroupedAssessmentsFollowUp = createSelector(
  selectSortedAndFilteredAssessmentsFollowUp,
  sortedAssessment => {
    return groupBy('followUpStatus')(sortedAssessment) as Record<FollowUpStatus, AssessmentFollowUp[]>;
  }
);
