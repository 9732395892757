import { css } from '@emotion/react';
import style from './field.style';

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;

const inputContainer = css`
  &&&& {
    > div {
      width: 100%;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
  }
`;

export default {
  actions,
  button,
  modal,
  modalContent,
  inputContainer,
};
