export interface Region {
  code: string;
  description: string;
}

export const toRegion = (o: Record<string, string>): Region => ({
  code: o.code,
  description: o.description,
});

export const emptyRegion = (): Region => ({
  code: '',
  description: '',
});
