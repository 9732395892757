import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { BrandScheduler, BrandSchedulerQueryParams } from '../model/brandScheduler';

interface BrandSchedulersSliceState {
  data: BrandScheduler[];
  isFetching: boolean;
  error: string;
}

export const initialState: BrandSchedulersSliceState = {
  data: [],
  isFetching: false,
  error: '',
};

const brandSchedulersSlice = createSlice({
  name: 'brandSchedulers',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandSchedulersSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<BrandSchedulersSliceState>, { payload }: PayloadAction<BrandScheduler[]>) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
      };
    },
    errorFetch: (state: Draft<BrandSchedulersSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<BrandSchedulersSliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache } = brandSchedulersSlice.actions;

export const fetchBrandSchedulers = (params: BrandSchedulerQueryParams) => async (dispatch: any, state: any) => {
  if (!state().brandSchedulers.isFetching) {
    dispatch(startFetch());
    try {
      const brandSchedulers = (await api.getBrandSchedulers(params)).sort((bs1, bs2) =>
        bs1.email.localeCompare(bs2.email)
      );
      dispatch(finishFetch(brandSchedulers));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const selectBrandSchedulers = (state: any) => state.brandSchedulers.data as BrandScheduler[];
export const selectIsFetchingBrandSchedulers = (state: any): boolean => state.brandSchedulers.isFetching;

export default brandSchedulersSlice.reducer;
