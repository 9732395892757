/** @jsxImportSource @emotion/react */

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Confirm } from 'semantic-ui-react';
import { User } from '../model/user.model';
import style from './userCard.style';
import { SimpleField } from '../../../shared/SimpleField';
import { toBrand } from '../../../shared/model/brand.model';
import { ROLES } from '../../auth/model/principal.model';

interface UserCardReadOnlyProps {
  user: User;
  onDelete: (id: string) => void;
  onEdit: () => void;
}

export const UserCardReadOnly = ({ user, onDelete, onEdit }: UserCardReadOnlyProps): JSX.Element => {
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fullName = useMemo((): string => {
    if (user.firstName == null || user.familyName == null) {
      return '-';
    }
    return `${user.firstName} ${user.familyName}`;
  }, [user.firstName, user.familyName]);

  const isRoleAuditor = useMemo((): boolean => {
    return user.roles.includes(ROLES.AUDITOR);
  }, [user.roles]);

  const isRoleArchiveReaderOrBrandScheduler = useMemo((): boolean => {
    return user.roles.includes(ROLES.ARCHIVE_READER) || user.roles.includes(ROLES.BRAND_SCHEDULER);
  }, [user.roles]);

  return (
    <div css={style.card}>
      <div css={style.info}>
        <SimpleField value={user.email} title={t('users.email')} icon='mail' />
        <SimpleField value={`${fullName}`} title={t('users.name')} icon='user' />
      </div>
      <div css={style.roles}>
        <SimpleField value={user.roles.join(', ')} title={t('users.roles')} icon='cogs' />
      </div>
      {isRoleArchiveReaderOrBrandScheduler && (
        <div css={style.info}>
          <SimpleField
            value={user.regions != null ? user.regions.join(', ') : t('users.allRegionsOptionDescription').valueOf()}
            title={t('users.regions')}
            icon='globe'
          />
          <SimpleField
            value={
              user.countries != null
                ? user.countries.map(country => country.description).join(', ')
                : t('users.allCountriesOptionDescription').valueOf()
            }
            title={t('users.countries')}
            icon='globe'
          />
        </div>
      )}
      {isRoleAuditor && (
        <div css={style.info}>
          <SimpleField
            value={
              user.isRegionalManagerOf != null && user.isRegionalManagerOf.length > 0
                ? user.isRegionalManagerOf.join(', ')
                : '-'
            }
            title={t('users.isRegionalManagerOf')}
            icon='globe'
          />
          <SimpleField title='' icon='check'>
            <div css={style.checkBoxContainer}>
              <span css={style.checkBoxLabel}>{t('users.approvalRequired')}</span>
              <Checkbox css={style.checkBox} toggle checked={user.approvalRequired} disabled />
            </div>
          </SimpleField>
        </div>
      )}

      <div css={style.brands}>
        <SimpleField
          value={user.brands.map(b => toBrand(b).description).join(', ')}
          title={t('users.brands')}
          icon='copyright'
        />
      </div>
      <div css={style.buttonsContainer}>
        <Button content={t('users.delete')} icon='trash' onClick={() => setConfirmOpen(true)} />
        <Button content={t('users.edit')} icon='edit' onClick={onEdit} />
        <Confirm
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => onDelete(user.id)}
          size='mini'
        />
      </div>
    </div>
  );
};
