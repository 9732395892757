/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import style from './topicCommentComponent.style';
import { TopicComment } from '../model/assessmentDetail.model';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { useAutosizeTextArea, useCtrlEnterToRunCallback } from '../../../core/utils';

interface TopicCommentProps {
  comment: TopicComment;
  editable?: boolean;
  onUpdateComment: (message: string, commentId: string) => void;
}

export const TopicCommentComponent = ({
  comment,
  editable = false,
  onUpdateComment,
}: TopicCommentProps): JSX.Element => {
  const user = useSelector(selectPrincipal);
  const [editMode, setEditMode] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(comment.message);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, inputValue);

  useEffect(() => {
    setInputValue(comment.message);
  }, [comment]);

  const formatDate = useCallback((d: Date, human: boolean): string => {
    return human ? `${moment(d).fromNow(true)} ago` : moment(d).format('yyyy-MM-DD hh:mm');
  }, []);

  const isMyComment = useCallback((): boolean => {
    return user.email.toLowerCase() === comment.author.email;
  }, [comment.author.email, user.email]);

  const onConfirmIconClick = () => {
    if (inputValue !== '') {
      onUpdateComment(inputValue, comment.id);
      setEditMode(false);
    }
  };

  useCtrlEnterToRunCallback(onConfirmIconClick, editMode);

  const onCancelIconClick = () => {
    setInputValue(comment.message);
    setEditMode(false);
  };

  return (
    <div css={style.commentContainer(comment.fromAuditor, isMyComment())}>
      <div css={style.commentHeader(comment.fromAuditor, isMyComment())}>
        <div css={style.commentAuthor}>{isMyComment() ? 'Me' : comment.author.name} </div>
        <div css={style.commentCreated} title={formatDate(new Date(comment.created), false)}>
          {formatDate(new Date(comment.created), true)}
        </div>
      </div>
      <div css={style.commentContent(editMode)}>
        <textarea
          css={style.commentTextArea(editMode)}
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
          disabled={!editMode}
          ref={textAreaRef}
          rows={1}
        />
      </div>
      <div css={style.commentFooter}>
        {comment.lastUpdate !== null ? (
          <div css={style.commentFooterEdited} title={formatDate(new Date(comment.lastUpdate), false)}>
            Edited {formatDate(new Date(comment.lastUpdate), true)}
          </div>
        ) : (
          <div css={style.commentFooterEdited} />
        )}
        {editable &&
          isMyComment() &&
          (!editMode ? (
            <Button
              icon
              css={style.commentFooterButton}
              onClick={() => {
                setEditMode(true);
              }}>
              <Icon name='pencil' />
            </Button>
          ) : (
            <div>
              <Button icon css={style.commentFooterButton} onClick={onCancelIconClick}>
                <Icon name='x' />
              </Button>
              <Button icon css={style.commentFooterButton} onClick={onConfirmIconClick}>
                <Icon name='check' />
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};
