/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { Country } from '../../../shared/model/country.model';

interface CountryMultiSelectFieldProps {
  editable: boolean;
  countries: Country[];
  defaultValue?: Country[];
  onUpdate?: (country: Country[]) => void;
  loading: boolean;
}

export function CountryMultiSelectField({
  defaultValue = [],
  onUpdate,
  countries,
  editable,
  loading,
}: CountryMultiSelectFieldProps) {
  const toSelectItem = (item: Country): SelectItem => {
    return {
      code: item.code,
      description: item.description,
    };
  };

  return (
    <ModalMultiSelectField
      editable={editable}
      icon='globe'
      defaultValue={defaultValue != null ? defaultValue.map(c => toSelectItem(c)) : []}
      list={countries.map((c): SelectItem => toSelectItem(c) as SelectItem)}
      title={t('users.countries') || ''}
      onUpdateValue={(selectedCountry: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedCountry);
        }
      }}
      loading={loading}
    />
  );
}
