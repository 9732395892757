import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { from } from 'rxjs';
import { AxiosError, AxiosResponse } from 'axios';
import { take } from 'rxjs/operators';
import api from '../utils/api';

type OauthReceiverPros = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  location: any;
  tokenUrl: string;
  redirectUri: string;
  clientId: string;
  onAuthSuccess: (token: string) => void;
  onAuthError: (error: AxiosError) => void;
  render: (processing: boolean) => void;
};

export const OauthReceiver = (props: OauthReceiverPros): JSX.Element => {
  const { location, tokenUrl, redirectUri, clientId, onAuthSuccess, onAuthError, render } = props;
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const { code } = qs.parse(location.search);
    from(api.getToken(tokenUrl, code, redirectUri, clientId))
      .pipe(take(1))
      .subscribe({
        next: (result: AxiosResponse) => {
          onAuthSuccess(result.data.access_token);
        },
        error: e => onAuthError(e),
        complete: () => setProcessing(false),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{render(processing)}</>;
};
