/** @jsxImportSource @emotion/react */
import React from 'react';
import { ButtonConfiguration, WizardFooter } from './WizardFooter';
import style from './stepSegment.style';

export const StepSegment = ({
  children,
  footerConfiguration,
}: {
  children: JSX.Element[] | JSX.Element;
  footerConfiguration?: ButtonConfiguration[];
}) => {
  return (
    <div css={style.stepContainer}>
      <div css={style.stepContent}>{children}</div>
      {footerConfiguration && footerConfiguration.length > 0 && (
        <WizardFooter buttonsConfiguration={footerConfiguration} />
      )}
    </div>
  );
};
