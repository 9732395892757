/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useAppDispatch } from '../../../core/store';
import { selectPrincipal } from '../../auth/store/principalSlice';
import style from './auditProposalDetail.style';
import { BrandAuditProposalDetailFields } from '../components/proposalDetail/brandAuditProposalDetail/BrandAuditProposalDetailFields';
import { BrandAuditProposal, isBrandProposalDeletable, isBrandProposalEditable } from '../model/brandAuditProposal';
import {
  fetchBrandAuditProposal,
  selectBrandAuditProposal,
  selectIsFetching,
} from '../store/brandAuditProposalDetailSlice';
import { BrandAuditProposalDetailHeader } from '../components/proposalDetail/brandAuditProposalDetail/BrandAuditProposalDetailHeader';
import { BrandAuditProposalDetailFooter } from '../components/proposalDetail/brandAuditProposalDetail/BrandAuditProposalDetailFooter';
import { selectIsUpdating } from '../store/auditProposalDetailSlice';

export function BrandAuditProposalDetail(): JSX.Element {
  const { proposalId = '' } = useParams<{ proposalId: string }>();
  const dispatch = useAppDispatch();
  const proposal = useSelector(selectBrandAuditProposal) as BrandAuditProposal;
  const isFetching = useSelector(selectIsFetching);
  const isUpdating = useSelector(selectIsUpdating);
  const principal = useSelector(selectPrincipal);
  const userCanEdit = useMemo((): boolean => {
    return proposal != null ? isBrandProposalEditable(principal, proposal) : false;
  }, [principal, proposal]);
  const userCanDelete = useMemo((): boolean => {
    return proposal != null ? isBrandProposalDeletable(principal, proposal) : false;
  }, [principal, proposal]);

  useEffect(() => {
    dispatch(fetchBrandAuditProposal(proposalId));
  }, [dispatch, proposalId]);

  return isFetching || isUpdating ? (
    <Dimmer active inverted>
      <Loader inverted content='Loading' />
    </Dimmer>
  ) : proposal != null ? (
    <>
      <BrandAuditProposalDetailHeader proposal={proposal} deletable={userCanDelete} />
      <div css={style.component}>
        <div css={style.pageTitle}>{t('brandAuditProposals.proposalDetail.title')}</div>
        <BrandAuditProposalDetailFields proposal={proposal} editable={userCanEdit} />
        <BrandAuditProposalDetailFooter />
      </div>
    </>
  ) : (
    <div css={style.notFound}>{t('brandAuditProposals.proposalDetail.proposalNotFound')}</div>
  );
}
