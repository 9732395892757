import { checkRole, checkRoles, Principal, ROLES } from '../../auth/model/principal.model';

export type StatusCode =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'MERGING'
  | 'READY_TO_REVIEW_BY_REGIONAL_MANAGER'
  | 'UNDER_REVIEW_BY_REGIONAL_MANAGER'
  | 'READY_TO_REVIEW'
  | 'UNDER_REVIEW'
  | 'APPROVED_TO_BE_SENT'
  | 'SENT_TO_THE_STORES'
  | 'ACTIONS_PLAN_UNDER_REVIEW'
  | 'CLOSED'
  | 'DELETED';

export const STATUS: Record<StatusCode, Status> = {
  NEW: { code: 'NEW' },
  IN_PROGRESS: {
    code: 'IN_PROGRESS',
    forwardAction: { MERGING: 'MERGE_AUDIT' } as Record<StatusCode, string>,
    backAction: { DELETED: 'DELETE_ASSESSMENT' } as Record<StatusCode, string>,
  },
  MERGING: {
    code: 'MERGING',
    forwardAction: {
      READY_TO_REVIEW_BY_REGIONAL_MANAGER: 'SEND_TO_REVIEW',
      READY_TO_REVIEW: 'SEND_TO_REVIEW',
    } as Record<StatusCode, string>,
  },
  READY_TO_REVIEW_BY_REGIONAL_MANAGER: {
    code: 'READY_TO_REVIEW_BY_REGIONAL_MANAGER',
    forwardAction: { UNDER_REVIEW_BY_REGIONAL_MANAGER: 'START_REVIEW' } as Record<StatusCode, string>,
    backAction: { MERGING: 'SEND_BACK_TO_THE_AUDITORS' } as Record<StatusCode, string>,
  },
  UNDER_REVIEW_BY_REGIONAL_MANAGER: {
    code: 'UNDER_REVIEW_BY_REGIONAL_MANAGER',
    forwardAction: { READY_TO_REVIEW: 'SEND_TO_REVIEW' } as Record<StatusCode, string>,
    backAction: { READY_TO_REVIEW_BY_REGIONAL_MANAGER: 'SEND_BACK_TO_THE_AUDITORS' } as Record<StatusCode, string>,
  },
  READY_TO_REVIEW: {
    code: 'READY_TO_REVIEW',
    forwardAction: { UNDER_REVIEW: 'START_REVIEW' } as Record<StatusCode, string>,
    backAction: { MERGING: 'FIX_AUDIT' } as Record<StatusCode, string>,
  },
  UNDER_REVIEW: {
    code: 'UNDER_REVIEW',
    forwardAction: { APPROVED_TO_BE_SENT: 'APPROVE_REVIEW' } as Record<StatusCode, string>,
    backAction: { READY_TO_REVIEW: 'SEND_BACK_TO_THE_AUDITORS' } as Record<StatusCode, string>,
  },
  APPROVED_TO_BE_SENT: {
    code: 'APPROVED_TO_BE_SENT',
    forwardAction: { SENT_TO_THE_STORES: 'SEND_TO_THE_STORE' } as Record<StatusCode, string>,
    backAction: { UNDER_REVIEW: 'SEND_BACK_TO_REVIEW' } as Record<StatusCode, string>,
  },
  SENT_TO_THE_STORES: {
    code: 'SENT_TO_THE_STORES',
    forwardAction: { ACTIONS_PLAN_UNDER_REVIEW: 'SEND_TO_THE_AUDITORS' } as Record<StatusCode, string>,
  },
  ACTIONS_PLAN_UNDER_REVIEW: {
    code: 'ACTIONS_PLAN_UNDER_REVIEW',
    forwardAction: { CLOSED: 'ARCHIVE' } as Record<StatusCode, string>,
    backAction: {
      SENT_TO_THE_STORES: 'SEND_BACK_TO_THE_STORE',
      MERGING: 'SEND_BACK_TO_THE_AUDITORS',
    } as Record<StatusCode, string>,
  },
  CLOSED: {
    code: 'CLOSED',
    backAction: { ACTIONS_PLAN_UNDER_REVIEW: 'SEND_BACK_ACTIONS_PLAN_UNDER_REVIEW' } as Record<StatusCode, string>,
  },
  DELETED: {
    code: 'DELETED',
    backAction: { IN_PROGRESS: 'IN_PROGRESS' } as Record<StatusCode, string>,
  },
};

export interface Status {
  code: StatusCode;
  forwardAction?: Record<StatusCode, string>;
  backAction?: Record<StatusCode, string>;
}

export const getNextStatuses = (
  status: StatusCode,
  user: Principal,
  isAuditorOwner: boolean,
  isBrandCoordinator: boolean,
  isRegionalManagerApprovalRequired: boolean,
  isRegionalManager: boolean
): Status[] => {
  switch (status) {
    case STATUS.DELETED.code:
      return [];
    case STATUS.IN_PROGRESS.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner ? [STATUS.MERGING] : [];
    case STATUS.MERGING.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner
        ? isRegionalManagerApprovalRequired
          ? [STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER]
          : [STATUS.READY_TO_REVIEW]
        : [];
    case STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER.code:
      return isRegionalManager ? [STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER] : [];
    case STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code:
      return isRegionalManager ? [STATUS.READY_TO_REVIEW] : [];
    case STATUS.READY_TO_REVIEW.code:
      return checkRole(user, ROLES.AUDITOR_MANAGER) ? [STATUS.UNDER_REVIEW] : [];
    case STATUS.UNDER_REVIEW.code:
      return checkRole(user, ROLES.AUDITOR_MANAGER) ? [STATUS.APPROVED_TO_BE_SENT] : [];
    case STATUS.APPROVED_TO_BE_SENT.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner ? [STATUS.SENT_TO_THE_STORES] : [];
    case STATUS.SENT_TO_THE_STORES.code:
      return checkRole(user, ROLES.BASIC_USER) && isBrandCoordinator ? [STATUS.ACTIONS_PLAN_UNDER_REVIEW] : [];
    case STATUS.ACTIONS_PLAN_UNDER_REVIEW.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner ? [STATUS.CLOSED] : [];
    case STATUS.CLOSED.code:
      return [];
    default:
      throw new Error('Status not defined');
  }
};

const getActionPlanUnderReviewPrevStatuses = (user: Principal, isAuditorOwner: boolean) => {
  if (checkRole(user, ROLES.AUDITOR) && isAuditorOwner) {
    return [STATUS.SENT_TO_THE_STORES, STATUS.MERGING];
  }
  if (checkRole(user, ROLES.AUDITOR_MANAGER)) {
    return [STATUS.MERGING];
  }
  return [];
};

export const getPreviousStatuses = (
  status: StatusCode,
  user: Principal,
  isAuditorOwner: boolean,
  isBrandCoordinator: boolean,
  isRegionalManagerApprovalRequired: boolean,
  isRegionalManager: boolean
): Status[] => {
  switch (status) {
    case STATUS.CLOSED.code:
      return checkRoles(user, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]) ? [STATUS.ACTIONS_PLAN_UNDER_REVIEW] : [];
    case STATUS.ACTIONS_PLAN_UNDER_REVIEW.code:
      return getActionPlanUnderReviewPrevStatuses(user, isAuditorOwner);
    case STATUS.SENT_TO_THE_STORES.code:
      return [];
    case STATUS.APPROVED_TO_BE_SENT.code:
      return (checkRole(user, ROLES.AUDITOR) && isAuditorOwner) || checkRole(user, ROLES.AUDITOR_MANAGER)
        ? [STATUS.UNDER_REVIEW]
        : [];
    case STATUS.UNDER_REVIEW.code:
      return checkRole(user, ROLES.AUDITOR_MANAGER) ? [STATUS.READY_TO_REVIEW] : [];
    case STATUS.READY_TO_REVIEW.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner ? [STATUS.MERGING] : [];
    case STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code:
      return isRegionalManager ? [STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER] : [];
    case STATUS.READY_TO_REVIEW_BY_REGIONAL_MANAGER.code:
      return checkRole(user, ROLES.AUDITOR) && isAuditorOwner ? [STATUS.MERGING] : [];
    case STATUS.MERGING.code:
      return [];
    case STATUS.IN_PROGRESS.code:
      return [];
    case STATUS.DELETED.code:
      return [];
    default:
      throw new Error('Status not defined');
  }
};
