import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const radioBox = css`
  display: flex;
  background-color: white;
`;
const radioElement = css`
  ${globalStyle.subtitleNormal};
  && {
    flex: 1;
    line-height: unset;
    padding: 15px 10px;
  }
`;
const title = css`
  ${globalStyle.subtitleNormal};
  && {
    padding: 5px 10px;
    background-color: var(--saa-blue-20);
    font-weight: bold;
  }
`;
const container = css`
  color: var(--saa-primary-color);
  text-align: left;
  margin-bottom: 10px;
`;

export default {
  radioBox,
  radioElement,
  title,
  container,
};
