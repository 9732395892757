/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedStyles } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import style from './actionPlanEditorSelector.style';
import { ActionPlanEditor } from '../model/assessment';
import { AdUserDropdownField } from '../../users/components/AdUserDropdownField';
import { AdUser } from '../../users/model/user.model';
import { useCtrlEnterToRunCallback } from '../../../core/utils';
import { selectAssessmentBrand } from '../store/assessmentDetailSlice';

interface ActionPlanEditorProps {
  editor?: ActionPlanEditor | null;
  onSelect?: (value: ActionPlanEditor) => void;
  buttonCss?: SerializedStyles[];
}

export const ActionPlanEditorSelector = ({
  editor = null,
  onSelect = () => null,
  buttonCss = [],
}: ActionPlanEditorProps): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(editor?.email || '');
  const [name, setName] = React.useState(editor?.name || '');
  const assessmentBrand = useSelector(selectAssessmentBrand);

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setEmail(selectedUser.email);
      setName(`${selectedUser.firstName} ${selectedUser.familyName}`);
    } else {
      setEmail('');
      setName('');
    }
  };

  const onClick = (value: ActionPlanEditor) => {
    onSelect(value);
    setOpen(false);
  };

  useCtrlEnterToRunCallback(() => onClick({ name, email }), open);

  return (
    <>
      <Button css={[style.button, ...buttonCss]} onClick={() => setOpen(true)}>
        <Icon className='fas fa-user-tie' />
        {t('assessment.actionPlan.assign')}
      </Button>
      <Modal css={style.modal} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{t('assessment.actionPlan.editor')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.inputContainer}>
            <AdUserDropdownField
              onChange={onChange}
              placeholder={t('users.search') || ''}
              defaultValue={
                editor ? { email: editor.email, firstName: editor.name, familyName: '', validBrands: [] } : undefined
              }
              text='fullName'
              clearable
              assessmentBrand={assessmentBrand}
            />
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          <Button css={style.button} onClick={() => onClose()}>
            <Icon name='cancel' /> Cancel
          </Button>
          <Button css={style.button} onClick={() => onClick({ name, email })}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
