/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Button, Icon, Menu, Sidebar } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import style from './mainMenu.style';
import { logoutAction, useAppDispatch } from '../store';
import { selectPrincipal } from '../../features/auth/store/principalSlice';
import { AUDITOR_STAFF_ROLES, ROLES } from '../../features/auth/model/principal.model';

export const MainMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);
  const dispatch = useAppDispatch();
  const user = useSelector(selectPrincipal);
  const location = useLocation();

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const isActive = (currentPath: string) => {
    return location.pathname === currentPath;
  };

  const hasRole = (roles: string[]) => {
    return user.roles.filter(r => roles.includes(r)).length > 0;
  };

  return (
    <div>
      <Button icon css={style.button} onClick={() => setVisible(true)}>
        <Icon name='bars' css={style.buttonIcon} />
      </Button>
      <Sidebar
        as={Menu}
        animation='overlay'
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        css={style.sidebar}>
        {hasRole([ROLES.AUDITOR, ROLES.AUDITOR_MANAGER, ROLES.BASIC_USER, ROLES.ARCHIVE_READER]) && (
          <div css={style.menuContainer}>
            <div css={style.menuContainerHeader}>{t('menu.assessments')}</div>
            {hasRole([ROLES.AUDITOR, ROLES.AUDITOR_MANAGER, ROLES.BASIC_USER, ROLES.ARCHIVE_READER]) && (
              <Link to='/assessments' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/assessments')}>
                  <Icon name='clipboard list' />
                  <div> {t('menu.assessmentList')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([ROLES.AUDITOR, ROLES.AUDIT_ADMIN, ROLES.BASIC_USER, ROLES.ARCHIVE_READER]) && (
              <Link to='/assessments-followup' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/assessments-followup')}>
                  <Icon className='fas fa-clipboard-check' />
                  <div> {t('menu.assessmentsFollowUp')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([ROLES.AUDITOR, ROLES.AUDITOR_MANAGER, ROLES.BASIC_USER, ROLES.ARCHIVE_READER]) && (
              <Link to='/assessments-archive' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/assessments-archive')}>
                  <Icon name='archive' />
                  <div> {t('menu.assessmentsArchive')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([ROLES.AUDIT_ADMIN]) && (
              <Link to='/assessments-bulk-update' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/assessments-bulk-update')}>
                  <Icon name='pencil' />
                  <div> {t('menu.assessmentsBulkUpdate')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([ROLES.AUDITOR, ROLES.AUDITOR_MANAGER, ROLES.BASIC_USER, ROLES.ARCHIVE_READER]) && (
              <Link to='/assessments-report' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/assessments-report')}>
                  <Icon name='grid layout' />
                  <div> {t('menu.assessmentsReport')}</div>
                </Menu.Item>
              </Link>
            )}
          </div>
        )}

        {hasRole([...AUDITOR_STAFF_ROLES, ROLES.BRAND_SCHEDULER, ROLES.BASIC_USER, ROLES.AUDIT_SCHEDULER]) && (
          <div css={style.menuContainer}>
            <div css={style.menuContainerHeader}>{t('menu.assessmentsPlanning')}</div>
            {hasRole([...AUDITOR_STAFF_ROLES, ROLES.BRAND_SCHEDULER, ROLES.BASIC_USER]) && (
              <Link to='/audit-campaigns' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/audit-campaigns')}>
                  <Icon name='folder open' />
                  <div> {t('menu.auditCampaigns')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([...AUDITOR_STAFF_ROLES, ROLES.BRAND_SCHEDULER, ROLES.AUDITOR_MANAGER, ROLES.BASIC_USER]) && (
              <Link to='/brand-audit-proposals' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/brand-audit-proposals')}>
                  <Icon name='copyright' />
                  <div> {t('menu.brandAuditProposals')}</div>
                </Menu.Item>
              </Link>
            )}
            {hasRole([...AUDITOR_STAFF_ROLES, ROLES.BRAND_SCHEDULER, ROLES.AUDIT_SCHEDULER, ROLES.BASIC_USER]) && (
              <Link to='/audit-proposals-agenda' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/audit-proposals-agenda')}>
                  <Icon name='calendar alternate' />
                  <div> {t('menu.auditProposalsAgenda')}</div>
                </Menu.Item>
              </Link>
            )}
          </div>
        )}

        {hasRole([ROLES.ADMIN]) && (
          <div css={style.menuContainer}>
            <div css={style.menuContainerHeader}>{t('menu.administration')}</div>
            {hasRole([ROLES.ADMIN]) && (
              <Link to='/users' onClick={() => setVisible(false)}>
                <Menu.Item css={style.menuItem} active={isActive('/users')}>
                  <Icon name='users' />
                  <div> {t('menu.userList')}</div>
                </Menu.Item>
              </Link>
            )}
          </div>
        )}
        <div css={style.userContainer}>
          <div>
            <Icon name='user' />
            <div>{user.email.toLocaleLowerCase()}</div>
          </div>
          <Button type='button' onClick={() => logout()}>
            <Icon name='sign-out' />
            LOGOUT
          </Button>
        </div>
      </Sidebar>
    </div>
  );
};
