import { css } from '@emotion/react';

const button = css`
  && {
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;

    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const buttonContentContainer = css`
  display: flex;
  align-items: center;
`;

const imageContainer = css`
  padding-right: 5px;
`;

const image = css`
  width: 38px;
`;

export default {
  button,
  buttonContentContainer,
  image,
  imageContainer,
};
