/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, LabelGroup, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import {
  addEmptyUser,
  fetchUsers,
  selectFilter,
  selectFilters,
  selectIsFetching,
  selectShowResults,
  selectSortedFilteredUsers,
  setFilter,
  setFilters,
  setShowResults,
} from '../store/usersSlice';
import { emptyUsersFilters, UserFilters } from '../model/user.model';
import style from './users.style';
import { UserCard } from '../components/UserCard';
import { SearchBox } from '../../../shared/SearchBox';
import { scrollToTop, useCtrlEnterToRunCallback, useEscToRunCallback } from '../../../core/utils';
import { useAppDispatch } from '../../../core/store';
import { FilterBadge } from '../../../shared/FilterBadge';
import { BrandMultiSelectField } from '../components/BrandMultiSelectField';
import { toBrand } from '../../../shared/model/brand.model';
import { RoleMultiSelectField } from '../components/RoleMultiSelectField';
import { fetchKeringRegions, selectKeringRegions } from '../store/keringRegionsSlice';
import { RegionMultiSelectField } from '../components/RegionMultiSelectField';
import { ROLES } from '../../auth/model/principal.model';
import {
  clearKeringCountries,
  fetchKeringCountries,
  selectIsFetchingKeringCountries,
  selectKeringCountries,
} from '../store/keringCountriesSlice';
import { CountryMultiSelectField } from '../components/CountryMultiSelectField';
import { Country } from '../../../shared/model/country.model';

export const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const cardsContainerRef = useRef(null);
  const users = useSelector(selectSortedFilteredUsers);
  const isLoading = useSelector(selectIsFetching);
  const searchFilter = useSelector(selectFilter);
  const dispatch = useAppDispatch();
  const showFilters = !useSelector(selectShowResults);
  const setSearchBoxFilter = (filterValue: string) => {
    dispatch(setFilter(filterValue));
  };

  const setDebounceFilter = useDebouncedCallback(setSearchBoxFilter, 300);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <div css={style.container}>
      {showFilters ? (
        <UsersFilters />
      ) : (
        <>
          <div css={style.header}>
            <Button
              css={style.addButton(t('users.addUser'))}
              icon='plus'
              onClick={() => {
                dispatch(addEmptyUser());
                scrollToTop(cardsContainerRef.current);
              }}
            />
            <FiltersBadgesContainer />
            <SearchBox defaultValue={searchFilter} onChange={(filter: string) => setDebounceFilter(filter.trim())} />
          </div>
          {isLoading ? (
            <Loader active />
          ) : (
            <div css={style.userList} ref={cardsContainerRef}>
              {users.map(user => (
                <UserCard user={user} key={user.email} />
              ))}
              {users.length === 0 && <UserNotFound />}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const UsersFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectFilters);
  const keringRegions = useSelector(selectKeringRegions);
  const keringCountries = useSelector(selectKeringCountries);
  const isFetchingKeringCountries = useSelector(selectIsFetchingKeringCountries);

  const [brands, setBrands] = useState<string[]>(filters.brands);
  const [roles, setRoles] = useState<string[]>(filters.roles);
  const [regions, setRegions] = useState<string[]>(filters.regions);
  const [countries, setCountries] = useState<Country[]>(filters.countries);

  useEffect(() => {
    dispatch(fetchKeringRegions());
  }, [dispatch]);

  useEffect(() => {
    if (regions.length === 0) {
      dispatch(clearKeringCountries());
    } else {
      dispatch(fetchKeringCountries(regions));
    }
  }, [dispatch, regions]);

  const getFilters = useCallback(
    (): UserFilters => ({
      brands,
      roles,
      regions,
      countries,
    }),
    [brands, roles, regions, countries]
  );

  const search = useCallback(() => {
    dispatch(setFilters(getFilters()));
    dispatch(setShowResults(true));
  }, [dispatch, getFilters]);

  const clear = useCallback(() => {
    setBrands([]);
    setRoles([]);
    setRegions([]);
    setCountries([]);
  }, []);

  useCtrlEnterToRunCallback(search);

  return (
    <div css={style.filtersContainer}>
      <div>
        <BrandMultiSelectField
          defaultValue={brands}
          onUpdate={selectedValues => {
            setBrands(selectedValues);
          }}
        />
        <RoleMultiSelectField
          defaultValue={roles}
          onUpdate={selectedValues => {
            setRoles(selectedValues);
            if (!(selectedValues.includes(ROLES.BRAND_SCHEDULER) || selectedValues.includes(ROLES.ARCHIVE_READER))) {
              setRegions([]);
              setCountries([]);
            }
          }}
        />
        <RegionMultiSelectField
          regions={keringRegions}
          editable={roles.includes(ROLES.BRAND_SCHEDULER) || roles.includes(ROLES.ARCHIVE_READER)}
          defaultValue={regions}
          onUpdate={selectedValues => {
            setRegions(selectedValues);
            setCountries([]);
          }}
        />
        <CountryMultiSelectField
          countries={keringCountries}
          editable={
            (roles.includes(ROLES.BRAND_SCHEDULER) || roles.includes(ROLES.ARCHIVE_READER)) && regions.length > 0
          }
          defaultValue={countries}
          onUpdate={selectedValues => {
            setCountries(selectedValues);
          }}
          loading={isFetchingKeringCountries}
        />
      </div>

      <div css={style.buttonsContainer}>
        <Button css={style.button} onClick={clear} icon='eraser' content={t('assessment.archive.clear')} />
        <Button css={style.button} onClick={search} icon='search' content={t('assessment.archive.search')} />
      </div>
    </div>
  );
};

const FiltersBadgesContainer = () => {
  const { t } = useTranslation();
  const filters = useSelector(selectFilters);
  const dispatch = useAppDispatch();
  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState<number>(0);

  const getFiltersArray = useCallback(() => {
    const filtersBadges = {
      brands: filters.brands.map((b: string) => toBrand(b).description).join(', '),
      roles: filters.roles.join(', '),
      regions: filters.regions.join(', '),
      countries: filters.countries.map((country: Country) => country.description).join(', '),
    };

    return Object.keys(filtersBadges)
      .map(k => {
        const realValue = (filters as any)[k];

        return {
          show: !_.isEqual(realValue, (emptyUsersFilters as any)[k]),
          field: k,
          description: (filtersBadges as any)[k],
          label: t(`users.${k}`),
        };
      })
      .filter(f => f.show);
  }, [filters, t]);

  const onFilterButtonClick = useCallback(() => {
    dispatch(setShowResults(false));
  }, [dispatch]);

  useEscToRunCallback(onFilterButtonClick);

  useEffect(() => {
    const n =
      (filters.brands.length > 0 ? 1 : 0) +
      (filters.roles.length > 0 ? 1 : 0) +
      (filters.regions.length > 0 ? 1 : 0) +
      (filters.countries.length > 0 ? 1 : 0);
    setNumberOfFiltersApplied(n);
  }, [filters]);

  return (
    <div css={style.badgeGroup}>
      <div css={style.badges}>
        <LabelGroup circular>
          {getFiltersArray().map(f => (
            <FilterBadge value={f.description} label={f.label} />
          ))}
        </LabelGroup>
      </div>
      <div css={style.filterButtonContainer}>
        <Button css={style.filterButton} onClick={onFilterButtonClick}>
          <div css={style.icon}>
            <Icon name='filter' />
          </div>
          <span>{t('assessment.archive.filtersText')}</span>
        </Button>
        {numberOfFiltersApplied > 0 && (
          <Label css={style.filterButtonBadge} floating circular>
            {numberOfFiltersApplied}
          </Label>
        )}
      </div>
    </div>
  );
};

const UserNotFound = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.userNotFound}>
      <span>{t('users.userNotFound')}</span>
    </div>
  );
};
