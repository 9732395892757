/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useAppDispatch } from '../../../core/store';
import { SelectItem } from '../../../shared/ModalSelectField';
import { selectRegionalManagers, selectIsFetching, fetchRegionalManagers } from '../store/regionalManagersSlice';
import { Contact } from '../../users/model/user.model';
import { ModalMonoSelectField } from '../../../shared/ModalMonoSelectField';

interface RegionalManagerAssessmentFieldProps {
  defaultValue: Contact;
  editable: boolean;
  onEdited: (id: Contact) => void;
  assessmentKeringRegion: string;
}

export const RegionalManagerAssessmentField = ({
  defaultValue,
  editable,
  onEdited,
  assessmentKeringRegion,
}: RegionalManagerAssessmentFieldProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const regionalManagers = useSelector(selectRegionalManagers);
  const isFetching = useSelector(selectIsFetching);

  const extendedRegionalManagers = useMemo(() => {
    const list = _.uniqBy([...regionalManagers, defaultValue], e => e.email);
    return list.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  }, [regionalManagers, defaultValue]);

  useEffect(() => {
    if (editable) {
      dispatch(fetchRegionalManagers(assessmentKeringRegion));
    }
  }, [dispatch, editable, assessmentKeringRegion]);

  const toSelectItem = (item: Contact): SelectItem => {
    return {
      code: item.email,
      description: item.name || item.email,
    };
  };
  const toRegionalManager = (item: SelectItem): Contact => {
    return { name: item.description, email: item.code };
  };

  return (
    <ModalMonoSelectField
      defaultValue={toSelectItem(defaultValue)}
      list={extendedRegionalManagers.map(toSelectItem)}
      editable={editable}
      title={t('assessment.regionalManagers') || ''}
      icon='users'
      loading={isFetching}
      onUpdateValue={item => onEdited(toRegionalManager(item))}
    />
  );
};
