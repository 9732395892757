/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Button, Icon, Input, Label } from 'semantic-ui-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import style from './actionPlanCoordinatorSelection.style';
import { BrandCode } from '../../../../shared/model/brand.model';
import { AdUserDropdownField } from '../../../users/components/AdUserDropdownField';
import { AdUser } from '../../../users/model/user.model';
import { AssessmentBulkUpdateWizardSelectedValue } from '../../model/assessmentBulkUpdate';
import { selectSelectedAssessments, selectSortedAndFilteredResults } from '../../store/assessmentBulkUpdateSlice';

interface ActionPlanCoordinatorSelectionProps {
  defaultValue: AssessmentBulkUpdateWizardSelectedValue[] | null;
  editable?: boolean;
  onEdited?: (value: AssessmentBulkUpdateWizardSelectedValue[] | null) => void;
  brand?: BrandCode;
}

export const ActionPlanCoordinatorSelection = ({
  defaultValue = [],
  editable = false,
  onEdited = () => null,

  brand,
}: ActionPlanCoordinatorSelectionProps): JSX.Element => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [isValidForm, setIsValidForm] = useState(true);
  const coordinatorLimit = 2;
  const coordinatorLimitReached = useMemo(() => (defaultValue?.length || 0) >= coordinatorLimit, [defaultValue]);
  const selectedAssessments = useSelector(selectSelectedAssessments);
  const assessments = useSelector(selectSortedAndFilteredResults);

  const clearFields = () => {
    setEmail('');
    setName('');
    setRole('');
  };
  const validateCoordinator = (): boolean => {
    if (defaultValue?.some(coordinator => coordinator.email === email)) {
      return false;
    }
    if (email.trim() === '') {
      return false;
    }
    if (role.trim() === '') {
      return false;
    }

    return true;
  };

  const emailErrorMessage = useMemo((): string => {
    if (defaultValue?.some(coordinator => coordinator.email === email)) {
      return t('assessment.brandCoordinatorList.error.emailDuplicate');
    }
    if (email.trim() === '') {
      return t('assessment.brandCoordinatorList.error.emailEmpty');
    }
    return '';
  }, [email, defaultValue]);

  const roleErrorMessage = useMemo((): string => {
    if (role.trim() === '') {
      return t('assessment.brandCoordinatorList.error.roleEmpty');
    }
    return '';
  }, [role]);

  const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const onAdUserChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setEmail(selectedUser.email);
      const fullName = `${selectedUser.firstName} ${selectedUser.familyName}`;
      setName(fullName.trim());
    } else {
      setEmail('');
      setName('');
    }
  };
  const removeCoordinator = (coordinatorEmail: string) => {
    const newCoordinators = defaultValue?.filter(coordinator => coordinator.email !== coordinatorEmail) || null;
    onEdited(newCoordinators);
  };

  return (
    <div css={style.formContainer}>
      <div css={style.addCoordinatorForm}>
        <div css={style.input}>
          <AdUserDropdownField
            placeholder={t('users.search') || ''}
            onChange={onAdUserChange}
            error={!isValidForm && emailErrorMessage !== ''}
            cornerIcon='search'
            value={email}
            disabled={coordinatorLimitReached}
            assessmentBrand={
              assessments.find(assessment => selectedAssessments.includes(assessment.id))?.brand.code as BrandCode
            }
          />
        </div>
        <div css={style.nameContainer}>
          <Input
            value={name}
            type='text'
            label={{ icon: 'user' }}
            disabled
            labelPosition='left corner'
            placeholder='Name'
          />
        </div>
        <div css={style.nameContainer}>
          <Input
            value={role}
            type='text'
            label={{ icon: 'shopping bag' }}
            onChange={event => onChangeRole(event)}
            labelPosition='left corner'
            placeholder='Role'
            disabled={coordinatorLimitReached}
          />
          {!isValidForm && roleErrorMessage !== '' && (
            <Label basic color='red' pointing>
              {roleErrorMessage}
            </Label>
          )}
        </div>
        <div css={style.formButtons}>
          <Button
            css={style.button}
            disabled={!validateCoordinator()}
            onClick={() => {
              const isValid = validateCoordinator();
              setIsValidForm(isValid);
              if (isValid) {
                const newCoordinators = [...(defaultValue || []), { name, email, role }];
                onEdited(newCoordinators);
                clearFields();
              }
            }}
            icon='plus'
            content='Add'
          />
        </div>
      </div>

      <div css={style.actionPlanCoordinatorList}>
        {defaultValue?.map(c => (
          <div key={c.email} css={style.actionPlanCoordinatorItem}>
            <Icon name='user' />
            <div css={style.actionPlanCoordinatorInfo}>
              <div css={style.actionPlanCoordinatorEmail}>{c.email}</div>
              <div css={style.actionPlanCoordinatorName}>{c.name}</div>
              <div css={style.actionPlanCoordinatorRole}>{c.role}</div>
            </div>
            <Button icon onClick={() => removeCoordinator(c.email)}>
              <Icon name='remove' />
            </Button>
          </div>
        ))}
      </div>
      <div css={style.disclaimer}>{t('assessment.brandCoordinatorList.disclaimer')}</div>
    </div>
  );
};
