/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import style from './assessmentCreationFromProposal.style';
import { Auditor, BrandCoordinator } from '../../assessments/model/assessment';
import { AssessmentCreationFields, AssessmentCreationForm } from '../../assessments/components/AssessmentCreation';
import { GenericAuditProposal } from '../model/genericAuditProposal';
import { Contact } from '../../users/model/user.model';

interface YearMonth {
  year: number;
  month: number;
}

const dateToYearMonth = (date: Date): YearMonth => ({ year: date.getFullYear(), month: date.getMonth() + 1 });

interface AssessmentCreationFromProposalProps {
  proposal: GenericAuditProposal;
  onCreate?: (assessmentCreationRequest: {
    month: number;
    year: number;
    auditorEmails: string[];
    distributionList: string[];
    readOnlyDistributionList: string[];
    brandCoordinatorList: BrandCoordinator[];
  }) => void;
  onCancel?: () => void;
}
export const AssessmentCreationFromProposal = ({
  proposal,
  onCreate,
  onCancel,
}: AssessmentCreationFromProposalProps): JSX.Element => {
  const { t } = useTranslation();

  const [auditors, setAuditors] = useState<Auditor[]>([]);
  const [yearMonth, setYearMonth] = useState<YearMonth>(dateToYearMonth(new Date()));
  const [distributionList, setDistributionList] = useState<Contact[]>([]);
  const [readOnlyDistributionList, setReadOnlyDistributionList] = useState<Contact[]>([]);
  const [brandCoordinatorList, setBrandCoordinatorList] = useState<BrandCoordinator[]>([]);

  useEffect(() => {
    setYearMonth(dateToYearMonth(new Date(proposal.proposedDateFrom)));
    setAuditors(proposal.auditors);
    setDistributionList([]);
    setReadOnlyDistributionList([]);
    setBrandCoordinatorList([]);
  }, [proposal]);

  const fields: AssessmentCreationFields = useMemo(
    () => ({
      store: proposal.store,
      yearMonth,
      auditors,
      distributionList,
      readOnlyDistributionList,
      brandCoordinatorList,
    }),
    [proposal.store, yearMonth, auditors, distributionList, readOnlyDistributionList, brandCoordinatorList]
  );

  const create = useCallback(() => {
    if (onCreate) {
      onCreate({
        year: yearMonth.year,
        month: yearMonth.month,
        auditorEmails: auditors.map(a => a.email),
        distributionList: distributionList.map(c => c.email),
        readOnlyDistributionList: readOnlyDistributionList.map(c => c.email),
        brandCoordinatorList,
      });
    }
  }, [auditors, brandCoordinatorList, distributionList, readOnlyDistributionList, onCreate, yearMonth]);

  const canCreate = useMemo(() => proposal.store != null, [proposal.store]);

  return (
    <div css={style.component}>
      <div css={style.body}>
        <div css={style.content}>
          <AssessmentCreationForm
            fields={fields}
            onYearMonthChange={setYearMonth}
            onAuditorsChange={setAuditors}
            onDistributionListChange={setDistributionList}
            onReadOnlyDistributionListChange={setReadOnlyDistributionList}
            onBrandCoordinatorListChange={setBrandCoordinatorList}
          />
        </div>
      </div>
      <div css={style.footer}>
        {onCancel != null && (
          <Button type='button' css={style.button} onClick={onCancel}>
            <Icon name='chevron left' />
            {t('assessment.back')}
          </Button>
        )}

        <Button type='button' css={style.button} onClick={create} disabled={!canCreate}>
          <Icon name='save' />
          {t('assessment.createAssessment')}
        </Button>
      </div>
    </div>
  );
};
