/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Assessment, AssessmentCreationRequest, Auditor, sortedAssessmentStatus } from '../model/assessment';
import api from '../utils/api';
import { checkValueInclude, groupBy } from '../../../shared/utils';
import { selectAssessmentsFilter } from './filterSlice';
import { DEFAULT_CACHE_TTL } from '../../../constants';
import { toastService } from '../../../core/services/toastService';
import type { RootState } from '../../../core/store';
import { Status, StatusCode } from '../model/assessmentStatus';
import { AssessmentFilterInterface, emptyAssessmentFilterInterface } from '../model/assessmentFilter.model';
import { Brand } from '../../../shared/model/brand.model';

interface AssessmentsSliceState {
  data: Assessment[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
  filters: AssessmentFilterInterface;

  isOpen: boolean;
  status?: Status;
  filter: string;
  brands: Brand[];
  regions: string[];
  countries: string[];
  auditors: Auditor[];
  monthFrom: number;
}

const initialState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
  filters: emptyAssessmentFilterInterface,

  isOpen: false,
  filter: '',
  brands: [],
  regions: [],
  countries: [],
  auditors: [],
  monthFrom: 0,
} as AssessmentsSliceState;

export const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<Assessment[]>) => {
      return {
        ...state,
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    httpError: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<AssessmentsSliceState>) => ({ ...state, isValidCache: false }),
    changeFilter: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      filter: payload,
    }),
    toggleFilters: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOpen: payload,
    }),
    setFilters: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<AssessmentFilterInterface>) => ({
      ...state,
      filters: payload,
    }),
    finishBrandFetch: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...payload],
    }),
    finishRegionFetch: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...payload],
    }),
    finishCountriesFetch: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...payload],
    }),
    finishAuditorsFetch: (state: Draft<AssessmentsSliceState>, { payload }: PayloadAction<Auditor[]>) => ({
      ...state,
      isFetching: false,
      auditors: [...payload],
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  invalidateCache,
  toggleFilters,
  changeFilter,
  setFilters,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishAuditorsFetch,
} = assessmentsSlice.actions;

export const fetchAssessments = () => async (dispatch: any, state: any) => {
  if (!state().assessments.isValidCache) {
    dispatch(startFetch());
    try {
      const assessments = await api.getAssessments();
      dispatch(finishFetch(assessments));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
    setTimeout(() => dispatch(invalidateCache()), DEFAULT_CACHE_TTL);
  }
};

export const selectAssessments = (state: RootState) => state.assessments.data as Assessment[];

const selectFilters = ({ assessments }: RootState) => assessments.filters;

const selectFilteredAssessments = createSelector(
  selectAssessments,
  selectAssessmentsFilter,
  selectFilters,
  (assessments, filter, filters) => {
    return assessments.filter(a => {
      const isStatusMatch = filters.status.length === 0 || filters.status.includes(a.status.code);
      const isBrandMatch = filters.brands.length === 0 || filters.brands.map(b => b.code).includes(a.store.brand.code);
      const isRegionMatch = filters.regions.length === 0 || filters.regions.includes(a.store.keringRegion);
      const isCountryMatch =
        filters.countries.length === 0 || filters.countries.includes(a.store.keringCountry.description);
      const isAuditorMatch =
        filters.auditors.length === 0 ||
        a.auditors
          .map((auditor: Auditor) => auditor.email)
          .some((auditor: string) => filters.auditors.map(filtersAuditor => filtersAuditor.email).includes(auditor));
      const isMonthMatch =
        (filters.monthFrom === undefined || a.month >= filters.monthFrom) &&
        (filters.monthTo === undefined || a.month <= filters.monthTo);
      const isYearMatch =
        (filters.yearFrom === undefined || a.year >= filters.yearFrom) &&
        (filters.yearTo === undefined || a.year <= filters.yearTo);
      return (
        (checkValueInclude(a.code, filter) ||
          checkValueInclude(a.store.hfmCode, filter) ||
          checkValueInclude(a.store.brand.description, filter) ||
          checkValueInclude(a.store.keringRegion, filter) ||
          checkValueInclude(a.store.city, filter) ||
          checkValueInclude(a.store.name, filter) ||
          a.auditors.some(auditor => checkValueInclude(auditor.email, filter)) ||
          a.auditors.some(auditor => checkValueInclude(auditor.name, filter))) &&
        isStatusMatch &&
        isBrandMatch &&
        isRegionMatch &&
        isCountryMatch &&
        isAuditorMatch &&
        isMonthMatch &&
        isYearMatch
      );
    });
  }
);

const selectSortedAndFilteredAssessments = createSelector(selectFilteredAssessments, assessments => {
  return [...assessments].sort((a: Assessment, b: Assessment) =>
    sortedAssessmentStatus.indexOf(a.status.code) > sortedAssessmentStatus.indexOf(b.status.code) ? 1 : -1
  );
});

export const selectGroupedAssessments = createSelector(selectSortedAndFilteredAssessments, assessments => {
  return groupBy('status.code')(assessments) as Record<StatusCode, Assessment[]>;
});

export const createAssessment =
  (request: AssessmentCreationRequest) =>
  async (dispatch: any, state: any): Promise<Assessment | null> => {
    try {
      const assessment = await api.createAssessments(request);
      dispatch(invalidateCache());
      await dispatch(fetchAssessments());
      return assessment;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

const base64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadAttachments = (id: string, filename: string) => async (dispatch: any) => {
  try {
    const attachmentBase64 = await api.downloadAttachment(id);
    const attachmentBlob = base64toBlob(attachmentBase64, '');

    const url = window.URL.createObjectURL(attachmentBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toastService.success();
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};


const selectIsFetching = ({ assessments }: RootState): boolean => assessments.isFetching;
const selectIsOpen = ({ assessments }: RootState): boolean => assessments.isOpen;
const selectFilter = ({ assessments }: RootState): string => assessments.filter;
const selectBrands = ({ assessments }: RootState) => assessments.brands;
const selectRegions = ({ assessments }: RootState) => assessments.regions;
const selectCountries = ({ assessments }: RootState) => assessments.countries;
const selectAuditors = ({ assessments }: RootState) => assessments.auditors;

export {
  selectIsFetching,
  selectIsOpen,
  selectFilter,
  selectFilters,
  selectBrands,
  selectRegions,
  selectCountries,
  selectAuditors,
};

export const selectIsFetchingAssessments = (state: RootState): boolean => state.assessments.isFetching;

export default assessmentsSlice.reducer;
