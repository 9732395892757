/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react';
import { Input } from 'semantic-ui-react';
import style from './searchBox.style';

interface SearchBoxProps {
  defaultValue: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  loading?: boolean;
  placeholder?: string;
}

export const SearchBox = ({
  defaultValue,
  onChange,
  autoFocus = false,
  placeholder = 'Search...',
  loading,
}: SearchBoxProps): JSX.Element => {
  return (
    <Input
      icon='search'
      placeholder={placeholder}
      css={style.search}
      defaultValue={defaultValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      autoFocus={autoFocus}
      loading={loading}
    />
  );
};
