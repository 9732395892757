/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { t } from 'i18next';
import { ModalPage } from '../../../../../shared/ModalPage';
import { AssessmentCreationFromProposal } from '../../AssessmentCreationFromProposal';
import style from './detailFooter.style';
import { AssessmentCreationFromProposalRequest } from '../../../../assessments/model/assessment';
import { GenericAuditProposal } from '../../../model/genericAuditProposal';
import { ModalAlert } from '../../../../../shared/ModalAlert';

interface CreateAssessmentButtonProps {
  proposal: GenericAuditProposal;
  buttonLabel: string;
  onCreate: (assessmentCreationRequest: AssessmentCreationFromProposalRequest) => void;
}

export function CreateAssessmentButton({ proposal, buttonLabel, onCreate }: CreateAssessmentButtonProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const areDatesInRange = useCallback((): boolean => {
    const rangeList = proposal.proposedPeriods.map(range => {
      return {
        dateFrom: new Date(new Date(range.dateFrom).setHours(0)).getTime(),
        dateTo: new Date(new Date(range.dateTo).setHours(0)).getTime(),
      };
    });
    const dateTimeFrom = new Date(new Date(proposal.proposedDateFrom).setHours(0)).getTime();
    const dateTimeTo = new Date(new Date(proposal.proposedDateTo).setHours(0)).getTime();

    let result = false;
    rangeList.forEach(range => {
      if (
        dateTimeFrom >= range.dateFrom &&
        dateTimeFrom <= range.dateTo &&
        dateTimeTo >= dateTimeFrom &&
        dateTimeTo <= range.dateTo
      ) {
        result = true;
      }
    });

    return result;
  }, [proposal.proposedDateFrom, proposal.proposedDateTo, proposal.proposedPeriods]);

  const onCreateAssessmentButtonClick = useCallback(() => {
    if (proposal.type === 'internal' || proposal.type === 'brand') {
      setConfirmIsOpen(true);
    } else {
      setAlertIsOpen(true);
    }
  }, [proposal.type]);

  return (
    <>
      <Button
        css={style.fatFooterButtonEnd}
        labelPosition='right'
        icon='forward icon'
        content={buttonLabel}
        onClick={onCreateAssessmentButtonClick}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        onCancel={() => {
          setConfirmIsOpen(false);
        }}
        content={
          !areDatesInRange()
            ? t('auditCampaigns.proposalDetail.footer.proposeDifferentAuditDatesMessage')
            : t('brandAuditProposals.proposalDetail.footer.confirmationMessage')
        }
        onConfirm={() => {
          setConfirmIsOpen(false);
          setModalOpen(true);
        }}
        size='small'
      />
      <ModalAlert
        open={alertIsOpen}
        onClose={() => setAlertIsOpen(false)}
        message={t('brandAuditProposals.proposalDetail.footer.notEqualDatesErrorCreateAssessment')}
      />
      {modalOpen ? (
        <ModalPage onClose={() => setModalOpen(false)} title={t('assessment.creation.title') || ''}>
          <AssessmentCreationFromProposal
            proposal={proposal}
            onCreate={assessmentCreationRequest => {
              setModalOpen(false);
              onCreate(assessmentCreationRequest);
            }}
            onCancel={() => setModalOpen(false)}
          />
        </ModalPage>
      ) : (
        <></>
      )}
    </>
  );
}
