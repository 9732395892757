/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import style from './topicModal.style';
import { ModalPage } from '../../../shared/ModalPage';
import { FreeTextField } from './FreeTextField';
import { TopicForDiscussion } from '../model/assessmentDetail.model';
import {
  deleteTopicForDiscussion,
  selectCanCRUDTopic,
  selectCanWriteTopicComment,
} from '../store/assessmentDetailSlice';
import { useAppDispatch } from '../../../core/store';
import { TopicCommentComponent } from './TopicCommentComponent';
import { useAutosizeTextArea, useCtrlEnterToRunCallback } from '../../../core/utils';

interface TopicModalProps {
  selectedTopic: TopicForDiscussion;
  onEditTitle: (title: string) => void;
  onCreateComment: (message: string) => void;
  onUpdateComment: (message: string, commentId: string) => void;
  onClose: () => void;
}
export const TopicModal = ({
  onClose,
  selectedTopic,
  onEditTitle,
  onUpdateComment,
  onCreateComment,
}: TopicModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const canCreateTopicComment = useSelector(selectCanWriteTopicComment);
  const [inputValue, setInputValue] = useState('');
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const canCRUDTopic = useSelector(selectCanCRUDTopic);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, inputValue);

  const onSendIconClick = () => {
    if (inputValue !== '') {
      onCreateComment(inputValue);
      setInputValue('');
    }
  };

  useCtrlEnterToRunCallback(onSendIconClick, true);

  const onDeleteTopic = async () => {
    await dispatch(deleteTopicForDiscussion(selectedTopic.id));
    onClose();
  };

  return (
    <ModalPage onClose={onClose} title={selectedTopic.title !== '' ? selectedTopic.title : 'New Topic'}>
      <div css={style.modalContainer}>
        {canCRUDTopic && (
          <div css={style.topicInputContent}>
            <FreeTextField
              title='Title'
              defaultValue={selectedTopic.title}
              onChange={value => onEditTitle(value)}
              editable
              mutable
              placeholder='Enter a title...'
              openWithEdit
              openWithEditOnClose={onClose}
            />
          </div>
        )}
        <div css={style.commentsTitle}>Comments</div>
        <div css={style.commentsContainer}>
          {selectedTopic.comments.length > 0 ? (
            selectedTopic.comments
              .slice()
              .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
              .map(c => (
                <TopicCommentComponent comment={c} editable={canCreateTopicComment} onUpdateComment={onUpdateComment} />
              ))
          ) : (
            <></>
          )}
        </div>
        {canCreateTopicComment && (
          <div css={style.inputContainer}>
            <div css={style.textFieldContainer}>
              <textarea
                placeholder='Type here...'
                value={inputValue}
                onChange={event => {
                  setInputValue(event.target.value);
                }}
                ref={textAreaRef}
                rows={1}
              />
            </div>
            <div onClick={onSendIconClick}>
              <Icon css={style.sendIcon} name='paper plane' />
            </div>
          </div>
        )}
        {canCRUDTopic && (
          <div css={style.footer}>
            <div css={style.buttonRightContainer}>
              <Button css={style.button} icon labelPosition='left' onClick={() => setIsDeleteConfirmOpen(true)}>
                {t('assessment.audit.topicForDiscussion.delete')}
                <Icon name='trash alternate' />
              </Button>
            </div>
          </div>
        )}
        <Confirm
          css={style.modalConfirm}
          content={t('assessment.audit.topicForDiscussion.deleteConfirmMessage')}
          open={isDeleteConfirmOpen}
          onCancel={() => setIsDeleteConfirmOpen(false)}
          onConfirm={async () => {
            setIsDeleteConfirmOpen(false);
            await onDeleteTopic();
          }}
          confirmButton={<Button css={style.confirmButton}>Ok</Button>}
          size='mini'
        />
      </div>
    </ModalPage>
  );
};
