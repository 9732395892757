/* eslint-disable  @typescript-eslint/no-explicit-any */

export const groupBy = (key: string) => {
  return function group(array: any[]): Record<string, unknown> {
    return array.reduce((acc, obj) => {
      const property = deepFind(obj, key);
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
};

const deepFind = (obj: any, path: string): any => {
  const paths = path.split('.');
  let current = obj;
  let i;

  for (i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] == null) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
};

export const checkValueInclude = (value: string, filter: string): boolean => {
  return filter === '' || value.toLowerCase().includes(filter.toLowerCase());
};
