/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { css, keyframes } from '@emotion/react';
import { useDebouncedCallback } from 'use-debounce';
import {
  saveAuditorUpdateRequests,
  selectAllRequestsSynchronized,
  selectSynchronizing,
} from '../store/auditorUpdateRequestsSlice';
import { useAppDispatch } from '../../../core/store';

interface SyncAuditorRequestsIconProps {
  goBack: () => void;
}

export const SyncAuditorRequestsIcon = ({ goBack }: SyncAuditorRequestsIconProps): JSX.Element => {
  const synchronizing = useSelector(selectSynchronizing);
  const allRequestsSynchronized = useSelector(selectAllRequestsSynchronized);
  const dispatch = useAppDispatch();

  const handleSync = useDebouncedCallback(() => {
    dispatch(saveAuditorUpdateRequests());
  }, 300);

  return (
    <>
      {synchronizing ? (
        <Icon name='sync alternate' css={syncIcon} />
      ) : allRequestsSynchronized ? (
        <Icon css={icon} name='arrow left' onClick={goBack} />
      ) : (
        <Icon name='sync alternate' css={notSyncIcon} onClick={handleSync} />
      )}
    </>
  );
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

const icon = css`
  && {
    cursor: pointer;
    font-size: 1.714rem;
    margin: 0 0 0 10px;

    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const notSyncIcon = css`${icon};
  color: var(--saa-warning-dark);

  &&:hover {
    color: var(--saa-warning-dark)
  }

  &:focus {
    color: var(--saa-warning-dark)
  }`;

const syncIcon = css`${icon};
  animation: ${spin} 2s infinite;`;
