/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { t } from 'i18next';
import moment from 'moment';
import style from './detailFooter.style';
import { CalendarField } from '../../../../assessments/components/CalendarField';
import { getDate } from '../../../../../core/utils';

interface ReproposeBrandDatesButtonProps {
  auditDateFrom: string;
  auditDateTo: string;
  onConfirm: (from: string, to: string) => void;
}
export const ReproposeBrandDatesButton = ({
  auditDateTo,
  auditDateFrom,
  onConfirm,
}: ReproposeBrandDatesButtonProps): JSX.Element => {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [minDate, setMinDate] = useState(dateFrom !== '' ? getDate(dateFrom) : getDate());
  const [maxDate, setMaxDate] = useState(dateTo !== '' ? getDate(dateTo) : undefined);
  const today = getDate();

  const onDateUpdate = (from: string, to: string) => {
    const toValue = from !== '' && to === '' ? moment(from).add(1, 'day').format('YYYY-MM-DD') : to;
    setDateFrom(from);
    setDateTo(toValue);
    setMinDate(from !== '' ? getDate(from) : getDate());
    setMaxDate(toValue !== '' ? getDate(toValue) : undefined);
  };

  const onCancel = () => {
    onDateUpdate('', '');
    setConfirmIsOpen(false);
  };
  const isValidDates = useMemo(() => {
    return dateFrom !== '' && dateTo !== '' && (dateFrom !== auditDateFrom || dateTo !== auditDateTo);
  }, [dateFrom, dateTo, auditDateFrom, auditDateTo]);

  return (
    <>
      <Button
        css={style.fatFooterButtonStart}
        labelPosition='left'
        icon='backward'
        content={t('brandAuditProposals.proposalDetail.footer.reproposeBrandDatesButton')}
        onClick={() => setConfirmIsOpen(true)}
      />
      <Modal
        open={confirmIsOpen}
        onClose={() => {
          setConfirmIsOpen(false);
        }}
        size='tiny'
        closeOnEscape>
        <Modal.Header>{t('brandAuditProposals.proposalDetail.footer.reproposeBrandDatesHeader')}</Modal.Header>
        <Modal.Content css={style.noPadding}>
          <div css={style.acceptCalendarContainer}>
            <CalendarField
              title={t('auditCampaigns.proposalDetail.auditDateFrom')}
              customIcon='calendar check'
              editable
              defaultValue={dateFrom}
              onChange={value => onDateUpdate(value, dateTo)}
              minDate={today}
              maxDate={maxDate}
            />
            <CalendarField
              title={t('auditCampaigns.proposalDetail.auditDateTo')}
              customIcon='calendar check'
              editable
              defaultValue={dateTo}
              onChange={value => onDateUpdate(dateFrom, value)}
              minDate={minDate}
            />
          </div>
          <div css={style.reproposeBrandDatesWarning}>
            {t('brandAuditProposals.proposalDetail.footer.reproposeBrandDatesWarning')}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            primary
            disabled={!isValidDates}
            onClick={() => {
              onConfirm(dateFrom, dateTo);
              setConfirmIsOpen(false);
            }}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
