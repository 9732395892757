import { css } from '@emotion/react';
import style from '../../../shared/global.style';

const component = css`
  display: flex;
  height: calc(100% - 70px);
  flex-direction: column;
`;

const pageTitle = css`
  ${style.bodyNormal}
  background-color: var(--saa-primary-color-80);
  color: var(--saa-white-color);
  text-decoration: underline;
  text-align: center;
  padding: 10px;
`;

const notFound = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  background-color: var(--saa-white-color);
`;

const aboveFooterMessage = css`
  && {
    ${style.subtitleSmall};
    margin-top: 0;
    margin-bottom: 10px;
    background-color: var(--saa-primary-color-10);
    > span {
      padding: 10px;
      font-size: 0.7rem;
      cursor: pointer;
      > span {
        color: var(--saa-blue-60);
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
`;

export default {
  component,
  pageTitle,
  notFound,
  aboveFooterMessage,
};
