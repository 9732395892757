/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Radio, TextArea } from 'semantic-ui-react';
import style from './priorityField.style';
import { getPriorities, Priority } from '../model/assessmentDetail.model';
import { useCtrlEnterToRunCallback } from '../../../core/utils';

interface PriorityFieldProps {
  defaultValue: { priorityRationale: string; priority: Priority };
  editable?: boolean;
  onChange?: (value: { priorityRationale: string; priority: Priority }) => void;
  isValid?: boolean;
  mutable?: boolean;
  isRationalVisible?: boolean;
}

export const PriorityField = ({
  defaultValue,
  onChange = () => null,
  editable = false,
  isValid = true,
  mutable = false,
  isRationalVisible = true,
}: PriorityFieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [priorityRationale, setPriorityRationale] = React.useState(defaultValue.priorityRationale);
  const [priority, setPriority] = React.useState(defaultValue.priority);
  const { t } = useTranslation();

  useEffect(() => {
    if (mutable) {
      setPriorityRationale(defaultValue.priorityRationale);
      setPriority(defaultValue.priority);
    }
  }, [defaultValue, mutable]);

  const updateValue = () => {
    if (canUpdate()) {
      onChange({ priorityRationale, priority });
      setOpen(false);
    }
  };
  const cancelValue = () => {
    setPriority(defaultValue.priority);
    setPriorityRationale(defaultValue.priorityRationale);
    setOpen(false);
  };

  const onChangePriorityRationale = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPriorityRationale(event.target.value);
  };

  const canUpdate = () =>
    priorityRationale != null && priorityRationale.trim() !== '' && priority != null && priority !== Priority.PASS;

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <div>
      <div css={style.priorityTitle(priority, isValid)} title={t('assessment.checkList.priority') || ''}>
        {t('assessment.checkList.priority')}
        {priority !== Priority.PASS && <Icon className='fas fa-circle' />}
        {priority !== Priority.PASS && <span css={style.priorityLabel}>{t(`assessment.checkList.${priority}`)}</span>}
      </div>

      <div css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <div>
          {' '}
          {isRationalVisible && priorityRationale}{' '}
          {!isValid && <Icon name='exclamation circle' css={style.validationIcon} />}
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => cancelValue()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{t('assessment.checkList.priority')}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div style={{ overflow: 'auto' }}>
            <div css={style.inputContainer}>
              <div css={style.priorityRadioContainer(priority)}>
                {getPriorities().map(p => (
                  <Radio
                    key={p}
                    name='rate'
                    checked={p === priority}
                    onChange={() => setPriority(p)}
                    css={style.priorityRadioButton}
                    label={p}
                  />
                ))}
              </div>
              <TextArea
                value={priorityRationale}
                type='text'
                label={{ icon: 'note' }}
                onChange={event => onChangePriorityRationale(event)}
                placeholder={t('assessment.checkList.priorityRationale')}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={() => cancelValue()}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()} disabled={!canUpdate()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
