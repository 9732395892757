/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  AttachmentRef,
  AuditingResult,
  PictureRef,
  Priority,
  ResultStatus,
  ResultUpdateRequest,
  SubProcessResult,
} from '../model/assessmentDetail.model';
import { SubProcessResultBox } from './SubProcessResultBox';
import style from './checkList.style';
import { STATUS, Status as AssessmentStatus } from '../model/assessmentStatus';
import { FreeTextField } from './FreeTextField';
import { PriorityField } from './PriorityField';
import { AuditNotes } from './AuditNotes';
import {
  saveAuditManagerNoteAction,
  saveRegionalManagerNoteAction,
  selectAssessment,
  selectRegionalManager,
} from '../store/assessmentDetailSlice';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { AUDITOR_STAFF_ROLES, checkRole, checkRoles, ROLES } from '../../auth/model/principal.model';
import { toastService } from '../../../core/services/toastService';
import { AttachmentsListView } from './AttachmentsListView';
import { SubProcessFooter } from './SubProcessFooter';
import { MultiSelectField } from './MultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { setUnsavedChangesPresence } from '../../../core/unsavedChangesSlice';
import { BestCasePractice } from './BestCasePracticeField';
import { PrioritySelectionHelper } from './PrioritySelectionHelper';
import {
  fetchFindingSuggestionsForPriority,
  selectFindingSuggestionsForPriority,
} from '../store/findingSuggestionsSlice';
import { BrandCode } from '../../../shared/model/brand.model';

interface CheckListProps {
  result: AuditingResult | SubProcessResult;
  assessmentStatus: AssessmentStatus;
  processCode?: string;
  subProcessCode?: string;
  subProcessDescription?: string;
  subProcessInfo: string;
  subProcessAvailableKeyBusinessRisks?: string[];
  onSave?: (request: ResultUpdateRequest) => void;
  readonly?: boolean;
  isVisibleResultBox?: boolean;
}

export const CheckList = ({
  result,
  assessmentStatus,
  processCode,
  subProcessCode,
  subProcessInfo,
  onSave = () => null,
  readonly = true,
  isVisibleResultBox = true,
  subProcessAvailableKeyBusinessRisks = [],
  subProcessDescription,
}: CheckListProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(result.status);
  const [observation, setObservation] = useState(result.observation);
  const [finding, setFinding] = useState(result.finding);
  const [recommendation, setRecommendation] = useState(result.recommendation);
  const [priority, setPriority] = useState<Priority | ''>(result.priority);
  const [priorityRationale, setPriorityRationale] = useState(result.priorityRationale);
  const [bestCasePractice, setBestCasePractice] = useState<boolean>(result.bestCasePractice);
  const [bestCasePracticeNote, setBestCasePracticeNote] = useState<string>(result.bestCasePracticeNote);
  const [pictures, setPictures] = useState(result.pictures);
  const [attachments, setAttachments] = useState(result.attachments);
  const [keyBusinessRisks, setKeyBusinessRisks] = useState(result.keyBusinessRisks);
  const [pastExamplesOpen, setPastExamplesOpen] = useState<boolean>(false);
  const user = useSelector(selectPrincipal);
  const assessment = useSelector(selectAssessment);
  const findingSuggestions = useSelector(selectFindingSuggestionsForPriority);

  useEffect(() => {
    if (
      _.isEqual(result.status, status) &&
      _.isEqual(result.observation, observation) &&
      _.isEqual(result.finding, finding) &&
      _.isEqual(result.recommendation, recommendation) &&
      _.isEqual(result.priority, priority) &&
      _.isEqual(result.priorityRationale, priorityRationale) &&
      _.isEqual(result.pictures, pictures) &&
      _.isEqual(result.attachments, attachments) &&
      _.isEqual(result.keyBusinessRisks, keyBusinessRisks)
    ) {
      dispatch(setUnsavedChangesPresence(false));
    } else {
      dispatch(setUnsavedChangesPresence(true));
    }
  }, [
    attachments,
    dispatch,
    finding,
    keyBusinessRisks,
    observation,
    pictures,
    priority,
    priorityRationale,
    recommendation,
    result.attachments,
    result.finding,
    result.keyBusinessRisks,
    result.observation,
    result.pictures,
    result.priority,
    result.priorityRationale,
    result.recommendation,
    result.status,
    status,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const assessmentRegionalManager = useSelector(selectRegionalManager);

  const isAuditor = useCallback(() => checkRoles(user, AUDITOR_STAFF_ROLES), [user]);

  const onChangePriority = useCallback((value: { priorityRationale: string; priority: Priority }) => {
    setPriority(value.priority);
    setPriorityRationale(value.priorityRationale);
  }, []);

  const onChangeBestCasePractice = useCallback((value: { bestPractice: boolean; note: string }) => {
    setBestCasePractice(value.bestPractice);
    setBestCasePracticeNote(value.note);
  }, []);

  const changeStatus = useCallback((newStatus: any) => {
    setStatus(newStatus);
  }, []);

  const canManagerEdit = useCallback(() => {
    return checkRole(user, ROLES.AUDITOR_MANAGER) && STATUS.UNDER_REVIEW.code === assessmentStatus?.code;
  }, [user, assessmentStatus]);

  const showConfirmationModal = useCallback(() => {
    return (
      (priority === Priority.SIGNIFICANT || priority === Priority.HIGH) &&
      status === ResultStatus.FAILED &&
      attachments.length === 0 &&
      pictures.length === 0 &&
      assessmentStatus != null &&
      [STATUS.IN_PROGRESS.code, STATUS.MERGING.code].includes(assessmentStatus.code)
    );
  }, [priority, attachments.length, pictures.length, status, assessmentStatus]);

  const openPastExamples = useCallback(() => {
    setPastExamplesOpen(true);
    dispatch(
      fetchFindingSuggestionsForPriority(
        processCode,
        subProcessCode,
        assessment?.store.brand.code as BrandCode,
        assessment?.store.keringRegion,
        assessment?.store.keringCountry.code
      )
    );
  }, [dispatch, assessment, processCode, subProcessCode]);

  const canRegionalManagerEdit = useCallback(() => {
    return (
      assessmentRegionalManager != null &&
      assessmentRegionalManager.email === user.email &&
      checkRole(user, ROLES.AUDITOR) &&
      STATUS.UNDER_REVIEW_BY_REGIONAL_MANAGER.code === assessmentStatus?.code
    );
  }, [assessmentRegionalManager, user, assessmentStatus?.code]);

  const showManagerNote = useCallback(() => {
    return canManagerEdit() || (result.managerNote !== '' && result.managerNote != null);
  }, [canManagerEdit, result.managerNote]);

  const showRegionalManagerNote = useCallback(() => {
    return canRegionalManagerEdit() || (result.regionalManagerNote !== '' && result.regionalManagerNote != null);
  }, [canRegionalManagerEdit, result.regionalManagerNote]);

  const updateManagerNote = useCallback(
    async (note: string) => {
      const response =
        processCode &&
        subProcessCode &&
        (await dispatch(saveAuditManagerNoteAction({ note, processCode, subProcessCode })));
      if (response != null) toastService.success();
    },
    [dispatch, processCode, subProcessCode]
  );

  const updateRegionalManagerNote = useCallback(
    async (note: string) => {
      const response =
        processCode &&
        subProcessCode &&
        (await dispatch(saveRegionalManagerNoteAction({ note, processCode, subProcessCode })));
      if (response != null) toastService.success();
    },
    [dispatch, processCode, subProcessCode]
  );

  const saveAuditingResult = useCallback(async () => {
    if (processCode && subProcessCode) {
      switch (status) {
        case ResultStatus.TO_DO:
          setFinding('');
          setRecommendation('');
          setPriority('');
          setPriorityRationale('');
          setKeyBusinessRisks([]);
          setPictures([]);
          setAttachments([]);

          onSave({
            processCode,
            subProcessCode,
            status,
            observation,
            finding: '',
            recommendation: '',
            priority: '',
            priorityRationale: '',
            bestCasePractice: false,
            bestCasePracticeNote: '',
            pictures: [],
            attachments: [],
            keyBusinessRisks: [],
          });
          break;
        case ResultStatus.PASSED:
          setFinding('');
          setRecommendation('');
          setPriority(Priority.PASS);
          setPriorityRationale('');
          setKeyBusinessRisks([]);
          onSave({
            processCode,
            subProcessCode,
            status,
            observation,
            finding: '',
            recommendation: '',
            priority: Priority.PASS,
            priorityRationale: '',
            bestCasePractice,
            bestCasePracticeNote,
            pictures,
            attachments,
            keyBusinessRisks: [],
          });
          break;
        default:
          onSave({
            processCode,
            subProcessCode,
            status,
            observation,
            finding,
            recommendation,
            priority,
            priorityRationale,
            bestCasePractice,
            bestCasePracticeNote,
            pictures,
            attachments,
            keyBusinessRisks,
          });
      }
    }
  }, [
    attachments,
    bestCasePractice,
    bestCasePracticeNote,
    finding,
    keyBusinessRisks,
    observation,
    onSave,
    pictures,
    priority,
    priorityRationale,
    processCode,
    recommendation,
    status,
    subProcessCode,
  ]);

  const deletePicture = useCallback((picture: PictureRef) => {
    setPictures(prevState => prevState.filter(p => p.id !== picture.id));
  }, []);

  const deleteAttament = useCallback((attachment: AttachmentRef) => {
    setAttachments(prevState => prevState.filter(p => p.id !== attachment.id));
  }, []);

  const onClickFindingSuggestion = useCallback((p: Priority, f: string, r: string) => {
    setPriority(p);
    setFinding(f);
    setRecommendation(r);
    setPastExamplesOpen(false);
  }, []);

  return (
    <div css={style.container}>
      {isVisibleResultBox && (
        <SubProcessResultBox readonly={readonly} status={status} onChange={newStatus => changeStatus(newStatus)} />
      )}
      <div css={style.content}>
        {showManagerNote() && (
          <div css={style.auditNoteContainer(isVisibleResultBox)}>
            <AuditNotes
              defaultValue={result.managerNote}
              title={t('assessment.checkList.managerNote')}
              editable={canManagerEdit()}
              onEdited={note => updateManagerNote(note)}
            />
          </div>
        )}
        {showRegionalManagerNote() && (
          <div css={style.auditNoteContainer(isVisibleResultBox)}>
            <AuditNotes
              defaultValue={result.regionalManagerNote}
              title={t('assessment.checkList.regionalManagerNote')}
              editable={canRegionalManagerEdit()}
              onEdited={note => updateRegionalManagerNote(note)}
              type='regionalManagerNote'
            />
          </div>
        )}
        {isAuditor() && (
          <div css={style.observationContainer}>
            <FreeTextField
              title='Observation'
              defaultValue={observation}
              editable={!readonly}
              onChange={value => setObservation(value)}
            />
          </div>
        )}
        <div css={style.freeTextContainerFailed(status)}>
          <FreeTextField
            title='Finding'
            mutable
            defaultValue={status === ResultStatus.FAILED ? finding : ''}
            editable={status === ResultStatus.FAILED && (!readonly || canManagerEdit() || canRegionalManagerEdit())}
            onChange={value => setFinding(value)}
          />
        </div>
        <div css={style.freeTextContainerFailed(status)}>
          <FreeTextField
            title='Recommendation'
            mutable
            defaultValue={status === ResultStatus.FAILED ? recommendation : ''}
            editable={status === ResultStatus.FAILED && (!readonly || canManagerEdit() || canRegionalManagerEdit())}
            onChange={value => setRecommendation(value)}
          />
        </div>
        <div css={style.freeTextContainerFailed(status)}>
          <MultiSelectField
            title='Key business risks'
            defaultValue={
              status === ResultStatus.FAILED
                ? subProcessAvailableKeyBusinessRisks.length === 1
                  ? [...subProcessAvailableKeyBusinessRisks]
                  : keyBusinessRisks
                : []
            }
            editable={status === ResultStatus.FAILED && !readonly && subProcessAvailableKeyBusinessRisks.length > 1}
            onChange={value => setKeyBusinessRisks(value)}
            options={subProcessAvailableKeyBusinessRisks}
          />
        </div>
        <div css={style.freeTextContainerFailed(status)}>
          <PriorityField
            mutable
            defaultValue={
              status === ResultStatus.FAILED
                ? { priorityRationale, priority: priority as Priority }
                : { priorityRationale: '', priority: Priority.PASS }
            }
            editable={status === ResultStatus.FAILED && !readonly}
            onChange={onChangePriority}
            isRationalVisible={isAuditor()}
          />
        </div>
        {pastExamplesOpen && (
          <PrioritySelectionHelper
            subProcessCode={subProcessCode}
            subProcessDescription={subProcessDescription}
            setPastExamplesOpen={setPastExamplesOpen}
            findingSuggestions={findingSuggestions}
            onClickFindingSuggestion={onClickFindingSuggestion}
          />
        )}
        {isAuditor() && (
          <div css={style.freeTextContainerPassed(status)}>
            <BestCasePractice
              defaultValue={
                status === ResultStatus.PASSED
                  ? { bestPractice: bestCasePractice, note: bestCasePracticeNote }
                  : { bestPractice: false, note: '' }
              }
              editable={status === ResultStatus.PASSED && (!readonly || canManagerEdit() || canRegionalManagerEdit())}
              onChange={onChangeBestCasePractice}
              isNoteVisible={isAuditor()}
            />
          </div>
        )}
        {isAuditor() && (
          <AttachmentsListView
            pictures={status === ResultStatus.FAILED || status === ResultStatus.PASSED ? pictures : []}
            attachments={status === ResultStatus.FAILED || status === ResultStatus.PASSED ? attachments : []}
            onAddAttachments={attachs => setAttachments(prevState => prevState.concat(attachs))}
            onDeleteAttachment={deleteAttament}
            onDeletePicture={deletePicture}
            onAddPictures={pics => setPictures(prevState => prevState.concat(pics))}
            editable={(status === ResultStatus.FAILED || status === ResultStatus.PASSED) && !readonly}
          />
        )}
      </div>
      <SubProcessFooter
        showPastExamplesButton={status === ResultStatus.FAILED && !readonly}
        onClickPastExamplesButton={openPastExamples}
        onSave={() => saveAuditingResult()}
        canSave={!readonly || canManagerEdit() || canRegionalManagerEdit()}
        info={subProcessInfo}
        confirmationEnable={showConfirmationModal()}
        confirmationText={t('assessment.checkList.saveConfirmMessage') || undefined}
      />
    </div>
  );
};
