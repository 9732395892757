/** @jsxImportSource @emotion/react */
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import style from './detailFooter.style';
import { RescheduleAuditModalContent } from './RescheduleAuditModalContent';
import { RescheduleBrandModalContent } from './RescheduleBrandModalContent';
import { ProposedPeriod } from '../../../model/genericAuditProposal';

interface RescheduleButtonProps {
  buttonLabel: string;
  onBrandConfirm: (proposedPeriods: ProposedPeriod[], note: string, isRescheduled: boolean) => void;
  onAuditConfirm: (from: string, to: string, note: string, isRescheduled: boolean) => void;
  canReject: boolean;
  confirmHeader?: string;
  defaultDateFrom?: string;
  defaultDateTo?: string;
  isBrandContent?: boolean;
}

export function RescheduleButton({
  buttonLabel,
  onBrandConfirm,
  onAuditConfirm,
  canReject,
  confirmHeader = t('auditCampaigns.proposalDetail.footer.rescheduledHeader') || '',
  defaultDateFrom = '',
  defaultDateTo = '',
  isBrandContent = false,
}: RescheduleButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [proposedPeriodsState, setProposedPeriodsState] = useState<ProposedPeriod[]>([]);
  const [note, setNote] = useState('');
  const [isRescheduled, setIsRescheduled] = useState(true);

  useEffect(() => {
    if (isRescheduled) {
      setIsConfirmDisabled(note === '' || (proposedPeriodsState.length === 0 && (dateFrom === '' || dateTo === '')));
    } else {
      setIsConfirmDisabled(note === '');
    }
  }, [note, isRescheduled, defaultDateFrom, defaultDateTo, proposedPeriodsState.length, dateFrom, dateTo]);

  const onBrandContentChange = (
    proposedPeriods: ProposedPeriod[],
    contentNote: string,
    contentIsReschedule: boolean
  ) => {
    setProposedPeriodsState(proposedPeriods);
    setNote(contentNote);
    setIsRescheduled(contentIsReschedule);
  };

  const onAuditContentChange = (from: string, to: string, contentNote: string, contentIsReschedule: boolean) => {
    setDateFrom(from);
    setDateTo(to);
    setNote(contentNote);
    setIsRescheduled(contentIsReschedule);
  };

  return (
    <>
      <Button
        css={style.fatFooterButtonStart}
        labelPosition='left'
        icon='backward'
        content={buttonLabel}
        onClick={() => setConfirmIsOpen(true)}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        header={confirmHeader}
        onCancel={() => setConfirmIsOpen(false)}
        content={
          isBrandContent ? (
            <RescheduleBrandModalContent onChange={onBrandContentChange} canReject={canReject} />
          ) : (
            <RescheduleAuditModalContent onChange={onAuditContentChange} canReject={canReject} />
          )
        }
        onConfirm={() => {
          setConfirmIsOpen(false);
          if (isBrandContent) {
            onBrandConfirm(proposedPeriodsState, note, isRescheduled);
          } else {
            onAuditConfirm(dateFrom, dateTo, note, isRescheduled);
          }
        }}
        confirmButton={
          <Button disabled={isConfirmDisabled} css={style.confirmButton}>
            Confirm
          </Button>
        }
        size='mini'
      />
    </>
  );
}
