import { saveAs } from 'file-saver';
import ExcelJS, { Workbook, Worksheet } from 'exceljs';
import moment from 'moment';

export interface XLSXSheetParams {
  columnNames: string[];
  rowsData: string[][];
  sheetName: string;
  header?: string;
}

export default class XLSXUtils {
  static adjustColumnWidth(worksheet: Worksheet) {
    worksheet.columns.forEach(column => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
      // eslint-disable-next-line no-param-reassign
      column.width = Math.min(...[maxLength, 100]);
    });
  }

  static wrapColumnsText(worksheet: Worksheet) {
    worksheet.columns.forEach(column => {
      // eslint-disable-next-line no-param-reassign
      column.alignment = { vertical: 'bottom', horizontal: 'left', wrapText: true };
    });
  }

  static downloadXls(workbook: Workbook, fileName: string) {
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, XLSXUtils.getXLSFileName(fileName));
    });
  }

  static createXlsSingleSheet(columnNames: string[], rowsData: string[][], sheetName: string, header?: string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    if (header != null) {
      // merge header for avoid problem with width
      worksheet.getCell('A1').value = `${header}`;
      worksheet.getRow(3).values = columnNames;
      worksheet.columns = columnNames.map(cn => ({ key: cn }));
      worksheet.addRows(rowsData);
    } else {
      worksheet.columns = columnNames.map(cn => ({ header: cn, key: cn }));
      worksheet.addRows(rowsData);
    }
    return { workbook, worksheet };
  }

  static createXlsMultiSheet(sheets: XLSXSheetParams[]) {
    const workbook = new ExcelJS.Workbook();
    sheets.forEach(sheet => {
      const worksheet = workbook.addWorksheet(sheet.sheetName);
      if (sheet.header != null) {
        // merge header for avoid problem with width
        worksheet.getCell('A1').value = `${sheet.header}`;
        worksheet.getRow(3).values = sheet.columnNames;
        worksheet.columns = sheet.columnNames.map(cn => ({ key: cn }));
        worksheet.addRows(sheet.rowsData);
      } else {
        worksheet.columns = sheet.columnNames.map(cn => ({ header: cn, key: cn }));
        worksheet.addRows(sheet.rowsData);
      }
    });

    return { workbook };
  }

  static getXLSFileName = (fileName: string): string => `${fileName}_${moment().format('YYYY-MM-DD')}.xlsx`;
}
