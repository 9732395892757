import { css } from '@emotion/react';
import style from './global.style';

const container = css`
  background-color: var(--saa-primary-color-10);
  height: calc(100% - 60px);
`;

const header = css`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: var(--saa-primary-color);
  align-items: center;
`;

const title = css`
  && {
    ${style.subtitleNormal}
    margin: 0;
    color: var(--saa-white-color);
    flex-grow: 1;
  }
`;

const icon = css`
  && {
    justify-self: flex-end;
    margin-right: 10px;
    margin-left: auto;
    flex-shrink: 0;
    font-size: 1.714rem;
    cursor: pointer;
    :hover {
      color: var(--saa-primary-color-20);
    }
  }
`;

const dimmer = css`
  && {
    padding: 0;
    .content {
      width: 100%;
      height: 100%;
      max-width: 800px;
      max-height: 1200px;
      @media (min-width: 480px) {
        max-height: calc(100% - 50px);
      }
    }
  }
`;

const modalConfirm = css`
  ${style.modalConfirm}
`;

const confirmButton = css`
  ${style.confirmButton}
`;

export default {
  container,
  header,
  dimmer,
  icon,
  title,
  confirmButton,
  modalConfirm,
};
