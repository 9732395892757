import { css } from '@emotion/react';

const rowMultipleField = css`
  position: relative;
  display: flex;
  overflow: hidden;
  > div {
    flex: 1;
  }
  align-items: center;
`;

const acceptIcon = css`
  &&& {
    position: absolute;
    border-radius: 25px;
    padding: 0;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    font-size: 0.857rem;
    background-color: var(--saa-primary-color-10);
    && i {
      margin: 0;
      color: var(--saa-primary-color);
      height: auto;
    }
  }
`;

const modalConfirm = css`
  && {
    max-width: 75%;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    > div {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;

const confirmButton = css`
  &&&& {
    background-color: var(--saa-primary-color);
  }
`;

export default {
  rowMultipleField,
  acceptIcon,
  modalConfirm,
  confirmButton,
};
