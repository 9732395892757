export interface Country {
  code: string;
  description: string;
}

export const toCountry = (o: Record<string, string>): Country => ({
  code: o.code,
  description: o.description,
});

export const emptyCountry = (): Country => ({
  code: '',
  description: '',
});
