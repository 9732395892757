/** @jsxImportSource @emotion/react */
import { Checkbox, Table } from 'semantic-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './assessmentBulkUpdateTableHeaderRow.style';

interface CardTableHeaderRowProps {
  sortColumn?: string;
  direction?: 'ascending' | 'descending';
  handleSort?(column: string): void;
  checked: boolean;
  onCheckboxClick: () => void;
}
export const AssessmentBulkUpdateTableHeaderRow: React.FC<CardTableHeaderRowProps> = ({
  sortColumn,
  direction,
  handleSort,
  checked,
  onCheckboxClick,
}) => {
  const { t } = useTranslation();
  const getSortingDirection = (column: string): 'ascending' | 'descending' | undefined =>
    sortColumn === column ? direction : undefined;

  return (
    <Table.Header css={style.header}>
      <Table.Row>
        <Table.HeaderCell width={2} singleLine>
          <Checkbox checked={checked} onClick={onCheckboxClick} />
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('status')}
          onClick={() => handleSort && handleSort('status')}>
          {t('assessment.bulkUpdate.columns.status')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('brand')}
          onClick={() => handleSort && handleSort('brand')}>
          {t('assessment.bulkUpdate.columns.brand')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('region')}
          onClick={() => handleSort && handleSort('region')}>
          {t('assessment.bulkUpdate.columns.region')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('country')}
          onClick={() => handleSort && handleSort('country')}>
          {t('assessment.bulkUpdate.columns.country')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('city')}
          onClick={() => handleSort && handleSort('city')}>
          {t('assessment.bulkUpdate.columns.city')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('hfmCode')}
          onClick={() => handleSort && handleSort('hfmCode')}>
          {t('assessment.bulkUpdate.columns.hfmCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('jdaCode')}
          onClick={() => handleSort && handleSort('jdaCode')}>
          {t('assessment.bulkUpdate.columns.jdaCode')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('locationName')}
          onClick={() => handleSort && handleSort('locationName')}>
          {t('assessment.bulkUpdate.columns.locationName')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('year')}
          onClick={() => handleSort && handleSort('year')}>
          {t('assessment.bulkUpdate.columns.year')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('month')}
          onClick={() => handleSort && handleSort('month')}>
          {t('assessment.bulkUpdate.columns.month')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('followUpStatus')}
          onClick={() => handleSort && handleSort('followUpStatus')}>
          {t('assessment.bulkUpdate.columns.followUpStatus')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('auditors')}
          onClick={() => handleSort && handleSort('auditors')}>
          {t('assessment.bulkUpdate.columns.auditors')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('actionPlanCoordinators')}
          onClick={() => handleSort && handleSort('actionPlanCoordinators')}>
          {t('assessment.bulkUpdate.columns.actionPlanCoordinators')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('distributionList')}
          onClick={() => handleSort && handleSort('distributionList')}>
          {t('assessment.bulkUpdate.columns.distributionList')}
        </Table.HeaderCell>
        <Table.HeaderCell
          width={2}
          singleLine
          sorted={getSortingDirection('readOnlyDistributionList')}
          onClick={() => handleSort && handleSort('readOnlyDistributionList')}>
          {t('assessment.bulkUpdate.columns.readOnlyDistributionList')}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
