import { Brand } from '../../../shared/model/brand.model';

export interface AssessmentBulkUpdateQueryParams {
  assessmentStatuses: string[];
  brands: string[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  auditors: string[];
  actionPlanCoordinators: string[];
  distributionListMembers: string[];
  readOnlyDistributionListMembers: string[];
}

export interface AssessmentBulkUpdateFilters {
  assessmentStatuses: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  jdaCodes: string[];
  followUpStatuses: string[];
  auditors: string[];
  actionPlanCoordinators: string[];
  distributionListMembers: string[];
  readOnlyDistributionListMembers: string[];
}

export const emptyAssessmentBulkUpdateFilters: AssessmentBulkUpdateFilters = {
  assessmentStatuses: [],
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  jdaCodes: [],
  followUpStatuses: [],
  auditors: [],
  actionPlanCoordinators: [],
  distributionListMembers: [],
  readOnlyDistributionListMembers: [],
};

export const covertFiltersInQueryParams = (filters: AssessmentBulkUpdateFilters): AssessmentBulkUpdateQueryParams => ({
  ...filters,
  brands: filters.brands.map(b => b.code),
});
