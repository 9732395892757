/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { Region } from '../../../shared/model/region.model';

interface RegionMultiSelectFieldProps {
  editable: boolean;
  regions: Region[];
  defaultValue?: string[];
  onUpdate?: (region: string[]) => void;
}

export function RegionMultiSelectField({
  defaultValue = [],
  onUpdate,
  regions,
  editable,
}: RegionMultiSelectFieldProps) {
  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: item,
    };
  };

  return (
    <ModalMultiSelectField
      editable={editable}
      icon='globe'
      defaultValue={defaultValue != null ? defaultValue.map(r => toSelectItem(r)) : []}
      list={regions.map((r): SelectItem => toSelectItem(r.description) as SelectItem)}
      title={t('users.regions') || ''}
      onUpdateValue={(selectedRegion: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedRegion.map(i => i.code));
        }
      }}
    />
  );
}
