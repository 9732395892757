import { css } from '@emotion/react';

const checkBoxContainer = css`
  && {
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--saa-primary-color-10);
    padding: 10px 0 10px 10px;

    button {
      margin-left: auto;
      margin-right: 0;
      border-radius: 20px;
      background-color: var(--saa-white-color);
    }
  }
`;

const inputContainer = css`
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  width: 100%;
  &&&& {
    > div {
      flex: 1;
    }
    input {
      border-color: var(--saa-primary-color-10);
    }
    button {
      flex-shrink: 1;
      margin-left: 5px;
    }
  }
`;

const resultContainer = css`
  && {
    height: 190px;
    padding-right: 20px;
    padding-left: 20px;
    overflow: auto;
    margin-top: 10px;
  }
`;

const distributionListMemberInfo = css`
  padding-left: 5px;
`;

const distributionListMemberName = css`
  font-size: 0.8rem;
`;

export default {
  inputContainer,
  resultContainer,
  checkBoxContainer,
  distributionListMemberInfo,
  distributionListMemberName,
};
