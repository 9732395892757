/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { css } from '@emotion/react';
import './toast.css';

const SuccessIcon = (): JSX.Element => {
  return <Icon name='check' css={css`&&&{margin: 0; font-size: 1.714rem; color: var(--saa-white-color);}`} />;
};

const commonOptions: ToastOptions = { icon: false, theme: 'colored', autoClose: 2000 };

export const toastService = {
  success: (): React.ReactText =>
    toast.success(SuccessIcon, {
      className: 'success-toast',
      bodyClassName: 'success-toast-body',
      icon: false,
      autoClose: 2000,
    }),
  warning: (msg: string | ReactNode, options?: ToastOptions): React.ReactText =>
    toast.warning(msg, { ...commonOptions, ...options }),
  error: (msg: string | ReactNode, options?: ToastOptions): React.ReactText =>
    toast.error(msg, { ...commonOptions, ...options }),
  info: (msg: string | ReactNode, options?: ToastOptions): React.ReactText =>
    toast.info(msg, { ...commonOptions, ...options }),
  default: (msg: string | ReactNode, options?: ToastOptions): React.ReactText => toast(msg, options),
  dark: (msg: string | ReactNode, options?: ToastOptions): React.ReactText => toast.dark(msg, options),
};
