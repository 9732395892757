/** @jsxImportSource @emotion/react */
import React from 'react';
import { t } from 'i18next';
import { SelectItem } from '../../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../../shared/ModalMultiSelectField';

interface AssessmentStatusMultiSelectFieldProps {
  options: string[];
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (auditor: string[]) => void;
}

export const AssessmentStatusMultiSelectField = ({
  options,
  defaultValue = [],
  editable = false,
  onUpdate,
}: AssessmentStatusMultiSelectFieldProps) => {
  const toSelectItem = (item: string): SelectItem => {
    return {
      code: item,
      description: t(`assessment.status.${item}`),
    };
  };

  const toAuditor = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='exchange'
      defaultValue={defaultValue?.map(toSelectItem) || []}
      list={options.map(toSelectItem)}
      title={t('assessment.detail.assessmentStatus') || ''}
      editable={editable}
      onUpdateValue={(selectedAuditor: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedAuditor.map(toAuditor));
        }
      }}
    />
  );
}
