/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './header.style';
import { MainMenu } from '../core/components/MainMenu';

interface HeaderPros {
  title: string;
}

export const Header = ({ title }: HeaderPros): JSX.Element => {
  return (
    <div css={style.container}>
      <div css={style.menuButtonContainer}>
        <MainMenu />
      </div>
      <h5 css={style.title}>{title}</h5>
    </div>
  );
};
