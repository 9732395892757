import { css } from '@emotion/react';

const changesHistoryContainer = css`
  padding: 15px;
  overflow: auto;
`;

const changeDetailContainer = css`
  display: flex;
  flex-direction: row;
  background-color: var(--saa-white-color);
  padding: 10px 18px;
  border-bottom: solid 2px var(--saa-primary-color-10);
  align-items: center;
  position: relative;
  > i.icon {
    margin: 0;
  }
`;

const dotIconContainer = css`
  display: flex;
  padding-top: 6px;
`;

const bold = css`
  font-weight: 600;
`;

const authorTime = css`
  font-size: 0.9rem;
`;

const changeDetailText = css`
  margin-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default {
  changesHistoryContainer,
  changeDetailContainer,
  dotIconContainer,
  changeDetailText,
  bold,
  authorTime,
};
