/** @jsxImportSource @emotion/react */
import { t } from 'i18next';
import React, { useState } from 'react';
import { Button, Confirm, Form, TextArea } from 'semantic-ui-react';
import style from './detailFooter.style';
import modalStyle from './reproposeModalContent.style';
import { ModalAlert } from '../../../../../shared/ModalAlert';

interface ReproposeBrandProposalButtonProps {
  buttonLabel: string;
  onConfirm: (note: string) => void;
  confirmHeader?: string;
  showErrorAlert?: boolean;
  errorAlertMessage?: string;
}

export function ReproposeBrandProposalButton({
  buttonLabel,
  onConfirm,
  confirmHeader = t('brandAuditProposals.proposalDetail.footer.reproposedHeader') || '',
  showErrorAlert = false,
  errorAlertMessage = '',
}: ReproposeBrandProposalButtonProps): JSX.Element {
  const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [note, setNote] = useState('');

  const onContentChange = (contentNote: string) => {
    setNote(contentNote);
    setIsConfirmDisabled(contentNote.trim() === '');
  };

  const onButtonClick = () => {
    if (showErrorAlert === true) {
      setAlertIsOpen(true);
    } else {
      setConfirmIsOpen(true);
    }
  };

  return (
    <>
      <Button
        css={style.fatFooterButtonStart}
        labelPosition='left'
        icon='backward'
        content={buttonLabel}
        onClick={onButtonClick}
      />
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        header={confirmHeader}
        onCancel={() => setConfirmIsOpen(false)}
        content={<ModalContent onChange={onContentChange} />}
        onConfirm={() => {
          setConfirmIsOpen(false);
          onConfirm(note);
        }}
        confirmButton={
          <Button disabled={isConfirmDisabled} css={style.confirmButton}>
            Confirm
          </Button>
        }
        size='small'
      />
      <ModalAlert open={alertIsOpen} onClose={() => setAlertIsOpen(false)} message={errorAlertMessage} />
    </>
  );
}

interface RejectModalContentProps {
  onChange: (note: string) => void;
}

const ModalContent = ({ onChange }: RejectModalContentProps): JSX.Element => {
  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
      <Form css={modalStyle.fieldContent}>
        <h4>{t('auditCampaigns.proposalDetail.footer.reproposeNote')}</h4>
        <TextArea
          placeholder={t('auditCampaigns.proposalDetail.footer.reproposeNotePlaceHolder')}
          onChange={event => {
            onChangeNote(event);
          }}
        />
      </Form>
    </div>
  );
};
