/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { fetchStores, selectIsFetching, selectStores } from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';
import { Store } from '../../../shared/model/store.model';

interface StoreMultiSelectFieldProps {
  filters: AssessmentBulkUpdateFilters;
  defaultValue?: string[];
  editable?: boolean;
  onUpdate?: (value: string[]) => void;
}

export function StoreMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: StoreMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchStores(filters));
    }
  }, [dispatch, editable, filters]);
  const { t } = useTranslation();

  const storeList = useSelector(selectStores);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Store | undefined): SelectItem => {
    if (item == null) {
      return { code: '', description: '' };
    }

    return {
      code: item.jdaCode,
      description: `${item.jdaCode} - ${item.name}`,
    };
  };

  const toString = (item: SelectItem): string => {
    return item.code;
  };

  return (
    <ModalMultiSelectField
      icon='shopping bag'
      defaultValue={
        defaultValue != null ? defaultValue.map(v => toSelectItem(storeList.find((s: any) => s.jdaCode === v))) : []
      }
      loading={isFetching}
      list={storeList.map((b: any): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.bulkUpdate.filters.jdaCodes') || ''}
      editable={editable}
      onUpdateValue={(selectedStore: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedStore.map(i => toString(i)));
        }
      }}
    />
  );
}
