import { toStore } from '../../../shared/model/store.model';
import { Contact, toContact } from '../../users/model/user.model';
import { toProposalNote } from './auditProposal';
import { checkRole, checkRoles, Principal, ROLES } from '../../auth/model/principal.model';
import { AssessmentCreationFromProposalRequest, toAssessment, toAuditor } from '../../assessments/model/assessment';
import { BRAND_AUDIT_PROPOSAL_STATUS, GenericAuditProposal, ProposedPeriod } from './genericAuditProposal';
import { getUTCDateString, getDateTimeString } from '../../../core/utils';

export interface BrandAuditProposal extends GenericAuditProposal {
  status: BRAND_AUDIT_PROPOSAL_STATUS;
  type: 'brand';
}

export interface BrandAuditProposalRequest {
  storeJdaCode: string;
  proposedPeriods: ProposedPeriod[];
  distributionList: string[];
}

export interface UpdateBrandProposalBrandSchedulersRequest {
  brandSchedulers: string[];
}

export interface UpdateBrandProposalDistributionListRequest {
  distributionList: string[];
}

export interface UpdateBrandProposalAuditorsRequest {
  auditors: string[];
}
export interface UpdateBrandProposalProposedByBrandDateRequest {
  proposedPeriods: ProposedPeriod[];
}

export interface UpdateBrandProposalDateRequest {
  proposedDateFrom: Date;
  proposedDateTo: Date;
}

export interface BrandProposalChangeStatusRequest {
  status: BRAND_AUDIT_PROPOSAL_STATUS;
  note?: string;
  dateFrom?: string;
  dateTo?: string;
  proposedPeriods?: ProposedPeriod[];
  assessment?: AssessmentCreationFromProposalRequest;
}

export const toBrandAuditProposal = (o: Record<string, unknown>): BrandAuditProposal => {
  return {
    id: o.id as string,
    store: toStore(o.store as Record<string, unknown>),
    proposedDateFrom: o.proposedDateFrom != null ? getUTCDateString(o.proposedDateFrom as string) : '',
    proposedDateTo: o.proposedDateTo != null ? getUTCDateString(o.proposedDateTo as string) : '',
    proposedByBrandDateFrom:
      o.proposedByBrandDateFrom != null ? getUTCDateString(o.proposedByBrandDateFrom as string) : '',
    proposedByBrandDateTo: o.proposedByBrandDateTo != null ? getUTCDateString(o.proposedByBrandDateTo as string) : '',
    proposedPeriods: o.proposedPeriods as ProposedPeriod[],
    brandSchedulers: (o.brandSchedulers as Record<string, unknown>[]).map(toContact),
    distributionList: (o.distributionList as Record<string, unknown>[]).map(toContact),
    auditors: (o.auditors as Record<string, unknown>[]).map(toAuditor),
    status: o.status as BRAND_AUDIT_PROPOSAL_STATUS,
    createdBy: toContact(o.createdBy as Record<string, unknown>),
    createdAt: getDateTimeString(o.createdAt as string),
    note: o.note ? toProposalNote(o.note as Record<string, unknown>) : undefined,
    auditorNote: o.auditorNote ? toProposalNote(o.auditorNote as Record<string, unknown>) : undefined,
    brandNote: o.brandNote ? toProposalNote(o.brandNote as Record<string, unknown>) : undefined,
    assessment: o.assessment ? toAssessment(o.assessment as Record<string, unknown>) : undefined,
    type: 'brand',
  };
};

export const isBrandProposalEditable = (principal: Principal, proposal: BrandAuditProposal): boolean => {
  switch (proposal.status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
      return isConfiguredBrandScheduler(principal, proposal) || checkRole(principal, ROLES.AUDIT_ADMIN);
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
      return checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    default:
      return false;
  }
};

export const isBrandProposalDeletable = (principal: Principal, proposal: BrandAuditProposal): boolean => {
  switch (proposal.status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
    case BRAND_AUDIT_PROPOSAL_STATUS.CLOSED:
      return checkRole(principal, ROLES.AUDIT_ADMIN);
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
      return checkRoles(principal, [ROLES.AUDIT_ADMIN, ROLES.AUDITOR_MANAGER]);
    default:
      return false;
  }
};

export const isConfiguredAuditor = (principal: Principal, proposal: GenericAuditProposal): boolean => {
  return (
    checkRole(principal, ROLES.AUDITOR) &&
    proposal.auditors.some((auditor: Contact) => auditor.email === principal.email)
  );
};

export const isAuthor = (principal: Principal, proposal: GenericAuditProposal): boolean => {
  return principal.email === proposal.createdBy.email;
};

export const isConfiguredBrandScheduler = (principal: Principal, proposal: GenericAuditProposal): boolean => {
  return (
    checkRole(principal, ROLES.BRAND_SCHEDULER) && proposal.brandSchedulers.some(bs => bs.email === principal.email)
  );
};

export const checkStatus = (proposal: BrandAuditProposal, statuses: BRAND_AUDIT_PROPOSAL_STATUS[]): boolean => {
  return statuses.some(st => proposal.status === st);
};

export const canChangeBrandProposalStatus = (principal: Principal, brandProposal: BrandAuditProposal): boolean => {
  switch (brandProposal.status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return checkRole(principal, ROLES.AUDIT_SCHEDULER);
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return checkRole(principal, ROLES.AUDIT_SCHEDULER) || isConfiguredBrandScheduler(principal, brandProposal);
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
      return isConfiguredBrandScheduler(principal, brandProposal);
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
      return checkRole(principal, ROLES.AUDITOR_MANAGER);
    default:
      return false;
  }
};

export const canEditAuditDates = (principal: Principal, proposal: BrandAuditProposal): boolean => {
  switch (proposal.status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return checkRoles(principal, [ROLES.AUDIT_ADMIN, ROLES.AUDIT_SCHEDULER]);
    default:
      return false;
  }
};
export const canEditBrandSchedulerOrAuditors = (principal: Principal, proposal: BrandAuditProposal): boolean => {
  switch (proposal.status) {
    case BRAND_AUDIT_PROPOSAL_STATUS.PROPOSED:
    case BRAND_AUDIT_PROPOSAL_STATUS.REJECTED_BY_AUDITOR_MANAGER:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.TO_BE_APPROVED:
      return checkRoles(principal, [ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.SCHEDULED:
      return checkRoles(principal, [ROLES.AUDIT_SCHEDULER, ROLES.AUDITOR_MANAGER, ROLES.AUDIT_ADMIN]);
    case BRAND_AUDIT_PROPOSAL_STATUS.REPROPOSED_BY_AUDITOR_SCHEDULER:
      return checkRole(principal, ROLES.AUDIT_ADMIN);
    default:
      return false;
  }
};
